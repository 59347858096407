import {
  GET_CHANELLS_START,
  GET_CHANELLS_ERROR,
  GET_CHANELLS_SUCCESS,
  LEAVE_CHANNEL_START,
  LEAVE_CHANNEL_SUCCESS,
  LEAVE_CHANNEL_ERROR,
  GET_CHANNEL_INFO_START,
  GET_CHANNEL_INFO_SUCCESS,
  GET_CHANNEL_INFO_ERROR,
  GET_CHANNEL_USERS_START,
  GET_CHANNEL_USERS_SUCCESS,
  GET_CHANNEL_USERS_ERROR,
  GET_ORGANIZATION_STREAMS_SUCCESS,
  GET_ORGANIZATION_STREAMS_ERROR,
  GET_ORGANIZATION_STREAMS_START,
  JOIN_CHANNEL_START,
  JOIN_CHANNEL_SUCCESS,
  JOIN_CHANNEL_ERROR,
  GET_CHANNEL_STREAMS_START,
  GET_CHANNEL_STREAMS_SUCCESS,
  GET_CHANNEL_STREAMS_ERROR,
  REMOVE_CHANNEL_START,
  REMOVE_CHANNEL_SUCCESS,
  REMOVE_CHANNEL_ERROR,
  ADD_STREAM_START,
  ADD_STREAM_SUCCESS,
  ADD_STREAM_ERROR,
  DELETE_STREAM_START,
  DELETE_STREAM_SUCCESS,
  DELETE_STREAM_ERROR
} from '../../../../../constants/ActionTypes';

const INIT_STATE = {
  get_channels_start: false,
  create_channel_start: false,
  create_channel_success: false,
  create_channel_error: false,
  getChannelError: '',
  subscribedChannels: [],
  unsubscribedChannels: [],
  createChannelError: '',
  leave_channel_success: false,
  leave_channel_start: false,
  leave_channel_id: '',
  channelInfo: {},
  get_channel_info_start: false,
  get_channel_users_start: false,
  channelUsers: [],
  get_streams_start: false,
  streams: [],
  join_channel_id: '',
  join_channel_start: false,
  join_channel_success: false,
  get_channel_streams_start: false,
  channelStreams: [],
  remove_channel_success: false,
  remove_channel_start: false,
  add_stream_start: false,
  add_stream_success: false,
  delete_stream_start: false,
  delete_stream_success: false,
  channel_update_success: false,
  channel_update_start: false,
  get_channels_success: false,
  get_channels_error: false,
  leave_channel_error: false,
  add_and_get_stream_loading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHANELLS_START: {
      return {
        ...state,
        get_channels_start: true,
        get_channels_success: false,
        get_channels_error: false,
        leaveChannelSucces: false,
        create_channel_success: false,
        remove_channel_success: false,
        leaveChannelId: '',
        joinChannelSucces: false,
        removeChannelSuccess: false,
        createChannelSuccess: false,
        getChannelsLoader: true
      };
    }
    case GET_CHANELLS_SUCCESS: {
      return {
        ...state,
        get_channels_start: false,
        get_channels_success: true,
        get_channels_error: false,
        subscribedChannels: action.payload.subscribed,
        unsubscribedChannels: action.payload.unsubscribed
      };
    }
    case GET_CHANELLS_ERROR: {
      return {
        ...state,
        get_channels_start: false,
        get_channels_success: false,
        get_channels_error: true,
        channelList: []
      };
    }
    case LEAVE_CHANNEL_START: {
      return {
        ...state,
        leave_channel_id: action.payload,
        leave_channel_start: true,
        leave_channel_success: false,
        leave_channel_error: false
      };
    }
    case LEAVE_CHANNEL_SUCCESS: {
      return {
        ...state,
        leave_channel_success: true,
        leave_channel_start: false,
        leave_channel_error: false
      };
    }
    case LEAVE_CHANNEL_ERROR: {
      return {
        ...state,
        leave_channel_success: false,
        leave_channel_start: false,
        leave_channel_error: true
      };
    }

    case JOIN_CHANNEL_START: {
      return {
        ...state,
        join_channel_id: action.payload,
        join_channel_start: true,
        join_channel_success: false,
        join_channel_error: false
      };
    }
    case JOIN_CHANNEL_SUCCESS: {
      return {
        ...state,
        join_channel_success: true,
        join_channel_start: false,
        join_channel_error: false
      };
    }
    case JOIN_CHANNEL_ERROR: {
      return {
        ...state,
        join_channel_start: false,
        join_channel_start: false,
        join_channel_error: true
      };
    }
    case GET_CHANNEL_INFO_START: {
      return {
        ...state,
        channel_update_success: false,
        get_channel_info_start: true,
        get_channel_info_success: false,
        get_channel_info_error: false
      };
    }
    case GET_CHANNEL_INFO_SUCCESS: {
      return {
        ...state,
        channelInfo: action.payload,
        get_channel_info_start: false,
        get_channel_info_success: true,
        get_channel_info_error: false
      };
    }
    case GET_CHANNEL_INFO_ERROR: {
      return {
        ...state,
        get_channel_info_start: false,
        get_channel_info_success: false,
        get_channel_info_error: true
      };
    }
    case GET_CHANNEL_USERS_START: {
      return {
        ...state,
        leave_channel_success: false,
        leave_channel_id: '',
        join_channel_success: false,
        get_channel_users_start: true,
        get_channel_users_success: false,
        get_channel_users_error: false
      };
    }
    case GET_CHANNEL_USERS_SUCCESS: {
      return {
        ...state,
        channelUsers: action.payload,
        get_channel_users_start: false,
        get_channel_users_success: true,
        get_channel_users_error: false
      };
    }
    case GET_CHANNEL_USERS_ERROR: {
      return {
        ...state,
        get_channel_users_start: false,
        get_channel_users_success: false,
        get_channel_users_error: true
      };
    }
    case GET_ORGANIZATION_STREAMS_START: {
      return {
        ...state,
        get_streams_start: true,
        get_streams_success: false,
        get_streams_error: false
      };
    }
    case GET_ORGANIZATION_STREAMS_SUCCESS: {
      return {
        ...state,
        streams: action.payload,
        get_streams_start: false,
        get_streams_success: true,
        get_streams_error: false
      };
    }
    case GET_ORGANIZATION_STREAMS_ERROR: {
      return {
        ...state,
        get_streams_start: false,
        get_streams_success: false,
        get_streams_error: true
      };
    }
    case GET_CHANNEL_STREAMS_START: {
      return {
        ...state,
        delete_stream_success: false,
        add_stream_success: false,
        get_channel_streams_start: true,
        add_and_get_stream_loading: true,
        get_channel_streams_success: false,
        get_channel_streams_error: false
      };
    }
    case GET_CHANNEL_STREAMS_SUCCESS: {
      return {
        ...state,
        channelStreams: action.payload,
        get_channel_streams_start: false,
        add_and_get_stream_loading: false,
        get_channel_streams_success: true,
        get_channel_streams_error: false
      };
    }
    case GET_CHANNEL_STREAMS_ERROR: {
      return {
        ...state,
        get_channel_streams_start: false,
        get_channel_streams_success: false,
        get_channel_streams_error: true
      };
    }

    case REMOVE_CHANNEL_START: {
      return {
        ...state,
        remove_channel_start: true,
        remove_channel_success: false,
        remove_channel_error: false
      };
    }
    case REMOVE_CHANNEL_SUCCESS: {
      return {
        ...state,
        remove_channel_start: false,
        remove_channel_success: true,
        remove_channel_error: false
      };
    }
    case REMOVE_CHANNEL_ERROR: {
      return {
        ...state,
        remove_channel_start: false,
        remove_channel_success: false,
        remove_channel_error: true
      };
    }

    case ADD_STREAM_START: {
      return {
        ...state,
        add_stream_start: true,
        add_stream_success: false,
        add_stream_error: false,
        add_and_get_stream_loading: true
      };
    }
    case ADD_STREAM_SUCCESS: {
      return {
        ...state,
        add_stream_start: false,
        add_stream_success: true,
        add_stream_error: false
      };
    }
    case ADD_STREAM_ERROR: {
      return {
        ...state,
        add_stream_start: false,
        add_stream_success: false,
        add_stream_error: true
      };
    }

    case DELETE_STREAM_START: {
      return {
        ...state,
        delete_stream_start: true,
        delete_stream_success: false,
        delete_stream_error: false
      };
    }
    case DELETE_STREAM_SUCCESS: {
      return {
        ...state,
        delete_stream_start: false,
        delete_stream_success: true,
        delete_stream_error: false
      };
    }
    case DELETE_STREAM_ERROR: {
      return {
        ...state,
        delete_stream_start: false,
        delete_stream_success: false,
        delete_stream_error: true
      };
    }

    default:
      return state;
  }
};
