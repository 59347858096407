import React from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Icon,
  Row,
  Col,
  Alert,
  message,
  Typography
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignUp } from '../appRedux/actions/Auth';
import { Redirect } from 'react-router-dom';
import SignUpLayout from '../components/Layouts/SignUpLayout';

const { Text, Title, Paragraph } = Typography;

class SignUp extends React.Component {
  state = {
    loading: false,
    isSelectedCheckbox: false
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignUp(values);
      }
    });
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.signup_success !== prevProps.signup_success &&
      this.props.signup_success === true
    ) {
      const signedUpUser = this.props.user_data;

      if (signedUpUser.email_verified === true) {
        if (window.env.GET_REACT_APP_NAME === 'cliff') {
          this.props.history.push('/onboarding');
        } else {
          this.props.history.push('/dashboard');
        }
      } else {
        this.props.history.push('/email_verification');
      }

      // this.props.history.push({
      //   pathname: '/signin',
      //   state: { new_account: true }
      // });
      // if (window.env.GET_REACT_APP_NAME === 'cliff') {
      //   this.props.history.push('/onboarding');
      // } else {
      //   this.props.history.push('/dashboard');
      // }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    // if (this.props.token) return <Redirect to="/dashboard" />;
    return (
      <SignUpLayout>
        <div align="left">
          <Title level={2} className="ant-font-heavy">
            Welcome!
          </Title>
          <Paragraph>
            {window.env.GET_REACT_APP_NAME === 'cliff'
              ? 'Sign Up for Cliff.ai'
              : 'Sign Up for Greendeck'}
          </Paragraph>
        </div>
        {this.props.signup_error ? (
          <Alert
            className="ant-mt-5"
            message={this.props.signup_error || 'Something went wrong.'}
            type="error"
            banner
          />
        ) : null}

        <div align="left">
          <Form
            onSubmit={this.handleSubmit}
            layout="vertical"
            className="ant-mt-5"
          >
            <Row type="flex">
              <Col span={12} align="left" className="ant-px-1">
                <Form.Item
                  label={
                    <small className="ant-text-muted">YOUR FIRST NAME</small>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  hasFeedback
                >
                  {getFieldDecorator('first_name', {
                    rules: [
                      { required: true, message: 'Enter your First Name' }
                    ]
                  })(<Input size="large" placeholder="First Name" />)}
                </Form.Item>
              </Col>
              <Col span={12} align="left" className="ant-px-1">
                <Form.Item
                  label={
                    <small className="ant-text-muted">YOUR LAST NAME</small>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  hasFeedback
                >
                  {getFieldDecorator('last_name', {
                    rules: [{ required: true, message: 'Enter your Last Name' }]
                  })(<Input size="large" placeholder=" Last Name" />)}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <small className="ant-text-muted">YOUR EMAIL ADDRESS</small>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  }
                ]
              })(<Input size="large" placeholder="Email" />)}
            </Form.Item>
            <Form.Item
              label={<small className="ant-text-muted">YOUR PASSWORD</small>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your Password!' }
                ]
              })(
                <Input.Password
                  size="large"
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <small className="ant-text-muted">YOUR ORGANIZATION NAME</small>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('organization_name', {
                rules: [{ required: true, message: 'Enter Organization Name' }]
              })(<Input size="large" placeholder=" Organization Name" />)}
            </Form.Item>

            <Form.Item
              label={<small className="ant-text-muted">INVITATION CODE</small>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('invitation_code', {
                rules: [{ required: true, message: 'Enter Invitation Code' }]
              })(<Input size="large" placeholder="Invitation Code" />)}
            </Form.Item>

            <Form.Item
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       !value ? Promise.resolve() : Promise.reject('Should accept agreement'),
            //   },
            // ]}
            >
              {getFieldDecorator('terms', {
                valuePropName: 'checked',
                initialValue: false,
                rules: [
                  {
                    message:
                      'You need to accept terms and conditions to sign up',
                    validator: (rule, value, callback) =>
                      value === true ? callback() : callback(true)
                  }
                ]
              })(<Checkbox>I Accept Terms & Conditions</Checkbox>)}
            </Form.Item>
            <Form.Item>
              <Row type="flex">
                <Col xs={12} align="left">
                  <Button size="large" style={{ width: '55%' }} type="link">
                    <Link to="/signin">Already have an account?</Link>
                  </Button>
                </Col>
                <Col xs={12} align="center">
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: '85%' }}
                    htmlType="submit"
                    loading={this.props.signup_start ? true : false}
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </SignUpLayout>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    signup_error,
    signup_start,
    signup_success,
    initUserData,
    user_data,
    authUser
  } = auth;
  return {
    token,
    signup_error,
    signup_start,
    signup_success,
    user_data,
    initUserData
  };
};

export default connect(mapStateToProps, { userSignUp })(WrappedSignUpForm);
