import { CHANGE_OMNISEARCH_STATE } from '../../../../../constants/CliffApiConstants';

const INIT_STATE = {
  visible: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_OMNISEARCH_STATE: {
      return {
        ...state,
        visible: action.payload
      };
    }
    default:
      return state;
  }
};
