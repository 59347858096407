import {
  FETCH_PROMOTIONS_HISTORY_START,
  FETCH_PROMOTIONS_HISTORY_SUCCESS,
  FETCH_PROMOTIONS_HISTORY_ERROR,
  FETCH_PROMOTIONAL_TREND_START,
  FETCH_PROMOTIONAL_TREND_SUCCESS,
  FETCH_PROMOTIONAL_TREND_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROMOTIONS_HISTORY_START: {
      return {
        ...state,
        fetch_promotions_history_success: false,
        fetch_promotions_history_error: false,
        fetch_promotions_history_start: true
      };
    }

    case FETCH_PROMOTIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        fetch_promotions_history_success: true,
        fetch_promotions_history_error: false,
        fetch_promotions_history_start: false,
        promotionsHistoryData: action.payload
      };
    }

    case FETCH_PROMOTIONS_HISTORY_ERROR: {
      return {
        ...state,
        fetch_promotions_history_success: false,
        fetch_promotions_history_error: true,
        fetch_promotions_history_start: false
      };
    }

    case FETCH_PROMOTIONAL_TREND_START: {
      return {
        ...state,
        fetch_promotional_trend_success: false,
        fetch_promotional_trend_error: false,
        fetch_promotional_trend_start: true
      };
    }

    case FETCH_PROMOTIONAL_TREND_SUCCESS: {
      return {
        ...state,
        fetch_promotional_trend_success: true,
        fetch_promotional_trend_error: false,
        fetch_promotional_trend_start: false,
        promotionalTrendData: action.payload
      };
    }

    case FETCH_PROMOTIONAL_TREND_ERROR: {
      return {
        ...state,
        fetch_promotional_trend_success: false,
        fetch_promotional_trend_error: true,
        fetch_promotional_trend_start: false
      };
    }

    default:
      return state;
  }
};
