import {
  FETCH_ASSORTMENT_START,
  FETCH_ASSORTMENT_SUCCESS,
  FETCH_ASSORTMENT_ERROR,
  FETCH_ASSORTMENT_HISTORY_START,
  FETCH_ASSORTMENT_HISTORY_SUCCESS,
  FETCH_ASSORTMENT_HISTORY_ERROR,
  EXPORT_ASSORTMENT_DATA_START,
  EXPORT_ASSORTMENT_DATA_ERROR,
  EXPORT_ASSORTMENT_DATA_SUCCESS
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ASSORTMENT_START: {
      return {
        ...state,
        fetch_assortment_success: false,
        fetch_assortment_error: false,
        fetch_assortment_start: true
      };
    }

    case FETCH_ASSORTMENT_SUCCESS: {
      return {
        ...state,
        fetch_assortment_success: true,
        fetch_assortment_error: false,
        fetch_assortment_start: false,
        assortmentData: action.payload
      };
    }

    case FETCH_ASSORTMENT_ERROR: {
      return {
        ...state,
        fetch_assortment_success: false,
        fetch_assortment_error: true,
        fetch_assortment_start: false
      };
    }

    case FETCH_ASSORTMENT_HISTORY_START: {
      return {
        ...state,
        fetch_assortment_history_success: false,
        fetch_assortment_history_error: false,
        fetch_assortment_history_start: true
      };
    }

    case FETCH_ASSORTMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        fetch_assortment_history_success: true,
        fetch_assortment_history_error: false,
        fetch_assortment_history_start: false,
        assortmentHistoryData: action.payload
      };
    }

    case FETCH_ASSORTMENT_HISTORY_ERROR: {
      return {
        ...state,
        fetch_assortment_history_success: false,
        fetch_assortment_history_error: true,
        fetch_assortment_history_start: false
      };
    }

    case EXPORT_ASSORTMENT_DATA_START: {
      return {
        ...state,
        export_assortment_data_start: true,
        export_assortment_data_error: false,
        export_assortment_data_success: false
      };
    }

    case EXPORT_ASSORTMENT_DATA_ERROR: {
      return {
        ...state,
        export_assortment_data_start: false,
        export_assortment_data_error: true,
        export_assortment_data_success: false
      };
    }

    case EXPORT_ASSORTMENT_DATA_SUCCESS: {
      return {
        ...state,
        export_assortment_data_start: false,
        export_assortment_data_error: false,
        export_assortment_data_success: true,
        exportData: action.payload
      };
    }

    default:
      return state;
  }
};
