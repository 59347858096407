import axios from 'axios';
import { envEncryption } from '../../secureEnv';
axios.defaults.headers.common['Authorization'] = JSON.parse(
  localStorage.getItem('token')
);

const local = 'http://localhost:3001/';

let URL = window.env.GET_REACT_APP_ACCOUNTS_ENDPOINT;

if (!URL) {
  URL = envEncryption.decryptParsed(window.env.REACT_APP_ACCOUNTS_ENDPOINT);
}

console.info({
  URL,
  where: 'ACCOUNTS'
});

export default axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
