import {
  FETCH_KEYWORDS_HISTORY_START,
  FETCH_KEYWORDS_HISTORY_SUCCESS,
  FETCH_KEYWORDS_HISTORY_ERROR,
  FETCH_KEYWORDS_HISTORY_EMPTY
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_KEYWORDS_HISTORY_START: {
      return {
        ...state,
        fetch_keywords_history_success: false,
        fetch_keywords_history_start: true,
        fetch_keywords_history_error: false
      };
    }

    case FETCH_KEYWORDS_HISTORY_SUCCESS: {
      const chartData = transformTrendsData(action.payload, action.keyword);
      return {
        ...state,
        fetch_keywords_history_success: true,
        fetch_keywords_history_start: false,
        fetch_keywords_history_error: false,
        lineChartData: chartData[0],
        calendarHeatmapData: chartData[1]
      };
    }

    case FETCH_KEYWORDS_HISTORY_ERROR: {
      return {
        fetch_keywords_history_success: false,
        fetch_keywords_history_start: false,
        fetch_keywords_history_error: true
      };
    }

    case FETCH_KEYWORDS_HISTORY_EMPTY: {
      return {
        ...state,
        fetch_keywords_history_success: true,
        fetch_keywords_history_start: false,
        fetch_keywords_history_error: false,
        lineChartData: [],
        calendarHeatmapData: []
      };
    }

    default:
      return state;
  }
};

const transformTrendsData = (data, keyword) => {
  //Creating the graph data array
  const graphArray = data._all.map(para => {
    return [new Date(Number(para.ts))].concat(
      keyword.map(val => parseInt(para[`observed_${val.replace(/ /g, '_')}`]))
    );
  });
  graphArray.unshift(
    [{ type: 'date', label: 'Date' }].concat(
      keyword.map(value => {
        return { type: 'number', label: value };
      })
    )
  );

  //Creating the calendar data array
  const calendarArray = keyword.map(value => {
    return {
      name: value,
      calendar: data[`${value.replace(/ /g, '_')}`].observed.data
        .map(para => {
          return [new Date(parseInt(para.ts)), para.value];
        })
        .filter(para => para[0].getFullYear() === new Date().getFullYear() - 1)
    };
  });

  return [graphArray, calendarArray];
};
