import { urlDB } from './indexDB';

const URL_TABLE = 'savedURLs';

export const saveURLToLocal = (user_id, organization_id, url) => {
  const item = {
    user_org_combo: user_id + '_' + organization_id,
    url: url
  };
  if (url) {
    return urlDB.table(URL_TABLE).put(item);
  }
};

export const loadURLFromLocal = (user_id, organization_id) => {
  const user_org_combo = user_id + '_' + organization_id;

  return urlDB
    .table(URL_TABLE)
    .where({ user_org_combo: user_org_combo })
    .first(data => data);
};

export const clearCache = (user_id, organization_id) => {
  return urlDB.table(URL_TABLE).delete(`${user_id}_${organization_id}`);
};
