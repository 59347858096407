import {
  FETCH_INCIDENTS_START,
  FETCH_INCIDENTS_SUCCESS,
  FETCH_INCIDENTS_ERROR,
  FETCH_INCIDENTS_ERROR_BY_DATE,
  FETCH_INCIDENTS_START_BY_DATE,
  FETCH_INCIDENTS_SUCCESS_BY_DATE,
  FETCH_INCIDENTS_LOADING_BY_DATE
} from '../../../../constants/CliffApiConstants';

const INIT_STATE = {
  incidentsData: [],
  fetch_incidents_loading_by_date: null
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_INCIDENTS_START: {
      return {
        ...state,
        fetch_incidents_success: false,
        fetch_incidents_error: false,
        fetch_incidents_start: true
      };
    }

    case FETCH_INCIDENTS_SUCCESS: {
      return {
        ...state,
        fetch_incidents_success: true,
        fetch_incidents_error: false,
        fetch_incidents_start: false,
        incidentsData: [...payload.allIncidents.incidents],
        incidentsCount: payload.allIncidents.incidentsDocCount,
        incidentPageMap: payload.allIncidents.incidents.reduce(
          (accumalator, item) => {
            const { incidentDate } = item;
            accumalator[incidentDate] = 1;
            return accumalator;
          },
          {}
        )
      };
    }

    case FETCH_INCIDENTS_ERROR: {
      return {
        ...state,
        fetch_incidents_success: false,
        fetch_incidents_error: true,
        fetch_incidents_start: false
      };
    }

    case FETCH_INCIDENTS_SUCCESS_BY_DATE: {
      return {
        ...state,
        fetch_incidents_success_by_page: true,
        fetch_incidents_error_by_page: false,
        fetch_incidents_start_by_page: false,
        incidentsData: [...payload.newData],
        incidentPageMap: { ...payload.pageMap }
      };
    }

    case FETCH_INCIDENTS_ERROR_BY_DATE: {
      return {
        ...state,
        fetch_incidents_success_by_page: false,
        fetch_incidents_error_by_page: true,
        fetch_incidents_start_by_page: false
      };
    }
    case FETCH_INCIDENTS_START_BY_DATE: {
      return {
        ...state,
        fetch_incidents_success_by_page: false,
        fetch_incidents_error_by_page: false,
        fetch_incidents_start_by_page: true
      };
    }
    case FETCH_INCIDENTS_LOADING_BY_DATE: {
      return {
        ...state,
        fetch_incidents_loading_by_date: payload
      };
    }
    default:
      return state;
  }
};
