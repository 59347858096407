import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Dropdown,
  Row,
  Col,
  Menu,
  Button,
  Icon,
  List,
  Divider,
  Typography,
  Popover,
  Drawer,
  Tag
} from '@greendeck/atomic';
import { updateSelectedApp, initUser } from 'appRedux/actions/Auth';
import { Link } from 'react-router-dom';

import { Redirect, Route, Switch } from 'react-router-dom';

import languageData from '../LanguageModule/LanguageData';
import { switchLanguage } from '../../appRedux/actions/Setting';
import { saveURLToLocal, loadURLFromLocal } from 'util/indexDB/urlCRUD';
import Hotkeys from 'react-hot-keys';

const { Text } = Typography;

function handleButtonClick(e) {
  console.log('click left button', e);
}

function handleMenuClick(e) {
  console.log('click', e);
}
var hide = false;
if (window.env.GET_REACT_APP_NAME === 'cliff') {
  // hide = true;
}
class AppSwitcher extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.update_selected_app_success !==
        this.props.update_selected_app_success &&
      this.props.update_selected_app_success === true
    ) {
      this.setState({ clickedAppId: '' });
      this.props.initUser();
    }
  };

  render() {
    const { collapse } = this.props;
    const { locale } = this.props;
    var appsList = [];
    if (this.props.appsData) {
      var appsList = this.props.appsData.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }

    const appsOptions = (
      <>
        <Row type="flex" gutter={24} direction="column">
          {this.props.appsData
            ? appsList.map(app =>
                app.installed ? (
                  <div
                    onClick={() => {
                      this.setState({ clickedAppId: app.id });
                      const url = app.navs[0].href;
                      const user_id = JSON.parse(
                        localStorage.getItem('user_id')
                      );
                      const organization_id = JSON.parse(
                        localStorage.getItem('organization_id')
                      );
                      if (user_id && organization_id) {
                        saveURLToLocal(
                          user_id,
                          organization_id,
                          url
                        ).then(data => this.onClose());
                      }
                      this.props.updateSelectedApp(app.id, app.navs[0].href);
                    }}
                  >
                    <Link
                      to={app.navs[0] ? app.navs[0].href : '/signin'}
                      key={app.id}
                    >
                      <Col key={app.id}>
                        <Row
                          type="flex"
                          justify="start"
                          align="start"
                          className="ant-mb-3"
                        >
                          <div>
                            <Avatar
                              shape="square"
                              size={40}
                              className="ant-bg-primary"
                            >
                              {app.name.charAt(0).toUpperCase()}
                            </Avatar>
                          </div>
                          <div className="ant-px-3">
                            <div>
                              <Text strong>{app.name} </Text>
                              {!this.props.appsData &&
                              !this.props.initUserData ? null : this.props
                                  .initUserData &&
                                app.id ===
                                  this.props.initUserData.selected_application
                                    .id ? (
                                <Icon
                                  type="check-circle"
                                  theme="filled"
                                  className="ant-text-success ant-ml-3"
                                />
                              ) : null}
                              <br />
                              <small>
                                <Text ellipsis={true}>{app.category}</Text>
                              </small>
                            </div>
                          </div>
                          {this.state.clickedAppId === app.id && (
                            <Icon type="loading" className="ant-px-3" />
                          )}
                        </Row>
                      </Col>
                    </Link>
                  </div>
                ) : null
              )
            : null}
        </Row>
      </>
    );

    return (
      <>
        <Drawer
          title="Select app"
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          zIndex={100000}
          width={512}
        >
          {appsOptions}
        </Drawer>
        {collapse ? (
          <a onClick={this.showDrawer}>
            <Row
              type="flex"
              justify="start"
              align="stretch"
              className="ant-px-3 ant-pt-3 ant-mb-1"
            >
              <Col>
                {!hide && (
                  <Avatar shape="square" size={40} className="ant-bg-none">
                    <Icon type="appstore" className="ant-text-secondary"></Icon>
                  </Avatar>
                )}
              </Col>
            </Row>
          </a>
        ) : (
          <a onClick={this.showDrawer}>
            <Row
              type="flex"
              justify="center"
              align="center"
              className="ant-px-3 ant-pt-3"
            >
              {!hide && (
                <>
                  <Col span={6}>
                    <Avatar shape="square" size={40} className="ant-bg-none">
                      <Icon
                        type="appstore"
                        className="ant-text-secondary"
                      ></Icon>
                    </Avatar>
                  </Col>
                  <Col span={18} className="ant-px-3">
                    <Row type="flex">
                      <Col span={22} className="ant-my-0">
                        <Hotkeys
                          keyName="shift+a,alt+s"
                          onKeyDown={this.showDrawer.bind(this)}
                        >
                          <Text
                            className="ant-text-white"
                            style={{ width: '100%' }}
                            ellipsis
                            strong
                          >
                            Switch App
                          </Text>
                        </Hotkeys>
                      </Col>
                      {/* {
                       collapse?
                      <Col span={2} className="ant-my-0">
                        <Text className="ant-font-bold ant-text-white ant-ml-1">
                          <Icon type="caret-right" />
                        </Text>
                      </Col>
                      :null

                      } */}
                      <Col span={24} className="ant-my-0">
                        <Text className="ant-text-white" small>
                          {/*2 apps*/}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </a>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const {
    initUserData,
    appsData,
    update_selected_app_start,
    update_selected_app_success
  } = auth;
  const { locale } = settings;
  return {
    locale,
    initUserData,
    appsData,
    update_selected_app_start,
    update_selected_app_success
  };
};

export default connect(mapStateToProps, { updateSelectedApp, initUser })(
  AppSwitcher
);
