import { SET_CHANNELS, SET_STREAMS } from '../../constants/CliffApiConstants';

const INIT_STATE = {
  headerStreamsSelected: [],
  headerChannelsSelected: [],
  headerTimePeriodSelected: '14d',
  updateMeasureListBool: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CHANNELS: {
      return {
        ...state,
        headerChannelsSelected: action.channels
      };
    }
    case SET_STREAMS: {
      return {
        ...state,
        headerStreamsSelected: action.streams
      };
    }

    case 'SET_TIME_PERIOD': {
      return {
        ...state,
        headerTimePeriodSelected: action.period
      };
    }

    case 'SET_CUSTOM_RANGE': {
      return {
        ...state,
        headerCustomTimeRange: action.range
      };
    }

    case 'UPDATE_MEASURE_LIST': {
      return {
        ...state,
        updateMeasureListBool: action.payload
      };
    }

    default:
      return state;
  }
};
