import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button,
  Badge,
  Footer
} from '@greendeck/atomic';

const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const steps = [
  {
    title: 'Data Sources',
    description:
      'Cliff.ai connects seamlessly to popular databases and third-party SaaS applications to fetch metrics and monitor them for abnormal behaviour',
    stepText: 'Step 2 of 6',
    renderBody: incrementStepByOne => (
      <>
        <div align="center">
          <img
            height="600"
            src={
              window.env.PUBLIC_URL +
              '/product_illustrations/steps/sources_illustration.svg'
            }
            className="animated fadeInUp"
          ></img>
        </div>
      </>
    )
  },
  {
    title: "Let's start with a sample data source",
    description:
      'Here we have a sample data from an E-commerce store that we will monitor using Cliff.ai',
    stepText: 'Step 2 of 6',
    renderBody: () => (
      <>
        <div align="center">
          <img
            style={{ maxWidth: '100%' }}
            src={
              window.env.PUBLIC_URL +
              '/product_illustrations/steps/sources_sample.png'
            }
            className="animated fadeInUp"
          ></img>
        </div>
      </>
    )
  }
];

const NUM_STEPS = steps.length;

const sources = [
  {
    title: 'Google Sheets',
    description: 'You can use cliff with a variety of data sources',
    logo: 'https://i.ibb.co/NyrxV75/google-sheets.png'
  }
];

const Sources = props => {
  const step = props.currentSubStep;

  return (
    <div className="">
      <small className="ant-text-muted">{steps[step].stepText}</small>
      <Title level={2} strong>
        {steps[step].title}
      </Title>
      <Paragraph className="ant-mb-3">{steps[step].description}</Paragraph>
      <Row>
        <Col>{steps[step].renderBody(props.incrementSubStepBy)}</Col>
      </Row>
    </div>
  );
};

export default Sources;
