export const GET_SOURCES_START = 'GET_SOURCES_START';
export const GET_SOURCES_SUCCESS = 'GET_SOURCES_SUCCESS';
export const GET_SOURCES_ERROR = 'GET_SOURCES_ERROR';

export const GET_SOURCE_START = 'GET_SOURCE_START';
export const GET_SOURCE_SUCCESS = 'GET_SOURCE_SUCCESS';
export const GET_SOURCE_ERROR = 'GET_SOURCE_ERROR';
export const CLEAR_SOURCE_OBJECT = 'CLEAR_SOURCE_OBJECT';

export const AUTHORIZE_DATA_SOURCE_START = 'AUTHORIZE_DATA_SOURCE_START';
export const AUTHORIZE_DATA_SOURCE_SUCCESS = 'AUTHORIZE_DATA_SOURCE_SUCCESS';
export const AUTHORIZE_DATA_SOURCE_ERROR = 'AUTHORIZE_DATA_SOURCE_ERROR';

//get datastreams
export const FETCH_DATASTREAMS_START = 'FETCH_DATASTREAMS_START';
export const FETCH_DATASTREAMS_SUCCESS = 'FETCH_DATASTREAMS_SUCCESS';
export const FETCH_DATASTREAMS_ERROR = 'FETCH_DATASTREAMS_ERROR';
export const FETCH_DATASTREAMS_SUCCESS_TOTAL =
  'FETCH_DATASTREAMS_SUCCESS_TOTAL';
//get a datastream
export const GET_DATASTREAM_START = 'GET_DATASTREAM_START';
export const GET_DATASTREAM_SUCCESS = 'GET_DATASTREAM_SUCCESS';
export const GET_DATASTREAM_ERROR = 'GET_DATASTREAM_ERROR';

//get all datastream
export const GET_ALL_DATASTREAM_START = 'GET_ALL_DATASTREAM_START';
export const GET_ALL_DATASTREAM_SUCCESS = 'GET_ALL_DATASTREAM_SUCCESS';
export const GET_ALL_DATASTREAM_ERROR = 'GET_ALL_DATASTREAM_ERROR';

export const UPDATE_STREAM_START = 'UPDATE_STREAM_START';
export const UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS';
export const UPDATE_STREAM_ERROR = 'UPDATE_STREAM_ERROR';

//get source subscription
export const GET_SOURCE_SUBSCRIPTION_START = 'GET_SOURCE_SUBSCRIPTION_START';
export const GET_SOURCE_SUBSCRIPTION_SUCCESS =
  'GET_SOURCE_SUBSCRIPTION_SUCCESS';
export const GET_SOURCE_SUBSCRIPTION_ERROR = 'GET_SOURCE_SUBSCRIPTION_ERROR';

export const GET_SOURCE_SUBSCRIPTION_BY_ID_START =
  'GET_SOURCE_SUBSCRIPTION_BY_ID_START';
export const GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS =
  'GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS';
export const GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR =
  'GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR';
export const CLEAR_SOURCE_SUBSCRIPTION = 'CLEAR_SOURCE_SUBSCRIPTION';

//delete source subscription
export const DELETE_SOURCE_SUBSCRIPTION_START =
  'DELETE_SOURCE_SUBSCRIPTION_START';
export const DELETE_SOURCE_SUBSCRIPTION_SUCCESS =
  'DELETE_SOURCE_SUBSCRIPTION_SUCCESS';
export const DELETE_SOURCE_SUBSCRIPTION_ERROR =
  'DELETE_SOURCE_SUBSCRIPTION_ERROR';

//streams meta data
export const GET_STREAM_META_DATA_START = 'GET_STREAM_META_DATA_START';
export const GET_STREAM_META_DATA_SUCCESS = 'GET_STREAM_META_DATA_SUCCESS';
export const GET_STREAM_META_DATA_ERROR = 'GET_STREAM_META_DATA_ERROR';

//schedule data
export const GET_DATASCHEDULE_START = 'GET_DATASCHEDULE_START';
export const GET_DATASCHEDULE_SUCCESS = 'GET_DATASCHEDULE_SUCCESS';
export const GET_DATASCHEDULE_ERROR = 'GET_DATASCHEDULE_ERROR';

//sentry integration
export const AUTHORIZE_SENTRY_TOKEN_START = 'AUTHORIZE_SENTRY_TOKEN_START';
export const AUTHORIZE_SENTRY_TOKEN_SUCCESS = 'AUTHORIZE_SENTRY_TOKEN_SUCCESS';
export const AUTHORIZE_SENTRY_TOKEN_ERROR = 'AUTHORIZE_SENTRY_TOKEN_ERROR';
export const GET_SENTRY_ACCOUNTS_START = 'GET_SENTRY_ACCOUNTS_START';
export const GET_SENTRY_ACCOUNTS_SUCCESS = 'GET_SENTRY_ACCOUNTS_SUCCESS';
export const GET_SENTRY_ACCOUNTS_ERROR = 'GET_SENTRY_ACCOUNTS_ERROR';

// templates
export const GET_TEMPLATE_CATEGORIES_START = 'GET_TEMPLATE_CATEGORIES_START';
export const GET_TEMPLATE_CATEGORIES_SUCCESS =
  'GET_TEMPLATE_CATEGORIES_SUCCESS';
export const GET_TEMPLATE_CATEGORIES_ERROR = 'GET_TEMPLATE_CATEGORIES_ERROR';

export const GET_TEMPLATES_START = 'GET_TEMPLATES_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';

export const GET_TEMPLATE_SOURCES_START = 'GET_TEMPLATES_SOURCES_START';
export const GET_TEMPLATE_SOURCES_SUCCESS = 'GET_TEMPLATES_SOURCES_SUCCESS';
export const GET_TEMPLATE_SOURCES_ERROR = 'GET_TEMPLATES_SOURCES_ERROR';

export const SET_STREAMS = 'SET_STREAMS';
export const SET_CHANNELS = 'SET_CHANNELS';

export const GET_ALL_SOURCE_SUBSCRIPTIONS_START =
  'GET_ALL_SOURCE_SUBSCRIPTIONS_START';
export const GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS =
  'GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS';
export const GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR =
  'GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR';

//  get community outlier metrics
export const GET_OUTLIER_METRICS_START = 'GET_OUTLIER_METRICS_START';
export const GET_OUTLIER_METRICS_SUCCESS = 'GET_OUTLIER_METRICS_SUCCESS';
export const GET_OUTLIER_METRICS_ERROR = 'GET_OUTLIER_METRICS_ERROR';
export const RESET_OUTLIER_DATA = 'RESET_OUTLIER_DATA';

export const CHANGE_RELATED_STREAMS_BOOLEAN = 'CHANGE_RELATED_STREAMS_BOOLEAN';

export const SET_SOURCE_TEMPLATES = 'SET_SOURCE_TEMPLATES';

export const GET_ALL_SOURCES_START = 'GET_ALL_SOURCES_START';
export const GET_ALL_SOURCES_SUCCESS = 'GET_ALL_SOURCES_SUCCESS';
export const GET_ALL_SOURCES_ERROR = 'GET_ALL_SOURCES_ERROR';

//omnisearch reducers
export const CHANGE_OMNISEARCH_STATE = 'CHANGE_OMNISEARCH_STATE';

//incidents
export const FETCH_INCIDENTS_START = 'FETCH_INCIDENTS_START';
export const FETCH_INCIDENTS_SUCCESS = 'FETCH_INCIDENTS_SUCCESS';
export const FETCH_INCIDENTS_ERROR = 'FETCH_INCIDENTS_ERROR';

export const FETCH_INCIDENTS_START_BY_DATE = 'FETCH_INCIDENTS_START_BY_DATE';
export const FETCH_INCIDENTS_SUCCESS_BY_DATE = 'FETCH_INCIDENTS_SUCCESS_DATE';
export const FETCH_INCIDENTS_ERROR_BY_DATE = 'FETCH_INCIDENTS_ERROR_DATE';
export const FETCH_INCIDENTS_LOADING_BY_DATE =
  'FETCH_INCINDETS_LOADING_BY_DATE';
