import axios from 'axios';
import { envEncryption } from '../../secureEnv';

axios.defaults.headers.common['Authorization'] = JSON.parse(
  localStorage.getItem('token')
);

const local = 'http://localhost:3333/';
let URL = window.env.GET_REACT_APP_CLIFF_ENDPOINT;
if (!URL) {
  URL = envEncryption.decryptParsed(window.env.REACT_APP_CLIFF_ENDPOINT);
}
console.log({
  URL,
  where: 'cliff'
});

const apiObject = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// handle race conditions
let call = {};
export const makeRequestCliff = (config = {}, requestType = 'undefined') => {
  if (call[requestType]) {
    call[requestType].cancel('cancel');
  }
  call[requestType] = axios.CancelToken.source();
  config.cancelToken = call[requestType].token;
  return apiObject(config);
};
