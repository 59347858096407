import {
  FETCH_SALES_START,
  FETCH_SALES_SUCCESS,
  FETCH_SALES_ERROR,
  FETCH_SALES_STATS_START,
  FETCH_SALES_STATS_SUCCESS,
  FETCH_SALES_STATS_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SALES_START: {
      return {
        ...state,
        fetch_sales_success: false,
        fetch_sales_error: false,
        fetch_sales_start: true
      };
    }

    case FETCH_SALES_SUCCESS: {
      return {
        ...state,
        fetch_sales_success: true,
        fetch_sales_error: false,
        fetch_sales_start: false,
        salesData: action.payload
      };
    }

    case FETCH_SALES_ERROR: {
      return {
        ...state,
        fetch_sales_success: false,
        fetch_sales_error: true,
        fetch_sales_start: false
      };
    }

    case FETCH_SALES_STATS_START: {
      return {
        ...state,
        fetch_sales_stats_success: false,
        fetch_sales_stats_error: false,
        fetch_sales_stats_start: true
      };
    }

    case FETCH_SALES_STATS_SUCCESS: {
      return {
        ...state,
        fetch_sales_stats_success: true,
        fetch_sales_stats_error: false,
        fetch_sales_stats_start: false,
        salesStatsData: action.payload
      };
    }

    case FETCH_SALES_STATS_ERROR: {
      return {
        ...state,
        fetch_sales_stats_success: false,
        fetch_sales_stats_error: true,
        fetch_sales_stats_start: false
      };
    }

    default:
      return state;
  }
};
