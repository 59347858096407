import axios from 'axios';

axios.defaults.headers.common['Authorization'] = JSON.parse(
  localStorage.getItem('token')
);

switch (window.env.GET_REACT_APP_ENV) {
  case 'developement':
    var URL = 'https://dev-accounts.greendeck.co/';
    break;
  case 'staging':
    var URL = 'https://staging-accounts.greendeck.co/';
    break;
  case 'production':
    break;
    var URL = 'https://prod-accounts.greendeck.co/';
  default:
    var URL = 'https://dev-accounts.greendeck.co/';
}

export default axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
