import {
  GET_MONITORING_RETAILER_START,
  GET_MONITORING_RETAILER_SUCCESS,
  GET_MONITORING_RETAILER_ERROR,
  GET_MONITORING_DAILY_ERROR,
  GET_MONITORING_DAILY_START,
  GET_MONITORING_DAILY_SUCCESS
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MONITORING_DAILY_START: {
      return {
        ...state,
        get_monitoring_daily_start: true,
        get_monitoring_daily_success: false,
        get_monitoring_daily_error: false
      };
    }
    case GET_MONITORING_DAILY_SUCCESS: {
      return {
        ...state,
        get_monitoring_daily_start: false,
        get_monitoring_daily_success: true,
        get_monitoring_daily_error: false,
        daily_stats: action.payload
      };
    }

    case GET_MONITORING_DAILY_ERROR: {
      return {
        ...state,
        get_monitoring_daily_start: false,
        get_monitoring_daily_success: false,
        get_monitoring_daily_error: true
      };
    }

    case GET_MONITORING_RETAILER_START: {
      return {
        ...state,
        get_monitoring_retailer_start: true,
        get_monitoring_retailer_success: false,
        get_monitoring_retailer_error: false
      };
    }

    case GET_MONITORING_RETAILER_SUCCESS: {
      return {
        ...state,
        get_monitoring_retailer_start: false,
        get_monitoring_retailer_success: true,
        get_monitoring_retailer_error: false,
        retailer_data: action.payload
      };
    }
    case GET_MONITORING_RETAILER_ERROR: {
      return {
        ...state,
        get_monitoring_retailer_start: false,
        get_monitoring_retailer_success: false,
        get_monitoring_retailer_error: true
      };
    }

    default:
      return state;
  }
};
