import {
  FETCH_NEWSLETTERS_START,
  FETCH_NEWSLETTERS_SUCCESS,
  FETCH_NEWSLETTERS_ERROR,
  FETCH_NEWSLETTERS_STATS_START,
  FETCH_NEWSLETTERS_STATS_SUCCESS,
  FETCH_NEWSLETTERS_STATS_ERROR,
  FETCH_SAVED_NEWSLETTERS_START,
  FETCH_SAVED_NEWSLETTERS_SUCCESS,
  FETCH_SAVED_NEWSLETTERS_ERROR,
  SAVE_NEWSLETTER_START,
  SAVE_NEWSLETTER_SUCCESS,
  SAVE_NEWSLETTER_ERROR,
  NEWSLETTERS_COLLECTIONS_INFO_START,
  NEWSLETTERS_COLLECTIONS_INFO_SUCCESS,
  NEWSLETTERS_COLLECTIONS_INFO_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_NEWSLETTERS_START: {
      return {
        ...state,
        fetch_newsletters_success: false,
        fetch_newsletters_error: false,
        fetch_newsletters_start: true
      };
    }

    case FETCH_NEWSLETTERS_SUCCESS: {
      return {
        ...state,
        fetch_newsletters_success: true,
        fetch_newsletters_error: false,
        fetch_newsletters_start: false,
        newslettersData: action.payload
      };
    }

    case FETCH_NEWSLETTERS_ERROR: {
      return {
        ...state,
        fetch_newsletters_success: false,
        fetch_newsletters_error: true,
        fetch_newsletters_start: false
      };
    }

    case FETCH_NEWSLETTERS_STATS_START: {
      return {
        ...state,
        newslettersStatsData: undefined,
        fetch_newsletters_stats_start: true,
        fetch_newsletters_stats_error: false,
        fetch_newsletters_stats_success: false
      };
    }

    case FETCH_NEWSLETTERS_STATS_SUCCESS: {
      return {
        ...state,
        newslettersStatsData: action.payload,
        fetch_newsletters_stats_error: false,
        fetch_newsletters_stats_success: true,
        fetch_newsletters_stats_start: false
      };
    }

    case FETCH_NEWSLETTERS_STATS_ERROR: {
      return {
        ...state,
        fetch_newsletters_stats_error: true,
        fetch_newsletters_stats_success: false,
        fetch_newsletters_stats_start: false
      };
    }

    case FETCH_SAVED_NEWSLETTERS_START: {
      return {
        ...state,
        fetch_saved_newsletters_success: false,
        fetch_saved_newsletters_error: false,
        fetch_saved_newsletters_start: true
      };
    }

    case FETCH_SAVED_NEWSLETTERS_SUCCESS: {
      return {
        ...state,
        savedNewslettersData: action.payload.hits,
        fetch_saved_newsletters_success: true,
        fetch_saved_newsletters_error: false,
        fetch_saved_newsletters_start: false
      };
    }

    case FETCH_SAVED_NEWSLETTERS_ERROR: {
      return {
        ...state,
        fetch_saved_newsletters_success: false,
        fetch_saved_newsletters_error: true,
        fetch_saved_newsletters_start: false
      };
    }

    case SAVE_NEWSLETTER_ERROR: {
      return {
        ...state,
        save_newsletter_success: false,
        save_newsletter_error: true,
        save_newsletter_start: false
      };
    }

    case SAVE_NEWSLETTER_START: {
      return {
        ...state,
        save_newsletter_success: false,
        save_newsletter_error: false,
        save_newsletter_start: true
      };
    }

    case SAVE_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        save_newsletter_success: true,
        save_newsletter_error: false,
        save_newsletter_start: false
      };
    }

    case NEWSLETTERS_COLLECTIONS_INFO_ERROR: {
      return {
        ...state,
        newsletters_colelction_info_success: false,
        newsletters_colelction_info_error: true,
        newsletters_colelction_info_start: false
      };
    }

    case NEWSLETTERS_COLLECTIONS_INFO_START: {
      return {
        ...state,
        newsletters_colelction_info_success: false,
        newsletters_colelction_info_error: false,
        newsletters_colelction_info_start: true
      };
    }

    case NEWSLETTERS_COLLECTIONS_INFO_SUCCESS: {
      return {
        ...state,
        savedNewslettersCollectionInfo: action.payload.collection_names,
        newsletters_colelction_info_success: true,
        newsletters_colelction_info_error: false,
        newsletters_colelction_info_start: false
      };
    }

    default:
      return state;
  }
};
