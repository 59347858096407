import {
  FETCH_BRAND_COMPARISON_START,
  FETCH_BRAND_COMPARISON_SUCCESS,
  FETCH_BRAND_COMPARISON_ERROR,
  FETCH_BRAND_COMPARISON_ANALYTICS_START,
  FETCH_BRAND_COMPARISON_ANALYTICS_SUCCESS,
  FETCH_BRAND_COMPARISON_ANALYTICS_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BRAND_COMPARISON_START: {
      return {
        ...state,
        fetch_brand_comparison_success: false,
        fetch_brand_comparison_error: false,
        fetch_brand_comparison_start: true
      };
    }

    case FETCH_BRAND_COMPARISON_SUCCESS: {
      return {
        ...state,
        fetch_brand_comparison_success: true,
        fetch_brand_comparison_error: false,
        fetch_brand_comparison_start: false,
        brandComparisonData: action.payload
      };
    }

    case FETCH_BRAND_COMPARISON_ERROR: {
      return {
        ...state,
        fetch_brand_comparison_success: false,
        fetch_brand_comparison_error: true,
        fetch_brand_comparison_start: false
      };
    }

    case FETCH_BRAND_COMPARISON_ANALYTICS_START: {
      return {
        ...state,
        fetch_brand_comparison_analytics_success: false,
        fetch_brand_comparison_analytics_error: false,
        fetch_brand_comparison_analytics_start: true
      };
    }

    case FETCH_BRAND_COMPARISON_ANALYTICS_SUCCESS: {
      return {
        ...state,
        fetch_brand_comparison_analytics_success: true,
        fetch_brand_comparison_analytics_error: false,
        fetch_brand_comparison_analytics_start: false,
        brandComparisonAnalyticsData: action.payload
      };
    }

    case FETCH_BRAND_COMPARISON_ANALYTICS_ERROR: {
      return {
        ...state,
        fetch_brand_comparison_analytics_success: false,
        fetch_brand_comparison_analytics_error: true,
        fetch_brand_comparison_analytics_start: false
      };
    }

    default:
      return state;
  }
};
