import React, { Fragment } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Avatar,
  Alert,
  Skeleton,
  Icon,
  notification
} from '@greendeck/atomic';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  userSignIn,
  getUserInfo,
  userEmailVerification
} from '../appRedux/actions/Auth';
import SignInLayout from '../components/Layouts/SignInLayout';
import { convertToTitleCase } from '../util/generics/TitleCase';
import { loadURLFromLocal } from 'util/indexDB/urlCRUD';

const { Text, Title, Paragraph } = Typography;

var email_param = '';
var qs = require('qs');
const { Panel } = Collapse;
class SignIn extends React.Component {
  state = {
    loading: false,
    selectedSavedEmail: false,
    saved_accounts: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
        window.analytics.identify('123', {
          name: values.first_name,
          email: values.email
        });
        window.analytics.track('Article Bookmarked', {
          title: 'Snow Fall',
          subtitle: 'The Avalanche at Tunnel Creek',
          author: 'John Branch'
        });
      }
    });
  };

  handleSubmitCollapse = (e, email) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.email = email;
        this.props.userSignIn(values);
        window.analytics.identify('123', {
          name: values.first_name,
          email: email
        });
      }
    });
  };

  handleSelectAccount = item => {
    this.setState({ selectedSavedEmail: item.email });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.signin_success !== prevProps.signin_success &&
      this.props.signin_success === true
    ) {
      // Getting the default organization preferences to ultimately check whether the organization has onboarded or not
      let default_organization_preference_data;
      if (this.props.default_organiztion_preferences) {
        default_organization_preference_data = this.props
          .default_organiztion_preferences;
        console.log(
          'Default organization preferences',
          default_organization_preference_data
        );
      }
      let user;
      if (this.props.user_data) {
        user = this.props.user_data;
        console.log(user);
      }

      // if (window.env.GET_REACT_APP_NAME === 'cliff' && default_organization_preference_data.onboarded) {
      //   this.props.history.push('/apps/anomaly-detection/getting-started');
      // } else {
      //   this.props.history.push('/dashboard');
      // }

      if (user.email_verified === false) {
        this.props.history.push('/email_verification');
      } else {
        const user_id = JSON.parse(localStorage.getItem('user_id'));
        const organization_id = JSON.parse(
          localStorage.getItem('organization_id')
        );
        if (window.env.GET_REACT_APP_NAME === 'cliff') {
          loadURLFromLocal(user_id, organization_id).then(data => {
            if (data) {
              this.props.history.push(data.url);
            } else if (default_organization_preference_data.onboarded) {
              this.props.history.push(
                '/apps/anomaly-detection/getting-started'
              );
            } else {
              this.props.history.push('/onboarding');
            }
          });
        } else {
          this.props.history.push('/dashboard');
        }
      }
    }
  }

  componentDidMount() {
    this.props.getUserInfo();
    localStorage.removeItem('recentSelectedStreams');
    if (
      JSON.parse(localStorage.getItem('saved_accounts')) != null &&
      JSON.parse(localStorage.getItem('saved_accounts')).length > 0
    ) {
      if (this.state.saved_accounts == false) {
        this.setState({ saved_accounts: true });
      }
    }
    if (this.props.location.state && this.props.location.state.new_account) {
      notification.open({
        message: 'Account created successfully',
        icon: <Icon type="check-circle" style={{ color: 'green' }} />
      });
    }
  }

  callback = key => {
    console.log(key);
  };

  handleChangeView = value => {
    this.setState({ saved_accounts: false });
  };

  handleSavedAccountsView = () => {
    this.setState({ saved_accounts: true });
  };

  handleRemoveSavedAccount = email => {
    let temp_account_list = JSON.parse(localStorage.getItem('saved_accounts'));
    temp_account_list.forEach(function(item) {
      if (item.email == email) {
        let index = temp_account_list.indexOf(item);
        temp_account_list.splice(index, 1);
      }
    });

    localStorage.setItem('saved_accounts', JSON.stringify(temp_account_list));

    if (
      JSON.parse(localStorage.getItem('saved_accounts')) != null &&
      JSON.parse(localStorage.getItem('saved_accounts')).length > 0
    ) {
      this.setState({ saved_accounts: false });
      this.setState({ saved_accounts: true });
    }
    if (temp_account_list.length < 1) {
      this.setState({ saved_accounts: false });
    }
  };

  getSSOLoginLink = () => {
    const realm = window.env.GET_REACT_APP_KEYCLOAK_REALM;

    const baseUrl = `${window.env.GET_REACT_APP_KEYCLOAK_URI}/auth/realms/${realm}/protocol/openid-connect/auth`;

    const urlParams = {
      client_id: window.env.GET_REACT_APP_KEYCLOAK_CLIENT_ID,
      redirect_uri: window.env.GET_REACT_APP_KEYCLOAK_REDIRECT_URI,
      response_type: 'code'
    };

    const urlParamsString = new URLSearchParams(urlParams).toString();
    console.log({ UR: urlParamsString });
    return `${baseUrl}?${urlParamsString}`;
  };

  forbiddenAlert = (
    <Alert
      style={{ marginTop: '1rem' }}
      message="Account disabled"
      description={
        <Text>
          This account has been disabled by the user. To reactivate the account,
          please contact us at{' '}
          <a href="mailto:support@greendeck.co">support@greendeck.co</a>
        </Text>
      }
      type="info"
      showIcon
    />
  );

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.props.userInfoData) {
      var userInfoData = this.props.userInfoData;
    }

    if (this.props.location.search) {
      email_param = qs.parse(this.props.location.search.slice(1));
    }

    if (JSON.parse(localStorage.getItem('saved_accounts')) != null) {
      var savedAccountsList = JSON.parse(
        localStorage.getItem('saved_accounts')
      );
    }

    return (
      <SignInLayout>
        <div align="left">
          <Title level={3} className="ant-font-heavy">
            Welcome back!
          </Title>
          <Paragraph>
            Login to your{' '}
            {window.env.GET_REACT_APP_NAME !== 'cliff' ? 'Greendeck' : 'Cliff'}{' '}
            account
          </Paragraph>
          {this.props.signin_forbidden && this.forbiddenAlert}
        </div>
        {this.props.signin_error ? (
          <Alert
            className="ant-mt-5"
            message={this.props.signin_error || 'Sorry something went wrong.'}
            type="error"
            banner
          />
        ) : null}

        {window.env.GET_REACT_APP_SHOW_LOGIN_FORM &&
          window.env.GET_REACT_APP_SHOW_LOGIN_FORM === 'true' &&
          (this.state.saved_accounts ? (
            <>
              <Collapse
                align="left"
                accordion
                expandIconPosition="right"
                onChange={this.callback}
                className="ant-mt-3"
              >
                {savedAccountsList
                  ? savedAccountsList.map(item => (
                      <Panel
                        align="left"
                        key={JSON.stringify(
                          savedAccountsList.indexOf(item) + 1
                        )}
                        header={
                          <>
                            <Row type="flex">
                              <Col span={4}>
                                <Avatar
                                  shape="circle"
                                  size={40}
                                  className="ant-bg-primary ant-mr-3"
                                >
                                  {item.first_name.charAt(0).toUpperCase() +
                                    item.last_name.charAt(0).toUpperCase()}
                                </Avatar>
                              </Col>
                              <Col span={20}>
                                <Text strong>
                                  {convertToTitleCase(item.first_name) +
                                    ' ' +
                                    convertToTitleCase(item.last_name)}
                                </Text>
                                <br />
                                <Text small>{item.email}</Text>
                              </Col>
                            </Row>
                          </>
                        }
                      >
                        <Form
                          onSubmit={value =>
                            this.handleSubmitCollapse(value, item.email)
                          }
                          layout="vertical"
                          className="ant-mt-3"
                          key={JSON.stringify(
                            savedAccountsList.indexOf(item) + 1
                          )}
                        >
                          <Form.Item
                            label={
                              <small className="ant-text-muted">
                                YOUR PASSWORD
                              </small>
                            }
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            hasFeedback
                            key={JSON.stringify(
                              savedAccountsList.indexOf(item) + 1
                            )}
                          >
                            {getFieldDecorator('password', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your Password!'
                                }
                              ]
                            })(
                              <Input.Password
                                size="large"
                                type="password"
                                placeholder="Password"
                              />
                            )}
                          </Form.Item>
                          <Form.Item>
                            {getFieldDecorator('remember', {
                              valuePropName: 'checked',
                              initialValue: true
                            })(<Checkbox>Remember me</Checkbox>)}
                          </Form.Item>
                          <Form.Item>
                            <Row type="flex">
                              <Col xs={12} align="center" className="ant-pr-1">
                                <Button
                                  size="large"
                                  type="link"
                                  style={{ width: '55%' }}
                                >
                                  <Link to="/forgot_password">
                                    Forgot Password?
                                  </Link>
                                </Button>
                              </Col>
                              <Col xs={12} align="center">
                                <Button
                                  type="primary"
                                  style={{ width: '75%' }}
                                  size="large"
                                  htmlType="submit"
                                  loading={
                                    this.props.signin_start ? true : false
                                  }
                                >
                                  Login
                                </Button>
                              </Col>
                              <Col
                                xs={{ offset: 8 }}
                                lg={{ offset: 12 }}
                                align="center"
                              >
                                <Button
                                  size="medium"
                                  className=" ant-mt-4"
                                  type="link"
                                  icon="stop"
                                  style={{ color: 'red' }}
                                  onClick={() =>
                                    this.handleRemoveSavedAccount(item.email)
                                  }
                                >
                                  Forget this account
                                </Button>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Form>
                      </Panel>
                    ))
                  : null}
              </Collapse>

              <div className="ant-mt-4">
                <Button
                  type="primary"
                  className="ant-w-100"
                  size="large"
                  htmlType="submit"
                  onClick={() => this.handleChangeView('saved_accounts_view')}
                >
                  Use a different account?
                </Button>
              </div>
            </>
          ) : (
            <div align="left">
              <Form
                onSubmit={this.handleSubmit}
                layout="vertical"
                className="ant-mt-5"
              >
                <Form.Item
                  label={
                    <small className="ant-text-muted">YOUR EMAIL ADDRESS</small>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  hasFeedback
                >
                  {getFieldDecorator('email', {
                    initialValue: this.state.selectedSavedEmail
                      ? this.state.selectedSavedEmail
                      : email_param.email,
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid email !'
                      }
                    ]
                  })(<Input size="large" placeholder="Email" />)}
                </Form.Item>
                <Form.Item
                  label={
                    <small className="ant-text-muted">YOUR PASSWORD</small>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  hasFeedback
                >
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Please input your Password!' }
                    ]
                  })(
                    <Input.Password
                      size="large"
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true
                  })(<Checkbox>Remember me</Checkbox>)}
                </Form.Item>
                <Form.Item>
                  <Row type="flex">
                    <Col span={12} align="center">
                      <Button size="large" style={{ width: '75%' }} type="link">
                        <Link to="/forgot_password">Forgot Password?</Link>
                      </Button>
                    </Col>
                    <Col span={12} align="center">
                      <Button
                        type="primary"
                        style={{ width: '75%' }}
                        size="large"
                        htmlType="submit"
                        loading={this.props.signin_start ? true : false}
                      >
                        Login
                      </Button>
                    </Col>
                    <Col span={24} align="left" className="ant-mt-3">
                      Don't have an account?{' '}
                      <Link to="/signup">
                        <Button type="link" className="ant-font-bold">
                          Sign up
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </div>
          ))}

        {window.env.GET_REACT_APP_DEPLOYMENT_TYPE !== 'cloud' &&
          window.env.GET_REACT_APP_SHOW_LOGIN_FORM === 'true' && (
            <Divider>OR</Divider>
          )}

        {window.env.GET_REACT_APP_DEPLOYMENT_TYPE &&
          window.env.GET_REACT_APP_DEPLOYMENT_TYPE !== 'cloud' && (
            <Fragment>
              <Button
                className="ant-mt-2"
                type="primary"
                block
                size="large"
                href={this.getSSOLoginLink()}
              >
                Login using SSO
              </Button>
            </Fragment>
          )}

        {!this.props.user_info_success &&
        !this.props.user_info_error &&
        !this.props.user_info_start ? null : this.props.user_info_start ? (
          <div className="ant-bg-light ant-p-5 ant-mt-4" align="left">
            <Skeleton
              paragraph={{ rows: 2, height: '6px' }}
              title={{ height: '6px' }}
              active
            />
          </div>
        ) : this.props.user_info_error ? (
          <Text>Sorry something went wrong on our end. </Text>
        ) : this.props.user_info_success &&
          this.props.userInfoData &&
          userInfoData &&
          userInfoData.ip &&
          userInfoData.geo &&
          userInfoData.geo.country &&
          userInfoData.geo.city ? (
          <div className="ant-bg-light ant-p-5 ant-mt-4" align="left">
            <Text>
              <small>Your IP address: {userInfoData.ip} </small>
            </Text>
            <br />
            <Text>
              <small>
                Location:{' '}
                <i
                  className={
                    'flag flag-16 flag-' +
                    userInfoData.geo.country.toLowerCase()
                  }
                ></i>
                {userInfoData.geo.city}, {userInfoData.geo.country}{' '}
              </small>{' '}
            </Text>
            <br />
            <Text>
              <small>
                We log your IP address to ensure that your account is always
                safe
              </small>
            </Text>
          </div>
        ) : null}
      </SignInLayout>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    signin_error,
    signin_start,
    signin_success,
    signin_forbidden,
    userInfoData,
    user_info_success,
    user_info_start,
    user_info_error,
    initUserData,
    user_data,
    default_organiztion_preferences
  } = auth;
  return {
    token,
    signin_error,
    signin_start,
    signin_success,
    signin_forbidden,
    userInfoData,
    user_info_success,
    user_info_start,
    user_info_error,
    initUserData,
    user_data,
    default_organiztion_preferences
  };
};

export default connect(mapStateToProps, { userSignIn, getUserInfo })(
  WrappedNormalLoginForm
);
