//delete a collection
export const REMOVE_SAVED_PRODUCTS_START = 'REMOVE_SAVED_PRODUCTS_START';
export const REMOVE_SAVED_PRODUCTS_SUCCESS = 'REMOVE_SAVED_PRODUCTS_SUCCESS';
export const REMOVE_SAVED_PRODUCTS_ERROR = 'REMOVE_SAVED_PRODUCTS_ERROR';

//get a saved collection from elastic

export const FETCH_SAVED_PRODUCT_COLLECTIONS_START =
  'FETCH_SAVED_PRODUCT_COLLECTIONS_START';
export const FETCH_SAVED_PRODUCT_COLLECTIONS_SUCCESS =
  'FETCH_SAVED_PRODUCT_COLLECTIONS_SUCCESS';
export const FETCH_SAVED_PRODUCT_COLLECTIONS_ERROR =
  'FETCH_SAVED_PRODUCT_COLLECTIONS_ERROR';

//update saved product collection
export const UPDATE_SAVED_PRODUCTS_START = 'UPDATE_SAVED_PRODUCTS_START';
export const UPDATE_SAVED_PRODUCTS_SUCCESS = 'UPDATE_SAVED_PRODUCTS_SUCCESS';
export const UPDATE_SAVED_PRODUCTS_ERROR = 'UPDATE_SAVED_PRODUCTS_ERROR';

//fetch Saved Products Start
export const SAVE_PRODUCT_ERROR = 'SAVE_PRODUCT_ERROR';
export const SAVE_PRODUCT_START = 'SAVE_PRODUCT_START';
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS';

//Fetch Saved Products
export const FETCH_SAVED_PRODUCTS_START = 'FETCH_SAVED_PRODUCTS_START';
export const FETCH_SAVED_PRODUCTS_SUCCESS = 'FETCH_SAVED_PRODUCTS_SUCCESS';
export const FETCH_SAVED_PRODUCTS_ERROR = 'FETCH_SAVED_PRODUCTS_ERROR';

//Fetch Products
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

//Fetch Products Stats
export const FETCH_PRODUCTS_STATS_START = 'FETCH_PRODUCTS_STATS_START';
export const FETCH_PRODUCTS_STATS_SUCCESS = 'FETCH_PRODUCTS_STATS_SUCCESS';
export const FETCH_PRODUCTS_STATS_ERROR = 'FETCH_PRODUCTS_STATS_ERROR';

//Fetch Products Pricing History
export const PRODUCT_PRICING_HISTORY_START = 'PRODUCT_PRICING_HISTORY_START';
export const PRODUCT_PRICING_HISTORY_SUCCESS =
  'PRODUCT_PRICING_HISTORY_SUCCESS';
export const PRODUCT_PRICING_HISTORY_ERROR = 'PRODUCT_PRICING_HISTORY_ERROR';

//Fetch Newsletters
export const FETCH_NEWSLETTERS_START = 'FETCH_NEWSLETTERS_START';
export const FETCH_NEWSLETTERS_SUCCESS = 'FETCH_NEWSLETTERS_SUCCESS';
export const FETCH_NEWSLETTERS_ERROR = 'FETCH_NEWSLETTERS_ERROR';

//Fetch Newsletters Stats
export const FETCH_NEWSLETTERS_STATS_START = 'FETCH_NEWSLETTERS_STATS_START';
export const FETCH_NEWSLETTERS_STATS_SUCCESS =
  'FETCH_NEWSLETTERS_STATS_SUCCESS';
export const FETCH_NEWSLETTERS_STATS_ERROR = 'FETCH_NEWSLETTERS_STATS_ERROR';

//Fetch Saved Newsletters
export const FETCH_SAVED_NEWSLETTERS_START = 'FETCH_SAVED_NEWSLETTERS_START';
export const FETCH_SAVED_NEWSLETTERS_SUCCESS =
  'FETCH_SAVED_NEWSLETTERS_SUCCESS';
export const FETCH_SAVED_NEWSLETTERS_ERROR = 'FETCH_SAVED_NEWSLETTERS_ERROR';

//Save Newsletter
export const SAVE_NEWSLETTER_START = 'SAVE_NEWSLETTER_START';
export const SAVE_NEWSLETTER_SUCCESS = 'SAVE_NEWSLETTER_SUCCESS';
export const SAVE_NEWSLETTER_ERROR = 'SAVE_NEWSLETTER_ERROR';

//Newsletter Collection
export const NEWSLETTERS_COLLECTIONS_INFO_START =
  'NEWSLETTERS_COLLECTIONS_INFO_START';
export const NEWSLETTERS_COLLECTIONS_INFO_SUCCESS =
  'SNEWSLETTERS_COLLECTIONS_INFO_SUCCESS';
export const NEWSLETTERS_COLLECTIONS_INFO_ERROR =
  'NEWSLETTERS_COLLECTIONS_INFO_ERROR';

//Fetch Snapshots
export const FETCH_SNAPSHOTS_START = 'FETCH_SNAPSHOTS_START';
export const FETCH_SNAPSHOTS_SUCCESS = 'FETCH_SNAPSHOTS_SUCCESS';
export const FETCH_SNAPSHOTS_ERROR = 'FETCH_SNAPSHOTS_ERROR';

//Fetch Snapshots Stats
export const FETCH_SNAPSHOTS_STATS_START = 'FETCH_SNAPSHOTS_STATS_START';
export const FETCH_SNAPSHOTS_STATS_SUCCESS = 'FETCH_SNAPSHOTS_STATS_SUCCESS';
export const FETCH_SNAPSHOTS_STATS_ERROR = 'FETCH_SNAPSHOTS_STATS_ERROR';

//Fetch Saved Snapshots
export const FETCH_SAVED_SNAPSHOTS_START = 'FETCH_SAVED_SNAPSHOTS_START';
export const FETCH_SAVED_SNAPSHOTS_SUCCESS = 'FETCH_SAVED_SNAPSHOTS_SUCCESS';
export const FETCH_SAVED_SNAPSHOTS_ERROR = 'FETCH_SAVED_SNAPSHOTS_ERROR';

//Save Snapshot
export const SAVE_SNAPSHOT_START = 'SAVE_SNAPSHOT_START';
export const SAVE_SNAPSHOT_SUCCESS = 'SAVE_SNAPSHOT_SUCCESS';
export const SAVE_SNAPSHOT_ERROR = 'SAVE_SNAPSHOT_ERROR';

//Snapshots Collection
export const SNAPSHOTS_COLLECTIONS_INFO_START =
  'SNAPSHOTS_COLLECTIONS_INFO_START';
export const SNAPSHOTS_COLLECTIONS_INFO_SUCCESS =
  'SNAPSHOTS_COLLECTIONS_INFO_SUCCESS';
export const SNAPSHOTS_COLLECTIONS_INFO_ERROR =
  'SNAPSHOTS_COLLECTIONS_INFO_ERROR';

//Fetch Promotions History
export const FETCH_PROMOTIONS_HISTORY_START = 'FETCH_PROMOTIONS_HISTORY_START';
export const FETCH_PROMOTIONS_HISTORY_SUCCESS =
  'FETCH_PROMOTIONS_HISTORY_SUCCESS';
export const FETCH_PROMOTIONS_HISTORY_ERROR = 'FETCH_PROMOTIONS_HISTORY_ERROR';

//Fetch Promotional Trend
export const FETCH_PROMOTIONAL_TREND_START = 'FETCH_PROMOTIONAL_TREND_START';
export const FETCH_PROMOTIONAL_TREND_SUCCESS =
  'FETCH_PROMOTIONAL_TREND_SUCCESS';
export const FETCH_PROMOTIONAL_TREND_ERROR = 'FETCH_PROMOTIONAL_TREND_ERROR';

//Price Positioning
export const PRICE_POSITIONING_START = 'PRICE_POSITIONING_START';
export const PRICE_POSITIONING_SUCCESS = 'PRICE_POSITIONING_SUCCESS';
export const PRICE_POSITIONING_ERROR = 'PRICE_POSITIONING_ERROR';
export const PRICE_POSITIONING_EXPORT_START = 'PRICE_POSITIONING_EXPORT_START';
export const PRICE_POSITIONING_EXPORT_SUCCESS =
  'PRICE_POSITIONING_EXPORT_SUCCESS';
export const PRICE_POSITIONING_EXPORT_ERROR = 'PRICE_POSITIONING_EXPORT_ERROR';

//Get Classification Treee
export const GET_CLASSIFICATION_TREE_START = 'GET_CLASSIFICATION_TREE_START';
export const GET_CLASSIFICATION_TREE_SUCCESS =
  'GET_CLASSIFICATION_TREE_SUCCESS';
export const GET_CLASSIFICATION_TREE_ERROR = 'GET_CLASSIFICATION_TREE_ERROR';

export const GET_CLASSIFICATION_TREE_START2 = 'GET_CLASSIFICATION_TREE_START2';
export const GET_CLASSIFICATION_TREE_SUCCESS2 =
  'GET_CLASSIFICATION_TREE_SUCCESS2';
export const GET_CLASSIFICATION_TREE_ERROR2 = 'GET_CLASSIFICATION_TREE_ERROR2';

//Export Data
export const EXPORT_DATA_START = 'EXPORT_DATA_START';
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_ERROR = 'EXPORT_DATA_ERROR';

//Fetch Sales
export const FETCH_SALES_START = 'FETCH_SALES_START';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_ERROR = 'FETCH_SALES_ERROR';

//Fetch Sales Stats
export const FETCH_SALES_STATS_START = 'FETCH_SALES_STATS_START';
export const FETCH_SALES_STATS_SUCCESS = 'FETCH_SALES_STATS_SUCCESS';
export const FETCH_SALES_STATS_ERROR = 'FETCH_SALES_STATS_ERROR';

//Export Excel
export const FETCH_EXPORTEXCEL_START = 'FETCH_EXPORTEXCEL_START';
export const FETCH_EXPORTEXCEL_SUCCESS = 'FETCH_EXPORTEXCEL_SUCCESS';
export const FETCH_EXPORTEXCEL_ERROR = 'FETCH_EXPORTEXCEL_ERROR';

//Fetch Assortment
export const FETCH_ASSORTMENT_START = 'FETCH_ASSORTMENT_START';
export const FETCH_ASSORTMENT_SUCCESS = 'FETCH_ASSORTMENT_SUCCESS';
export const FETCH_ASSORTMENT_ERROR = 'FETCH_ASSORTMENT_ERROR';
export const FETCH_ASSORTMENT_HISTORY_START = 'FETCH_ASSORTMENT_HISTORY_START';
export const FETCH_ASSORTMENT_HISTORY_SUCCESS =
  'FETCH_ASSORTMENT_HISTORY_SUCCESS';
export const FETCH_ASSORTMENT_HISTORY_ERROR = 'FETCH_ASSORTMENT_HISTORY_ERROR';

//Fetch Architecture
export const FETCH_ARCHITECTURE_START = 'FETCH_ARCHITECTURE_START';
export const FETCH_ARCHITECTURE_SUCCESS = 'FETCH_ARCHITECTURE_SUCCESS';
export const FETCH_ARCHITECTURE_ERROR = 'FETCH_ARCHITECTURE_ERROR';

//Fetch Architecture analytics
export const FETCH_ARCHITECTURE_ANALYTICS_START =
  'FETCH_ARCHITECTURE_ANALYTICS_START';
export const FETCH_ARCHITECTURE_ANALYTICS_SUCCESS =
  'FETCH_ARCHITECTURE_ANALYTICS_SUCCESS';
export const FETCH_ARCHITECTURE_ANALYTICS_ERROR =
  'FETCH_ARCHITECTURE_ANALYTICS_ERROR';

//Fetch Options
export const FETCH_PRICE_DISTRIBUTION_START = 'FETCH_PRICE_DISTRIBUTION_START';
export const FETCH_PRICE_DISTRIBUTION_SUCCESS =
  'FETCH_PRICE_DISTRIBUTION_SUCCESS';
export const FETCH_PRICE_DISTRIBUTION_ERROR = 'FETCH_PRICE_DISTRIBUTION_ERROR';

// Fetch New ins
export const FETCH_NEWINS_START = 'FETCH_NEWINSS_START';
export const FETCH_NEWINS_SUCCESS = 'FETCH_NEWINSS_SUCCESS';
export const FETCH_NEWINS_ERROR = 'FETCH_NEWINSS_ERROR';

// Fetch Brand Comparison
export const FETCH_BRAND_COMPARISON_START = 'FETCH_BRAND_COMPARISON_START';
export const FETCH_BRAND_COMPARISON_SUCCESS = 'FETCH_BRAND_COMPARISON_SUCCESS';
export const FETCH_BRAND_COMPARISON_ERROR = 'FETCH_BRAND_COMPARISON_ERROR';

// Fetch Brand Comparison
export const FETCH_BRAND_COMPARISON_ANALYTICS_START =
  'FETCH_BRAND_COMPARISON_ANALYTICS_START';
export const FETCH_BRAND_COMPARISON_ANALYTICS_SUCCESS =
  'FETCH_BRAND_COMPARISON_ANALYTICS_SUCCESS';
export const FETCH_BRAND_COMPARISON_ANALYTICS_ERROR =
  'FETCH_BRAND_COMPARISON_ANALYTICS_ERROR';

// Fetch Travel Sales
export const FETCH_TRAVEL_SALES_START = 'FETCH_TRAVEL_SALES_START';
export const FETCH_TRAVEL_SALES_SUCCESS = 'FETCH_TRAVEL_SALES_SUCCESS';
export const FETCH_TRAVEL_SALES_ERROR = 'FETCH_TRAVEL_SALES_ERROR';

// Fetch Travel Products
export const FETCH_TRAVEL_PRODUCTS_START = 'FETCH_TRAVEL_PRODUCTS_START';
export const FETCH_TRAVEL_PRODUCTS_SUCCESS = 'FETCH_TRAVEL_PRODUCTS_SUCCESS';
export const FETCH_TRAVEL_PRODUCTS_ERROR = 'FETCH_TRAVEL_PRODUCTS_ERROR';

//Fetch Travel Products Stats
export const FETCH_TRAVEL_PRODUCTS_STATS_START =
  'FETCH_TRAVEL_PRODUCTS_STATS_START';
export const FETCH_TRAVEL_PRODUCTS_STATS_SUCCESS =
  'FETCH_TRAVEL_PRODUCTS_STATS_SUCCESS';
export const FETCH_TRAVEL_PRODUCTS_STATS_ERROR =
  'FETCH_TRAVEL_PRODUCTS_STATS_ERROR';

// Fetch Taxonomy
export const FETCH_TAXONOMY_START = 'FETCH_TAXONOMY_START';
export const FETCH_TAXONOMY_SUCCESS = 'FETCH_TAXONOMY_SUCCESS';
export const FETCH_TAXONOMY_ERROR = 'FETCH_TAXONOMY_ERROR';

// Fetch Taxonomy Products
export const FETCH_TAXONOMY_PRODUCTS_START = 'FETCH_TAXONOMY_PRODUCTS_START';
export const FETCH_TAXONOMY_PRODUCTS_SUCCESS =
  'FETCH_TAXONOMY_PRODUCTS_SUCCESS';
export const FETCH_TAXONOMY_PRODUCTS_ERROR = 'FETCH_TAXONOMY_PRODUCTS_ERROR';

// Fetch Product Matching
export const FETCH_PRODUCT_MATCHING_START = 'FETCH_PRODUCT_MATCHING_START';
export const FETCH_PRODUCT_MATCHING_SUCCESS = 'FETCH_PRODUCT_MATCHING_SUCCESS';
export const FETCH_PRODUCT_MATCHING_ERROR = 'FETCH_PRODUCT_MATCHING_ERROR';

// Match Product
export const FETCH_MATCH_PRODUCT_START = 'FETCH_MATCH_PRODUCT_START';
export const FETCH_MATCH_PRODUCT_SUCCESS = 'FETCH_MATCH_PRODUCT_SUCCESS';
export const FETCH_MATCH_PRODUCT_ERROR = 'FETCH_MATCH_PRODUCT_ERROR';

// Match Product
export const FETCH_INVENTORY_ITEMS_START = 'FETCH_INVENTORY_ITEMS_START';
export const FETCH_INVENTORY_ITEMS_SUCCESS = 'FETCH_INVENTORY_ITEMS_SUCCESS';
export const FETCH_INVENTORY_ITEMS_ERROR = 'FETCH_INVENTORY_ITEMS_ERROR';

// Match Product
export const FETCH_INVENTORY_BRAND_SUMMARY_START =
  'FETCH_INVENTORY_BRAND_SUMMARY_START';
export const FETCH_INVENTORY_BRAND_SUMMARY_SUCCESS =
  'FETCH_INVENTORY_BRAND_SUMMARY_SUCCESS';
export const FETCH_INVENTORY_BRAND_SUMMARY_ERROR =
  'FETCH_INVENTORY_BRAND_SUMMARY_ERROR';

// Match Product
export const FETCH_INVENTORY_CLASSIFICATION_SUMMARY_START =
  'FETCH_INVENTORY_CLASSIFICATION_SUMMARY_START';
export const FETCH_INVENTORY_CLASSIFICATION_SUMMARY_SUCCESS =
  'FETCH_INVENTORY_CLASSIFICATION_SUMMARY_SUCCESS';
export const FETCH_INVENTORY_CLASSIFICATION_SUMMARY_ERROR =
  'FETCH_INVENTORY_CLASSIFICATION_SUMMARY_ERROR';

// fetch metrics
export const FETCH_METRICS_START = 'FETCH_METRICS_START';
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_ERROR = 'FETCH_METRICS_ERROR';
export const GET_METRIC_DATA_BY_ID_START = 'GET_METRIC_DATA_BY_ID_START';
export const GET_METRIC_DATA_BY_ID_SUCCESS = 'GET_METRIC_DATA_BY_ID_SUCCESS';
export const GET_METRIC_DATA_BY_ID_ERROR = 'GET_METRIC_DATA_BY_ID_ERROR';
export const GET_CALENDAR_HEATMAP_DATA_START =
  'GET_CALENDAR_HEATMAP_DATA_START';
export const GET_CALENDAR_HEATMAP_DATA_SUCCESS =
  'GET_CALENDAR_HEATMAP_DATA_SUCCESS';
export const GET_CALENDAR_HEATMAP_DATA_ERROR =
  'GET_CALENDAR_HEATMAP_DATA_ERROR';

export const GET_HEAT_MAP_DATA_START = 'GET_HEAT_MAP_DATA_START';
export const GET_HEAT_MAP_DATA_SUCCESS = 'GET_HEAT_MAP_DATA_SUCCESS';
export const GET_HEAT_MAP_DATA_ERROR = 'GET_HEAT_MAP_DATA_ERROR';

//metric comments
export const GET_ALL_COMMENTS_START = 'GET_ALL_COMMENTS_START';
export const GET_ALL_COMMENTS_SUCCESS = 'GET_ALL_COMMENTS_SUCCESS';
export const GET_ALL_COMMENTS_ERROR = 'GET_ALL_COMMENTS_ERROR';
export const POST_METRIC_COMMENT_START = 'POST_METRIC_COMMENT_START';
export const POST_METRIC_COMMENT_SUCCESS = 'POST_METRIC_COMMENT_SUCCESS';
export const POST_METRIC_COMMENT_ERROR = 'POST_METRIC_COMMENT_ERROR';

//metrics related metrices
export const GET_RELATED_METRCS_START = 'GET_RELATED_METRCS_START';
export const GET_RELATED_METRCS_SUCCESS = 'GET_RELATED_METRCS_SUCCESS';
export const GET_RELATED_METRCS_ERROR = 'GET_RELATED_METRCS_ERROR';

//get measures list
export const GET_MEASURES_LIST_START = 'GET_MEASURES_LIST_START';
export const GET_MEASURES_LIST_SUCCESS = 'GET_MEASURES_LIST_SUCCESS';
export const GET_MEASURES_LIST_ERROR = 'GET_MEASURES_LIST_ERROR';

// fetch alerts for anomaly detection
export const FETCH_ALERTS_START = 'FETCH_ALERTS_START';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_ERROR = 'FETCH_ALERTS_ERROR';
export const FETCH_ALERTS_SUMMARY_START = 'FETCH_ALERTS_SUMMARY_START';
export const FETCH_ALERTS_SUMMARY_SUCCESS = 'FETCH_ALERTS_SUMMARY_SUCCESS';
export const FETCH_ALERTS_SUMMARY_ERROR = 'FETCH_ALERTS_SUMMARY_ERROR';
export const FETCH_ALERT_RULES_START = 'FETCH_ALERT_RULES_START';
export const FETCH_ALERT_RULES_SUCCESS = 'FETCH_ALERT_RULES_SUCCESS';
export const FETCH_ALERT_RULES_ERROR = 'FETCH_ALERT_RULES_ERROR';
export const GET_ALERT_RULES_START = 'GET_ALERT_RULES_START';
export const GET_ALERT_RULES_SUCCESS = 'GET_ALERT_RULES_SUCCESS';
export const GET_ALERT_RULES_ERROR = 'GET_ALERT_RULES_ERROR';
export const SET_ALERTS_CURRENT_PAGE = 'SET_ALERTS_CURRENT_PAGE';

//favourite metrics
export const GET_FAVOURITE_METRICS_START = 'GET_FAVOURITE_METRICS_START';
export const GET_FAVOURITE_METRICS_SUCCESS = 'GET_FAVOURITE_METRICS_SUCCESS';
export const GET_FAVOURITE_METRICS_ERROR = 'GET_FAVOURITE_METRICS_ERROR';

// dashboard activities
export const GET_ACTIVITIES_START = 'GET_ACTIVITIES_START';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_ERROR = 'GET_ACTIVITIES_ERROR';

// get price changes
export const GET_PRICE_CHANGES_START = 'GET_PRICE_CHANGES_START';
export const GET_PRICE_CHANGES_SUCCESS = 'GET_PRICE_CHANGES_SUCCESS';
export const GET_PRICE_CHANGES_ERROR = 'GET_PRICE_CHANGES_ERROR';

// get price changes
export const FETCH_NEWINS_HISTORY_START = 'FETCH_NEWINS_HISTORY_START';
export const FETCH_NEWINS_HISTORY_SUCCESS = 'FETCH_NEWINS_HISTORY_SUCCESS';
export const FETCH_NEWINS_HISTORY_ERROR = 'FETCH_NEWINS_HISTORY_ERROR';

// get keywords history
export const FETCH_KEYWORDS_HISTORY_START = 'FETCH_KEYWORDS_HISTORY_START';
export const FETCH_KEYWORDS_HISTORY_SUCCESS = 'FETCH_KEYWORDS_HISTORY_SUCCESS';
export const FETCH_KEYWORDS_HISTORY_ERROR = 'FETCH_KEYWORDS_HISTORY_ERROR';
export const FETCH_KEYWORDS_HISTORY_EMPTY = 'FETCH_KEYWORDS_HISTORY_EMPTY';

// internal monitoring daily/retailer stats
export const GET_MONITORING_DAILY_START = 'GET_MONITORING_DAILY_START';
export const GET_MONITORING_DAILY_SUCCESS = 'GET_MONITORING_DAILY_SUCCESS';
export const GET_MONITORING_DAILY_ERROR = 'GET_MONITORING_DAILY_ERROR';

export const GET_MONITORING_RETAILER_START = 'GET_MONITORING_RETAILER_START';
export const GET_MONITORING_RETAILER_SUCCESS =
  'GET_MONITORING_RETAILER_SUCCESS';
export const GET_MONITORING_RETAILER_ERROR = 'GET_MONITORING_RETAILER_ERROR';

// get stores data
export const GET_STORES_START = 'GET_STORES_START';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_ERROR = 'GET_STORES_ERROR';

// export assortment data
export const EXPORT_ASSORTMENT_DATA_START = 'EXPORT_ASSORTMENT_DATA_START';
export const EXPORT_ASSORTMENT_DATA_SUCCESS = 'EXPORT_ASSORTMENT_DATA_SUCCESS';
export const EXPORT_ASSORTMENT_DATA_ERROR = 'EXPORT_ASSORTMENT_DATA_ERROR';

// export newins data
export const EXPORT_NEWINS_DATA_START = 'EXPORT_NEWINS_DATA_START';
export const EXPORT_NEWINS_DATA_SUCCESS = 'EXPORT_NEWINS_DATA_SUCCESS';
export const EXPORT_NEWINS_DATA_ERROR = 'EXPORT_NEWINS_DATA_ERROR';

// export architecture data
export const EXPORT_ARCHITECTURE_DATA_START = 'EXPORT_ARCHITECTURE_DATA_START';
export const EXPORT_ARCHITECTURE_DATA_SUCCESS =
  'EXPORT_ARCHITECTURE_DATA_SUCCESS';
export const EXPORT_ARCHITECTURE_DATA_ERROR = 'EXPORT_ARCHITECTURE_DATA_ERROR';

// export overlap-analysis data
export const EXPORT_OVERLAP_ANALYSIS_DATA_START =
  'EXPORT_OVERLAP_ANALYSIS_DATA_START';
export const EXPORT_OVERLAP_ANALYSIS_DATA_SUCCESS =
  'EXPORT_OVERLAP_ANALYSIS_DATA_SUCCESS';
export const EXPORT_OVERLAP_ANALYSIS_DATA_ERROR =
  'EXPORT_OVERLAP_ANALYSIS_DATA_ERROR';

// overlap analysis data
export const GET_OVERLAP_ANALYSIS_START = 'GET_OVERLAP_ANALYSIS_START';
export const GET_OVERLAP_ANALYSIS_SUCCESS = 'GET_OVERLAP_ANALYSIS_SUCCESS';
export const GET_OVERLAP_ANALYSIS_ERROR = 'GET_OVERLAP_ANALYSIS_ERROR';
