import {
  FETCH_DATASTREAMS_START,
  FETCH_DATASTREAMS_SUCCESS,
  FETCH_DATASTREAMS_SUCCESS_TOTAL,
  FETCH_DATASTREAMS_ERROR,
  GET_DATASTREAM_START,
  GET_DATASTREAM_SUCCESS,
  GET_DATASTREAM_ERROR,
  GET_SOURCE_SUBSCRIPTION_START,
  GET_SOURCE_SUBSCRIPTION_SUCCESS,
  GET_SOURCE_SUBSCRIPTION_ERROR,
  GET_STREAM_META_DATA_START,
  GET_STREAM_META_DATA_SUCCESS,
  GET_STREAM_META_DATA_ERROR,
  // Schedule
  GET_DATASCHEDULE_START,
  GET_DATASCHEDULE_SUCCESS,
  GET_DATASCHEDULE_ERROR,
  GET_ALL_DATASTREAM_START,
  GET_ALL_DATASTREAM_SUCCESS,
  GET_ALL_DATASTREAM_ERROR
} from '../../../../constants/CliffApiConstants';

const INIT_STATE = {
  initURL: '',
  streams: [],
  streamData: [],
  scheduleData: [],
  sourceSubscriptionData: {},
  sourceSubscriptionLoader: false,
  streamMetaData: {},
  totalRowCount: 0,
  activeStreams: [],
  inactiveStreams: [],
  fetch_datastreams_success: false,
  fetch_datastreams_error: false,
  fetch_datastreams_start: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_DATASTREAMS_START: {
      return {
        ...state,
        fetch_datastreams_success: false,
        fetch_datastreams_error: false,
        fetch_datastreams_start: true
      };
    }
    case FETCH_DATASTREAMS_SUCCESS: {
      const activeStreams = payload.streams.filter(
        stream => stream.status === 'live' || stream.status === 'in_progress'
      );

      const inactiveStreams = payload.streams.filter(
        stream =>
          stream.status === 'paused' ||
          stream.status === 'limit_exceeded' ||
          stream.status === 'broken_connection'
      );

      return {
        ...state,
        fetch_datastreams_success: true,
        fetch_datastreams_error: false,
        fetch_datastreams_start: false,
        streams: payload.streams,
        activeStreams,
        inactiveStreams,
        activeStreamsCount: activeStreams.length || state.activeStreamsCount,
        inactiveStreamsCount:
          inactiveStreams.length === 0
            ? 0
            : inactiveStreams.length || state.inactiveStreamsCount
      };
    }

    case FETCH_DATASTREAMS_SUCCESS_TOTAL: {
      return {
        ...state,
        fetch_datastreams_success: true,
        fetch_datastreams_error: false,
        fetch_datastreams_start: false,
        total: payload
      };
    }

    case FETCH_DATASTREAMS_ERROR: {
      return {
        ...state,
        fetch_datastreams_success: false,
        fetch_datastreams_error: true,
        fetch_datastreams_start: false
      };
    }

    //GET ALL DATA STREAMS
    case GET_ALL_DATASTREAM_START: {
      return {
        ...state,
        fetch_all_datastreams_success: false,
        fetch_all_datastreams_error: false,
        fetch_all_datastreams_start: true
      };
    }

    case GET_ALL_DATASTREAM_SUCCESS: {
      return {
        ...state,
        fetch_all_datastreams_success: true,
        fetch_all_datastreams_error: false,
        fetch_all_datastreams_start: false,
        all_streams: payload
      };
    }

    case GET_ALL_DATASTREAM_ERROR: {
      return {
        ...state,
        fetch_all_datastreams_success: false,
        fetch_all_datastreams_error: true,
        fetch_all_datastreams_start: false
      };
    }

    // Schedule Data Reducer start
    case GET_DATASCHEDULE_START: {
      return {
        ...state,
        scheduleData: [],
        get_scheduledata_success: false,
        get_scheduledata_error: false,
        get_scheduledata_start: true
      };
    }

    case GET_DATASCHEDULE_SUCCESS: {
      return {
        ...state,
        scheduleData: payload,
        get_scheduledata_success: true,
        get_scheduledata_error: false,
        get_scheduledata_start: false
      };
    }

    case GET_DATASCHEDULE_ERROR: {
      return {
        ...state,
        scheduleData: [],
        get_scheduledata_success: false,
        get_scheduledata_error: true,
        get_scheduledata_start: false
      };
    }
    // Schedule Data Reducer Ends

    case GET_DATASTREAM_START: {
      return {
        ...state,
        streamData: [],
        get_datastream_success: false,
        get_datastream_error: false,
        get_datastream_start: true
      };
    }

    case GET_DATASTREAM_SUCCESS: {
      return {
        ...state,
        streamData: payload.rows,
        totalRowCount: payload.total,
        get_datastream_success: true,
        get_datastream_error: false,
        get_datastream_start: false
      };
    }

    case GET_DATASTREAM_ERROR: {
      return {
        ...state,
        streamData: [],
        get_datastream_success: false,
        get_datastream_error: true,
        get_datastream_start: false
      };
    }

    case GET_SOURCE_SUBSCRIPTION_START: {
      return { ...state, sourceSubscriptionLoader: true };
    }

    case GET_SOURCE_SUBSCRIPTION_SUCCESS: {
      const data = payload.sort((a, b) => (a.id > b.id ? 1 : -1));
      return {
        ...state,
        sourceSubscriptionLoader: false,
        sourceSubscriptionData: data
      };
    }

    case GET_SOURCE_SUBSCRIPTION_ERROR: {
      return { ...state, sourceSubscriptionLoader: false };
    }

    case 'GET_SUBSCRIPTION_STREAMS_START': {
      return {
        ...state,
        get_subscriptions_streams_start: true,
        get_subscriptions_streams_success: false,
        get_subscriptions_streams_false: false,
        subscriptionStreams: []
      };
    }

    case 'GET_SUBSCRIPTION_STREAMS_SUCCESS': {
      return {
        ...state,
        subscriptionStreams: payload,
        get_subscriptions_streams_start: false,
        get_subscriptions_streams_success: true,
        get_subscriptions_streams_error: false
      };
    }

    case 'GET_SUBSCRIPTION_STREAMS_ERROR': {
      return {
        ...state,
        get_subscriptions_streams_start: false,
        get_subscriptions_streams_success: false,
        get_subscriptions_streams_error: true
      };
    }

    case GET_STREAM_META_DATA_START: {
      return {
        ...state,
        metaDataLOader: true,
        get_metadata_start: true,
        get_metadata_success: false,
        get_metadata_error: false
      };
    }

    case GET_STREAM_META_DATA_SUCCESS: {
      return {
        ...state,
        metaDataLOader: false,
        streamMetaData: payload,
        get_metadata_start: false,
        get_metadata_success: true,
        get_metadata_error: false
      };
    }

    case GET_STREAM_META_DATA_ERROR: {
      return {
        ...state,
        metaDataLOader: false,
        get_metadata_start: false,
        get_metadata_success: false,
        get_metadata_error: true
      };
    }

    case 'GET_DIMENSION_UNIQUE_VALUES_SUCCESS': {
      return {
        ...state,
        get_dimension_unique_values_success: true,
        get_dimension_unique_values_start: false,
        get_dimension_unique_values_error: false,
        uniqueDimensions: payload
      };
    }

    case 'GET_DIMENSION_UNIQUE_VALUES_START': {
      return {
        ...state,
        get_dimension_unique_values_success: false,
        get_dimension_unique_values_start: true,
        get_dimension_unique_values_error: false
      };
    }

    case 'GET_DIMENSION_UNIQUE_VALUES_ERROR': {
      return {
        ...state,
        get_dimension_unique_values_success: false,
        get_dimension_unique_values_start: false,
        get_dimension_unique_values_error: true
      };
    }

    case 'REFRESH_STREAMS': {
      return {
        ...state,
        refreshToken: payload
      };
    }

    default:
      return state;
  }
};
