import React, { Component } from 'react';
import { getRegionsList, selectRegion } from '../../appRedux/actions/Auth';
import { connect } from 'react-redux';
import {
  Layout,
  Popover,
  message,
  Button,
  Menu,
  Row,
  Skeleton,
  Affix,
  Tooltip,
  Typography,
  Spin,
  Icon
} from '@greendeck/atomic';

const { Text } = Typography;

const spinnerIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

class RegionSwitcher extends Component {
  render() {
    if (this.props.regionsData) {
      var regionsList = this.props.regionsData.available_regions;
    }

    const regionMenu = (
      <Menu>
        {!this.props.get_region_start &&
        !this.props.get_region_error &&
        !this.props.get_region_success ? null : this.props.get_region_start ? (
          <Spin indicator={spinnerIcon} />
        ) : this.props.get_region_error ? (
          <span> Something went wrong. </span>
        ) : this.props.get_region_success && this.props.regionsData ? (
          regionsList.map(region => (
            <Menu.Item
              key={JSON.stringify(region)}
              onClick={e => this.props.selectRegion(region)}
            >
              <Row type="flex">
                <div className="ant-mt-2">
                  <i className={`flag flag-24 flag-${region.code}`} />
                </div>
                <div>
                  <span className="ant-ml-3">
                    {region.name == 'Global'
                      ? 'All regions'
                      : region.name + ' (' + region.code.toUpperCase() + ')'}
                  </span>
                </div>
              </Row>
            </Menu.Item>
          ))
        ) : null}
      </Menu>
    );

    return (
      <Tooltip title="Switch Region" placement="right">
        <Popover
          placement="bottomRight"
          content={<Affix>{regionMenu}</Affix>}
          trigger="click"
          className="ant-ml-3 animated fadeIn"
        >
          <Button onClick={() => this.props.getRegionsList()}>
            {this.props.initUserData ? (
              <>
                {this.props.initUserData.selected_region == 'global'
                  ? 'All regions'
                  : this.props.initUserData.selected_region.toUpperCase()}{' '}
              </>
            ) : null}
          </Button>
        </Popover>
      </Tooltip>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    subscribedWebsitesData,
    regionsData,
    get_region_start,
    get_region_success,
    get_region_error,
    select_region_success,
    select_region_error,
    select_region_start,
    initUserData
  } = auth;
  return {
    subscribedWebsitesData,
    get_region_start,
    get_region_success,
    get_region_error,
    regionsData,
    select_region_success,
    select_region_error,
    select_region_start,
    initUserData
  };
};

export default connect(mapStateToProps, { getRegionsList, selectRegion })(
  RegionSwitcher
);
