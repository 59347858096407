import {
  GET_SOURCES_START,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_ERROR,
  GET_SOURCE_START,
  GET_SOURCE_SUCCESS,
  GET_SOURCE_ERROR,
  AUTHORIZE_DATA_SOURCE_START,
  AUTHORIZE_DATA_SOURCE_SUCCESS,
  AUTHORIZE_DATA_SOURCE_ERROR,
  DELETE_SOURCE_SUBSCRIPTION_START,
  DELETE_SOURCE_SUBSCRIPTION_SUCCESS,
  DELETE_SOURCE_SUBSCRIPTION_ERROR,
  AUTHORIZE_SENTRY_TOKEN_START,
  AUTHORIZE_SENTRY_TOKEN_SUCCESS,
  AUTHORIZE_SENTRY_TOKEN_ERROR,
  GET_SENTRY_ACCOUNTS_START,
  GET_SENTRY_ACCOUNTS_SUCCESS,
  GET_SENTRY_ACCOUNTS_ERROR,
  GET_ALL_SOURCE_SUBSCRIPTIONS_START,
  GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR,
  CHANGE_RELATED_STREAMS_BOOLEAN,
  SET_SOURCE_TEMPLATES,
  GET_ALL_SOURCES_START,
  GET_ALL_SOURCES_SUCCESS,
  GET_ALL_SOURCES_ERROR,
  GET_SOURCE_SUBSCRIPTION_BY_ID_START,
  GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS,
  GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR,
  CLEAR_SOURCE_SUBSCRIPTION,
  CLEAR_SOURCE_OBJECT
} from '../../../../constants/CliffApiConstants';

const INIT_STATE = {
  get_sources_start: false,
  get_sources_success: false,
  get_sources_error: false,
  sources: [],
  outboundSources: [],
  deleteSourceLoader: false,
  deleteSourceSuccess: false,
  sentryAuthorized: false,
  sentryFormLoader: false,
  sentryAccounts: [],
  sentryAccountsLoader: false,
  sentryError: '',
  sentryMessage: '',
  sourceTemplates: [],
  allSubscriptions: [],
  source: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SOURCES_START: {
      return {
        ...state,
        get_sources_start: true,
        get_sources_success: false,
        get_sources_error: false,
        deleteSourceSuccess: false
      };
    }

    case GET_SOURCES_SUCCESS: {
      const sources = action.payload.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
      return {
        ...state,
        get_sources_start: false,
        get_sources_success: true,
        get_sources_error: false,
        sources
      };
    }

    case GET_SOURCES_ERROR: {
      return {
        ...state,
        get_sources_start: false,
        get_sources_success: false,
        get_sources_error: true
      };
    }

    case GET_SOURCE_START: {
      return {
        ...state,
        get_source_start: true,
        get_source_success: false,
        get_source_error: false
      };
    }

    case GET_SOURCE_SUCCESS: {
      return {
        ...state,
        get_source_start: false,
        get_source_success: true,
        get_source_error: false,
        source: action.payload
      };
    }

    case GET_SOURCE_ERROR: {
      return {
        ...state,
        get_source_start: false,
        get_source_success: false,
        get_source_error: true
      };
    }

    case CLEAR_SOURCE_OBJECT: {
      return {
        ...state,
        source: {},
        get_source_start: true,
        get_source_success: false,
        get_source_error: false
      };
    }

    case AUTHORIZE_DATA_SOURCE_START: {
      return {
        ...state,
        authorize_data_source_start: true,
        authorize_data_source_success: false,
        authorize_data_source_error: false
      };
    }

    case AUTHORIZE_DATA_SOURCE_SUCCESS: {
      return {
        ...state,
        authMessage: action.payload,
        authorize_data_source_start: false,
        authorize_data_source_success: true,
        authorize_data_source_error: false
      };
    }

    case AUTHORIZE_DATA_SOURCE_ERROR: {
      return {
        ...state,
        authorize_data_source_start: false,
        authorize_data_source_success: false,
        authorize_data_source_error: true
      };
    }

    case DELETE_SOURCE_SUBSCRIPTION_START: {
      return {
        ...state,
        deleteSourceSuccess: false,
        deleteSourceLoader: true
      };
    }

    case DELETE_SOURCE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        deleteSourceLoader: false,
        deleteSourceSuccess: true
      };
    }

    case DELETE_SOURCE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        deleteSourceSuccess: false,
        deleteSourceLoader: false
      };
    }

    case AUTHORIZE_SENTRY_TOKEN_START: {
      return {
        ...state,
        sentryFormLoader: true,
        sentryError: '',
        sentryMessage: '',
        sentryAuthorized: false
      };
    }

    case AUTHORIZE_SENTRY_TOKEN_SUCCESS: {
      return {
        ...state,
        sentryMessage: action.payload.alreadyExists
          ? 'Token already exists in organization'
          : '',
        sentryFormLoader: false,
        sentryAuthorized: true
      };
    }

    case AUTHORIZE_SENTRY_TOKEN_ERROR: {
      return {
        ...state,
        sentryError: action.payload,
        sentryFormLoader: false,
        sentryAuthorized: false
      };
    }

    case GET_SENTRY_ACCOUNTS_START: {
      return {
        ...state,
        sentryAccountsLoader: true
      };
    }

    case GET_SENTRY_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        sentryAccountsLoader: false,
        sentryAccounts: action.payload.accounts
      };
    }

    case GET_SENTRY_ACCOUNTS_ERROR: {
      return {
        ...state,
        sentryAccountsLoader: false
      };
    }

    case GET_ALL_SOURCE_SUBSCRIPTIONS_START: {
      return {
        ...state,
        deleteSourceSuccess: false,
        get_all_source_subscriptions_start: true,
        get_all_source_subscriptions_success: false,
        get_all_source_subscriptions_error: false
      };
    }

    case GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        allSubscriptions: action.payload,
        get_all_source_subscriptions_start: false,
        get_all_source_subscriptions_success: true,
        get_all_source_subscriptions_error: false
      };
    }

    case GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        get_all_source_subscriptions_start: false,
        get_all_source_subscriptions_success: false,
        get_all_source_subscriptions_error: true
      };
    }

    case GET_ALL_SOURCES_START: {
      return {
        ...state,
        get_all_sources_start: true,
        get_all_sources_success: false,
        get_all_sources_error: false
      };
    }

    case GET_ALL_SOURCES_SUCCESS: {
      return {
        ...state,
        allSources: action.payload,
        get_all_sources_start: false,
        get_all_sources_success: true,
        get_all_sources_error: false
      };
    }

    case GET_ALL_SOURCES_ERROR: {
      return {
        ...state,
        get_all_sources_start: false,
        get_all_sources_success: false,
        get_all_sources_error: true
      };
    }

    //

    case GET_SOURCE_SUBSCRIPTION_BY_ID_START: {
      return {
        ...state,
        get_source_subscription_by_id_start: true,
        get_source_subscription_by_id_success: false,
        get_source_subscription_by_id_error: false
      };
    }

    case GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS: {
      return {
        ...state,
        sourceSubscription: action.payload,
        get_source_subscription_by_id_start: false,
        get_source_subscription_by_id_success: true,
        get_source_subscription_by_id_error: false
      };
    }
    case GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR: {
      return {
        ...state,
        get_source_subscription_by_id_start: false,
        get_source_subscription_by_id_success: false,
        get_source_subscription_by_id_error: true
      };
    }

    //

    case CHANGE_RELATED_STREAMS_BOOLEAN: {
      return {
        ...state,
        relatedStreamsBool: action.payload
      };
    }

    case SET_SOURCE_TEMPLATES: {
      return {
        ...state,
        sourceTemplates: action.payload
      };
    }

    case CLEAR_SOURCE_SUBSCRIPTION: {
      return {
        ...state,
        sourceSubscription: null
      };
    }

    default:
      return state;
  }
};
