import {
  FETCH_SAVED_PRODUCTS_START,
  FETCH_SAVED_PRODUCTS_SUCCESS,
  FETCH_SAVED_PRODUCTS_ERROR,
  SAVE_PRODUCT_ERROR,
  SAVE_PRODUCT_START,
  SAVE_PRODUCT_SUCCESS,
  UPDATE_SAVED_PRODUCTS_START,
  UPDATE_SAVED_PRODUCTS_SUCCESS,
  UPDATE_SAVED_PRODUCTS_ERROR,
  FETCH_SAVED_PRODUCT_COLLECTIONS_START,
  FETCH_SAVED_PRODUCT_COLLECTIONS_SUCCESS,
  FETCH_SAVED_PRODUCT_COLLECTIONS_ERROR,
  REMOVE_SAVED_PRODUCTS_START,
  REMOVE_SAVED_PRODUCTS_SUCCESS,
  REMOVE_SAVED_PRODUCTS_ERROR
} from '../../../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REMOVE_SAVED_PRODUCTS_START: {
      return {
        ...state,
        remove_saved_products_success: false,
        remove_saved_products_error: false,
        remove_saved_products_start: true
      };
    }

    case REMOVE_SAVED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        remove_saved_products_success: true,
        remove_saved_products_error: false,
        remove_saved_products_start: false
      };
    }

    case REMOVE_SAVED_PRODUCTS_ERROR: {
      return {
        ...state,
        remove_saved_products_success: false,
        remove_saved_products_error: true,
        remove_saved_products_start: false
      };
    }

    case FETCH_SAVED_PRODUCT_COLLECTIONS_START: {
      return {
        ...state,
        fetch_saved_product_collections_success: false,
        fetch_saved_product_collections_error: false,
        fetch_saved_product_collections_start: true
      };
    }

    case FETCH_SAVED_PRODUCT_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        savedProductCollectionsData: action.payload,
        fetch_saved_product_collections_success: true,
        fetch_saved_product_collections_error: false,
        fetch_saved_product_collections_start: false
      };
    }

    case FETCH_SAVED_PRODUCT_COLLECTIONS_ERROR: {
      return {
        ...state,
        fetch_saved_product_collections_success: false,
        fetch_saved_product_collections_error: true,
        fetch_saved_product_collections_start: false
      };
    }

    case UPDATE_SAVED_PRODUCTS_START: {
      return {
        ...state,
        update_saved_products_success: false,
        update_saved_products_error: false,
        update_saved_products_start: true
      };
    }

    case UPDATE_SAVED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        updateProductsData: action.payload,
        update_saved_products_success: true,
        update_saved_products_error: false,
        update_saved_products_start: false
      };
    }

    case UPDATE_SAVED_PRODUCTS_ERROR: {
      return {
        ...state,
        update_saved_products_success: false,
        update_saved_products_error: true,
        update_saved_products_start: false
      };
    }
    case FETCH_SAVED_PRODUCTS_START: {
      return {
        ...state,
        fetch_saved_products_success: false,
        fetch_saved_products_error: false,
        fetch_saved_products_start: true
      };
    }

    case FETCH_SAVED_PRODUCTS_SUCCESS: {
      return {
        ...state,
        savedProductsData: action.payload,
        fetch_saved_products_success: true,
        fetch_saved_products_error: false,
        fetch_saved_products_start: false
      };
    }

    case FETCH_SAVED_PRODUCTS_ERROR: {
      return {
        ...state,
        fetch_saved_products_success: false,
        fetch_saved_products_error: true,
        fetch_saved_products_start: false
      };
    }
    case SAVE_PRODUCT_ERROR: {
      return {
        ...state,
        save_product_success: false,
        save_product_error: true,
        save_product_start: false
      };
    }

    case SAVE_PRODUCT_START: {
      return {
        ...state,
        save_product_success: false,
        save_product_error: false,
        save_product_start: true
      };
    }

    case SAVE_PRODUCT_SUCCESS: {
      return {
        ...state,
        save_product_success: true,
        save_product_error: false,
        save_product_start: false
      };
    }
    default:
      return state;
  }
};
