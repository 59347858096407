import {
  SET_VERIFIED_START,
  SET_VERIFIED_ERROR,
  SET_VERIFIED_SUCCESS,
  SET_FLAGGED_SUCCESS,
  SET_FLAGGED_START,
  SET_FLAGGED_ERROR
} from '../../constants/ProductSimilarity';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_VERIFIED_START: {
      return {
        ...state,
        set_verified_start: true,
        set_verified_error: false,
        set_verified_success: false
      };
    }

    case SET_VERIFIED_SUCCESS: {
      return {
        ...state,
        set_verified_start: false,
        set_verified_error: false,
        set_verified_success: true
      };
    }

    case SET_VERIFIED_ERROR: {
      return {
        ...state,
        set_verified_start: false,
        set_verified_error: true,
        set_verified_success: false
      };
    }

    case SET_FLAGGED_SUCCESS: {
      console.log({ DFH: action });
      return {
        ...state,
        set_flagged_start: false,
        set_flagged_error: false,
        set_flagged_success: true,
        flagged_id: action.id
      };
    }

    case SET_FLAGGED_START: {
      return {
        ...state,
        set_flagged_start: true,
        set_flagged_error: false,
        set_flagged_success: false,
        flagged_id: action.id
      };
    }

    case SET_FLAGGED_ERROR: {
      return {
        ...state,
        set_flagged_start: false,
        set_flagged_error: true,
        set_flagged_success: false
      };
    }

    default:
      return state;
  }
};
