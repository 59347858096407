export const ADD_FILTER = 'ADD_FILTER';
export const EDIT_FILTER = 'EDIT_FILTER';
export const EDIT_FILTER_START = 'SET_FILTER_START';
export const EDIT_FILTER_DONE = 'EDIT_FILTER_DONE';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const SET_EDIT_FILTER = 'SET_EDIT_FILTER';
export const LOAD_SAVED_FILTER = 'LOAD_SAVED_FILTER';
export const SET_FILTERS = 'SET_FILTERS';
export const CUSTOM_FILTER = 'CUSTOM_FILTER';
export const SHOW_POPOVER = 'SHOW_POPOVER';
export const HIDE_POPOVER = 'HIDE_POPOVER';
