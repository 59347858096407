import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Progress,
  Tooltip,
  Icon
} from '@greendeck/atomic';
import { connect } from 'react-redux';
import { getAllSubscriptions } from '../../routes/apps/anomaly-detection/integrations/IntegrationsController';
import { getSourceSubscriptionByOrgId } from '../../routes/apps/anomaly-detection/data-streams/DataStreamsController';
import { getAlertRules } from '../../routes/apps/anomaly-detection/alerts/AlertsController';
const { Title } = Typography;

function OnboardingProgress(props) {
  const [check, setCheck] = useState(0);
  useEffect(() => {
    props.getAllSubscriptions();
    props.getAlertRules();
    props.getSourceSubscriptionByOrgId();
  }, []);

  useEffect(() => {
    let current_check = 0;
    if (props.allSubscriptions.length !== 0) current_check++;
    if (props.streams.length > 1) current_check++;
    if (props.alertRules.length !== 0) current_check++;
    setCheck(current_check);
  }, [
    props.allSubscriptions.length,
    props.streams.length,
    props.alertRules.length
  ]);
  return (
    <Row
    // className="ant-pl-1"
    >
      <Col
        // xs={21}
        span={24}
        // align = 'center'
        className={
          window.screen.width > 800 ? 'ant-ml-2 ant-pr-2' : 'ant-ml-3 ant-pr-1'
        }
      >
        <Tooltip title={`Onboarding ${check}/3`}>
          <Row
            type="flex"
            align="center"
            className="ant-pl-2"
            // gutter={10}
          >
            <Col className="ant-pr-2" span={18}>
              <Progress
                percent={(check / 3) * 100}
                strokeColor="#6078ff"
                showInfo={false}
              />
            </Col>
            <Col
              span={1}
              className={props.isCollapsed ? `ant-mr-1` : `ant-ml-2`}
            >
              {check == 3 && (
                <Icon
                  className="ant-mt-1"
                  style={{ color: '#23dc8e' }}
                  type="check-circle"
                  theme="filled"
                />
              )}
            </Col>
          </Row>
        </Tooltip>
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ integrations, dataStreams, alerts }) => {
  const { allSubscriptions } = integrations;
  const { streams } = dataStreams;
  const { alertRules } = alerts;
  return {
    allSubscriptions,
    streams,
    alertRules
  };
};

export default connect(mapStateToProps, {
  getAllSubscriptions,
  getSourceSubscriptionByOrgId,
  getAlertRules
})(OnboardingProgress);
