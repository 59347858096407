import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './assets/vendors/flag/sprite-flags-24x24.css';
import './assets/vendors/flag/sprite-flags-16x16.css';
import './assets/vendors/flag/sprite-flags-48x48.css';
import './assets/styles/custom.css';
import '@greendeck/atomic/dist/@greendeck/atomic.css';
import configureStore, { history } from './appRedux/store';
import App from './containers/App/index';
import CacheBuster from './components/CacheBuster/CacheBuster.js';
import { Alert } from '@greendeck/atomic';

export const store = configureStore();

const NextApp = () => (
  <>
    <CacheBuster>
      {({
        loading,
        isLatestVersion,
        refreshCacheAndReload,
        currentVersion,
        latestVersion
      }) => {
        if (loading) return null;
        // the app is not running the latest version
        if (!loading && !isLatestVersion) {
          if (window.env.GET_REACT_APP_ENV == 'production') {
            refreshCacheAndReload();
          } else {
            return (
              <div>
                {!window.location.href.includes('localhost') && (
                  <Alert
                    type="info"
                    message={
                      <span>
                        You're running <code>v{currentVersion}</code> but there
                        is a new version <code>v{latestVersion}</code>{' '}
                        availaible{' '}
                        <strong>
                          {' '}
                          <a
                            className="ant-font-bold"
                            onClick={refreshCacheAndReload}
                          >
                            <u className="ant-ml-1">Refresh now</u>
                          </a>
                        </strong>{' '}
                      </span>
                    }
                  ></Alert>
                )}
              </div>
            );
          }
        }
        //The app is running the latest version
        if (!loading && isLatestVersion) {
          return null;
        }

        return null;
      }}
    </CacheBuster>
    <Provider store={store}>
      <BrowserRouter basename={window.env.PUBLIC_URL || ''} history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </>
);

export default NextApp;
