import axios from 'util/config/accounts';
import {
  FETCH_ALERTS_START,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_ERROR,
  FETCH_ALERTS_SUMMARY_START,
  FETCH_ALERTS_SUMMARY_SUCCESS,
  FETCH_ALERTS_SUMMARY_ERROR,
  FETCH_ALERT_RULES_START,
  FETCH_ALERT_RULES_SUCCESS,
  FETCH_ALERT_RULES_ERROR,
  GET_ALERT_RULES_START,
  GET_ALERT_RULES_SUCCESS,
  GET_ALERT_RULES_ERROR,
  SET_ALERTS_CURRENT_PAGE
} from '../../../../constants/ApiActionTypes';

import { ALERTS_LIST } from '../../../../constants/RequestTypes';

import { makeRequest } from 'util/request/RequestUtil';
import { makeRequestCliff } from 'util/request/cliffApi';
import { fileToObject } from '@greendeck/atomic/lib/upload/utils';

let organizationId = JSON.parse(localStorage.getItem('organization_id'));

// export const getAlerts = filterObject => {
//   // optionalQueryArgs are passed as query parameters only if no filter is applied
//   return dispatch => {
//     dispatch({ type: FETCH_ALERTS_START });
//     let url =
//       'v1/alerts/organizations/' +
//       JSON.parse(localStorage.getItem('organization_id')) +
//       '/list';
//     // const queryParams = new URLSearchParams(optionalQueryArgs);
//     // const queryString = queryParams.toString();

//     // if (filterObject.conditions.conditionParams.length === 0 && queryString) {
//     //   url = url + '?' + queryString;
//     // }
//     const obj = {
//       conditions: {
//         condition: 'and',
//         conditionParams: []
//       }
//     };

//     let config = {
//       method: 'post',
//       url: url,
//       data: filterObject
//     };
//     makeRequestCliff(config, 'ALERTS_LIST')
//       .then(({ data }) => {
//         if (data) {
//           dispatch({ type: FETCH_ALERTS_SUCCESS, payload: data });
//         }
//       })
//       .catch(function(error) {
//         if (error.message !== 'cancel') {
//           dispatch({ type: FETCH_ALERTS_ERROR });
//         }
//       });
//   };
// };

export const getAlertsByRuleId = filterObj => {
  return dispatch => {
    dispatch({ type: FETCH_ALERTS_START });
    let url = 'v2/alerts.getAlertsByRuleId';

    let config = {
      method: 'post',
      url: url,
      data: filterObj
    };
    makeRequestCliff(config, 'ALERTS_LIST')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_ALERTS_SUCCESS, payload: data });
        }
      })
      .catch(function(error) {
        if (error.message !== 'cancel') {
          dispatch({ type: FETCH_ALERTS_ERROR });
        }
      });
  };
};

export const getMembers = () => {
  axios.defaults.headers.common['Authorization'] = JSON.parse(
    localStorage.getItem('token')
  );
  return axios.get(
    'v2/organizations/' +
      JSON.parse(localStorage.getItem('organization_id')) +
      '/members',
    {}
  );
};

export const getChannels = () => {
  const url = `v3/channels.list`;
  let config = {
    method: 'post',
    url,
    data: {
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    }
  };
  return makeRequestCliff(config, 'GET ALL CHANNELS');
};

export const createAlertRule = data => {
  const url = `v1/alert-rules/`;
  let config = {
    method: 'post',
    url,
    data
  };
  return makeRequestCliff(config, 'CREATE_RULE');
};

export const updateAlertRule = (alertId, data) => {
  const url = `v1/alert-rules/${alertId}`;
  let config = {
    method: 'put',
    url,
    data
  };
  return makeRequestCliff(config, 'UPDATE_RULE');
};

export const deleteAlertRule = alertId => {
  const url = `v1/alert-rules/${alertId}`;
  let config = {
    method: 'delete',
    url
  };
  return makeRequestCliff(config, 'DELETE_RULE');
};

export const deleteMultipleAlertRules = alertIds => {
  const url = `/v1/alert-rules/delete`;
  let config = {
    method: 'post',
    url,
    data: { rule_ids: alertIds }
  };
  return makeRequestCliff(config, 'DELETE_RULES');
};

export const getAffectedAlertMetrics = (metrics, startTime, endTime) => {
  const url = `/v1/alerts/affected-metrics?startTime=${startTime}&endTime=${endTime}`;
  let config = {
    method: 'post',
    url,
    data: { metrics }
  };
  return makeRequestCliff(config, 'GET_AFFECTED_METRICS');
};

// export const getAlertDetails = id => {
//   const url = `/v1/alerts/details/${id}`;
//   let config = {
//     method: 'get',
//     url
//   };
//   return makeRequestCliff(config, 'GET_ALERT_DETAILS');
// };

export const getAlertDetails = id => dispatch => {
  dispatch({ type: GET_ALERT_RULES_START });
  const url = `/v1/alerts/details/${id}`;
  let config = {
    method: 'get',
    url: url
  };

  makeRequestCliff(config, 'GET ALERT DETAILS')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ALERT_RULES_SUCCESS,
          payload: data.alert_details
        });
      } else {
        dispatch({ type: GET_ALERT_RULES_ERROR });
      }
    })
    .catch(function(error) {
      dispatch({ type: GET_ALERT_RULES_ERROR });
    });
};

export const updateAlertDetails = (id, data) => {
  const url = `/v1/alerts/details/${id}`;
  let config = {
    method: 'put',
    url,
    data
  };
  return makeRequestCliff(config, 'UPDATE_ALERT_DETAILS');
};

export const getDimensionBreakdown = id => {
  const url = `/v1/alerts/dimension-breakdown/${id}`;
  let config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_DIMENSION_BREAKDOWN');
};

export const getAlertStatistics = alertRuleId => {
  const url = `/v2/alerts.averageDailyStatistics`;
  let config = {
    method: 'post',
    url,
    data: {
      alertRuleId
    }
  };
  return makeRequestCliff(config, 'GET_ALERTS_STASTISTICS');
};

export const getAlertRules = () => {
  organizationId = JSON.parse(localStorage.getItem('organization_id'));

  return dispatch => {
    dispatch({ type: FETCH_ALERT_RULES_START });
    const url = `v1/alert-rules/organization/${organizationId}`;

    let config = {
      method: 'get',
      url: url
    };
    makeRequestCliff(config, 'ALERT_RULES_LIST')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: FETCH_ALERT_RULES_SUCCESS,
            payload: data.alert_rules
          });
        } else {
          dispatch({ type: FETCH_ALERT_RULES_ERROR });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ALERT_RULES_ERROR });
      });
  };
};

export const getAlertRule = id => {
  const url = `v1/alert-rules/${id}`;
  let config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_ALERT_RULE');
};

export const getAlertMeasureEstimates = data => {
  const url = `v1/alert-rules/alerts-estimate`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'GET_ALERT_ESTIMATES');
};

export const setAlertsPage = page => dispatch => {
  dispatch({ type: SET_ALERTS_CURRENT_PAGE, payload: page });
};

export const getSlackChannels = sub_id => {
  const url = `/v1/source-subscriptions/slack/subscriptions/${sub_id}/channels`;
  let config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, `GET_ALERT_CHANNELS_${sub_id}`);
};
export const getAlertSummary = ruleId => {
  return dispatch => {
    dispatch({ type: FETCH_ALERTS_SUMMARY_START });
    const url = `/v1/alerts/summary/${ruleId}`;
    let config = {
      method: 'get',
      url
    };
    makeRequestCliff(config, 'GET_ALERT_SUMMARY')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_ALERTS_SUMMARY_SUCCESS, payload: data });
        }
      })
      .catch(function(error) {
        if (error.message !== 'cancel') {
          dispatch({ type: FETCH_ALERTS_SUMMARY_ERROR });
        }
      });
  };
};
