import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import pkg from '../package.json';
import { BrowserRouter as Router } from 'react-router-dom';
import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';
import { envEncryption } from './secureEnv';

if (window.env.GET_REACT_APP_ENV != 'development') {
  Sentry.init({
    dsn: 'https://f7ae859ee7b44f919bd1f17d1c6f90bc@sentry.io/1740415',
    environment: window.env.GET_REACT_APP_ENV,
    release: 'greendeck-frontend@' + pkg.version
  });
}

// Wrap the rendering in a function:
const render = Component => {
  var title = document.getElementById('greendeck-title');
  var icon = document.getElementById('app-icon');
  title.innerHTML = 'Greendeck';
  let ico = icon.href.split('/');
  ico[3] = 'icon.ico';
  ico = ico.join('/');
  let reactAppName = window.env.GET_REACT_APP_NAME;
  if (!reactAppName) {
    reactAppName = envEncryption.decryptParsed(window.env.REACT_APP_NAME);
  }
  if (reactAppName === 'cliff') {
    title.innerHTML = 'Cliff.ai';
    icon.href = ico;
  }

  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp />
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
