import {
  FETCH_ARCHITECTURE_START,
  FETCH_ARCHITECTURE_SUCCESS,
  FETCH_ARCHITECTURE_ERROR,
  FETCH_ARCHITECTURE_ANALYTICS_START,
  FETCH_ARCHITECTURE_ANALYTICS_SUCCESS,
  FETCH_ARCHITECTURE_ANALYTICS_ERROR,
  EXPORT_ARCHITECTURE_DATA_START,
  EXPORT_ARCHITECTURE_DATA_SUCCESS,
  EXPORT_ARCHITECTURE_DATA_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ARCHITECTURE_START: {
      return {
        ...state,
        fetch_architecture_success: false,
        fetch_architecture_error: false,
        fetch_architecture_start: true
      };
    }

    case FETCH_ARCHITECTURE_SUCCESS: {
      return {
        ...state,
        fetch_architecture_success: true,
        fetch_architecture_error: false,
        fetch_architecture_start: false,
        architectureData: action.payload
      };
    }

    case FETCH_ARCHITECTURE_ERROR: {
      return {
        ...state,
        fetch_architecture_success: false,
        fetch_architecture_error: true,
        fetch_architecture_start: false
      };
    }

    case FETCH_ARCHITECTURE_ANALYTICS_START: {
      return {
        ...state,
        fetch_architecture_analytics_success: false,
        fetch_architecture_analytics_error: false,
        fetch_architecture_analytics_start: true
      };
    }

    case FETCH_ARCHITECTURE_ANALYTICS_SUCCESS: {
      action.payload.hits = action.payload.hits.map(hit => ({
        ...hit,
        price_history: hit.price_history.map(history => ({
          ...history,
          date: new Date(history.date)
        }))
      }));
      console.log(action.payload.hits);
      return {
        ...state,
        fetch_architecture_analytics_success: true,
        fetch_architecture_analytics_error: false,
        fetch_architecture_analytics_start: false,
        architectureAnalyticsData: action.payload
      };
    }

    case FETCH_ARCHITECTURE_ANALYTICS_ERROR: {
      return {
        ...state,
        fetch_architecture_analytics_success: false,
        fetch_architecture_analytics_error: true,
        fetch_architecture_analytics_start: false
      };
    }

    case EXPORT_ARCHITECTURE_DATA_START: {
      return {
        ...state,
        export_architecture_data_start: true,
        export_architecture_data_success: false,
        export_architecture_data_error: false
      };
    }

    case EXPORT_ARCHITECTURE_DATA_SUCCESS: {
      return {
        ...state,
        export_architecture_data_start: false,
        export_architecture_data_success: true,
        export_architecture_data_error: false,
        exportData: action.payload
      };
    }
    case EXPORT_ARCHITECTURE_DATA_ERROR: {
      return {
        ...state,
        export_architecture_data_start: false,
        export_architecture_data_success: false,
        export_architecture_data_error: true
      };
    }

    default:
      return state;
  }
};
