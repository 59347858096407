const codeSplitterLoadFallBack = async (
  componentFactoryPromise, //lazy component
  maxRetries = 10, // number of retries
  retryAfter = 750 //try loading component after particular time to provided in ms
) => {
  //handle base case if max number of retries exhausted
  //reload Page in this situations
  if (maxRetries === 0) {
    window.location.reload();
  } else {
    try {
      //try to resolve promise
      return await componentFactoryPromise();
    } catch (_) {
      // if this promise gets rejected then then call again this recursive callback
      //do recursion over this retryAfter time at the same time decrease number of retries
      setTimeout(() => {
        codeSplitterLoadFallBack(
          componentFactoryPromise,
          maxRetries - 1,
          retryAfter
        );
      }, retryAfter);
    }
  }
};

export default codeSplitterLoadFallBack;
