import {
  FETCH_TRAVEL_PRODUCTS_START,
  FETCH_TRAVEL_PRODUCTS_SUCCESS,
  FETCH_TRAVEL_PRODUCTS_ERROR,
  FETCH_TRAVEL_PRODUCTS_STATS_START,
  FETCH_TRAVEL_PRODUCTS_STATS_SUCCESS,
  FETCH_TRAVEL_PRODUCTS_STATS_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRAVEL_PRODUCTS_START: {
      return {
        ...state,
        fetch_travel_products_success: false,
        fetch_travel_products_error: false,
        fetch_travel_products_start: true
      };
    }

    case FETCH_TRAVEL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        fetch_travel_products_success: true,
        fetch_travel_products_error: false,
        fetch_travel_products_start: false,
        travelProductsData: action.payload
      };
    }

    case FETCH_TRAVEL_PRODUCTS_ERROR: {
      return {
        ...state,
        fetch_travel_products_success: false,
        fetch_travel_products_error: true,
        fetch_travel_products_start: false
      };
    }

    case FETCH_TRAVEL_PRODUCTS_STATS_START: {
      return {
        ...state,
        fetch_travel_products_stats_success: false,
        fetch_travel_products_stats_error: false,
        fetch_travel_products_stats_start: true
      };
    }

    case FETCH_TRAVEL_PRODUCTS_STATS_SUCCESS: {
      return {
        ...state,
        fetch_travel_products_stats_success: true,
        fetch_travel_products_stats_error: false,
        fetch_travel_products_stats_start: false,
        travelProductsStatsData: action.payload
      };
    }

    case FETCH_TRAVEL_PRODUCTS_STATS_ERROR: {
      return {
        ...state,
        fetch_travel_products_stats_success: false,
        fetch_travel_products_stats_error: true,
        fetch_travel_products_stats_start: false
      };
    }

    default:
      return state;
  }
};
