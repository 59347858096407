import React, { Suspense } from 'react';
import { Row, Col } from '@greendeck/atomic';
import Spinner from '../Loader/Spinner';

const CodeSplitter = ({ codeSplittingComponent, fallBackComponent }) => {
  return (
    <Suspense
      fallback={
        fallBackComponent || (
          <Row type="flex" align="center" justify="center">
            <Col span={24}>
              <Spinner spinnerSize={5} />
            </Col>
          </Row>
        )
      }
    >
      {codeSplittingComponent}
    </Suspense>
  );
};

export default CodeSplitter;
