import Dexie from 'dexie';

const DEFUALT_VERSION = 1;

export const filtersDB = new Dexie('GreendeckFiltersDB');
filtersDB.version(DEFUALT_VERSION).stores({
  savedFilters: '[user_org_combo+view_name], filter'
});

export const urlDB = new Dexie('GreendeckUrlDB');
urlDB.version(DEFUALT_VERSION).stores({
  savedURLs: 'user_org_combo, url'
});

export const operatorsDB = new Dexie('GreendeckOperatorsDB');
operatorsDB.version(DEFUALT_VERSION).stores({
  savedOperators: '[user_org_combo+filter_key], operator'
});

export const recentFiltersDB = new Dexie('GreendeckRecentFiltersDB');
recentFiltersDB.version(DEFUALT_VERSION).stores({
  recentFilters: '[user_org_combo+view_name], recent_filters'
});
