import React from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Icon,
  Row,
  Col,
  Alert,
  message,
  Typography
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  userSignUp,
  userEmailVerification,
  userResendEmailVerificationCode
} from '../appRedux/actions/Auth';
import { Redirect } from 'react-router-dom';
import AuthLayout from '../components/Layouts/AuthLayout';
import OtpInput from 'react-otp-input';
import { loadURLFromLocal } from 'util/indexDB/urlCRUD';
import { string } from 'prop-types';
const { Text, Title, Paragraph } = Typography;

const inputStyle = {
  width: '200px'
};

class EmailVerification extends React.Component {
  state = {
    loading: false,
    isSelectedCheckbox: false,
    userdata: '',
    otp: '',
    userEmail: ''
  };

  handleEmailVerificationSubmit = () => {
    const values = this.state.otp;
    this.setState({
      verificationLoader: true
    });
    let user_id;
    user_id = JSON.parse(localStorage.getItem('user_id'));
    if (user_id) {
      this.props.userEmailVerification({
        otp: this.state.otp,
        userId: user_id
      });
    }
  };

  handleResendEmailVerification = () => {
    // if (this.props.user_data) {
    let user_id;
    user_id = JSON.parse(localStorage.getItem('user_id'));
    userResendEmailVerificationCode(user_id).then(({ data }) => {
      if (data) {
        message.success(
          'Verification code has been successfully sent to your registered email address'
        );
      } else {
        message.error('Something went wrong please try again');
      }
    });
    // }
  };

  handleChange = otp => {
    this.setState({ otp });
    console.log(otp);
  };

  componentDidMount() {
    if (!this.props.user_data) {
      console.log(this.props.user_data);
      // this.props.history.push('/signin');
    }
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.email_verification_error !==
        prevProps.email_verification_error &&
      this.props.email_verification_error === true
    ) {
      message.error(this.props.email_verification_data);
      this.setState({
        verificationLoader: false
      });
    }
    if (this.props.user_data !== prevProps.user_data && !this.props.user_data) {
      this.props.history.push('/signin');
    }
    if (this.props.user_data && this.props.user_data !== prevProps.user_data) {
      let user;
      user = this.props.user_data;
      this.setState({ userEmail: user.email });
    }

    if (
      this.props.email_verification_success !==
        prevProps.email_verification_success &&
      this.props.email_verification_success
    ) {
      const user_id = this.props.email_verification_data.user.id;
      const organization_id = this.props.email_verification_data.organization
        ._id;
      this.setState({
        verificationLoader: false
      });

      // if (window.env.GET_REACT_APP_NAME === 'cliff') {
      //   this.props.history.push('/onboarding');
      // } else {
      //   this.props.history.push('/dashboard');
      // }

      if (
        window.env.GET_REACT_APP_NAME === 'cliff' &&
        this.props.email_verification_success
      ) {
        if (this.props.organization_preferences.onboarded) {
          this.props.history.push('/apps/anomaly-detection/getting-started');
        } else {
          this.props.history.push('/onboarding');
        }
      }
      if (
        window.env.GET_REACT_APP_NAME !== 'cliff' &&
        this.props.email_verification_success
      ) {
        this.props.history.push('/dashboard');
      }
      // loadURLFromLocal(user_id, organization_id).then(data => {
      //   if (data) {
      //     this.props.history.push(data.url);
      //   } else {
      //   }
      // });
    }
  };

  handleOnKeyDown = e => {
    console.log('event', e);
  };
  render() {
    let user;
    if (this.props.user_data) {
      user = this.props.user_data;
    }
    return (
      <AuthLayout>
        <div>
          <>
            <Row type="flex" align="center">
              <Col className="ant-mb-3" align="left" span={24}>
                <Row
                  onClick={() => this.props.history.push('/signin')}
                  type="flex"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Icon
                    className="ant-text-primary"
                    style={{ fontSize: '16px' }}
                    type="left"
                  />
                  <Text strong className="ant-text-primary ant-ml-2">
                    Back to sign in
                  </Text>
                </Row>
              </Col>
              <Col align="center" span={24}>
                <Title level={2} className="ant-font-heavy">
                  Verify Your email address
                </Title>
              </Col>
              <Col align="center" span={24}>
                <Paragraph className="ant-mb-4 ant-mt-4 ant-ml-2">
                  A 4 digit code has been sent to{' '}
                  <strong>{user && user.email}</strong> to verify your email
                  address !
                </Paragraph>
              </Col>
              <Col align="center" span={24}>
                <Paragraph strong className="ant-mb-4 ant-mt-3 ant-ml-2">
                  Input your 4 digits code below !
                </Paragraph>
              </Col>

              <Col align="center" span={24}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <OtpInput
                    inputStyle={{
                      width: '47px',
                      height: '47px',
                      borderRadius: '5px',
                      marginLeft: '10px',
                      border: '0.2px solid #d3d3d3'
                    }}
                    value={this.state.otp}
                    onChange={this.handleChange}
                    numInputs={4}
                  />
                </div>
              </Col>
              <Col align="center" span={24}>
                <Button
                  type="submit"
                  style={{ width: '100%', height: '40px' }}
                  className="ant-mt-4"
                  disabled={this.state.otp.toString().split('').length < 4}
                  loading={this.state.verificationLoader}
                  onClick={() => this.handleEmailVerificationSubmit()}
                >
                  Continue
                </Button>
              </Col>
              <Col align="center" span={24}>
                <Button
                  type="link"
                  onClick={() => this.handleResendEmailVerification()}
                >
                  Resend verification code
                </Button>
              </Col>
            </Row>
          </>
        </div>
      </AuthLayout>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    token,
    signup_error,
    signup_start,
    signup_success,
    initUserData,
    user_data,
    email_verification_start,
    email_verification_success,
    email_verification_error,
    email_verification_data,
    organization_preferences
  } = auth;
  return {
    token,
    signup_error,
    signup_start,
    signup_success,
    initUserData,
    user_data,
    email_verification_start,
    email_verification_success,
    email_verification_error,
    email_verification_data,
    organization_preferences
  };
};

export default connect(mapStateToProps, {
  userSignUp,
  userEmailVerification,
  userResendEmailVerificationCode
})(EmailVerification);
