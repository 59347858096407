import {
  ADD_FILTER,
  EDIT_FILTER_START,
  EDIT_FILTER_DONE,
  REMOVE_FILTER,
  REMOVE_ALL_FILTERS,
  SET_EDIT_FILTER,
  LOAD_SAVED_FILTER,
  SET_FILTERS,
  CUSTOM_FILTER
} from '../../constants/FilterConstants';

const INIT_STATE = {
  condition: 'and',
  conditionParams: []
};

const DEBUG = false;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FILTER: {
      DEBUG && console.log({ FIL1: action });
      if (Object.keys(state).length === 0) {
        return {
          conditionParams: [action.condition],
          editMode: false
        };
      } else {
        console.log({ AC: action });
        const x = {
          ...state,
          condition: action.optionalCondition
            ? action.optionalCondition
            : 'and',
          conditionParams: state.conditionParams.concat(action.condition)
        };
        console.log(x);
        return x;
      }
    }

    case EDIT_FILTER_START: {
      DEBUG && console.log({ FIL2: action });
      return {
        ...state,
        editMode: true,
        editCondition: action.editCondition
      };
    }

    case EDIT_FILTER_DONE: {
      let obj;
      DEBUG && console.log({ FIL3: action });
      obj = {
        ...state,
        editMode: false,
        editCondition: null
      };

      if (action.editCondition) {
        obj = {
          ...state,
          editMode: false,
          editCondition: null,
          conditionParams: state.conditionParams.map((filter, i) => {
            if (
              filter.conditionParams[0].id ===
              action.editCondition.conditionParams[0].id
            ) {
              // const extraParams =
              //   action.editCondition.conditionParams[0].extraParams;
              return {
                ...filter,
                conditionParams: action.editCondition.conditionParams
              };
            } else {
              console.log({ ID: filter.conditionParams[0].id });
              console.log({ ID2: action.editCondition.conditionParams[0].id });

              return filter;
            }
          })
        };
      }

      return obj;
    }

    case REMOVE_FILTER: {
      DEBUG && console.log({ FIL4: action });
      const obj = {
        ...state,
        conditionParams: state.conditionParams.filter(
          item =>
            item.conditionParams[0].id !==
            action.deleteCondition.conditionParams[0].id
        )
      };
      return obj;
    }

    case REMOVE_ALL_FILTERS: {
      DEBUG && console.log({ FIL5: action });
      const newState = {
        condition: 'and',
        conditionParams: []
      };
      return newState;
    }

    case LOAD_SAVED_FILTER: {
      DEBUG && DEBUG && console.log({ FIL6: action });
      const obj = {
        ...action.savedFilter,
        savedFilter: {
          id: action.id,
          name: action.name
        }
      };
      return obj;
    }

    case SET_FILTERS: {
      const f = action.filterObject;
      const savedFilter = state && state.savedFilter;
      const obj = {
        ...action.filterObject,
        savedFilter
      };
      DEBUG && console.log({ FIL7: obj });
      return obj;
      // return action.filterObject;
    }

    case CUSTOM_FILTER: {
      return action.payload;
    }

    default:
      return state;
  }
};
