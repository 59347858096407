import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LocaleProvider } from '@greendeck/atomic';
import { IntlProvider } from 'react-intl';
import { saveURLToLocal, loadURLFromLocal } from 'util/indexDB/urlCRUD';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import Join from '../Join';
import EmailVerification from '../EmailVerification';
import AcceptInvitation from '../AcceptInvitation';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import KeycloakRedirect from '../KeycloakRedirect';
import Demo from '../Demo';
import { setInitUrl } from 'appRedux/actions/Auth';
import { setUserOS } from 'appRedux/actions/Common';
import axios from 'util/Api';

import { getUser, initUser } from '../../appRedux/actions/Auth';

// turn of console.log
const env = window.env.GET_REACT_APP_ENV;

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  state = {
    lastOpenedUrl: ''
  };

  componentDidUpdate(prevProps) {}

  componentWillMount() {
    const token = JSON.parse(localStorage.getItem('token'));
    const { pathname, search } = window.location;
    if (pathname === '/apps/anomaly-detection/integrations/redirect') {
      const OAuthUrl = pathname + search;
      localStorage.setItem('oAuthRedirect', OAuthUrl);
    }

    const combinedUrl =
      this.props.location.pathname + this.props.location.search;
    if (
      token &&
      (combinedUrl === '' || combinedUrl === '/' || combinedUrl === '/signin')
    ) {
      const user_id = JSON.parse(localStorage.getItem('user_id'));
      const organization_id = JSON.parse(
        localStorage.getItem('organization_id')
      );

      loadURLFromLocal(user_id, organization_id).then(data => {
        data && this.props.history.push(data.url);
      });
    }
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      // console.log("prevProps.token",prevProps.token);
      // console.log("this.props.token",this.props.token);
      axios.defaults.headers.common['Authorization'] = JSON.parse(
        localStorage.getItem('token')
      );

      if (
        !this.props.location.pathname.includes('onboarding') &&
        window.location.pathname.includes('/email_verification') &&
        window.location.pathname.includes('/forgot_password') &&
        !this.props.sign_out_success
      ) {
        this.props.getUser();
        this.props.initUser();
      }
    }
  }

  componentDidMount() {
    const getOS = () => {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null,
        keyCombo = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      if (['Mac OS', 'iOS'].includes(os)) {
        os = 'iOS';
      } else {
        os = 'Windows';
      }
      return os;
    };
    const os = getOS();
    this.props.setUserOS(os);
  }

  render() {
    // fetch from indexdb
    // store in a variable

    const {
      match,
      location,
      locale,
      token,
      initURL,
      initUserData
    } = this.props;
    console.log({ I: initURL });
    const user_id = JSON.parse(localStorage.getItem('user_id'));
    const organization_id = JSON.parse(localStorage.getItem('organization_id'));

    if (location.pathname === '/') {
      if (token === null) {
        if (location.pathname === '/demo') {
          return <Redirect to={'/demo'} />;
        }
        return <Redirect to={'/signin'} />;
      } else if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        initURL === '/dashboard'
      ) {
        return <Redirect to={'/dashboard'} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/join" component={Join} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/reset_password" component={ResetPassword} />
            <Route
              exact
              path="/email_verification"
              component={EmailVerification}
            />
            <Route exact path="/accept_invite" component={AcceptInvitation} />
            <Route exact path="/demo" component={Demo} />
            <Route
              exact
              path="/keycloak_redirect"
              component={KeycloakRedirect}
            />
            <RestrictedRoute
              path={`${match.url}`}
              token={token}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { authUser, token, initURL, initUserData, sign_out_success } = auth;
  return { locale, token, authUser, initURL, initUserData, sign_out_success };
};
export default connect(mapStateToProps, {
  setInitUrl,
  getUser,
  initUser,
  setUserOS
})(App);
