import React from 'react';
import { Spin, Icon } from '@greendeck/atomic';

const spinnerIcon = (
  <Icon
    type="loading"
    style={{ fontSize: 40, marginLeft: 600, marginTop: 200 }}
    spin
  />
);

class SpinnerTwo extends React.Component {
  render() {
    return <Spin indicator={spinnerIcon} />;
  }
}

export default SpinnerTwo;
