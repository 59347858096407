import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  getOrganization,
  updateSelectedApp,
  userSignOut,
  updateOrganizationPreferencesGeneric,
  initUser
} from '../../appRedux/actions/Auth';
import SpinnerTwo from '../../components/Loader/SpinnerTwo';

import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button
} from '@greendeck/atomic';
import { convertToTitleCase } from '../../util/generics/TitleCase';

import OnboardingMain from './views/Main';
import Wizard from './views/Wizard';

const { Meta } = Card;
const { Title, Text } = Typography;

class OnBoarding extends React.Component {
  state = {
    currentStage: '',
    from: '', // denotes the point from which the user came to onboarding; currently only possible value is 'sidebar'
    confetti: false
  };

  componentDidMount = () => {
    const currentStage = new URLSearchParams(this.props.location.search);

    const from = this.props.location.from && this.props.location.state.from;
    this.setState({ currentStage: currentStage.get('stage') });
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.organization_preferences_success !==
        prevProps.organization_preferences_success &&
      this.props.organization_preferences_success === true
    ) {
      this.props.initUser();
    }
  };

  changeStage = stageName => {
    this.props.history.push(`/onboarding?stage=${stageName}`);
    this.setState({ currentStage: stageName });
  };

  skipOnboarding = () => {
    this.props.history.push('/apps/anomaly-detection/metrics');
  };

  redirectTo = url => {
    this.props.history.push(url);
  };

  turnOnConffetti = () => {
    this.setState({ confetti: true });
  };

  turnOffOnboarding = redirectTo => {
    const preference = {
      onboarded: true
    };
    this.props.history.push(redirectTo);
    this.props.updateOrganizationPreferencesGeneric(preference);
  };

  render() {
    if (this.props.appsData) {
      var appsList = this.props.appsData;
    }
    if (this.props.initUserData) {
      var userFirstName = this.props.initUserData.user.first_name;
    }

    return this.props.initUserData ? (
      <div>
        {!this.state.currentStage ||
        this.state.currentStage === 'welcome' ||
        this.state.from === 'sidebar' ? (
          <OnboardingMain
            user={this.props.initUserData.user}
            changeMainStage={this.changeStage}
            skipOnboarding={this.skipOnboarding}
            signOut={this.props.userSignOut}
            turnOffOnboarding={this.turnOffOnboarding}
            turnOffOnboardingLoading={this.props.organization_preferences_start}
          />
        ) : (
          <Wizard
            changeMainStage={this.changeStage}
            redirectTo={this.redirectTo}
            signOut={this.props.userSignOut}
            turnOffOnboardingLoading={this.props.organization_preferences_start}
            turnOffOnboarding={this.turnOffOnboarding}
          />
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ auth }) => {
  const {
    token,
    organization_data,
    initUserData,
    appsData,
    organization_preferences_success,
    organization_preferences_start
  } = auth;
  return {
    token,
    organization_data,
    initUserData,
    appsData,
    organization_preferences_success,
    organization_preferences_start
  };
};

export default connect(mapStateToProps, {
  getOrganization,
  initUser,
  updateSelectedApp,
  userSignOut,
  updateOrganizationPreferencesGeneric,
  initUser
})(OnBoarding);
