import {
  FETCH_PRICE_DISTRIBUTION_START,
  FETCH_PRICE_DISTRIBUTION_SUCCESS,
  FETCH_PRICE_DISTRIBUTION_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRICE_DISTRIBUTION_START: {
      return {
        ...state,
        fetch_price_distribution_success: false,
        fetch_price_distribution_error: false,
        fetch_price_distribution_start: true
      };
    }

    case FETCH_PRICE_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        fetch_price_distribution_success: true,
        fetch_price_distribution_error: false,
        fetch_price_distribution_start: false,
        pricedistributionData: action.payload
      };
    }

    case FETCH_PRICE_DISTRIBUTION_ERROR: {
      return {
        ...state,
        fetch_price_distribution_success: false,
        fetch_price_distribution_error: true,
        fetch_price_distribution_start: false
      };
    }

    default:
      return state;
  }
};
