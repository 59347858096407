import {
  GET_ACTIVITIES_START,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  activityData: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITIES_START: {
      return {
        ...state,
        get_activities_start: true,
        get_activities_success: false,
        get_activities_error: false
      };
    }
    case GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activityData: action.payload,
        get_activities_start: false,
        get_activities_success: true,
        get_activities_error: false
      };
    }
    case GET_ACTIVITIES_ERROR: {
      return {
        ...state,
        get_activities_start: false,
        get_activities_success: false,
        get_activities_error: true
      };
    }
    default:
      return state;
  }
};
