import axios from 'axios';
import { makeRequestCliff } from 'util/request/cliffApi';
import axiosAccounts from 'util/config/accounts';
import {
  GET_SOURCES_START,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_ERROR,
  GET_SOURCE_START,
  GET_SOURCE_SUCCESS,
  GET_SOURCE_ERROR,
  AUTHORIZE_DATA_SOURCE_START,
  AUTHORIZE_DATA_SOURCE_SUCCESS,
  AUTHORIZE_DATA_SOURCE_ERROR,
  AUTHORIZE_SENTRY_TOKEN_START,
  AUTHORIZE_SENTRY_TOKEN_SUCCESS,
  AUTHORIZE_SENTRY_TOKEN_ERROR,
  GET_SENTRY_ACCOUNTS_START,
  GET_SENTRY_ACCOUNTS_SUCCESS,
  GET_SENTRY_ACCOUNTS_ERROR,
  DELETE_SOURCE_SUBSCRIPTION_START,
  DELETE_SOURCE_SUBSCRIPTION_SUCCESS,
  DELETE_SOURCE_SUBSCRIPTION_ERROR,
  GET_ALL_SOURCE_SUBSCRIPTIONS_START,
  GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR,
  CHANGE_RELATED_STREAMS_BOOLEAN,
  SET_SOURCE_TEMPLATES,
  GET_ALL_SOURCES_START,
  GET_ALL_SOURCES_SUCCESS,
  GET_ALL_SOURCES_ERROR,
  GET_SOURCE_SUBSCRIPTION_BY_ID_START,
  GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS,
  GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR,
  CLEAR_SOURCE_SUBSCRIPTION,
  CLEAR_SOURCE_OBJECT
} from '../../../../constants/CliffApiConstants';
import GoogleOAuthConfig from 'util/config/google';

const localhost = 'http://localhost:3000';
const dev = 'https://development.cliff.ai';
const staging = 'https://staging.cliff.ai';
const prod = 'https://app.cliff.ai';

let host;

if (window.env.GET_REACT_APP_ENV === 'development') {
  host = dev;
} else if (window.env.GET_REACT_APP_ENV === 'staging') {
  host = staging;
} else if (window.env.GET_REACT_APP_ENV === 'production') {
  host = prod;
}

export const getSources = type => dispatch => {
  dispatch({ type: GET_SOURCES_START });
  const url = `/v3/sources.list`;
  const config = {
    method: 'post',
    url: url,
    data: {
      type,
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    }
  };
  makeRequestCliff(config, 'GET_SOURCES')
    .then(({ data }) => {
      if (data) {
        dispatch({ type: GET_SOURCES_SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_SOURCES_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_SOURCES_ERROR });
      }
    });
};

export const getSource = sourceId => dispatch => {
  dispatch({ type: GET_SOURCE_START });
  const url = '/v3/sources.getById';
  const config = {
    method: 'post',
    url: url,
    data: {
      sourceId
    }
  };
  makeRequestCliff(config, 'GET_SOURCE')
    .then(({ data }) => {
      if (data) {
        dispatch({ type: GET_SOURCE_SUCCESS, payload: data.source });
      } else {
        dispatch({ type: GET_SOURCE_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_SOURCE_ERROR });
      }
    });
};

export const clearSourceObject = () => dispatch => {
  dispatch({ type: CLEAR_SOURCE_OBJECT });
};

export const getCredentials = subscriptionId => {
  // const url = `/v1/source-subscriptions/getAuthCredentials/${sourceSubId}`;
  const url = `/v3/sourceSubscriptions.getAuthCredentials`;
  const config = {
    url,
    method: 'post',
    data: {
      subscriptionId
    }
  };
  return makeRequestCliff(config, 'GET_CREDENTIALS');
};

export const authorizeDataSource = (credentials, sourceId) => dispatch => {
  dispatch({ type: AUTHORIZE_DATA_SOURCE_START });
  const url =
    'v1/' +
    JSON.parse(localStorage.getItem('organization_id')) +
    '/streams/authorize?sourceId=' +
    sourceId;
  const config = {
    method: 'post',
    url: url,
    data: credentials
  };

  makeRequestCliff(config, 'AUTHORIZE_DATA_SOURCE')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: AUTHORIZE_DATA_SOURCE_SUCCESS,
          payload: data.message
        });
      } else {
        dispatch({ type: AUTHORIZE_DATA_SOURCE_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: AUTHORIZE_DATA_SOURCE_ERROR });
      }
    });
};

// const localhost = 'http://localhost:3000';
// const dev = 'https://development.greendeck.co';

export const verifyGoogleAuthCode = verificationObj => {
  const url =
    'v1/auth/' +
    JSON.parse(localStorage.getItem('organization_id')) +
    '/google/verify?redirect_uri=' +
    GoogleOAuthConfig.REDIRECT_URI;
  const data = {
    ...verificationObj,
    utcOffset: new Date().getTimezoneOffset()
  };
  const config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'VERIFY_GOOGLE_AUTH_CODE');
};

export const getAccountsList = sourceSubscriptionId => {
  const url = `v1/streams/organization/${localStorage.getItem(
    'organization_id'
  )}/google/analytics/accounts/list?sourceSubscriptionId=${sourceSubscriptionId}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_ACCOUNT_DATA');
};

// Google Analytics v4
export const getGoogleV4AccountsList = sourceSubscriptionId => {
  const url = `v1/streams/organization/${localStorage.getItem(
    'organization_id'
  )}/google/analyticsV4/accounts/list?sourceSubscriptionId=${sourceSubscriptionId}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_ACCOUNT_DATA');
};

export const getGoogleV4MeasuresAndDimensionsList = (
  sourceSubscriptionId,
  property
) => {
  const url = `v1/streams/organization/${localStorage.getItem(
    'organization_id'
  )}/google/analyticsV4/measuresdimensions/subscriptions/${sourceSubscriptionId}/list?property=${property}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_MEASURES_DIMENSIONS_DATA');
};
//

export const getMetricsAndDimensions = sourceSubscriptionId => {
  const url = `v1/streams/organization/${localStorage.getItem(
    'organization_id'
  )}/google/analytics/accounts/data/list?sourceSubscriptionId=${sourceSubscriptionId}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_MEASURES_DATA');
};

export const createStream = streamData => {
  const url = `/v3/streams.create`;
  const config = {
    method: 'post',
    url: url,
    data: {
      ...streamData,
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    }
  };
  return makeRequestCliff(config, 'CREATE_STREAM');
};

export const deleteSourceSubscription = sourceSubscriptionId => dispatch => {
  dispatch({ type: DELETE_SOURCE_SUBSCRIPTION_START });
  // const url = `v1/source-subscriptions/organization/${JSON.parse(
  //   localStorage.getItem('organization_id')
  // )}/subscription/${subscriptionId}`;

  const url = `/v3/sourceSubscriptions.delete`;
  const config = {
    method: 'post',
    url: url,
    data: {
      sourceSubscriptionId
    }
  };
  makeRequestCliff(config, 'DELETE SOURCE SUBSCRIPTION')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: DELETE_SOURCE_SUBSCRIPTION_SUCCESS,
          payload: data.message
        });
      } else {
        dispatch({ type: DELETE_SOURCE_SUBSCRIPTION_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: DELETE_SOURCE_SUBSCRIPTION_ERROR });
      }
    });
};

export const getAddedSources = () => {
  const url = `v1/streams/organization/${JSON.parse(
    localStorage.getItem('organization_id')
  )}/added/list`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_ADDED_SOURCES');
};

export const authorizeSentryToken = data => dispatch => {
  dispatch({ type: AUTHORIZE_SENTRY_TOKEN_START });
  const url = `v1/auth/sentry/authorize`;
  const config = {
    method: 'post',
    url: url,
    data: {
      token: data.tokenInput,
      subscription_name: data.subscription_name,
      utcOffset: new Date().getTimezoneOffset(),
      user_id: JSON.parse(localStorage.getItem('user_id')),
      email: JSON.parse(localStorage.getItem('init_data')).user.email,
      organization_id: JSON.parse(localStorage.getItem('organization_id'))
    }
  };

  makeRequestCliff(config, 'AUTHORIZE_SENTRY_TOKEN')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: AUTHORIZE_SENTRY_TOKEN_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: AUTHORIZE_SENTRY_TOKEN_ERROR,
          payload: 'Something went wrong'
        });
      }
    })
    .catch(function(e) {
      dispatch({
        type: AUTHORIZE_SENTRY_TOKEN_ERROR,
        payload: e.response.data.error
      });
    });
};

export const getSentryAccounts = subscriptionId => dispatch => {
  dispatch({ type: GET_SENTRY_ACCOUNTS_START });
  const url = `v1/source-subscriptions/sentry/subscriptionId/${subscriptionId}`;

  const config = {
    method: 'get',
    url: url
  };
  makeRequestCliff(config, 'GET SENTRY ACCOUNTS')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_SENTRY_ACCOUNTS_SUCCESS,
          payload: data
        });
      } else {
        dispatch({ type: GET_SENTRY_ACCOUNTS_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_SENTRY_ACCOUNTS_ERROR });
      }
    });
};

export const createRestSubscription = data => {
  const url = `v1/auth/restApi/create`;
  const config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'CREATE API KEY');
};

// export const createSegmentSubscription = data => {
//   const url = `v1/auth/segment/create`;
//   const config = {
//     method: 'post',
//     url: url,
//     data
//   };
//   return makeRequestCliff(config, 'CREATE SEGMENT SUBSCRIPTION');
// };

export const getUserSites = subscription_id => {
  const url = `/v1/source-subscriptions/organization/${JSON.parse(
    localStorage.getItem('organization_id')
  )}/google/searchconsole/sites/list?sourceSubscriptionId=${subscription_id}`;

  const config = {
    method: 'get',
    url
  };

  return makeRequestCliff(config, 'GET USER SITES');
};

export const getUserSpreadsheets = subscriptionId => {
  const url = `v1/source-subscriptions/google/subscriptionId/${subscriptionId}/list`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET USER SPREAD SHEETS');
};

export const getSpreadsheetIdData = (subscriptionId, sheetId) => {
  const url = `/v1/source-subscriptions/google/sheets/subscriptions/${subscriptionId}/sheetList?spreadsheetId=${sheetId}`;
  const config = {
    method: 'get',
    url
  };

  return makeRequestCliff(config, 'GET SPREADSHEET DATA');
};

export const getSpreadsheetData = (subscriptionId, sheetId, range) => {
  const url = `v1/source-subscriptions/google/subscriptionId/${subscriptionId}/sheetId/${sheetId}/data?range=${range}`;
  const config = {
    method: 'get',
    url
  };

  return makeRequestCliff(config, 'GET SPREADSHEET DATA');
};

export const streamValidate = data => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  return axios.post(`${url}/stream/is_valid`, data);
};

export const getSourceMeasures = sourceId => {
  console.log('getSourceMeasures');
  const url = `/v3/sources.getMeasuresAndDimenions`;
  const config = {
    method: 'post',
    url,
    data: {
      sourceId
    }
  };
  return makeRequestCliff(config, `GET_SOURCE_MEASURES_${sourceId}`);
};

export const streamPreviewTable = data => {
  const base = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const url = `${base}/stream/preview`;
  const config = {
    method: 'post',
    url,
    data
  };

  return makeRequestCliff(config, 'GET SPREADSHEET DATA');
};

export const authorizeStripe = data => {
  const url = `v1/auth/stripe/verify`;
  const config = {
    method: 'post',
    url,
    data
  };
  return makeRequestCliff(config, 'AUTHORIZE STRIPE');
};

export const authorizeDatabase = data => {
  const url = `v2/sourceSubscriptions.create`;
  const config = {
    method: 'post',
    url,
    data
  };
  return makeRequestCliff(config, 'AUTHORIZE DATABASE');
};

export const createOrUpdateSourceSubscription = verificationObj => {
  const action = verificationObj.action || 'create';
  const url = `/v3/sourceSubscriptions.${action}`;
  const data = {
    ...verificationObj
  };
  const config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'VERIFY_SOURCE_AUTH_CODE');
};

export const validatorOnSchemaUpdate = subscriptionId => {
  const data = {
    environment: window.env.REACT_APP_ENV,
    subscription_id: subscriptionId
  };

  const config = {
    method: 'post',
    url: `${window.env.REACT_APP_VALIDATION_ENDPOINT}/sync/update_airbyte_connection`,
    data
  };

  return axios(config);
};

export const createOrUpdateSourceSubscriptionIsValid = verificationObj => {
  const collector_name = verificationObj.collector_name;
  delete verificationObj.collector_name;
  const data = {
    collector_name: collector_name,
    auth_credentials: {
      ...verificationObj
    }
  };

  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  return axios.post(`${url}/source_subscription/is_valid`, data);
};

export const updateAuthCredentials = data => {
  const url = '/v3/sourceSubscriptions.update';
  const config = {
    method: 'post',
    url,
    data
  };
  return makeRequestCliff(config, 'UPDATE_AUTHCREDENTIALS');
};

export const getDatabaseTables = (subscriptionId, schema) => {
  const url = schema
    ? `/v1/source-subscriptions/postgres/subscriptions/${subscriptionId}/tables?schema=${schema}`
    : `/v1/source-subscriptions/postgres/subscriptions/${subscriptionId}/tables`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET DATABASE TABLES');
};

export const getDatabaseColumns = (subscriptionId, tableName) => {
  const url = `/v1/source-subscriptions/postgres/subscriptions/${subscriptionId}/tables/${tableName}/columns`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET DATABASE TABLE COLUMNS');
};

export const getMongoCollections = subscriptionId => {
  const url = `/v1/source-subscriptions/mongodb/subscriptions/${subscriptionId}/collections`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET MONGO COLLECTIONS');
};

export const getMongoCollectionFields = (subscriptionId, collectionName) => {
  const url = `/v1/source-subscriptions/mongodb/subscriptions/${subscriptionId}/collections/${collectionName}/fields`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET MONGO COLLECTIONS');
};

export const createHubspotSubscription = verificationObj => {
  const url = `/v1/auth/hubspot/verify`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'VERIFY_HUBSPOT');
};

// export const createCloudwatchSubscription = data => {
//   const url = '/v2/sourceSubscriptions.create';
//   const config = {
//     method: 'post',
//     url,
//     data
//   };
//   return makeRequestCliff(config, 'CREATE_CLOUDWATCH_SUBSCRIPTION');
// };

export const getHubspotFields = subscriptionId => {
  const url = `/v1/source-subscriptions/${subscriptionId}/hubspot/form-fields`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_HUBSPOT_FIELDS');
};

// Snowflake

// export const getAllSnowflakeWarehouse = subscriptionId => {
//   const url = `/v1/source-subscriptions/snowflake/subscriptions/${subscriptionId}/data?type=warehouse`;
//   const config = {
//     method: 'get',
//     url
//   };
//   return makeRequestCliff(config, 'GET_ALL_SNOWFLAKE_WREHOUSE');
// };

// export const getAllSnowflakeDatabase = (subscriptionId, warehouseId) => {
//   const url = `/v1/source-subscriptions/snowflake/subscriptions/${subscriptionId}/data?type=database&warehouse=${warehouseId}`;
//   const config = {
//     method: 'get',
//     url
//   };
//   return makeRequestCliff(config, 'GET_ALL_SNOWFLAKE_DATABASE');
// };

export const getAllSnowflakeSchema = (
  subscriptionId,
  warehouseId,
  databaseId
) => {
  const url = `/v1/source-subscriptions/snowflake/subscriptions/${subscriptionId}/data?type=schema`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_SNOWFLAKE_SCHEMA');
};

export const getAllSnowflakeTables = (subscriptionId, schemaId) => {
  const url = `/v1/source-subscriptions/snowflake/subscriptions/${subscriptionId}/data?type=table&schema=${schemaId}`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_SNOWFLAKE_TABLES');
};

export const getSnowflakeTableFields = (subscriptionId, schemaId, tableId) => {
  const url = `/v1/source-subscriptions/snowflake/subscriptions/${subscriptionId}/data?type=column&schema=${schemaId}&table=${tableId}`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_SNOWFLAKE_TABLE_FIELDS');
};

export const getAllBigQueryProjects = subscriptionId => {
  const url = `/v1/source-subscriptions/bigquery/subscriptions/${subscriptionId}/projects`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_BIGQUERY_PROJECTS');
};

export const getAllBigQueryDatasets = (subscriptionId, projectId) => {
  const url = `/v1/source-subscriptions/bigquery/subscriptions/${subscriptionId}/projects/${projectId}/datasets`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_BIGQUERY_DATASETS');
};

export const getAllBigQueryTables = (subscriptionId, projectId, datasetId) => {
  const url = `/v1/source-subscriptions/bigquery/subscriptions/${subscriptionId}/projects/${projectId}/datasets/${datasetId}/tables`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_BIGQUERY_TABLES');
};

export const getBigQueryTableFields = (
  subscriptionId,
  projectId,
  datasetId,
  tableId
) => {
  const url = `/v1/source-subscriptions/bigquery/subscriptions/${subscriptionId}/projects/${projectId}/datasets/${datasetId}/tables/${tableId}`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_BIGQUERY_TABLE_FIELDS');
};

export const getAllSubscriptions = () => dispatch => {
  dispatch({ type: GET_ALL_SOURCE_SUBSCRIPTIONS_START });
  const url = '/v3/sourceSubscriptions.listAllSubscriptions';
  const config = {
    method: 'post',
    url: url,
    data: {
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    }
  };
  makeRequestCliff(config, 'GET ALL SOURCE SUBSCRIPTIONS')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ALL_SOURCE_SUBSCRIPTIONS_SUCCESS,
          payload: data.subscriptions
        });
      } else {
        dispatch({ type: GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_ALL_SOURCE_SUBSCRIPTIONS_ERROR });
      }
    });
};

export const getMembers = () => {
  axiosAccounts.defaults.headers.common['Authorization'] = JSON.parse(
    localStorage.getItem('token')
  );
  return axiosAccounts.get(
    'v2/organizations/' +
      JSON.parse(localStorage.getItem('organization_id')) +
      '/members',
    {}
  );
};

export const getAllElasticIndexes = subscriptionId => {
  const url = `/v1/source-subscriptions/elasticsearch/subscriptions/${subscriptionId}/indices`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_ELASTIC_INDEXES');
};

export const getAllElasticIndexFields = (subscriptionId, indexes) => {
  const url = `/v1/source-subscriptions/elasticsearch/subscriptions/${subscriptionId}/indices/fields?indices=${JSON.stringify(
    indexes
  )}`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_ELASTIC_INDEX_FIELDS');
};

export const getAllRedshiftSchemas = subscriptionId => {
  const url = `/v1/source-subscriptions/postgres/subscriptions/${subscriptionId}/schemas`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_ALL_REDSHIFT_SCHEMAS');
};

export const createSlackSubscription = verificationObj => {
  const url = `/v1/auth/slack/verify`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'VERIFY_SLACK');
};

export const createShopifySubscription = verificationObj => {
  const url = `/v3/sourceSubscriptions.auth.shopify`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'VERIFY_SHOPIFY');
};

export const createSegmentSubscription = verificationObj => {
  const url = `/v1/auth/segment/auth`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'VERIFY_SEGMENT');
};

export const getAllCloudWatchNamespace = sourceSubscriptionId => {
  const url = `/v1/source-subscriptions/cloudwatch/subscriptions/${sourceSubscriptionId}/metrics`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_NAMESPACE_DATA');
};

export const changeRelatedStreamsBool = bool => dispatch =>
  dispatch({ type: CHANGE_RELATED_STREAMS_BOOLEAN, payload: bool });

export const setSourceTemplates = data => dispatch =>
  dispatch({ type: SET_SOURCE_TEMPLATES, payload: data });

// Graphite
export const getAllGraphiteMeasures = sourceSubscriptionId => {
  const url = `/v1/source-subscriptions/graphite/subscriptions/${sourceSubscriptionId}/measures`;
  const config = {
    method: 'get',
    url
  };
  return makeRequestCliff(config, 'GET_MEASURES_DATA');
};

export const getAllSources = () => dispatch => {
  dispatch({ type: GET_ALL_SOURCES_START });
  const url = `v1/sources/list`;
  const config = {
    method: 'get',
    url: url
  };
  makeRequestCliff(config, 'GET ALL SOURCES')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_ALL_SOURCES_SUCCESS,
          payload: data
        });
      } else {
        dispatch({ type: GET_ALL_SOURCES_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_ALL_SOURCES_ERROR });
      }
    });
};

export const getDiscoverSchemas = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/source_subscription/discover_schema`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_DISCOVER_SCHEMAS');
};

export const getQueryResult = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/source_subscription/get_query`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_QUERY_RESULT');
};

export const postQuery = verificationObj => {
  const url = `/v3/queryInterface.postQuery`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'POST_QUERY');
};

export const getQueries = verificationObj => {
  const url = `/v3/queryInterface.getQueries`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_QUERY');
};

export const deleteQuery = verificationObj => {
  const url = `/v3/queryInterface.deleteQuery`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'DELETE_QUERY');
};

export const getSourceSubscriptionById = sourceSubscriptionId => dispatch => {
  dispatch({ type: GET_SOURCE_SUBSCRIPTION_BY_ID_START });
  const url = `/v3/sourceSubscriptions.getById`;
  const config = {
    method: 'post',
    url,
    data: { sourceSubscriptionId }
  };

  makeRequestCliff(config, 'GET SOURCE SUBSCRIPTION BY ID')
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_SOURCE_SUBSCRIPTION_BY_ID_SUCCESS,
          payload: data.sourceSubscription
        });
      } else {
        dispatch({ type: GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR });
      }
    })
    .catch(function(error) {
      if (!axios.isCancel(error)) {
        dispatch({ type: GET_SOURCE_SUBSCRIPTION_BY_ID_ERROR });
      }
    });
};

export const clearSourceSubscription = () => dispatch =>
  dispatch({ type: CLEAR_SOURCE_SUBSCRIPTION });

export const getSubscriptionLogs = subscription_id => {
  // const url = `/v2/syncSchedules.getCollectionLogs`;
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/sync/get_logs`;

  const config = {
    method: 'post',
    url,
    data: {
      env: window.env.GET_REACT_APP_ENV,
      subscription_id
    }
  };
  return makeRequestCliff(config, 'GET_SUBSCRIPTION_LOGS');
};

export const getSubscriptionStatusDetails = sourceSubscriptionId => {
  const url = `/v3/sourceSubscriptions.getSubscriptionStatusDetails`;
  const config = {
    method: 'post',
    url,
    data: { sourceSubscriptionId }
  };
  return makeRequestCliff(config, 'GET_SUBSCRIPTION_STATUS_DETAILS');
};

export const getSubscriptionAggregatedLogs = (
  sourceSubscriptionId,
  timeInterval
) => {
  const url = `/v2/sourceSubscriptions.getSubscriptionAggregatedLogs`;
  const config = {
    method: 'post',
    url,
    data: { sourceSubscriptionId, timeInterval }
  };
  return makeRequestCliff(config, 'GET_SUBSCRIPTION_AGGREGATED_LOGS');
};

export const syncSubscriptionUpdateController = verificationObj => {
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/${verificationObj.endpoint}`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'SYNC_SUBSCRIPTION_UPDATE_CONTROLLER');
};

export const refreshDiscoverSchema = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  return axios.post(`${url}/${verificationObj.endpoint}`, verificationObj);
};

export const getSegmentProperties = sourceSubscriptionId => {
  const url = `/v2/sourceSubscriptions.getSegmentProperties`;
  const config = {
    method: 'post',
    url,
    data: { sourceSubscriptionId }
  };
  return makeRequestCliff(config, 'GET SEGMENT PROPERTIES');
};

export const getMySQLTables = verificationObj => {
  const url = `/v3/sourceSubscriptions.MySQL.getTables`;
  const config = {
    method: 'post',
    data: verificationObj,
    url
  };

  return makeRequestCliff(config, 'GET_MY-SQL_TABLES');
};

export const getMySQLTableColumns = verificationObj => {
  const url = `/v3/sourceSubscriptions.MySQL.getColumns`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'GET_MY-SQL_TABLES_COLUMNS');
};

export const getClickHouseTables = verificationObj => {
  const url = `/v3/sourceSubscriptions.ClickHouse.getTables`;
  const config = {
    method: 'post',
    data: verificationObj,
    url
  };

  return makeRequestCliff(config, 'GET_CLICKHOUSE_TABLES');
};

export const getClickHouseTableColumns = verificationObj => {
  const url = `/v3/sourceSubscriptions.ClickHouse.getColumns`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'GET_CLICKHOUSE_TABLES_COLUMNS');
};

export const dbtAuthorize = verificationObj => {
  const url = `/v3/dbt.authorize`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  return makeRequestCliff(config, 'DBT_AUTHORIZE');
};

export const getTransformationConnectedSubscriptions = data => {
  const url = `/v3/dbt.getConnectedSubscriptions`;
  const config = {
    method: 'post',
    url,
    data: data
  };
  return makeRequestCliff(config, 'DBT_AUTHORIZE');
};

export const gitAuthorize = verificationObj => {
  const url = `/v3/git.authorize`;
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };
  console.log(config);
  return makeRequestCliff(config, 'GIT_AUTHORIZE');
};

export const getGitRepositories = verificationObj => {
  const url = '/v3/git.getRepositories';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'GET_GIT_REPOSITORY');
};

export const getGitBranches = verificationObj => {
  const url = '/v3/git.getBranches';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'GET_GIT_BRANCHES');
};

export const updateGitRepoBranch = verificationObj => {
  const url = '/v3/git.updateRepositoryBranch';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'UPDATE_GIT_REPO_BRANCHES');
};

export const getDbtSources = () => {
  const type = 'all';
  const url = `/v1/sources/get/organization/${JSON.parse(
    localStorage.getItem('organization_id')
  )}/type/${type}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, 'GET_DBT_SOURCES');
};

export const getDbtModelFromRepository = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/dbt/get_models`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_DBT_MODELS_FROM_REPOSITORY');
};

export const getDbtModelCompiled = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/dbt/get_compiled_models`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_DBT_MODELS_COMPILED');
};

export const getAllDbtJobs = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/dbt/get_jobs`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_DBT_JOBS');
};

export const getDbtModelFromJob = verificationObj => {
  const url = window.env.GET_REACT_APP_VALIDATION_ENDPOINT;
  const config = {
    method: 'post',
    url: `${url}/dbt/get_model_by_id`,
    data: verificationObj
  };
  return makeRequestCliff(config, 'GET_DBT_MODEL_FROM_JOB');
};

export const updateDefaultSchema = verificationObj => {
  const url = '/v2/sourceSubscriptions.updateDefaultSchema';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'UPDATE_DEFAULT_SCHEMA');
};

export const getAllDbtTransformation = verificationObj => {
  const url = '/v3/sourceSubscriptions.transformation.getDbtSubscriptions';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'GET_DBT_SUBSCRIPTION_BY_SUBSCRIPTION_ID');
};

export const updateSubscriptionTransformationById = verificationObj => {
  const url =
    '/v3/sourceSubscriptions.transformation.updateSubscriptionTransformationId';
  const config = {
    method: 'post',
    url,
    data: verificationObj
  };

  return makeRequestCliff(config, 'UPDATE_SOURCE_SUBSCRIPTION_TRANSFORM_ID');
};

export const viberSubscriptionExixtsCheck = () => {
  const url = '/v2/sourceSubscriptions.viber.exists';
  const config = {
    method: 'post',
    url,
    data: {
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    }
  };
  return makeRequestCliff(config, 'VIBER SUBSCRIPTION EXISTS CHECK');
};

export const sendOTP = mobile_number => {
  const url = `${window.env.GET_REACT_APP_ALERT_NOTIFIER_SERVICE}/user/send/otp`;
  const config = {
    method: 'post',
    url,
    data: {
      mobile_number
    }
  };
  return makeRequestCliff(config, 'SEND OTP');
};

export const verifyOTP = (mobile_number, otp) => {
  const url = `${window.env.GET_REACT_APP_ALERT_NOTIFIER_SERVICE}/user/verify/otp`;
  const config = {
    method: 'post',
    url,
    data: {
      mobile_number,
      otp
    }
  };
  return makeRequestCliff(config, 'VERIFY OTP');
};

// Below function is used to remove the mobile number of the user
export const deleteUserConnection = source_subscription_id => {
  const url = `${window.env.GET_REACT_APP_ALERT_NOTIFIER_SERVICE}/user/delete`;
  const config = {
    method: 'post',
    url,
    data: {
      source_subscription_id
    }
  };
  return makeRequestCliff(config, 'DELETE USER CONNECTION');
};
