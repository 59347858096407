import React, { Component } from 'react';
import { Offline, Online } from 'react-detect-offline';
import {
  Layout,
  Menu,
  Icon,
  Switch,
  Breadcrumb,
  Alert,
  Drawer,
  Affix
} from '@greendeck/atomic';
import SidebarContent from '../Sidebar/SidebarContent';
import App from 'routes/index';
import { connect } from 'react-redux';
import AppHeader from '../AppHeader/AppHeader';
import AppFooter from '../AppFooter/AppFooter';
import { initUser } from '../../appRedux/actions/Auth';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import OnBoarding from '../../routes/onboarding/OnBoarding';

const optimizely = createInstance({
  sdkKey: window.env.GET_REACT_APP_OPTIMIZELY_KEY,
  datafileOptions: {
    updateInterval: 20000,
    autoUpdate: true,
    urlTemplate: 'https://cdn.optimizely.com/datafiles/%s.json'
  }
});

const { Header, Sider, Footer, Content } = Layout;
const { SubMenu } = Menu;

export class MainApp extends Component {
  componentDidMount() {
    this.props.initUser();
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    // if (!this.props.location.pathname.includes('onboarding')) {
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      alert_visible: false,
      sideWidth: 200,
      online: true
    };
    this.handleClose = () => {
      this.setState({ alert_visible: false });
    };
  }

  handleConnectionChange = () => {
    if (!window.navigator.onLine) this.setState({ online: false });
    else this.setState({ online: true });
  };

  onboardingVisible = () => {
    // this function tells whether the onboarding function should be visible
    // for cliff or not (return true/false)
    // console.log(window.env.GET_REACT_APP_NAME === 'cliff' && this.props.initUserData.organization_onboarded === false);
    // console.log(this.props.location);
    const from = this.props.location.state && this.props.location.state.from;
    const pathname = this.props.location.pathname;
    return (
      window.env.GET_REACT_APP_NAME === 'cliff' &&
      (this.props.initUserData.organization_onboarded === false ||
        pathname === '/onboarding')
    );
  };

  render() {
    const { match } = this.props;
    if (
      this.props.initUserData &&
      window.env.GET_REACT_APP_ENV == 'production'
    ) {
      // Intercom

      try {
        window.Intercom('boot', {
          app_id: 'c7keiwq8',
          name: this.props.initUserData.user.first_name, // Full name
          email: this.props.initUserData.user.email
        });
      } catch (e) {}

      try {
        window.mixpanel.init('645b767d972340b7937101d80ba274cd');
        window.mixpanel.identify(this.props.initUserData.user.id);
        window.mixpanel.people.set({
          $email: this.props.initUserData.user.email,
          'First Name': this.props.initUserData.user.first_name,
          'Last Name': this.props.initUserData.user.last_name,
          Company: this.props.initUserData.default_organization
            .organization_name,
          user_id: this.props.initUserData.user.id
        });
      } catch (e) {}

      // Fullstory
      // window.FS.identify(this.props.initUserData.user.id, {
      //   displayName: this.props.initUserData.user.first_name,
      //   email: this.props.initUserData.user.email
      // });
    }

    return this.props.initUserData ? (
      this.onboardingVisible() ? (
        <OnBoarding
          history={this.props.history}
          location={this.props.location}
        />
      ) : (
        <OptimizelyProvider
          optimizely={optimizely}
          user={{
            id: this.props.initUserData.user.id,
            attributes: this.props.initUserData.user
          }}
          timeout={500}
        >
          <Layout>
            <SidebarContent
              changeSideWidth={bool =>
                this.setState({
                  sideWidth: bool ? (window.screen.width > 800 ? 80 : 0) : 200
                })
              }
              pathname={this.props.location.pathname}
            />
            <Layout>
              {/**/}
              {this.state.alert_visible ? (
                <Alert
                  message={
                    <span>
                      <strong>
                        You're currently in trial mode. Enjoying things so far?{' '}
                        <a href="mailto:support@greendeck.co" target="_blank">
                          <u className="ant-ml-1">Leave a feedback</u>
                        </a>{' '}
                      </strong>
                    </span>
                  }
                  type="info"
                  banner
                  closable
                  showIcon
                  afterClose={this.handleClose}
                  className="ant-text-center"
                />
              ) : null}

              {!this.state.online && (
                <Alert
                  message={
                    <span>
                      <strong>
                        You have been disconnected. We will keep trying to
                        reconnect
                      </strong>
                    </span>
                  }
                  type="warning"
                  banner
                  showIcon
                  className="ant-text-center"
                />
              )}

              <AppHeader />

              <Content
                style={{
                  marginLeft: this.state.sideWidth,
                  minHeight: '100vh'
                }}
              >
                <App match={match} />
              </Content>
              <AppFooter sideWidth={this.state.sideWidth} />
            </Layout>
          </Layout>
        </OptimizelyProvider>
      )
    ) : null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { initUserData } = auth;
  return { initUserData };
};

export default connect(mapStateToProps, { initUser })(MainApp);
