import axios from 'axios';
import {
  FETCH_DATASTREAMS_START,
  FETCH_DATASTREAMS_SUCCESS,
  FETCH_DATASTREAMS_SUCCESS_TOTAL,
  FETCH_DATASTREAMS_ERROR,
  GET_DATASTREAM_START,
  GET_DATASTREAM_SUCCESS,
  GET_DATASTREAM_ERROR,
  // Schedule
  GET_DATASCHEDULE_START,
  GET_DATASCHEDULE_SUCCESS,
  GET_DATASCHEDULE_ERROR,
  GET_SOURCE_SUBSCRIPTION_START,
  GET_SOURCE_SUBSCRIPTION_SUCCESS,
  GET_SOURCE_SUBSCRIPTION_ERROR,
  GET_STREAM_META_DATA_START,
  GET_STREAM_META_DATA_SUCCESS,
  GET_STREAM_META_DATA_ERROR,
  UPDATE_STREAM_ERROR,
  UPDATE_STREAM_SUCCESS,
  UPDATE_STREAM_START,
  GET_ALL_DATASTREAM_START,
  GET_ALL_DATASTREAM_SUCCESS,
  GET_ALL_DATASTREAM_ERROR
} from '../../../../constants/CliffApiConstants';

import {
  DATASTREAMS_LIST,
  GET_DATASTREAM
} from '../../../../constants/RequestTypes';
import { makeRequestCliff } from 'util/request/cliffApi';

export const getDataStreams = (status, includeDemo, fetchType) => {
  return dispatch => {
    dispatch({ type: FETCH_DATASTREAMS_START });
    const url = '/v3/streams.list';
    let config = {
      method: 'post',
      url: url,
      data: {
        organizationId: JSON.parse(localStorage.getItem('organization_id')),
        status,
        includeDemo
      }
    };

    makeRequestCliff(config, 'GET DATA STREAMS')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: FETCH_DATASTREAMS_SUCCESS,
            payload: { streams: data.streams, fetchType }
          });

          dispatch({
            type: FETCH_DATASTREAMS_SUCCESS_TOTAL,
            payload: data.total
          });
        } else {
          dispatch({ type: FETCH_DATASTREAMS_ERROR });
        }
      })
      .catch(function(error) {
        if (!axios.isCancel(error)) {
          dispatch({ type: FETCH_DATASTREAMS_ERROR });
        }
      });
  };
};

export const getAllStreams = (status, loading_false) => {
  return dispatch => {
    dispatch({ type: GET_ALL_DATASTREAM_START, payload: loading_false });
    const url = `/v1/streams/organization/${JSON.parse(
      localStorage.getItem('organization_id')
    )}/list?status=${status}`;
    let config = {
      method: 'get',
      url: url
    };

    makeRequestCliff(config, 'GET ALL DATA STREAMS')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_ALL_DATASTREAM_SUCCESS,
            payload: data.streams
          });
        } else {
          dispatch({ type: GET_ALL_DATASTREAM_ERROR });
        }
      })
      .catch(function(error) {
        if (!axios.isCancel(error)) {
          dispatch({ type: GET_ALL_DATASTREAM_ERROR });
        }
      });
  };
};
// export const getScheduleData = streamId => {
//     return axios.get(
//       '/v1/streams/' + streamId '/collector-runs'
//     )
export const getScheduleData = (streamId, pageNumber) => {
  return dispatch => {
    dispatch({ type: GET_DATASCHEDULE_START });
    const url = `/v2/streams.listCollectorSchedules`;
    let config = {
      method: 'post',
      url: url,
      data: {
        streamId,
        pageNumber
      }
    };

    makeRequestCliff(config, 'GET SCHEDULE DATA')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_DATASCHEDULE_SUCCESS,
            payload: data
          });
        } else {
          dispatch({ type: GET_DATASCHEDULE_ERROR });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_DATASCHEDULE_ERROR });
      });
  };
};
export const getStreamData = ({ streamId, offset }) => {
  return dispatch => {
    dispatch({ type: GET_DATASTREAM_START });
    const url = `/v1/streams/organization/${JSON.parse(
      localStorage.getItem('organization_id')
    )}/data/list?streamId=${streamId}&offset=${offset}`;
    let config = {
      method: 'get',
      url: url
    };

    makeRequestCliff(config, 'GET STREAM DATA')
      .then(({ data }) => {
        if (data) {
          data.rows = data.rows.map(ele => {
            delete ele['index'];
            return ele;
          });
          dispatch({
            type: GET_DATASTREAM_SUCCESS,
            payload: data
          });
        } else {
          dispatch({ type: GET_DATASTREAM_ERROR });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_DATASTREAM_ERROR });
      });
  };
};

export const getStreamMetaData = streamId => {
  return dispatch => {
    dispatch({ type: GET_STREAM_META_DATA_START });
    const url = `/v3/streams.getById`;
    let config = {
      method: 'post',
      url: url,
      data: {
        streamId
      }
    };

    makeRequestCliff(config, 'GET STREAM META DATA')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_STREAM_META_DATA_SUCCESS,
            payload: data.stream
          });
        } else {
          dispatch({ type: GET_STREAM_META_DATA_ERROR });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_STREAM_META_DATA_ERROR });
      });
  };
};

export const getSourceSubscriptionByOrgId = () => {
  return dispatch => {
    dispatch({ type: GET_SOURCE_SUBSCRIPTION_START });
    // const url = `/v1/source-subscriptions/organization/${JSON.parse(
    //   localStorage.getItem('organization_id')
    // )}/group/list`;

    const data = {
      organizationId: JSON.parse(localStorage.getItem('organization_id'))
    };
    const url = `/v3/sourceSubscriptions.groupList`;
    let config = {
      method: 'post',
      url: url,
      data
    };

    makeRequestCliff(config, 'GET SOURCE SUBSCRIPTION DATA')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_SOURCE_SUBSCRIPTION_SUCCESS,
            payload: data.results
          });
        } else {
          dispatch({ type: GET_SOURCE_SUBSCRIPTION_ERROR });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_SOURCE_SUBSCRIPTION_ERROR });
      });
  };
};

export const getStreamBySubscriptionId = subscriptionId => {
  return dispatch => {
    dispatch({ type: 'GET_SUBSCRIPTION_STREAMS_START' });
    const url = `/v1/source-subscriptions/organization/${JSON.parse(
      localStorage.getItem('organization_id')
    )}/streams/list?sourceSubscriptionId=${subscriptionId}`;
    let config = {
      method: 'get',
      url: url
    };

    makeRequestCliff(config, 'GET_SUBSCRIPTIONS_STREAMS')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: 'GET_SUBSCRIPTION_STREAMS_SUCCESS',
            payload: data.streams
          });
        } else {
          dispatch({ type: 'GET_SUBSCRIPTION_STREAMS_ERROR' });
        }
      })
      .catch(function(error) {
        dispatch({ type: 'GET_SUBSCRIPTION_STREAMS_ERROR' });
      });
  };
};

export const getCredentialsData = subscriptionId => {
  // const url = `v1/streams/credentials/get?sourceSubscriptionId=${sourceSubscriptionId}`;
  const url = `/v3/sourceSubscriptions.getAuthCredentials`;
  let config = {
    method: 'post',
    url: url,
    data: {
      subscriptionId
    }
  };
  return makeRequestCliff(config, `GET_SUBSCRIPTIONS_DATA_${Math.random()}`);
};

export const updateStream = (streamId, updates) => {
  const url = `/v3/streams.update`;
  const config = {
    method: 'post',
    url: url,
    data: {
      streamId,
      updates
    }
  };

  return makeRequestCliff(config, 'UPDATE_STREAM');
};

export const updateManyStreamByIds = organizationId => {
  const url = `/v3/streams.updateManyByIds`;
  const config = {
    method: 'post',
    url: url,
    data: {
      organizationId
    }
  };

  return makeRequestCliff(config, 'UPDATE_STREAM');
};

export const clearArchivedStreamData = data => {
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/stream/clean_archive_data`;
  const config = {
    method: 'post',
    url: url,
    data
  };

  return makeRequestCliff(config, 'CLEAR_ARCHIVED_STREAM');
};

export const updateStreamMonitoring = updateObject => {
  const url = `/v3/streams.updateMetricsMonitoringQuery`;
  const config = {
    method: 'post',
    url: url,
    data: updateObject
  };

  return makeRequestCliff(config, 'UPDATE_STREAM');
};

export const getMetricDescriptions = (sourceId, data) => {
  const url = `/v3/sources.getMeasureAndDimenionDescription`;
  const config = {
    method: 'post',
    url: url,
    data: data
  };

  return makeRequestCliff(config, 'GET_METRIC_DESCRIPTIONS');
};

export const archiveMultipleStreams = streams => {
  const url = `/v1/streams/archiveMultipleStreams`;
  const config = {
    method: 'put',
    url: url,
    data: { streams }
  };

  return makeRequestCliff(config, 'ARCHIVE_MULTIPLE_STREAMS');
};

export const getStreamDimensionValues = (streamId, dimensions) => dispatch => {
  dimensions = Object.fromEntries(dimensions);
  delete dimensions._init_metric_;
  const url = `/v1/streams/${streamId}/dimensions/uniqueValues?${new URLSearchParams(
    dimensions
  ).toString()}`;
  const config = {
    method: 'get',
    url: url
  };
  dispatch({ type: 'GET_DIMENSION_UNIQUE_VALUES_START' });
  return makeRequestCliff(config, 'GET_STREAM_DIM_VALUES')
    .then(({ data }) => {
      console.log('data.unique_dimensions', data.unique_dimensions);
      dispatch({
        type: 'GET_DIMENSION_UNIQUE_VALUES_SUCCESS',
        payload: data.unique_dimensions
      });
    })
    .catch(e => {
      if (!axios.isCancel(e)) {
        dispatch({ type: 'GET_DIMENSION_UNIQUE_VALUES_ERROR' });
      }
    });
};

export const getStreamUniqueDimensions = (streamId, dimensions) => {
  const getDimensionQuery = dimensions => {
    return dimensions.reduce((acc, curr, i) => {
      acc = `${acc}${i === 0 ? '' : '&'}${curr}=_null`;
      return acc;
    }, '');
  };

  const url = `/v1/streams/${streamId}/dimensions/uniqueValues?${getDimensionQuery(
    dimensions
  )}`;
  const config = {
    method: 'get',
    url: url
  };
  return makeRequestCliff(config, `'GET_STREAM_DIM_VALUES'${streamId}`);
};

export const setMeasureAndDimensionAlias = data => {
  const url = `/v1/streams/aliases`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'SET_MEASURE_AND_DIMENSION_ALIASES');
};

export const refreshStreams = () => dispatch =>
  dispatch({ type: 'REFRESH_STREAMS', payload: Math.random() });

export const retryFetchDataStreams = data => {
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/stream/retry_run`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, 'RETRY_FETCH_METRICS');
};

export const pauseWorker = (streams_id = []) => {
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/stream/pause/scheduling`;
  let config = {
    method: 'post',
    url: url,
    data: {
      streams_id
    }
  };
  return makeRequestCliff(config, 'PAUSE_WORKER');
};

export const resumeWorker = (streams_id = []) => {
  const url = `${window.env.GET_REACT_APP_VALIDATION_ENDPOINT}/stream/resume/scheduling`;
  let config = {
    method: 'post',
    url: url,
    data: {
      streams_id
    }
  };
  return makeRequestCliff(config, 'RESUME_WORKER');
};

export const deleteLocalStorageStreams = async streamIds => {
  const streamIdSet = new Set(streamIds);

  let recentSelectedStreams = localStorage.getItem('recentSelectedStreams');

  if (recentSelectedStreams) {
    recentSelectedStreams = JSON.parse(recentSelectedStreams);
    recentSelectedStreams = recentSelectedStreams.filter(
      stream => !streamIdSet.has(stream.id)
    );

    localStorage.setItem(
      'recentSelectedStreams',
      JSON.stringify(recentSelectedStreams)
    );
  }
};
