import {
  GET_ALL_RRP_TASKS_START,
  GET_ALL_RRP_TASKS_SUCCESS,
  GET_ALL_RRP_TASKS_ERROR,
  COMPLETE_RRP_START,
  COMPLETE_RRP_SUCCESS,
  COMPLETE_RRP_ERROR,
  GET_RRP_EXCEL_START,
  GET_RRP_EXCEL_SUCCESS,
  GET_RRP_EXCEL_ERROR,
  DELETE_OUTPUT_EXCEL_START,
  DELETE_OUTPUT_EXCEL_SUCCESS,
  DELETE_OUTPUT_EXCEL_ERROR
} from '../../../../constants/ActionTypes';

const INIT_STATE = {
  rrp_count: 0,
  tasksList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_RRP_TASKS_START: {
      return {
        ...state,
        get_all_rrp_tasks_start: true,
        get_all_rrp_tasks_success: false,
        get_all_rrp_tasks_error: false
      };
    }

    case GET_ALL_RRP_TASKS_SUCCESS: {
      return {
        ...state,
        get_all_rrp_tasks_start: false,
        get_all_rrp_tasks_success: true,
        get_all_rrp_tasks_error: false,
        tasksList: action.payload.task_list,
        rrp_count: action.payload.rrp_count
      };
    }

    case GET_ALL_RRP_TASKS_ERROR: {
      return {
        ...state,
        get_all_rrp_tasks_start: false,
        get_all_rrp_tasks_success: false,
        get_all_rrp_tasks_error: true
      };
    }

    case GET_RRP_EXCEL_START: {
      return {
        ...state,
        get_rrp_excel_start: true,
        get_rrp_excel_success: false,
        get_rrp_excel_error: false
      };
    }

    case GET_RRP_EXCEL_SUCCESS: {
      return {
        ...state,
        get_rrp_excel_start: false,
        get_rrp_excel_success: true,
        get_rrp_excel_error: false,
        excelOutput: action.payload
      };
    }

    case GET_RRP_EXCEL_ERROR: {
      return {
        ...state,
        get_rrp_excel_start: false,
        get_rrp_excel_success: false,
        get_rrp_excel_error: true
      };
    }

    case DELETE_OUTPUT_EXCEL_START: {
      return {
        ...state,
        delete_output_excel_start: true,
        delete_output_excel_success: false,
        delete_output_excel_error: false
      };
    }

    case DELETE_OUTPUT_EXCEL_SUCCESS: {
      return {
        ...state,
        delete_output_excel_start: false,
        delete_output_excel_success: true,
        delete_output_excel_error: false
      };
    }

    case DELETE_OUTPUT_EXCEL_ERROR: {
      return {
        ...state,
        delete_output_excel_start: false,
        delete_output_excel_success: false,
        delete_output_excel_error: true
      };
    }

    default:
      return state;
  }
};
