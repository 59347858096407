export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_STATS = 'PRODUCT_STATS';
export const PRODUCT_SAVED = 'PRODUCT_SAVED';
export const PRODUCT_COLLECTION = 'PRODUCT_COLLECTION';

export const PRICE_CHANGED = 'PRICE_CHANGED';

export const SALES_LIST = 'SALES_LIST';
export const SALES_STATS = 'SALES_STATS';

export const AUTOCOMPLETE = 'AUTOCOMPLETE';

export const ASSORTMENT_LIST = 'ASSORTMENT_LIST';
export const ARCHITECTURE_LIST = 'ARCHITECTURE_LIST';

export const PRICE_DISTRIBUTION_LIST = 'PRICE_DISTRIBUTION_LIST';

export const NEWINS_LIST = 'NEWINS_LIST';

export const BENCHMARKING_LIST = 'BENCHMARKING_LIST';
export const OVERLAP_ANALYSIS_LIST = 'OVERLAP_ANALYSIS_LIST';

export const BRAND_COMPARISON_LIST = 'BRAND_COMPARISON_LIST';
export const BRAND_COMPARISON_ANALYTICS = 'BRAND_COMPARISON_ANALYTICS';

export const TRAVEL_PRODUCTS_LIST = 'TRAVEL_PRODUCTS_LIST';
export const TRAVEL_PRODUCTS_STATS = 'TRAVEL_PRODUCTS_STATS';

export const TRAVEL_SALES_LIST = 'TRAVEL_SALES_LIST';

export const NEWSLETTERS_LIST = 'NEWSLETTERS_LIST';
export const NEWSLETTERS_STATS = 'NEWSLETTERS_STATS';
export const NEWSLETTERS_SAVED = 'NEWSLETTERS_SAVED';

export const SNAPSHOTS_LIST = 'SNAPSHOTS_LIST';
export const SNAPSHOTS_STATS = 'SNAPSHOTS_STATS';
export const SNAPSHOTS_SAVED = 'SNAPSHOTS_SAVED';

export const CLASSIFICATION_TREE = 'CLASSIFICATION_TREE';

export const METRICS_LIST = 'METRICS_LIST';
export const ALERTS_LIST = 'ALERTS_LIST';

export const DATASTREAMS_LIST = 'DATASTREAMS_LIST';
export const GET_DATASTREAM = 'GET_DATASTREAM';

export const GET_PRODUCTS_MONITORING = 'GET_PRODUCTS_MONITORING';
export const UPDATE_PRODUCTS_MONITORING = 'UPDATE_PRODUCTS_MONITORING';
export const GET_PRODUCTS_MONITORING_FILTERS =
  'GET_PRODUCTS_MONITORING_FILTERS';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const STORES_DATA = 'STORES_DATA';

export const EXPORT_ASSORTMENT_DATA = 'EXPORT_ASSORTMENT_DATA';
export const EXPORT_NEWINS_DATA = 'EXPORT_NEWINS_DATA';
export const EXPORT_ARCHITECTURE_DATA = 'EXPORT_ARCHITECTURE_DATA';
export const EXPORT_OVERLAP_ANALYSIS_DATA = 'EXPORT_OVERLAP_ANALYSIS_DATA';
