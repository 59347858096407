import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Alert
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userAcceptInvitation } from '../appRedux/actions/Auth';
import { Redirect } from 'react-router-dom';
import AuthLayout from '../components/Layouts/AuthLayout';
var url_params = '';

const { Title, Paragraph } = Typography;
class AcceptInvitation extends Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      console.log('values', values);
      if (!err) {
        this.props.userAcceptInvitation(url_params, values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      console.log('Accept invitation  CHECKK');
      console.log(this.props);
    }
  }

  // componentDidMount(){
  //
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('organization_id');
  //   localStorage.removeItem('init_data');
  //   window.location.reload();
  //
  //
  // }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (!this.props.accept_invitation_success) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('organization_id');
      localStorage.removeItem('init_data');
    }

    url_params = this.props.location.search;
    if (
      window.env.GET_REACT_APP_NAME === 'cliff' &&
      this.props.accept_invitation_success &&
      this.props.token
    ) {
      if (this.props.organization_preferences.onboarded) {
        return <Redirect to="/apps/anomaly-detection/getting-started" />;
      } else {
        return <Redirect to="/onboarding" />;
      }
    }
    if (
      window.env.GET_REACT_APP_NAME !== 'cliff' &&
      this.props.accept_invitation_success &&
      this.props.token
    ) {
      return <Redirect to="/dashboard" />;
    }

    // if (this.props.token) return <Redirect to="/dashboard" />;

    return (
      <AuthLayout>
        <div align="center">
          <Title level={2} className="ant-font-heavy">
            Welcome !
          </Title>
          <Paragraph>Accept Invitation below</Paragraph>
        </div>

        {this.props.accept_invitation_error ? (
          <Alert
            className="ant-mt-5"
            message="Sorry your joining token has been expired. Please try again."
            type="error"
            banner
          />
        ) : null}

        <div align="left">
          <Form
            onSubmit={this.handleSubmit}
            layout="vertical"
            className="ant-mt-5"
          >
            <Form.Item>
              <Row type="flex">
                <Col span={24} align="right">
                  <Button
                    type="primary"
                    className="ant-w-100"
                    size="large"
                    htmlType="submit"
                    loading={this.props.accept_invitation_start ? true : false}
                  >
                    Accept Invitation
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </AuthLayout>
    );
  }
}

const WrappedSignUpForm = Form.create()(AcceptInvitation);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    accept_invitation_start,
    accept_invitation_success,
    accept_invitation_error,
    organization_preferences
  } = auth;
  return {
    token,
    accept_invitation_start,
    accept_invitation_success,
    accept_invitation_error,
    organization_preferences
  };
};

export default connect(mapStateToProps, { userAcceptInvitation })(
  WrappedSignUpForm
);
