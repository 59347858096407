import {
  FETCH_STORES_SUCCESS,
  FETCH_STORES_ERROR,
  FETCH_STORES_START,
  FETCH_WEBSITES_SUCCESS,
  SET_CURRENT_STORE,
  SET_EDIT_MODE,
  CREATE_STORE,
  CREATE_WEBSITE
} from '../../../../constants/StoreList';

const INIT_STATE = {
  storeList: [],
  currentWebsites: [],
  editMode: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_STORES_START: {
      return {
        ...state,
        fetch_stores_success: false,
        fetch_stores_error: false,
        fetch_stores_start: true
      };
    }

    case FETCH_STORES_SUCCESS: {
      console.log(action);
      return {
        ...state,
        fetch_stores_success: true,
        fetch_stores_error: false,
        fetch_stores_start: false,
        storeList: action.storeList,
        currentStore: action.storeList[0]
      };
    }

    case FETCH_STORES_ERROR: {
      return {
        ...state,
        fetch_stores_success: false,
        fetch_stores_error: true,
        fetch_stores_start: false
      };
    }

    case FETCH_WEBSITES_SUCCESS: {
      const obj = {
        ...state,
        currentWebsites: action.currentWebsites
      };
      console.log({ OBJ: obj });
      return obj;
    }

    case SET_CURRENT_STORE: {
      console.log(action);
      return {
        ...state,
        currentStore: action.store
      };
    }

    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.editMode
      };
    }

    case CREATE_STORE: {
      console.log(action);
      return {
        ...state,
        storeList: state.storeList.concat([action.store])
      };
    }

    case CREATE_WEBSITE: {
      return {
        ...state,
        currentWebsites: state.currentWebsites
          .map(region => region._id)
          .includes(action.website.region)
          ? state.currentWebsites.map(region => {
              if (region._id === action.website.region) {
                region.websites.push(action.website);
                return region;
              }
              return region;
            })
          : state.currentWebsites.concat([
              {
                _id: action.website.region,
                websites: [action.website]
              }
            ])
      };
    }

    default:
      return state;
  }
};
