import { makeRequestCliff } from '../../../../../util/request/cliffApi';
import { CHANGE_OMNISEARCH_STATE } from '../../../../../constants/CliffApiConstants';

export const createOmniSearchRecent = data => {
  const url = `v3/omniSearch.postRecentsSearches`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, `CREATE OMNISEARCH RECENT`);
};

export const getOmniSearchRecents = data => {
  const url = `v3/omniSearch.getRecentsSearches`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, `GET OMNISEARCH RECENTS`);
};

export const getOmniSearchData = data => {
  const url = `v2/omniSearch.autoComplete`;
  let config = {
    method: 'post',
    url: url,
    data
  };
  return makeRequestCliff(config, `GET OMNISEARCH DATA`);
};

export const omniSearchStateChange = bool => dispatch => {
  dispatch({ type: CHANGE_OMNISEARCH_STATE, payload: bool });
};
