import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Result,
  Typography,
  Alert
} from '@greendeck/atomic';
import { Redirect, Route, Switch } from 'react-router-dom';
import { userForgotPassword } from '../appRedux/actions/Auth';
import AuthLayout from '../components/Layouts/AuthLayout';

const { Text, Title, Paragraph } = Typography;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ForgotPassword extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userForgotPassword(values);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;
    return (
      <AuthLayout>
        <div align="left">
          <Title level={3} className="ant-font-heavy">
            Forgot password?
          </Title>
          <Paragraph> Reset your password below</Paragraph>
        </div>

        {this.props.forgot_password_error ? (
          <Alert
            className="ant-mt-5"
            message="Invalid Email Address. Please try again."
            type="error"
            banner
          />
        ) : null}

        {this.props.forgot_password_success ? null : (
          <div align="left">
            <Form
              onSubmit={this.handleSubmit}
              layout="vertical"
              className="ant-mt-5"
            >
              <Form.Item
                label={
                  <small className="ant-text-muted">
                    ENTER YOUR EMAIL HERE
                  </small>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                hasFeedback
              >
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email !'
                    }
                  ]
                })(<Input size="large" placeholder="Email" />)}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="ant-w-50"
                  size="large"
                  htmlType="submit"
                  loading={this.props.forgot_password_start ? true : false}
                >
                  Reset password
                </Button>
              </Form.Item>
              <Form.Item>
                <Link to="/signin">Back to Login</Link>
              </Form.Item>
            </Form>
          </div>
        )}
        {this.props.forgot_password_success ? (
          <Result
            status="success"
            title="Successfully Initiated Reset Password!"
            subTitle={
              <>
                <Text>
                  Please Check Your Email to obtain Password Reset Link
                </Text>
                <br />
                <Text>
                  <span style={{ color: '#6078FF' }}>
                    <b>Note:</b>
                  </span>{' '}
                  Please check your spam folder in case the mail isn't visible
                  in your inbox.
                </Text>
              </>
            }
            extra={[
              <a href="/">
                <Button type="primary" className="ant-w-50" size="large">
                  Go Back
                </Button>{' '}
              </a>,
              ,
            ]}
          />
        ) : null}
      </AuthLayout>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create()(ForgotPassword);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    password_reset_token,
    forgot_password_error,
    forgot_password_start,
    forgot_password_success
  } = auth;
  return {
    token,
    password_reset_token,
    forgot_password_error,
    forgot_password_start,
    forgot_password_success
  };
};

export default connect(mapStateToProps, { userForgotPassword })(
  WrappedHorizontalLoginForm
);
