import React from 'react';
import { selectCurrency } from '../../appRedux/actions/Auth';
import { connect } from 'react-redux';
import {
  Popover,
  Button,
  Menu,
  Row,
  Tooltip,
  Affix,
  Col
} from '@greendeck/atomic';
import getCurrencySymbol from '../../util/generics/getCurrencySymbol';

const CurrencySwitcher = props => {
  const currencyList = [
    {
      code: 'local',
      name: 'Local Currency'
    },
    {
      code: 'USD',
      name: 'US Dollar'
    },
    {
      code: 'AUD',
      name: 'Australian Dollar'
    },
    {
      code: 'GBP',
      name: 'Pound Sterling'
    },
    {
      code: 'HKD',
      name: 'Hong Kong Dollar'
    },
    {
      code: 'EUR',
      name: 'Euro'
    },
    {
      code: 'AED',
      name: 'UAE Dirham'
    },
    {
      code: 'RUB',
      name: 'Russian ruble'
    }
  ];

  const currencyMenu = (
    <Menu>
      {currencyList.map(currency => (
        <Menu.Item
          key={JSON.stringify(currency)}
          onClick={e => props.selectCurrency(currency)}
        >
          <Row>
            <Col span={4}>{getCurrencySymbol(currency.code)}</Col>
            <Col span={20}>
              {currency.code == 'local'
                ? 'Local Currency'
                : `${currency.name} (${currency.code.toUpperCase()})`}
            </Col>
          </Row>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Tooltip title="Switch Currency" placement="right">
      <Popover
        placement="bottomRight"
        content={<Affix>{currencyMenu}</Affix>}
        trigger="click"
        className="ant-ml-3 animated fadeIn"
      >
        <Button onClick={() => currencyList}>
          {props.initUserData && props.initUserData.selected_currency ? (
            <>
              {props.initUserData.selected_currency === 'local'
                ? 'Local Currency'
                : props.initUserData.selected_currency.toUpperCase()}{' '}
            </>
          ) : null}
        </Button>
      </Popover>
    </Tooltip>
  );
};

const mapStateToProps = ({ auth }) => {
  const {
    get_currency_start,
    get_currency_success,
    get_currency_error,
    currencyData,
    initUserData
  } = auth;
  return {
    get_currency_start,
    get_currency_success,
    get_currency_error,
    currencyData,
    initUserData
  };
};

export default connect(mapStateToProps, { selectCurrency })(CurrencySwitcher);
