import {
  FETCH_ALERTS_START,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_ERROR,
  FETCH_ALERT_RULES_START,
  FETCH_ALERT_RULES_SUCCESS,
  FETCH_ALERT_RULES_ERROR,
  GET_ALERT_RULES_START,
  GET_ALERT_RULES_SUCCESS,
  GET_ALERT_RULES_ERROR,
  SET_ALERTS_CURRENT_PAGE,
  FETCH_ALERTS_SUMMARY_START,
  FETCH_ALERTS_SUMMARY_SUCCESS,
  FETCH_ALERTS_SUMMARY_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: '',
  alertRules: [],
  alertsData: {},
  alertDetails: {},
  current_page: 1
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALERTS_SUMMARY_START: {
      return {
        ...state,
        fetch_alerts_summary_success: false,
        fetch_alerts_summary_error: false,
        fetch_alerts_summary_start: true,
        alertDetails: {}
      };
    }

    case FETCH_ALERTS_SUMMARY_SUCCESS: {
      return {
        ...state,
        fetch_alerts_summary_success: true,
        fetch_alerts_summary_error: false,
        fetch_alerts_summary_start: false,
        alertsData: {
          ...action.payload,
          // alerts: action.payload.alerts,
          // total: action.payload.total,
          summary: action.payload.summary,
          weeklyCount: action.payload.weeklyCount,
          monthlyCount: action.payload.monthlyCount
        }
      };
    }

    case FETCH_ALERTS_SUMMARY_ERROR: {
      return {
        ...state,
        fetch_alerts_success: false,
        fetch_alerts_error: true,
        fetch_alerts_start: false
      };
    }

    case FETCH_ALERTS_START: {
      return {
        ...state,
        fetch_alerts_success: false,
        fetch_alerts_error: false,
        fetch_alerts_start: true
      };
    }

    case FETCH_ALERTS_SUCCESS: {
      return {
        ...state,
        fetch_alerts_success: true,
        fetch_alerts_error: false,
        fetch_alerts_start: false,
        alertsList: action.payload.alerts,
        total: action.payload.total
      };
    }

    case FETCH_ALERTS_ERROR: {
      return {
        ...state,
        fetch_alerts_success: false,
        fetch_alerts_error: true,
        fetch_alerts_start: false
      };
    }

    case FETCH_ALERT_RULES_START: {
      return {
        ...state,
        fetch_alert_rules_success: false,
        fetch_alert_rules_error: false,
        fetch_alert_rules_start: true
      };
    }

    case FETCH_ALERT_RULES_SUCCESS: {
      return {
        ...state,
        fetch_alert_rules_success: true,
        fetch_alert_rules_error: false,
        fetch_alert_rules_start: false,
        alertRules: action.payload
      };
    }

    case FETCH_ALERT_RULES_ERROR: {
      return {
        ...state,
        fetch_alert_rules_success: false,
        fetch_alert_rules_error: true,
        fetch_alert_rules_start: false
      };
    }

    case GET_ALERT_RULES_START: {
      return {
        ...state,
        fetch_alert_details_success: false,
        fetch_alert_details_error: false,
        fetch_alert_details_start: true
      };
    }

    case GET_ALERT_RULES_SUCCESS: {
      return {
        ...state,
        fetch_alert_details_success: true,
        fetch_alert_details_error: false,
        fetch_alert_details_start: false,
        alertDetails: action.payload
      };
    }

    case GET_ALERT_RULES_ERROR: {
      return {
        ...state,
        fetch_alert_details_success: false,
        fetch_alert_details_error: true,
        fetch_alert_details_start: false
      };
    }

    case SET_ALERTS_CURRENT_PAGE: {
      return {
        ...state,
        current_page: action.payload
      };
    }

    default:
      return state;
  }
};
