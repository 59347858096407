import {
  ADD_FILTER,
  EDIT_FILTER_START,
  EDIT_FILTER_DONE,
  REMOVE_FILTER,
  REMOVE_ALL_FILTERS,
  SET_EDIT_FILTER,
  LOAD_SAVED_FILTER,
  SET_FILTERS,
  CUSTOM_FILTER
} from '../../constants/FilterConstants';

export const addFilter = (filterObject, optionalCondition) => {
  return {
    type: ADD_FILTER,
    condition: filterObject,
    optionalCondition
  };
};

export const editFilterStart = filterObject => {
  return {
    type: EDIT_FILTER_START,
    editCondition: filterObject
  };
};

export const editFilterDone = filterObject => {
  return {
    type: EDIT_FILTER_DONE,
    editCondition: filterObject
  };
};

export const removeFilter = filterObject => {
  return {
    type: REMOVE_FILTER,
    deleteCondition: filterObject
  };
};

export const removeAllFilters = () => {
  return {
    type: REMOVE_ALL_FILTERS
  };
};

export const setEditFilter = filterObject => {
  return {
    type: REMOVE_ALL_FILTERS,
    editFilter: filterObject
  };
};

export const loadSavedFilter = (id, name, filter) => {
  return {
    type: LOAD_SAVED_FILTER,
    id: id,
    name: name,
    savedFilter: filter
  };
};

export const setFilters = filterObject => {
  return {
    type: SET_FILTERS,
    filterObject
  };
};

export const setCustomFilters = filterObject => {
  return {
    type: CUSTOM_FILTER,
    payload: filterObject
  };
};
