import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button,
  Layout,
  List
} from '@greendeck/atomic';

const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

const OnboardingMain = props => {
  const [signOutLoading, setSignOutLoading] = useState(false);

  const handleSignOut = () => {
    props.signOut();
    setSignOutLoading(true);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>
        <div className="ant-container ant-pt-3">
          <Row>
            <Col>
              <img
                style={{ width: '7.5rem' }}
                src="https://greendeck-cdn.s3.amazonaws.com/cliff_icons/cliff_light.svg"
              />
            </Col>
            <Col align="right">
              <Button
                loading={signOutLoading}
                disabled={signOutLoading}
                type="link"
                onClick={handleSignOut}
              >
                {signOutLoading ? 'Signing out...' : 'Sign out'}
              </Button>
            </Col>
          </Row>

          <Row className="ant-mt-5 ant-pt-5" gutter={32}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 10 }}
              xl={{ span: 10 }}
              className="ant-pr-5 ant-mb-3"
            >
              <Row>
                <Title level={1} strong>
                  Hi {props.user.first_name}, 👋 <br /> Welcome to{' '}
                  <span class="ant-text-primary">Cliff.ai</span>
                </Title>
              </Row>
              <Paragraph className="ant-mt-3">
                Track unexpected changes in your metrics instantly without
                creating dashboards or complex data pipelines so that you can
                have a good night's sleep.
                <strong> In just 3 simple steps:</strong>
                <List itemLayout="horizontal" className="ant-mt-3">
                  <List.Item>
                    <Icon type="check-circle" />{' '}
                    <strong className="ant-ml-2">
                      No code data collection from multiple sources
                    </strong>
                  </List.Item>
                  <List.Item>
                    <Icon type="check-circle" />{' '}
                    <strong className="ant-ml-2">
                      Automatically track metrics for any unexpected changes
                    </strong>
                  </List.Item>
                  <List.Item>
                    <Icon type="check-circle" />{' '}
                    <strong className="ant-ml-2">
                      Get notifications on <Icon type="slack" /> Slack /{' '}
                      <Icon type="mail" /> Email
                    </strong>
                  </List.Item>
                </List>
              </Paragraph>
              <Button
                onClick={() => props.changeMainStage('demo')}
                className="ant-mt-5"
                block
                type="primary"
                size="large"
              >
                Show me how it's done!{' '}
                <small class="ant-ml-2">({'<'} 2 mins)</small>
              </Button>
              <Button
                onClick={() =>
                  props.turnOffOnboarding(
                    '/apps/anomaly-detection/integrations/inbound'
                  )
                }
                block
                className="ant-mt-3"
                loading={props.turnOffOnboardingLoading}
                type="link"
                size="medium"
                hidden
              >
                Skip onboarding
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 14 }}
              xl={{ span: 14 }}
              className="ant-pl-5"
            >
              <Row gutter={16} className="ant-mb-5">
                <Col span={12}>
                  <Card>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={
                        window.env.PUBLIC_URL +
                        '/product_illustrations/steps/collect_icon.svg'
                      }
                      className="ant-mb-3"
                    ></img>
                    <Title level={4} strong>
                      Collect
                    </Title>
                    <Text>
                      Gather data from over 100+ sources without ETL pipelines
                    </Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={
                        window.env.PUBLIC_URL +
                        '/product_illustrations/steps/detect_icon.svg'
                      }
                      className="ant-mb-3"
                    ></img>
                    <Title level={4} strong>
                      Detect
                    </Title>
                    <Text>
                      Monitor all of your metrics for any unexpected issues.
                    </Text>
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} className="ant-mt-5">
                <Col span={12}>
                  <Card>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={
                        window.env.PUBLIC_URL +
                        '/product_illustrations/steps/inspect_icon.svg'
                      }
                      className="ant-mb-3"
                    ></img>
                    <Title level={4} strong>
                      Inspect
                    </Title>
                    <Text>
                      Quickly discover which factors lead to unexpected changes
                      in your metrics
                    </Text>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={
                        window.env.PUBLIC_URL +
                        '/product_illustrations/steps/act_icon.svg'
                      }
                      className="ant-mb-3"
                    ></img>
                    <Title level={4} strong>
                      Act
                    </Title>
                    <Text>
                      Alerts that are integrated into your existing workflow.
                      Respond quickly
                    </Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default OnboardingMain;
