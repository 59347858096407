export const FETCH_STORES_START = 'FETCH_STORES_START';
export const FETCH_STORES_ERROR = 'FETCH_STORES_ERROR';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';

export const FETCH_WEBSITES_SUCCESS = 'FETCH_WEBSITES_SUCCESS';
export const SET_CURRENT_STORE = 'SET_CURRENT_STORE';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

export const CREATE_STORE = 'CREATE_STORE';
export const CREATE_WEBSITE = 'CREATE_WEBSITE';
