import {
  EXPORT_DATA_START,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_ERROR
} from '../../constants/ApiActionTypes';

import {
  SAVE_EXPORT_START,
  SAVE_EXPORT_SUCCESS,
  SAVE_EXPORT_ERROR,
  GET_EXPORT_DATA_START,
  GET_EXPORT_DATA_SUCCESS,
  GET_EXPORT_DATA_ERROR,
  DELETE_EXPORT_START,
  DELETE_EXPORT_SUCCESS,
  DELETE_EXPORT_ERROR
} from '../../constants/ActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPORT_DATA_START: {
      return {
        ...state,
        export_data_success: false,
        export_data_error: false,
        export_data_start: true
      };
    }

    case EXPORT_DATA_SUCCESS: {
      return {
        ...state,
        exportUrl: action.payload,
        export_data_success: true,
        export_data_error: false,
        export_data_start: false
      };
    }

    case EXPORT_DATA_ERROR: {
      return {
        ...state,
        export_data_success: false,
        export_data_error: true,
        export_data_start: false
      };
    }

    case SAVE_EXPORT_START: {
      return {
        ...state,
        save_export_data_success: false,
        save_export_data_error: false,
        save_export_data_start: true
      };
    }

    case SAVE_EXPORT_SUCCESS: {
      return {
        ...state,
        save_export_data_success: true,
        save_export_data_error: false,
        save_export_data_start: false
      };
    }

    case SAVE_EXPORT_ERROR: {
      return {
        ...state,
        save_export_data_success: false,
        save_export_data_error: true,
        save_export_data_start: false
      };
    }

    case GET_EXPORT_DATA_START: {
      return {
        ...state,
        fetch_export_data_success: false,
        fetch_export_data_error: false,
        fetch_export_data_start: true
      };
    }

    case GET_EXPORT_DATA_SUCCESS: {
      return {
        ...state,
        fetch_export_data_success: true,
        fetch_export_data_error: false,
        fetch_export_data_start: false,
        savedExportsData: action.payload
      };
    }

    case GET_EXPORT_DATA_ERROR: {
      return {
        ...state,
        fetch_export_dat_success: false,
        fetch_export_data_error: true,
        fetch_export_data_start: false
      };
    }

    case DELETE_EXPORT_START: {
      return {
        ...state,
        delete_export_data_success: false,
        delete_export_data_error: false,
        delete_export_data_start: true
      };
    }

    case DELETE_EXPORT_SUCCESS: {
      return {
        ...state,
        delete_export_data_success: true,
        delete_export_data_error: false,
        delete_export_data_start: false
      };
    }

    case DELETE_EXPORT_ERROR: {
      return {
        ...state,
        delete_export_data_success: false,
        delete_export_data_error: true,
        delete_export_data_start: false
      };
    }

    default:
      return state;
  }
};
