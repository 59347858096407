import React, { Component } from 'react';
import { Layout, Row, Col, Tooltip, Icon } from '@greendeck/atomic';
import { footerText } from 'util/universalFooter';
import { connect } from 'react-redux';

const { Footer } = Layout;

class AppFooter extends Component {
  render() {
    return (
      <Footer
        style={{
          background: '#fff',
          marginLeft: this.props.sideWidth,
          marginRight: this.props.rightCollapseWidth
          // zIndex: 10
        }}
        className="ant-p-3"
      >
        <Row>
          <Col align="left">
            <Tooltip title={window.env.GET_REACT_APP_ENV}>
              {window.env.GET_REACT_APP_NAME} v
              {window.env.GET_REACT_APP_VERSION}{' '}
              {window.env.GET_REACT_APP_ENV == 'production'
                ? null
                : window.env.GET_REACT_APP_ENV}
            </Tooltip>
          </Col>
          <Col align="center">
            {
              footerText[
                window.env.GET_REACT_APP_NAME.toLocaleLowerCase() || 'greendeck'
              ]
            }
          </Col>
          <Col align="right">
            <a href="https://www.cliff.ai/slack" target="_blank">
              {window.env.GET_REACT_APP_NAME == 'cliff' ? (
                <>
                  {' '}
                  Join our <Icon type="slack" /> Slack community
                </>
              ) : null}
            </a>
          </Col>
        </Row>
      </Footer>
    );
  }
}

const mapStateToProps = ({ appFooter }) => {
  const { rightCollapseWidth } = appFooter;
  return {
    rightCollapseWidth
  };
};

export default connect(mapStateToProps, {})(AppFooter);
