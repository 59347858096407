import {
  PRICE_POSITIONING_START,
  PRICE_POSITIONING_SUCCESS,
  PRICE_POSITIONING_ERROR,
  PRICE_POSITIONING_EXPORT_SUCCESS,
  PRICE_POSITIONING_EXPORT_START,
  PRICE_POSITIONING_EXPORT_ERROR,
  GET_OVERLAP_ANALYSIS_START,
  GET_OVERLAP_ANALYSIS_SUCCESS,
  GET_OVERLAP_ANALYSIS_ERROR,
  EXPORT_OVERLAP_ANALYSIS_DATA_START,
  EXPORT_OVERLAP_ANALYSIS_DATA_SUCCESS,
  EXPORT_OVERLAP_ANALYSIS_DATA_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRICE_POSITIONING_START: {
      return {
        ...state,
        price_positioning_success: false,
        price_positioning_error: false,
        price_positioning_start: true
      };
    }

    case PRICE_POSITIONING_SUCCESS: {
      return {
        ...state,
        price_positioning_success: true,
        price_positioning_error: false,
        price_positioning_start: false,
        pricePositioningData: action.payload
      };
    }

    case PRICE_POSITIONING_ERROR: {
      return {
        ...state,
        price_positioning_success: false,
        price_positioning_error: true,
        price_positioning_start: false
      };
    }

    case PRICE_POSITIONING_EXPORT_START: {
      return {
        ...state,
        price_positioning_export_success: false,
        price_positioning_export_error: false,
        price_positioning_export_start: true
      };
    }

    case PRICE_POSITIONING_EXPORT_SUCCESS: {
      return {
        ...state,
        price_positioning_export_success: true,
        price_positioning_export_error: false,
        price_positioning_export_start: false,
        exportData: action.payload
      };
    }

    case PRICE_POSITIONING_EXPORT_ERROR: {
      return {
        ...state,
        price_positioning_export_success: false,
        price_positioning_export_error: true,
        price_positioning_export_start: false
      };
    }

    case GET_OVERLAP_ANALYSIS_START: {
      return {
        ...state,
        get_overlap_analysis_start: true,
        get_overlap_analysis_success: false,
        get_overlap_analysis_error: false
      };
    }

    case GET_OVERLAP_ANALYSIS_SUCCESS: {
      return {
        ...state,
        get_overlap_analysis_start: false,
        get_overlap_analysis_success: true,
        get_overlap_analysis_error: false,
        overlapAnalysisData: action.payload
      };
    }

    case GET_OVERLAP_ANALYSIS_ERROR: {
      return {
        ...state,
        get_overlap_analysis_start: false,
        get_overlap_analysis_success: false,
        get_overlap_analysis_error: true
      };
    }

    case EXPORT_OVERLAP_ANALYSIS_DATA_START: {
      return {
        ...state,
        export_overlap_analysis_data_start: true,
        export_overlap_analysis_data_success: false,
        export_overlap_analysis_data_error: false
      };
    }

    case EXPORT_OVERLAP_ANALYSIS_DATA_SUCCESS: {
      return {
        ...state,
        export_overlap_analysis_data_start: false,
        export_overlap_analysis_data_success: true,
        export_overlap_analysis_data_error: false,
        exportData: action.payload
      };
    }

    case EXPORT_OVERLAP_ANALYSIS_DATA_ERROR: {
      return {
        ...state,
        export_overlap_analysis_data_start: false,
        export_overlap_analysis_data_success: false,
        export_overlap_analysis_data_error: true
      };
    }

    default:
      return state;
  }
};
