import { envEncryption } from '../../secureEnv';
//for development
const google_oauth_dev = {
  CLIENT_ID:
    '391458886763-j9m9qb7a3nctq1jum9rtr3m6bvj3rd4p.apps.googleusercontent.com'
};

// Also used for staging since there is no separate staging deployment of the
// backend

//for production
const google_oauth_prod = {
  CLIENT_ID:
    '391458886763-hr9q7tal5i4t6uerf3da9drk724oih9k.apps.googleusercontent.com'
};

const checkRedirection = () => {
  const localhost = 'http://localhost:3000';
  const dev =
    window.env.REACT_APP_CLIFF_DOMAIN || 'https://development.cliff.ai';
  const staging =
    window.env.REACT_APP_CLIFF_DOMAIN || 'https://staging.cliff.ai';
  const prod = window.env.REACT_APP_CLIFF_DOMAIN || 'https://app.cliff.ai';
  let googleConfig;
  const env = envEncryption.decryptData(window.env.REACT_APP_ENV);
  switch (env) {
    case 'development':
      googleConfig = {
        ...google_oauth_dev,
        REDIRECT_URI: dev + '/apps/anomaly-detection/integrations/redirect'
      };
      break;

    case 'staging':
      googleConfig = {
        ...google_oauth_prod,
        REDIRECT_URI: staging + `/apps/anomaly-detection/integrations/redirect`
      };

      break;

    case 'production':
      googleConfig = {
        ...google_oauth_prod,
        REDIRECT_URI: prod + '/apps/anomaly-detection/integrations/redirect'
      };
      break;

    default:
      googleConfig = {
        ...google_oauth_dev,
        REDIRECT_URI: dev + '/apps/anomaly-detection/integrations/redirect'
      };
      break;
  }
  return googleConfig;
};

export default checkRedirection();
