import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_INFO_START,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  USER_DATA,
  USER_TOKEN_SET,
  USER_PASSWORD_RESET_TOKEN,
  UPDATE_USER_PROFILE,
  FETCH_MEMBERS,
  MEMBERS_DATA,
  MODULES_DATA_START,
  MODULES_DATA_SUCCESS,
  MODULES_DATA_ERROR,
  SUBSCRIBED_MODULES_DATA_START,
  SUBSCRIBED_MODULES_DATA_SUCCESS,
  SUBSCRIBED_MODULES_DATA_ERROR,
  SUBSCRIBE_MODULE_START,
  SUBSCRIBE_MODULE_SUCCESS,
  SUBSCRIBE_MODULE_ERROR,
  UNSUBSCRIBE_MODULE_START,
  UNSUBSCRIBE_MODULE_SUCCESS,
  UNSUBSCRIBE_MODULE_ERROR,
  APPS_DATA_START,
  APPS_DATA_SUCCESS,
  APPS_DATA_ERROR,
  SUBSCRIBE_APP_SUCCESS,
  SUBSCRIBE_APP_ERROR,
  SUBSCRIBE_APP_START,
  UNSUBSCRIBE_APP_SUCCESS,
  UNSUBSCRIBE_APP_ERROR,
  UNSUBSCRIBE_APP_START,
  SUBSCRIBED_WEBSITES_START,
  SUBSCRIBED_WEBSITES_SUCCESS,
  SUBSCRIBED_WEBSITES_ERROR,
  DELETE_ORGANIZATION_SUCCES,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_START,
  INVITATIONS_DATA,
  ORGANIZATION_DATA,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  SIGNUP_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
  SIGNIN_USER,
  SIGNIN_USER_FORBIDDEN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE,
  UPDATE_PROFILE_DETAILS_START,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_ERROR,
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
  RESEND_INVITATION_START,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_ERROR,
  DELETE_INVITATION_START,
  USER_PREFERENCES_SUCCESS,
  USER_PREFERENCES_ERROR,
  USER_PREFERENCES_START,
  UPDATE_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES_START,
  USER_PREFERENCES_DATA,
  ORGANIZATION_PREFERENCES_SUCCESS,
  ORGANIZATION_PREFERENCES_ERROR,
  ORGANIZATION_PREFERENCES_START,
  ORGANIZATION_PREFERENCES_DATA,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_ERROR,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_ERROR,
  DELETE_MEMBERSHIP_START,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_ERROR,
  UPDATE_MEMBERSHIP_START,
  DEFAULT_ORGANIZATION_SUCCESS,
  DEFAULT_ORGANIZATION_ERROR,
  DEFAULT_ORGANIZATION_START,
  WEBSITES_DATA_START,
  WEBSITES_DATA_SUCCESS,
  WEBSITES_DATA_ERROR,
  SUBSCRIBE_WEBSITE_START,
  SUBSCRIBE_WEBSITE_SUCCESS,
  SUBSCRIBE_WEBSITE_ERROR,
  UNSUBSCRIBE_WEBSITE_START,
  UNSUBSCRIBE_WEBSITE_SUCCESS,
  UNSUBSCRIBE_WEBSITE_ERROR,
  SAVE_FILTER_START,
  SAVE_FILTER_SUCCESS,
  SAVE_FILTER_ERROR,
  GET_SAVED_FILTERS_START,
  GET_SAVED_FILTERS_SUCCESS,
  GET_SAVED_FILTERS_ERROR,
  DELETE_SAVED_FILTER_START,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_ERROR,
  INIT_USER_DATA,
  FETCH_INIT_START,
  FETCH_INIT_SUCCESS,
  FETCH_INIT_ERROR,
  PROJECTS_DATA,
  SESSIONS_DATA,
  LOGS_DATA,
  LOGS_FILTERS_DATA,
  USAGE_AND_PAYMENT_DATA,
  JOIN_USER_SUCCESS,
  JOIN_USER_ERROR,
  JOIN_USER,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_START,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_ERROR,
  ADD_MEMBER,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
  GET_REGIONS_START,
  SELECT_REGION_START,
  SELECT_REGION_SUCCESS,
  SELECT_REGION_ERROR,
  UPDATE_SAVED_FILTER_START,
  UPDATE_SAVED_FILTER_SUCCESS,
  UPDATE_SAVED_FILTER_ERROR,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  GET_ALL_ROLE_TASKS_START,
  GET_ALL_ROLE_TASKS_SUCCESS,
  GET_ALL_ROLE_TASKS_ERROR,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_START,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_START,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
  SIGNOUT_ALL_START,
  SIGNOUT_ALL_SUCCESS,
  SIGNOUT_ALL_ERROR,
  VALIDATE_PASSWORD_START,
  VALIDATE_PASSWORD_SUCCESS,
  VALIDATE_PASSWORD_ERROR,
  CREATE_API_KEY_START,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_ERROR,
  UPDATE_API_KEY_START,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_ERROR,
  DELETE_API_KEY_START,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_ERROR,
  GET_ALL_API_KEY_START,
  GET_ALL_API_KEY_SUCCESS,
  GET_ALL_API_KEY_ERROR,
  GET_API_KEY_START,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_ERROR,
  EMAIL_VERIFICATON_START,
  EMAIL_VERIFICATON_SUCCESS,
  EMAIL_VERIFICATON_ERROR,
  SELECT_CURRENCY_START,
  SELECT_CURRENCY_SUCCESS,
  SELECT_CURRENCY_ERROR,
  SWITCH_ORGANIZATION_START,
  SWITCH_ORGANIZATION_SUCCESS,
  SWITCH_ORGANIZATION_ERROR
} from '../../constants/ActionTypes';

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  createApiLoader: false,
  createApiSuccess: false,
  getAllApiLoader: false,
  apis: [],
  apiKey: {},
  api_key: {},
  updateApiKeyLoader: false,
  updateApiSuccess: false,
  deleteApiSuccess: false,
  deleteApiKeyLoader: false,
  getApiLoader: false,
  organizationPreferencesData: {},
  sign_out_success: false,
  create_role_start: false,
  create_role_success: false,
  create_role_error: false,
  switchOrganizationStart: false,
  switchOrganizationError: null,
  switchOrganizationSuccess: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
        sign_out_success: true
      };
    }

    case SIGNUP_USER: {
      return {
        ...state,
        signup_success: false,
        signup_start: true,
        signup_error: false
      };
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        user_data: action.payload,
        token: 'token',
        authUser: 'authUser',
        signup_success: true,
        signup_start: false,
        signup_error: false
      };
    }

    case SIGNUP_USER_ERROR: {
      return {
        ...state,
        signup_success: false,
        signup_start: false,
        signup_error: action.payload || true
      };
    }

    case JOIN_USER: {
      return {
        ...state,
        join_start: true,
        join_error: false
      };
    }

    case JOIN_USER_SUCCESS: {
      return {
        ...state,
        token: 'token',
        authUser: 'authUser',
        join_success: true,
        join_start: false,
        organization_preferences: action.payload
      };
    }

    case JOIN_USER_ERROR: {
      return {
        ...state,
        join_error: true,
        join_start: false
      };
    }

    case ACCEPT_INVITATION_START: {
      return {
        ...state,
        accept_invitation_start: true,
        accept_invitation_error: false,
        accept_invitation_success: false
      };
    }

    case ACCEPT_INVITATION_SUCCESS: {
      return {
        ...state,
        token: 'token',
        authUser: 'authUser',
        accept_invitation_success: true,
        accept_invitation_start: false,
        accept_invitation_error: false,
        organization_preferences: action.payload
      };
    }

    case ACCEPT_INVITATION_ERROR: {
      return {
        ...state,
        accept_invitation_error: true,
        accept_invitation_start: false,
        accept_invitation_success: false
      };
    }

    case SIGNIN_USER: {
      return {
        ...state,
        signin_start: true,
        signin_success: false,
        signin_error: false,
        signin_forbidden: false
      };
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        user_data: action.payload.user,
        default_organiztion_preferences:
          action.payload.default_organization_preferences,
        token: true,
        authUser: 'authUser',
        signin_success: true,
        signin_error: false,
        signin_start: false,
        signin_forbidden: false,
        initUserData: JSON.parse(localStorage.getItem('init_data'))
      };
    }

    case SIGNIN_USER_ERROR: {
      return {
        ...state,
        signin_error: action.payload || true,
        signin_success: false,
        signin_start: false,
        signin_forbidden: false
      };
    }

    case SIGNIN_USER_FORBIDDEN: {
      return {
        ...state,
        signin_error: false,
        signin_success: false,
        signin_start: false,
        signin_forbidden: true
      };
    }

    // Email verification

    case EMAIL_VERIFICATON_START: {
      return {
        ...state,
        email_verification_start: true,
        email_verification_success: false,
        email_verification_error: false,
        email_verification_data: null
      };
    }

    case EMAIL_VERIFICATON_SUCCESS: {
      return {
        ...state,
        email_verification_start: false,
        email_verification_success: true,
        email_verification_error: false,
        email_verification_data: action.payload,
        organization_preferences: action.payload.organization_preferences
      };
    }

    case EMAIL_VERIFICATON_ERROR: {
      return {
        ...state,
        email_verification_start: false,
        email_verification_success: false,
        email_verification_error: true,
        email_verification_data: action.payload
      };
    }

    case FORGOT_PASSWORD: {
      return {
        ...state,
        forgot_password_start: true,
        forgot_password_error: false,
        forgot_password_success: false
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: 'authUser',
        forgot_password_success: true,
        forgot_password_start: false
      };
    }

    case FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        token: null,
        forgot_password_error: true,
        forgot_password_start: false
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        reset_password_start: true,
        reset_password_error: false,
        reset_password_success: false
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        // token: 'token',
        authUser: 'authUser',
        reset_password_success: true,
        reset_password_start: false
      };
    }

    case RESET_PASSWORD_ERROR: {
      return {
        ...state,
        reset_password_error: true,
        reset_password_start: false
      };
    }

    case RESEND_INVITATION_START: {
      return {
        ...state,
        resend_invitation_start: true,
        resend_invitation_error: false,
        resend_invitation_success: false,

        delete_invitation_success: false,
        delete_invitation_error: false,
        update_membership_success: false,
        update_membership_error: false,
        delete_membership_success: false,
        delete_membership_success: false
      };
    }

    case RESEND_INVITATION_SUCCESS: {
      return {
        ...state,
        resend_invitation_success: true,
        resend_invitation_start: false,
        resend_invitation_error: false
      };
    }

    case RESEND_INVITATION_ERROR: {
      return {
        ...state,
        resend_invitation_error: true,
        resend_invitation_start: false,
        resend_invitation_success: false
      };
    }

    case DELETE_INVITATION_START: {
      return {
        ...state,
        delete_invitation_start: true,
        delete_invitation_success: false,
        delete_invitation_error: false,

        resend_invitation_error: false,
        resend_invitation_success: false,
        update_membership_success: false,
        update_membership_error: false,
        delete_membership_success: false,
        delete_membership_success: false
      };
    }

    case DELETE_INVITATION_SUCCESS: {
      return {
        ...state,
        delete_invitation_success: true,
        delete_invitation_error: false,
        delete_invitation_start: false
      };
    }

    case DELETE_INVITATION_ERROR: {
      return {
        ...state,
        delete_invitation_error: true,
        delete_invitation_success: false,
        delete_invitation_start: false
      };
    }

    case DELETE_MEMBERSHIP_START: {
      return {
        ...state,
        delete_membership_success: false,
        delete_membership_error: false,
        delete_membership_start: true,

        delete_invitation_success: false,
        delete_invitation_error: false,
        resend_invitation_error: false,
        resend_invitation_success: false,
        update_membership_success: false,
        update_membership_error: false
      };
    }

    case DELETE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        delete_membership_success: true,
        delete_membership_error: false,
        delete_membership_start: false
      };
    }

    case DELETE_MEMBERSHIP_ERROR: {
      return {
        ...state,
        delete_membership_error: true,
        delete_membership_success: false,
        delete_membership_start: false
      };
    }

    case UPDATE_MEMBERSHIP_START: {
      return {
        ...state,
        update_membership_success: false,
        update_membership_error: false,
        update_membership_start: true,

        delete_invitation_success: false,
        delete_invitation_error: false,
        resend_invitation_error: false,
        resend_invitation_success: false,
        delete_membership_success: false,
        delete_membership_success: false
      };
    }

    case UPDATE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        update_membership_success: true,
        update_membership_error: false,
        update_membership_start: false
      };
    }

    case UPDATE_MEMBERSHIP_ERROR: {
      return {
        ...state,
        update_membership_error: true,
        update_membership_success: false,
        update_membership_start: false
      };
    }

    case UPDATE_PROFILE: {
      return {
        ...state,
        update_profile_start: true,
        update_profile_error: false
      };
    }

    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        update_profile_success: true,
        update_profile_start: false
      };
    }

    case UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        update_profile_error: true,
        update_profile_start: false
      };
    }

    case UPDATE_PROFILE_DETAILS_START: {
      return {
        ...state,
        update_profile_details_start: true,
        update_profile_details_error: false,
        update_profile_details_success: false
      };
    }

    case UPDATE_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        update_profile_details_success: true,
        update_profile_details_start: false,
        update_profile_details_error: false
      };
    }

    case UPDATE_PROFILE_DETAILS_ERROR: {
      return {
        ...state,
        update_profile_details_error: true,
        update_profile_details_start: false,
        update_profile_details_success: false
      };
    }

    case UPDATE_ORGANIZATION_START: {
      return {
        ...state,
        update_organization_start: true,
        update_organization_error: false,
        update_organization_success: false
      };
    }

    case UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        update_organization_success: true,
        update_organization_start: false,
        update_organization_error: false
      };
    }

    case UPDATE_ORGANIZATION_ERROR: {
      return {
        ...state,
        update_organization_error: true,
        update_organization_start: false,
        update_organization_success: false
      };
    }

    case ADD_MEMBER: {
      return {
        ...state,
        add_member_start: true,
        add_member_error: false,
        add_member_success: false
      };
    }

    case ADD_MEMBER_SUCCESS: {
      return {
        ...state,
        add_member_success: true,
        add_member_start: false,
        add_member_error: false
      };
    }

    case ADD_MEMBER_ERROR: {
      return {
        ...state,
        add_member_error: action.payload || true,
        add_member_start: false,
        add_member_success: false
      };
    }

    case CREATE_ORGANIZATION_START: {
      return {
        ...state,
        create_organization_start: true,
        create_organization_error: false,
        create_organization_success: false
      };
    }

    case CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        create_organization_success: true,
        create_organization_start: false,
        create_organization_error: false
      };
    }

    case CREATE_ORGANIZATION_ERROR: {
      return {
        ...state,
        create_organization_error: true,
        create_organization_start: false,
        create_organization_success: false
      };
    }

    case FETCH_START: {
      return {
        ...state,
        fetch_start: 'fetch_start'
      };
    }

    case FETCH_ERROR: {
      return {
        ...state,
        fetch_error: 'fetch_error'
      };
    }

    case FETCH_SUCCESS: {
      return {
        ...state,
        fetch_success: 'fetch_success'
      };
    }

    case 'UPDATE_SELECTED_APP_START': {
      return {
        ...state,
        update_selected_app_start: true,
        update_selected_app_success: false
      };
    }

    case 'UPDATE_SELECTED_APP_SUCCESS': {
      return {
        ...state,
        update_selected_app_start: false,
        update_selected_app_success: true
      };
    }

    case UPDATE_USER_PREFERENCES_START: {
      return {
        ...state,
        update_user_preferences_start: true,
        update_user_preferences_error: false,
        update_user_preferences_success: false
      };
    }

    case UPDATE_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        update_user_preferences_success: true,
        update_user_preferences_start: false,
        update_user_preferences_error: false
      };
    }

    case UPDATE_USER_PREFERENCES_ERROR: {
      return {
        ...state,
        update_user_preferences_error: true,
        update_user_preferences_start: false,
        update_user_preferences_success: false
      };
    }

    case USER_PREFERENCES_START: {
      return {
        ...state,
        user_preferences_start: true,
        user_preferences_error: false,
        user_preferences_success: false,
        update_notification_preferences: false
      };
    }

    case USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        user_preferences_success: true,
        userPreferencesData: action.payload,
        user_preferences_start: false,
        user_preferences_error: false
      };
    }

    case USER_PREFERENCES_ERROR: {
      return {
        ...state,
        user_preferences_error: true,
        user_preferences_start: false,
        user_preferences_success: false
      };
    }

    case USER_PREFERENCES_DATA: {
      return {
        ...state,
        userPreferencesData: action.payload,
        update_notification_preferences: true
      };
    }

    case ORGANIZATION_PREFERENCES_START: {
      return {
        ...state,
        organization_preferences_start: true,
        organization_preferences_error: false,
        organization_preferences_success: false
      };
    }

    case ORGANIZATION_PREFERENCES_SUCCESS: {
      return {
        ...state,
        organization_preferences_success: true,
        organization_preferences_start: false,
        organization_preferences_error: false
      };
    }

    case ORGANIZATION_PREFERENCES_ERROR: {
      return {
        ...state,
        organization_preferences_error: true,
        organization_preferences_start: false,
        organization_preferences_success: false
      };
    }

    case ORGANIZATION_PREFERENCES_DATA: {
      return {
        ...state,
        organizationPreferencesData: action.payload.organization_preference
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
        authUserData: action.payload,
        organizationData: JSON.parse(localStorage.getItem('organization_data'))
      };
    }

    case DEFAULT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        defaultOrganizationData: action.payload
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload
      };
    }

    case INIT_USER_DATA: {
      return {
        ...state,
        initUserData: action.payload
      };
    }

    case FETCH_INIT_START: {
      return {
        ...state,
        fetch_init_start: true,
        fetch_init_error: false,
        fetch_init_success: false
      };
    }

    case FETCH_INIT_ERROR: {
      return {
        ...state,
        fetch_init_start: false,
        fetch_init_error: true,
        fetch_init_success: false
      };
    }

    case FETCH_INIT_SUCCESS: {
      return {
        ...state,
        fetch_init_start: false,
        fetch_init_error: false,
        fetch_init_success: true,
        initUserData: action.payload
      };
    }

    case USER_PASSWORD_RESET_TOKEN: {
      return {
        ...state,
        password_reset_token: action.payload
      };
    }

    case FETCH_MEMBERS: {
      return {
        ...state
      };
    }

    case MEMBERS_DATA: {
      return {
        ...state,
        membersData: action.payload
      };
    }

    case APPS_DATA_START: {
      return {
        ...state,
        apps_data_start: true,
        apps_data_success: false,
        apps_data_error: false
      };
    }

    case APPS_DATA_SUCCESS: {
      return {
        ...state,
        appsData: action.payload,
        apps_data_success: true,
        apps_data_start: false,
        apps_data_error: false
      };
    }

    case APPS_DATA_ERROR: {
      return {
        ...state,
        apps_data_success: false,
        apps_data_start: false,
        apps_data_error: true
      };
    }

    case MODULES_DATA_START: {
      return {
        ...state,
        modules_data_start: true,
        modules_data_success: false,
        modules_data_error: false
      };
    }

    case MODULES_DATA_SUCCESS: {
      return {
        ...state,
        modulesData: action.payload,
        modules_data_success: true,
        modules_data_start: false,
        modules_data_error: false
      };
    }

    case MODULES_DATA_ERROR: {
      return {
        ...state,
        modules_data_success: false,
        modules_data_start: false,
        modules_data_error: true
      };
    }

    case SUBSCRIBED_MODULES_DATA_START: {
      return {
        ...state,
        subscribed_modules_data_start: true,
        subscribed_modules_data_success: false,
        subscribed_modules_data_error: false
      };
    }

    case SUBSCRIBED_MODULES_DATA_SUCCESS: {
      return {
        ...state,
        subscribedModulesData: action.payload,
        subscribed_modules_data_success: true,
        subscribed_modules_data_start: false,
        subscribed_modules_data_error: false
      };
    }

    case SUBSCRIBED_MODULES_DATA_ERROR: {
      return {
        ...state,
        subscribed_modules_data_success: false,
        subscribed_modules_data_start: false,
        subscribed_modules_data_error: true
      };
    }

    case SUBSCRIBE_MODULE_START: {
      return {
        ...state,
        subscribe_module_start: true,
        subscribe_module_success: false,
        subscribe_module_error: false
      };
    }

    case SUBSCRIBE_MODULE_ERROR: {
      return {
        ...state,
        subscribe_module_error: true,
        subscribe_module_start: false,
        subscribe_module_success: false
      };
    }

    case SUBSCRIBE_MODULE_SUCCESS: {
      return {
        ...state,
        subscribe_module_success: true,
        subscribe_module_start: false,
        subscribe_module_error: false
      };
    }

    case UNSUBSCRIBE_MODULE_START: {
      return {
        ...state,
        unsubscribe_module_start: true,
        unsubscribe_module_success: false,
        unsubscribe_module_error: false
      };
    }

    case UNSUBSCRIBE_MODULE_ERROR: {
      return {
        ...state,
        unsubscribe_module_start: false,
        unsubscribe_module_success: false,
        unsubscribe_module_error: true
      };
    }

    case UNSUBSCRIBE_MODULE_SUCCESS: {
      return {
        ...state,
        unsubscribe_module_start: false,
        unsubscribe_module_success: true,
        unsubscribe_module_error: false
      };
    }

    case SUBSCRIBE_APP_START: {
      return {
        ...state,
        subscribe_app_start: true,
        subscribe_app_success: false,
        subscribe_app_error: false
      };
    }

    case SUBSCRIBE_APP_ERROR: {
      return {
        ...state,
        subscribe_app_error: true,
        subscribe_app_start: false,
        subscribe_app_success: false
      };
    }

    case SUBSCRIBE_APP_SUCCESS: {
      return {
        ...state,
        subscribe_app_success: true,
        subscribe_app_start: false,
        subscribe_app_error: false
      };
    }

    case UNSUBSCRIBE_APP_START: {
      return {
        ...state,
        unsubscribe_app_start: true,
        unsubscribe_app_success: false,
        unsubscribe_app_error: false
      };
    }

    case UNSUBSCRIBE_APP_ERROR: {
      return {
        ...state,
        unsubscribe_app_start: false,
        unsubscribe_app_success: false,
        unsubscribe_app_error: true
      };
    }

    case UNSUBSCRIBE_APP_SUCCESS: {
      return {
        ...state,
        unsubscribe_app_start: false,
        unsubscribe_app_success: true,
        unsubscribe_app_error: false
      };
    }

    case WEBSITES_DATA_START: {
      return {
        ...state,
        websites_data_start: true,
        websites_data_success: false,
        websites_data_error: false
      };
    }

    case WEBSITES_DATA_SUCCESS: {
      return {
        ...state,
        websitesData: action.payload,
        websites_data_success: true,
        websites_data_start: false,
        websites_data_error: false
      };
    }

    case WEBSITES_DATA_ERROR: {
      return {
        ...state,
        websites_data_success: false,
        websites_data_start: false,
        websites_data_error: true
      };
    }

    case SAVE_FILTER_START: {
      return {
        ...state,
        save_filter_start: true,
        save_filter_success: false,
        save_filter_error: false
      };
    }

    case SAVE_FILTER_SUCCESS: {
      return {
        ...state,
        save_filter_success: true,
        save_filter_start: false,
        save_filter_error: false
      };
    }

    case SAVE_FILTER_ERROR: {
      return {
        ...state,
        save_filter_success: false,
        save_filter_start: false,
        save_filter_error: true
      };
    }

    case GET_SAVED_FILTERS_START: {
      return {
        ...state,
        saved_filters_start: true,
        saved_filters_success: false,
        saved_filters_error: false
      };
    }

    case GET_SAVED_FILTERS_SUCCESS: {
      return {
        ...state,
        savedFiltersData: action.payload,
        saved_filters_success: true,
        saved_filters_start: false,
        saved_filters_error: false
      };
    }

    case GET_SAVED_FILTERS_ERROR: {
      return {
        ...state,
        saved_filters_success: false,
        saved_filters_start: false,
        saved_filters_error: true
      };
    }

    case DELETE_SAVED_FILTER_START: {
      return {
        ...state,
        delete_saved_filter_start: true,
        delete_saved_filter_success: false,
        delete_saved_filter_error: false
      };
    }

    case DELETE_SAVED_FILTER_SUCCESS: {
      return {
        ...state,
        delete_saved_filter_success: true,
        delete_saved_filter_start: false,
        delete_saved_filter_error: false
      };
    }

    case DELETE_SAVED_FILTER_ERROR: {
      return {
        ...state,
        delete_saved_filter_success: false,
        delete_saved_filter_start: false,
        delete_saved_filter_error: true
      };
    }

    case SUBSCRIBE_WEBSITE_START: {
      return {
        ...state,
        subscribe_website_start: true,
        subscribe_website_success: false,
        subscribe_website_error: false
      };
    }

    case SUBSCRIBE_WEBSITE_ERROR: {
      return {
        ...state,
        subscribe_website_error: true,
        subscribe_website_start: false,
        subscribe_website_success: false
      };
    }

    case SUBSCRIBE_WEBSITE_SUCCESS: {
      return {
        ...state,
        subscribe_website_success: true,
        subscribe_website_start: false,
        subscribe_website_error: false
      };
    }

    case SUBSCRIBED_WEBSITES_START: {
      return {
        ...state,
        subscribed_websites_start: true,
        subscribed_websites_success: false,
        subscribed_websites_error: false
      };
    }

    case SUBSCRIBED_WEBSITES_ERROR: {
      return {
        ...state,
        subscribed_websites_error: true,
        subscribed_websites_start: false,
        subscribed_websites_success: false
      };
    }

    case SUBSCRIBED_WEBSITES_SUCCESS: {
      return {
        ...state,
        subscribedWebsitesData: action.payload.websites,
        subscribedCompetitorsData: action.payload.competitors,
        subscribed_websites_success: true,
        subscribed_websites_start: false,
        subscribed_websites_error: false
      };
    }

    case DELETE_ORGANIZATION_SUCCES: {
      return {
        ...state,
        delete_organization_succes: true
      };
    }

    case UNSUBSCRIBE_WEBSITE_START: {
      return {
        ...state,
        unsubscribe_website_start: true,
        unsubscribe_website_success: false,
        unsubscribe_website_error: false
      };
    }

    case UNSUBSCRIBE_WEBSITE_ERROR: {
      return {
        ...state,
        unsubscribe_website_error: true,
        unsubscribe_website_start: false,
        unsubscribe_website_success: false
      };
    }

    case UNSUBSCRIBE_WEBSITE_SUCCESS: {
      return {
        ...state,
        unsubscribe_website_success: true,
        unsubscribe_website_start: false,
        unsubscribe_website_error: false
      };
    }

    case SESSIONS_DATA: {
      return {
        ...state,
        sessionsData: action.payload
      };
    }

    case LOGS_DATA: {
      return {
        ...state,
        logsData: action.payload
      };
    }

    case LOGS_FILTERS_DATA: {
      return {
        ...state,
        logsFiltersData: action.payload
      };
    }

    case USAGE_AND_PAYMENT_DATA: {
      return {
        ...state,
        usageAndPaymentData: action.payload
      };
    }

    case PROJECTS_DATA: {
      return {
        ...state,
        projectsData: action.payload
      };
    }

    case DELETE_PROJECT_START: {
      return {
        ...state,
        delete_project_success: false,
        delete_project_error: false,
        delete_project_start: true
      };
    }

    case DELETE_PROJECT_SUCCESS: {
      return {
        ...state,
        delete_project_success: true,
        delete_project_error: false,
        delete_project_start: false
      };
    }

    case DELETE_PROJECT_ERROR: {
      return {
        ...state,
        delete_project_error: true,
        delete_project_success: false,
        delete_project_start: false
      };
    }

    case CHANGE_PASSWORD_START: {
      return {
        ...state,
        change_password_success: false,
        change_password_error: false,
        change_password_start: true
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        change_password_success: true,
        change_password_error: false,
        change_password_start: false
      };
    }

    case CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        change_password_error: true,
        change_password_success: false,
        change_password_start: false
      };
    }

    case INVITATIONS_DATA: {
      return {
        ...state,
        invitationsData: action.payload,

        update_membership_success: false,
        update_membership_error: false,
        delete_invitation_success: false,
        delete_invitation_error: false,
        resend_invitation_error: false,
        resend_invitation_success: false,
        delete_membership_success: false,
        delete_membership_success: false
      };
    }

    case USER_INFO_START: {
      return {
        ...state,
        user_info_success: false,
        user_info_error: false,
        user_info_start: true
      };
    }

    case USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfoData: action.payload,
        user_info_success: true,
        user_info_error: false,
        user_info_start: false
      };
    }

    case USER_INFO_ERROR: {
      return {
        ...state,
        user_info_error: true,
        user_info_success: false,
        user_info_start: false
      };
    }

    case GET_REGIONS_START: {
      return {
        ...state,
        get_region_success: false,
        get_region_error: false,
        get_region_start: true
      };
    }

    case GET_REGIONS_SUCCESS: {
      return {
        ...state,
        regionsData: action.payload,
        get_region_success: true,
        get_region_error: false,
        get_region_start: false
      };
    }

    case GET_REGIONS_ERROR: {
      return {
        ...state,
        get_region_error: true,
        get_region_success: false,
        get_region_start: false
      };
    }

    case SELECT_REGION_START: {
      return {
        ...state,
        select_region_success: false,
        select_region_error: false,
        select_region_start: true
      };
    }

    case SELECT_REGION_SUCCESS: {
      return {
        ...state,
        select_region_success: true,
        select_region_error: false,
        select_region_start: false
      };
    }

    case SELECT_REGION_ERROR: {
      return {
        ...state,
        select_region_error: true,
        select_region_success: false,
        select_region_start: false
      };
    }

    case SELECT_CURRENCY_START: {
      return {
        ...state,
        select_currency_start: true,
        select_currency_sucess: false,
        select_currency_error: false
      };
    }

    case SELECT_CURRENCY_SUCCESS: {
      return {
        ...state,
        select_currency_start: false,
        select_currency_sucess: true,
        select_currency_error: false
      };
    }

    case SELECT_CURRENCY_ERROR: {
      return {
        ...state,
        select_currency_start: false,
        select_currency_sucess: false,
        select_currency_error: true
      };
    }

    case ORGANIZATION_DATA: {
      return {
        ...state,
        organizationData: action.payload
      };
    }

    case UPDATE_SAVED_FILTER_START: {
      return {
        ...state,
        update_saved_filter_start: true,
        update_saved_filter_success: false,
        update_saved_filter_error: false
      };
    }

    case UPDATE_SAVED_FILTER_ERROR: {
      return {
        ...state,
        update_saved_filter_start: true,
        update_saved_filter_success: false,
        update_saved_filter_error: false
      };
    }

    case UPDATE_SAVED_FILTER_SUCCESS: {
      return {
        ...state,
        update_saved_filter_start: true,
        update_saved_filter_success: false,
        update_saved_filter_error: false
      };
    }

    //==================== Roles start===========

    case CREATE_ROLE_START: {
      return {
        ...state,
        create_role_start: true,
        create_role_success: false,
        create_role_error: false
      };
    }

    case CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        create_role_success: true,
        create_role_start: false,
        create_role_error: false
      };
    }

    case CREATE_ROLE_ERROR: {
      return {
        ...state,
        create_role_success: false,
        create_role_start: false,
        create_role_error: true
      };
    }

    case GET_ROLES_START: {
      return {
        ...state,
        get_roles_start: true,
        get_roles_success: false,
        get_roles_error: false
      };
    }

    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        rolesData: action.payload,
        get_roles_success: true,
        get_roles_start: false,
        get_roles_error: false
      };
    }

    case GET_ROLES_ERROR: {
      return {
        ...state,
        get_roles_success: false,
        get_roles_start: false,
        get_roles_error: true
      };
    }

    case DELETE_ROLE_START: {
      return {
        ...state,
        delete_role_start: true,
        delete_role_success: false,
        delete_role_error: false
      };
    }

    case DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        delete_role_success: true,
        delete_role_start: false,
        delete_role_error: false
      };
    }

    case DELETE_ROLE_ERROR: {
      return {
        ...state,
        delete_role_success: false,
        delete_role_start: false,
        delete_role_error: true
      };
    }

    case UPDATE_ROLE_START: {
      return {
        ...state,
        update_role_start: true,
        update_role_success: false,
        update_role_error: false
      };
    }

    case UPDATE_ROLE_ERROR: {
      return {
        ...state,
        update_role_start: true,
        update_role_success: false,
        update_role_error: false
      };
    }

    case UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        update_role_start: true,
        update_role_success: false,
        update_role_error: false
      };
    }

    //========Roles end here ===============

    //====== Role Task subscription starts here=========

    case CREATE_ROLE_TASK_SUBSCRIPTIONS_START: {
      return {
        ...state,
        create_role_task_subscription_start: true,
        create_role_task_subscription_success: false,
        create_role_task_subscription_error: false
      };
    }

    case CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        create_role_task_subscription_success: true,
        create_role_task_subscription_start: false,
        create_role_task_subscription_error: false
      };
    }

    case CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        create_role_task_subscription_success: false,
        create_role_task_subscription_start: false,
        create_role_task_subscription_error: true
      };
    }

    case GET_ALL_ROLE_TASKS_START: {
      return {
        ...state,
        get_all_role_tasks_start: true,
        get_all_role_tasks_success: false,
        get_all_role_tasks_error: false
      };
    }

    case GET_ALL_ROLE_TASKS_SUCCESS: {
      return {
        ...state,
        roleTasksData: action.payload,
        get_all_role_tasks_success: true,
        get_all_role_tasks_start: false,
        get_all_role_tasks_error: false
      };
    }

    case GET_ALL_ROLE_TASKS_ERROR: {
      return {
        ...state,
        get_all_role_tasks_success: false,
        get_all_role_tasks_start: false,
        get_all_role_tasks_error: true
      };
    }

    case DELETE_ROLE_TASK_SUBSCRIPTIONS_START: {
      return {
        ...state,
        delete_role_task_subscription_start: true,
        delete_role_task_subscription_success: false,
        delete_role_task_subscription_error: false
      };
    }

    case DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        delete_role_task_subscription_success: true,
        delete_role_task_subscription_start: false,
        delete_role_task_subscription_error: false
      };
    }

    case DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        delete_role_task_subscription_success: false,
        delete_role_task_subscription_start: false,
        delete_role_task_subscription_error: true
      };
    }

    //====Role tats subscription ends here=====

    case SIGNOUT_ALL_START: {
      return {
        ...state,
        signout_all_start: true,
        signout_all_success: false,
        signout_all_error: false
      };
    }

    case SIGNOUT_ALL_SUCCESS: {
      return {
        ...state,
        signout_all_start: false,
        signout_all_success: true,
        signout_all_error: false
      };
    }

    case SIGNOUT_ALL_ERROR: {
      return {
        ...state,
        signout_all_start: false,
        signout_all_success: false,
        signout_all_error: true
      };
    }

    case VALIDATE_PASSWORD_START: {
      return {
        ...state,
        validate_password_start: true,
        validate_password_success: false,
        validate_password_error: false
      };
    }

    case VALIDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        validate_password_start: false,
        validate_password_success: true,
        validate_password_error: false
      };
    }

    case VALIDATE_PASSWORD_ERROR: {
      return {
        ...state,
        validate_password_start: false,
        validate_password_success: false,
        validate_password_error: true
      };
    }

    case GET_ALL_API_KEY_START: {
      return {
        ...state,
        getAllApiLoader: false,
        createApiSuccess: false,
        updateApiSuccess: false,
        deleteApiSuccess: false
      };
    }

    case GET_ALL_API_KEY_SUCCESS: {
      return { ...state, getAllApiLoader: false, apis: action.payload };
    }

    case GET_ALL_API_KEY_ERROR: {
      return { ...state, getAllApiLoader: true };
    }

    case GET_API_KEY_START: {
      return { ...state, getApiLoader: false };
    }

    case GET_API_KEY_SUCCESS: {
      return { ...state, getApiLoader: false, api: action.payload };
    }

    case GET_API_KEY_ERROR: {
      return { ...state, getApiLoader: false };
    }

    case CREATE_API_KEY_START: {
      return { ...state, createApiLoader: false };
    }

    case CREATE_API_KEY_SUCCESS: {
      return {
        ...state,
        createApiLoader: false,
        createApiSuccess: true,
        api_key: action.payload
      };
    }

    case CREATE_API_KEY_ERROR: {
      return { ...state, createApiLoader: false, createApiSuccess: false };
    }

    case UPDATE_API_KEY_START: {
      return { ...state, updateApiKeyLoader: true };
    }

    case UPDATE_API_KEY_SUCCESS: {
      return { ...state, updateApiKeyLoader: false, updateApiSuccess: true };
    }

    case UPDATE_API_KEY_ERROR: {
      return { ...state, updateApiKeyLoader: false, updateApiSuccess: false };
    }

    case DELETE_API_KEY_START: {
      return { ...state, deleteApiKeyLoader: true };
    }

    case DELETE_API_KEY_SUCCESS: {
      return { ...state, deleteApiKeyLoader: false, deleteApiSuccess: true };
    }

    case DELETE_API_KEY_ERROR: {
      return { ...state, deleteApiKeyLoader: false, deleteApiSuccess: false };
    }

    case 'CLEAR_API_SUCCESS': {
      return { ...state, createApiSuccess: false };
    }

    case SWITCH_ORGANIZATION_START: {
      return {
        ...state,
        switchOrganizationStart: true,
        switchOrganizationError: null,
        switchOrganizationSuccess: false
      };
    }

    case SWITCH_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        switchOrganizationStart: false,
        switchOrganizationError: null,
        switchOrganizationSuccess: true
      };
    }

    case SWITCH_ORGANIZATION_ERROR: {
      return {
        ...state,
        switchOrganizationStart: false,
        switchOrganizationError: action.payload,
        switchOrganizationSuccess: false
      };
    }

    // dev-count-of-historical-data-points
    default:
      return state;
  }
};
