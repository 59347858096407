import {
  FETCH_SNAPSHOTS_START,
  FETCH_SNAPSHOTS_SUCCESS,
  FETCH_SNAPSHOTS_ERROR,
  FETCH_SNAPSHOTS_STATS_SUCCESS,
  FETCH_SNAPSHOTS_STATS_ERROR,
  FETCH_SNAPSHOTS_STATS_START,
  FETCH_SAVED_SNAPSHOTS_START,
  FETCH_SAVED_SNAPSHOTS_SUCCESS,
  FETCH_SAVED_SNAPSHOTS_ERROR,
  SAVE_SNAPSHOT_START,
  SAVE_SNAPSHOT_SUCCESS,
  SAVE_SNAPSHOT_ERROR,
  SNAPSHOTS_COLLECTIONS_INFO_START,
  SNAPSHOTS_COLLECTIONS_INFO_SUCCESS,
  SNAPSHOTS_COLLECTIONS_INFO_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SNAPSHOTS_START: {
      return {
        ...state,
        fetch_snapshots_success: false,
        fetch_snapshots_error: false,
        fetch_snapshots_start: true
      };
    }

    case FETCH_SNAPSHOTS_SUCCESS: {
      return {
        ...state,
        fetch_snapshots_success: true,
        fetch_snapshots_error: false,
        fetch_snapshots_start: false,
        snapshotsData: action.payload
      };
    }

    case FETCH_SNAPSHOTS_ERROR: {
      return {
        ...state,
        fetch_snapshots_success: false,
        fetch_snapshots_error: true,
        fetch_snapshots_start: false
      };
    }

    case FETCH_SNAPSHOTS_STATS_SUCCESS: {
      return {
        ...state,
        snapshotsStatsData: action.payload,
        fetch_snapshots_stats_error: false,
        fetch_snapshots_stats_success: true
      };
    }

    case FETCH_SNAPSHOTS_STATS_ERROR: {
      return {
        ...state,
        fetch_snapshots_stats_error: true,
        fetch_snapshots_stats_success: false
      };
    }

    case FETCH_SAVED_SNAPSHOTS_START: {
      return {
        ...state,
        fetch_saved_snapshots_success: false,
        fetch_saved_snapshots_error: false,
        fetch_saved_snapshots_start: true
      };
    }

    case FETCH_SAVED_SNAPSHOTS_SUCCESS: {
      return {
        ...state,
        savedSnapshotsData: action.payload.hits,
        fetch_saved_snapshots_success: true,
        fetch_saved_snapshots_error: false,
        fetch_saved_snapshots_start: false
      };
    }

    case FETCH_SAVED_SNAPSHOTS_ERROR: {
      return {
        ...state,
        fetch_saved_snapshots_success: false,
        fetch_saved_snapshots_error: true,
        fetch_saved_snapshots_start: false
      };
    }

    case SAVE_SNAPSHOT_ERROR: {
      return {
        ...state,
        save_snapshot_success: false,
        save_snapshot_error: true,
        save_snapshot_start: false
      };
    }

    case SAVE_SNAPSHOT_START: {
      return {
        ...state,
        save_snapshot_success: false,
        save_snapshot_error: false,
        save_snapshot_start: true
      };
    }

    case SAVE_SNAPSHOT_SUCCESS: {
      return {
        ...state,
        save_snapshot_success: true,
        save_snapshot_error: false,
        save_snapshot_start: false
      };
    }

    case SNAPSHOTS_COLLECTIONS_INFO_ERROR: {
      return {
        ...state,
        snapshots_colelction_info_success: false,
        snapshots_colelction_info_error: true,
        snapshots_colelction_info_start: false
      };
    }

    case SNAPSHOTS_COLLECTIONS_INFO_START: {
      return {
        ...state,
        snapshots_colelction_info_success: false,
        snapshots_colelction_info_error: false,
        snapshots_colelction_info_start: true
      };
    }

    case SNAPSHOTS_COLLECTIONS_INFO_SUCCESS: {
      return {
        ...state,
        savedSnapshotsCollectionInfo: action.payload.collection_names,
        snapshots_colelction_info_success: true,
        snapshots_colelction_info_error: false,
        snapshots_colelction_info_start: false
      };
    }

    default:
      return state;
  }
};
