import axios from 'axios';
import { envEncryption } from '../../secureEnv';

axios.defaults.headers.common['Authorization'] = JSON.parse(
  localStorage.getItem('token')
);

let URL = window.env.GET_REACT_APP_GREENDECK_ENDPOINT;
if (!URL) {
  URL = envEncryption.decryptParsed(window.env.REACT_APP_GREENDECK_ENDPOINT);
}

console.log({
  URL: URL,
  where: 'api'
});

export default axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
