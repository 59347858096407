import {
  FETCH_PRODUCT_MATCHING_START,
  FETCH_PRODUCT_MATCHING_SUCCESS,
  FETCH_PRODUCT_MATCHING_ERROR,
  FETCH_MATCH_PRODUCT_START,
  FETCH_MATCH_PRODUCT_SUCCESS,
  FETCH_MATCH_PRODUCT_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_MATCHING_START: {
      return {
        ...state,
        fetch_product_matching_success: false,
        fetch_product_matching_error: false,
        fetch_product_matching_start: true
      };
    }

    case FETCH_PRODUCT_MATCHING_SUCCESS: {
      return {
        ...state,
        fetch_product_matching_success: true,
        fetch_product_matching_error: false,
        fetch_product_matching_start: false,
        productMatchingData: action.payload
      };
    }

    case FETCH_PRODUCT_MATCHING_ERROR: {
      return {
        ...state,
        fetch_product_matching_success: false,
        fetch_product_matching_error: true,
        fetch_product_matching_start: false
      };
    }

    case FETCH_MATCH_PRODUCT_START: {
      return {
        ...state,
        fetch_match_product_success: false,
        fetch_match_product_error: false,
        fetch_match_product_start: true
      };
    }

    case FETCH_MATCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        fetch_match_product_success: true,
        fetch_match_product_error: false,
        fetch_match_product_start: false
      };
    }

    case FETCH_MATCH_PRODUCT_ERROR: {
      return {
        ...state,
        fetch_match_product_success: false,
        fetch_match_product_error: true,
        fetch_match_product_start: false
      };
    }

    default:
      return state;
  }
};
