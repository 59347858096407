import {
  FETCH_METRICS_START,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_ERROR,
  GET_METRIC_DATA_BY_ID_START,
  GET_METRIC_DATA_BY_ID_SUCCESS,
  GET_METRIC_DATA_BY_ID_ERROR,
  GET_CALENDAR_HEATMAP_DATA_START,
  GET_CALENDAR_HEATMAP_DATA_SUCCESS,
  GET_CALENDAR_HEATMAP_DATA_ERROR,
  GET_HEAT_MAP_DATA_START,
  GET_HEAT_MAP_DATA_SUCCESS,
  GET_HEAT_MAP_DATA_ERROR,
  GET_ALL_COMMENTS_START,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_COMMENTS_ERROR,
  POST_METRIC_COMMENT_START,
  POST_METRIC_COMMENT_SUCCESS,
  POST_METRIC_COMMENT_ERROR,
  GET_RELATED_METRCS_START,
  GET_RELATED_METRCS_SUCCESS,
  GET_RELATED_METRCS_ERROR,
  GET_MEASURES_LIST_START,
  GET_MEASURES_LIST_SUCCESS,
  GET_MEASURES_LIST_ERROR,
  GET_FAVOURITE_METRICS_START,
  GET_FAVOURITE_METRICS_SUCCESS,
  GET_FAVOURITE_METRICS_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: '',
  metricData: {},
  calendarHeatmapData: [],
  heatmapData: [],
  comments: [],
  related_metrics: [],
  measures_list: [],
  queryData: {},
  favourite_metrics: [],
  fetch_metrics_success: false,
  fetch_metrics_error: false,
  fetch_metrics_start: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_METRICS_START: {
      return {
        ...state,
        fetch_metrics_success: false,
        fetch_metrics_error: false,
        fetch_metrics_start: true
      };
    }

    case FETCH_METRICS_SUCCESS: {
      return {
        ...state,
        fetch_metrics_success: true,
        fetch_metrics_error: false,
        fetch_metrics_start: false,
        metricsData: action.payload
      };
    }

    case FETCH_METRICS_ERROR: {
      return {
        ...state,
        fetch_metrics_success: false,
        fetch_metrics_error: true,
        fetch_metrics_start: false
      };
    }

    case GET_METRIC_DATA_BY_ID_START: {
      return {
        ...state,
        get_metric_by_id_start: true,
        get_metric_by_id_error: false,
        get_metric_by_id_success: false
      };
    }

    case GET_METRIC_DATA_BY_ID_SUCCESS: {
      return {
        ...state,
        get_metric_by_id_success: true,
        get_metric_by_id_error: false,
        get_metric_by_id_start: false,
        metricData: action.payload
      };
    }

    case GET_METRIC_DATA_BY_ID_ERROR: {
      return {
        ...state,
        get_metric_by_id_success: false,
        get_metric_by_id_error: true,
        get_metric_by_id_start: false
      };
    }

    case GET_CALENDAR_HEATMAP_DATA_START: {
      return {
        ...state,
        get_calendar_heatmap_data_start: true,
        get_calendar_heatmap_data_error: false,
        get_calendar_heatmap_data_success: false
      };
    }

    case GET_CALENDAR_HEATMAP_DATA_SUCCESS: {
      return {
        ...state,
        get_calendar_heatmap_data_start: false,
        get_calendar_heatmap_data_error: false,
        get_calendar_heatmap_data_success: true,
        calendarHeatmapData: action.payload
      };
    }

    case GET_CALENDAR_HEATMAP_DATA_ERROR: {
      return {
        ...state,
        get_calendar_heatmap_data_start: false,
        get_calendar_heatmap_data_error: true,
        get_calendar_heatmap_data_success: false
      };
    }

    case GET_HEAT_MAP_DATA_START: {
      return {
        ...state,
        get_heatmap_data_start: true,
        get_heatmap_data_error: false,
        get_heatmap_data_success: false
      };
    }

    case GET_HEAT_MAP_DATA_SUCCESS: {
      return {
        ...state,
        get_heatmap_data_start: false,
        get_heatmap_data_error: false,
        get_heatmap_data_success: true,
        heatmapData: action.payload
      };
    }

    case GET_HEAT_MAP_DATA_ERROR: {
      return {
        ...state,
        get_heatmap_data_start: false,
        get_heatmap_data_error: true,
        get_heatmap_data_success: false
      };
    }

    case GET_ALL_COMMENTS_START: {
      return {
        ...state,
        get_all_comments_start: true,
        get_all_comments_error: false,
        get_all_comments_success: false,
        post_metric_comment_success: false
      };
    }

    case GET_ALL_COMMENTS_SUCCESS: {
      return {
        ...state,
        get_all_comments_start: false,
        get_all_comments_error: false,
        get_all_comments_success: true,
        comments: action.payload.reverse()
      };
    }

    case GET_ALL_COMMENTS_ERROR: {
      return {
        ...state,
        get_all_comments_start: false,
        get_all_comments_error: true,
        get_all_comments_success: false
      };
    }

    case POST_METRIC_COMMENT_START: {
      return {
        ...state,
        post_metric_comment_start: true,
        post_metric_comment_error: false,
        post_metric_comment_success: false
      };
    }

    case POST_METRIC_COMMENT_SUCCESS: {
      return {
        ...state,
        post_metric_comment_start: false,
        post_metric_comment_error: false,
        post_metric_comment_success: true
      };
    }

    case POST_METRIC_COMMENT_ERROR: {
      return {
        ...state,
        post_metric_comment_start: false,
        post_metric_comment_error: true,
        post_metric_comment_success: false
      };
    }

    case GET_RELATED_METRCS_START: {
      return {
        ...state,
        get_related_metrics_start: true,
        get_related_metrics_error: false,
        get_related_metrics_success: false
      };
    }

    case GET_RELATED_METRCS_SUCCESS: {
      return {
        ...state,
        get_related_metrics_start: false,
        get_related_metrics_error: false,
        get_related_metrics_success: true,
        related_metrics: action.payload
      };
    }

    case GET_RELATED_METRCS_ERROR: {
      return {
        ...state,
        get_related_metrics_start: false,
        get_related_metrics_error: true,
        get_related_metrics_success: false
      };
    }

    case GET_MEASURES_LIST_START: {
      return {
        ...state,
        get_measures_list_start: true,
        get_measures_list_error: false,
        get_measures_list_success: false
      };
    }

    case GET_MEASURES_LIST_SUCCESS: {
      return {
        ...state,
        get_measures_list_start: false,
        get_measures_list_error: false,
        get_measures_list_success: true,
        measures_list: action.payload
      };
    }

    case GET_MEASURES_LIST_ERROR: {
      return {
        ...state,
        get_measures_list_start: false,
        get_measures_list_error: true,
        get_measures_list_success: false
      };
    }

    case 'CLEAR_METRIC_STATE': {
      return {
        ...state,
        metricsData: []
      };
    }

    case 'SET_METRIC_SEARCH_QUERY_DATA': {
      return {
        ...state,
        queryData: action.payload.data,
        queryState: action.payload.bool,
        selectedQueries: action.payload.selected
      };
    }

    case 'SEARCH_QUERY_LOADER': {
      return {
        ...state,
        search_query_loader: action.payload
      };
    }

    case 'METRIC_LIST_LOCAL_UPDATE': {
      const { key, data } = action.payload;
      state[key] = data;
      return {
        ...state
      };
    }

    case 'FAVOURITES_LIST_LOCAL_UPDATE': {
      return {
        ...state,
        favourite_metrics: action.payload
      };
    }

    case 'SET_METRICS_FILTER_LIST': {
      return {
        ...state,
        filterList: action.payload
      };
    }

    case GET_FAVOURITE_METRICS_START: {
      return {
        ...state,
        get_favourite_metrics_start: true,
        get_favourite_metrics_error: false,
        get_favourite_metrics_success: false
      };
    }

    case GET_FAVOURITE_METRICS_SUCCESS: {
      return {
        ...state,
        get_favourite_metrics_start: false,
        get_favourite_metrics_error: false,
        get_favourite_metrics_success: true,
        favourite_metrics: action.payload || []
      };
    }

    case GET_FAVOURITE_METRICS_ERROR: {
      return {
        ...state,
        get_favourite_metrics_start: false,
        get_favourite_metrics_error: true,
        get_favourite_metrics_success: false
      };
    }

    default:
      return state;
  }
};
