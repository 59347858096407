const crypto = require('crypto');
const algorithm = 'aes-128-cbc';
const password = 'd6F3Efeq';

class EncryptData {
  constructor(algorithm, password) {
    this.algorithm = algorithm;
    this.password = password;
  }

  encryptData(text) {
    const cipher = crypto.createCipher(this.algorithm, this.password);
    const crypted = cipher.update(text, 'utf8', 'hex');
    crypted += cipher.final('hex');
    return crypted;
  }

  decryptData(encrytedData) {
    try {
      if (
        encrytedData === null ||
        encrytedData === undefined ||
        !window.env.ENV_ENCRYTPION
      ) {
        return encrytedData;
      }
      let dec;
      const decipher = crypto.createDecipher(this.algorithm, this.password);
      dec = decipher.update(encrytedData, 'hex', 'utf8');
      dec += decipher.final('utf8');
      return dec;
    } catch (error) {
      return encrytedData;
    }
  }

  decryptParsed(text) {
    try {
      if (!text) {
        return text;
      }

      return String(this.decryptData(text)).replace('\n', '');
    } catch (error) {
      return text;
    }
  }
}

export const defineWindowSetterGetter = () => {
  try {
    const encryptionObject = new EncryptData(algorithm, password);
    if (window.env) {
      Object.keys(window['env']).forEach(key => {
        if (!window.env[`GET_${key}`]) {
          Object.defineProperty(window['env'], `GET_${key}`, {
            get: function() {
              if (key.includes('REACT_APP')) {
                return `${String(
                  encryptionObject.decryptData(this[key])
                ).replace('\n', '')}`;
              } else {
                return this[key];
              }
            }
          });
        }
      });
    }
  } catch (error) {
    console.log({
      error
    });
  }
};

export const envEncryption = new EncryptData(algorithm, password);
