import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCTS_STATS_SUCCESS,
  FETCH_PRODUCTS_STATS_ERROR,
  PRODUCT_PRICING_HISTORY_START,
  PRODUCT_PRICING_HISTORY_SUCCESS,
  PRODUCT_PRICING_HISTORY_ERROR,
  GET_PRICE_CHANGES_START,
  GET_PRICE_CHANGES_SUCCESS,
  GET_PRICE_CHANGES_ERROR,
  GET_STORES_START,
  GET_STORES_SUCCESS,
  GET_STORES_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START: {
      return {
        ...state,
        fetch_products_success: false,
        fetch_products_error: false,
        fetch_products_start: true
      };
    }

    case FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        fetch_products_success: true,
        fetch_products_error: false,
        fetch_products_start: false,
        productsData: action.payload
      };
    }

    case FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        fetch_products_success: false,
        fetch_products_error: true,
        fetch_products_start: false
      };
    }

    case FETCH_PRODUCTS_STATS_SUCCESS: {
      return {
        ...state,
        productsStatsData: action.payload,
        fetch_products_stats_error: false,
        fetch_products_stats_success: true
      };
    }

    case FETCH_PRODUCTS_STATS_ERROR: {
      return {
        ...state,
        fetch_products_stats_error: true,
        fetch_products_stats_success: false
      };
    }

    case PRODUCT_PRICING_HISTORY_START: {
      return {
        ...state,
        product_pricing_history_success: false,
        product_pricing_history_error: false,
        product_pricing_history_start: true
      };
    }

    case PRODUCT_PRICING_HISTORY_SUCCESS: {
      return {
        ...state,
        product_pricing_history_success: true,
        product_pricing_history_error: false,
        product_pricing_history_start: false,
        productsPricingHistoryData: action.payload
      };
    }

    case PRODUCT_PRICING_HISTORY_ERROR: {
      return {
        ...state,
        product_pricing_history_success: false,
        product_pricing_history_error: true,
        product_pricing_history_start: false
      };
    }

    case GET_PRICE_CHANGES_START: {
      return {
        ...state,
        price_changes_success: false,
        price_changes_error: false,
        price_changes_start: true
      };
    }

    case GET_PRICE_CHANGES_SUCCESS: {
      return {
        ...state,
        price_changes_success: true,
        price_changes_error: false,
        price_changes_start: false,
        priceChangesData: action.payload
      };
    }

    case GET_PRICE_CHANGES_ERROR: {
      return {
        ...state,
        price_changes_success: false,
        price_changes_error: true,
        price_changes_start: false
      };
    }

    case GET_STORES_START: {
      return {
        ...state,
        get_stores_start: true,
        get_stores_success: false,
        get_stores_error: false
      };
    }

    case GET_STORES_SUCCESS: {
      return {
        ...state,
        storesData: action.payload,
        get_stores_start: false,
        get_stores_success: true,
        get_stores_error: false
      };
    }

    case GET_STORES_ERROR: {
      return {
        ...state,
        storesData: action.payload,
        get_stores_start: false,
        get_stores_success: false,
        get_stores_error: true
      };
    }

    default:
      return state;
  }
};
