import {
  GET_OUTLIER_METRICS_START,
  GET_OUTLIER_METRICS_SUCCESS,
  GET_OUTLIER_METRICS_ERROR,
  RESET_OUTLIER_DATA
} from '../../../../constants/CliffApiConstants';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OUTLIER_METRICS_START: {
      return {
        ...state,
        get_outlier_metrics_start: true,
        get_outlier_metrics_success: false,
        get_outlier_metrics_error: false
      };
    }
    case GET_OUTLIER_METRICS_SUCCESS: {
      return {
        ...state,
        get_outlier_metrics_start: false,
        get_outlier_metrics_success: true,
        get_outlier_metrics_error: false,
        outlierMetrics: action.payload
      };
    }
    case GET_OUTLIER_METRICS_ERROR: {
      return {
        ...state,
        get_outlier_metrics_start: false,
        get_outlier_metrics_success: false,
        get_outlier_metrics_error: true
      };
    }
    case RESET_OUTLIER_DATA: {
      return {
        ...state,
        outlierMetrics: null,
        get_outlier_metrics_start: false,
        get_outlier_metrics_success: false,
        get_outlier_metrics_error: false
      };
    }
    default:
      return state;
  }
};
