import {
  TOGGLE_SELF_STORE_START,
  TOGGLE_SELF_STORE_SUCCESS,
  TOGGLE_SELF_STORE_ERROR,
  GET_ALL_SUBSCRIPTIONS_START,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_ERROR
} from '../../../../../constants/ActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SELF_STORE_START: {
      return {
        ...state,
        toggle_self_store_start: true,
        toggle_self_store_success: false,
        toggle_self_store_error: false
      };
    }
    case TOGGLE_SELF_STORE_SUCCESS: {
      return {
        ...state,
        toggle_self_store_start: false,
        toggle_self_store_success: true,
        toggle_self_store_error: false
      };
    }
    case TOGGLE_SELF_STORE_ERROR: {
      return {
        ...state,
        toggle_self_store_start: false,
        toggle_self_store_success: false,
        toggle_self_store_error: true
      };
    }

    case GET_ALL_SUBSCRIPTIONS_START: {
      return {
        ...state,
        get_all_subscriptions_start: true,
        get_all_subscriptions_success: false,
        get_all_subscriptions_error: false
      };
    }
    case GET_ALL_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        allSubscriptions: action.payload.website_subscriptions,
        get_all_subscriptions_start: false,
        get_all_subscriptions_success: true,
        get_all_subscriptions_error: false
      };
    }
    case GET_ALL_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        get_all_subscriptions_start: false,
        get_all_subscriptions_success: false,
        get_all_subscriptions_error: true
      };
    }

    default:
      return state;
  }
};
