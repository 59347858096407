// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const USER_OS = 'USER_OS';
export const SWITCH_ORGANIZATION_START = 'SWITCH_ORGANIZATION_START';
export const SWITCH_ORGANIZATION_SUCCESS = 'SWITCH_ORGANIZATION_SUCCESS';
export const SWITCH_ORGANIZATION_ERROR = 'SWITCH_ORGANIZATION_ERROR';

// Get User Info
export const USER_INFO_START = 'USER_INFO_START';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';

//Signin User
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

//SignUp User
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_ERROR = 'SIGNIN_USER_ERROR';
export const SIGNIN_USER_FORBIDDEN = 'SIGNIN_USER_FORBIDDEN';

// Join User
export const JOIN_USER = 'JOIN_USER';
export const JOIN_USER_SUCCESS = 'JOIN_USER_SUCCESS';
export const JOIN_USER_ERROR = 'JOIN_USER_ERROR';

// Invite User
export const ACCEPT_INVITATION_START = 'ACCEPT_INVITATION_START';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR';

// Forgot Password
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const USER_PASSWORD_RESET_TOKEN = 'user_password_reset_token';

// Reset Password
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// Email Verification
export const EMAIL_VERIFICATON_START = 'EMAIL_VERIFICATON_START';
export const EMAIL_VERIFICATON_SUCCESS = 'EMAIL_VERIFICATON_SUCCESS';
export const EMAIL_VERIFICATON_ERROR = 'EMAIL_VERIFICATON_ERROR';

// Update UserProfile
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

// Update Organization
export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

// Update Organization
export const UPDATE_PROFILE_DETAILS_START = 'UPDATE_PROFILE_DETAILS_START';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_ERROR = 'UPDATE_PROFILE_DETAILS_ERROR';

// Signout User
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

// Add Member
export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_ERROR = 'ADD_MEMBER_ERROR';

// Create New Organization
export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const INVITATIONS_DATA = 'INVITATIONS_DATA';

export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_ERROR = 'RESEND_INVITATION_ERROR';
export const RESEND_INVITATION_START = 'RESEND_INVITATION_START';

export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR';
export const DELETE_INVITATION_START = 'DELETE_INVITATION_START';

export const DELETE_MEMBERSHIP_SUCCESS = 'DELETE_MEMBERSHIP_SUCCESS';
export const DELETE_MEMBERSHIP_ERROR = 'DELETE_MEMBERSHIP_ERROR';
export const DELETE_MEMBERSHIP_START = 'DELETE_MEMBERSHIP_START';

export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS';
export const UPDATE_MEMBERSHIP_ERROR = 'UPDATE_MEMBERSHIP_ERROR';
export const UPDATE_MEMBERSHIP_START = 'UPDATE_MEMBERSHIP_START';

export const FETCH_INIT_START = 'FETCH_INIT_START';
export const FETCH_INIT_ERROR = 'FETCH_INIT_ERROR';
export const FETCH_INIT_SUCCESS = 'FETCH_INIT_SUCCESS';
export const INIT_USER_DATA = 'INIT_USER_DATA';

// Get One ( Default ) Organization
export const DEFAULT_ORGANIZATION_SUCCESS = 'DEFAULT_ORGANIZATION_SUCCESS';
export const DEFAULT_ORGANIZATION_ERROR = 'DEFAULT_ORGANIZATION_ERROR';
export const DEFAULT_ORGANIZATION_START = 'DEFAULT_ORGANIZATION_START';

// Delete Organization
export const DELETE_ORGANIZATION_SUCCES = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
export const DELETE_ORGANIZATION_START = 'DELETE_ORGANIZATION_START';

// Delete Project
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';

// Change Password
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';

// ValidatePassword
export const VALIDATE_PASSWORD_SUCCESS = 'VALIDATE_PASSWORD_SUCCESS';
export const VALIDATE_PASSWORD_ERROR = 'VALIDATE_PASSWORD_ERROR';
export const VALIDATE_PASSWORD_START = 'VALIDATE_PASSWORD_START';

// Get Preferences
export const USER_PREFERENCES_SUCCESS = 'USER_PREFERENCES_SUCCESS';
export const USER_PREFERENCES_ERROR = 'USER_PREFERENCES_ERROR';
export const USER_PREFERENCES_START = 'USER_PREFERENCES_START';
export const USER_PREFERENCES_DATA = 'USER_PREFERENCES_DATA';
export const UPDATE_USER_PREFERENCES_ERROR = 'UPDATE_USER_PREFERENCES_ERROR';
export const UPDATE_USER_PREFERENCES_START = 'UPDATE_USER_PREFERENCES_START';
export const UPDATE_USER_PREFERENCES_SUCCESS =
  'UPDATE_USER_PREFERENCES_SUCCESS';

// Get  ORGANIZATION Preferences
export const ORGANIZATION_PREFERENCES_SUCCESS =
  'ORGANIZATION_PREFERENCES_SUCCESS';
export const ORGANIZATION_PREFERENCES_ERROR = 'ORGANIZATION_PREFERENCES_ERROR';
export const ORGANIZATION_PREFERENCES_START = 'ORGANIZATION_PREFERENCES_START';
export const ORGANIZATION_PREFERENCES_DATA = 'ORGANIZATION_PREFERENCES_DATA';

export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const UPDATE_USER_PROFILE = 'update_user_profile';

export const SESSIONS_DATA = 'SESSIONS_DATA';
export const LOGS_DATA = 'LOGS_DATA';
export const LOGS_FILTERS_DATA = 'LOGS_FILTERS_DATA';

export const USAGE_AND_PAYMENT_DATA = 'USAGE_AND_PAYMENT_DATA';

export const PROJECTS_DATA = 'PROJECTS_DATA';
export const FETCH_MEMBERS = 'fetch_members';
export const MEMBERS_DATA = 'members_data';
export const ORGANIZATION_DATA = 'organization_data';

export const APPS_DATA_START = 'APPS_DATA_START';
export const APPS_DATA_SUCCESS = 'APPS_DATA_SUCCESS';
export const APPS_DATA_ERROR = 'APPS_DATA_ERROR';

export const MODULES_DATA_START = 'MODULES_DATA_START';
export const MODULES_DATA_SUCCESS = 'MODULES_DATA_SUCCESS';
export const MODULES_DATA_ERROR = 'MODULES_DATA_ERROR';

export const SUBSCRIBED_MODULES_DATA_START = 'SUBSCRIBED_MODULES_DATA_START';
export const SUBSCRIBED_MODULES_DATA_SUCCESS =
  'SUBSCRIBED_MODULES_DATA_SUCCESS';
export const SUBSCRIBED_MODULES_DATA_ERROR = 'SUBSCRIBED_MODULES_DATA_ERROR';

export const SUBSCRIBE_MODULE_START = 'SUBSCRIBE_MODULE_START';
export const SUBSCRIBE_MODULE_SUCCESS = 'SUBSCRIBE_MODULE_SUCCESS';
export const SUBSCRIBE_MODULE_ERROR = 'SUBSCRIBE_MODULE_ERROR';

export const UNSUBSCRIBE_MODULE_START = 'UNSUBSCRIBE_MODULE_START';
export const UNSUBSCRIBE_MODULE_SUCCESS = 'UNSUBSCRIBE_MODULE_SUCCESS';
export const UNSUBSCRIBE_MODULE_ERROR = 'UNSUBSCRIBE_MODULE_ERROR';

export const SUBSCRIBE_APP_ERROR = 'SUBSCRIBE_APP_ERROR';
export const SUBSCRIBE_APP_START = 'SUBSCRIBE_APP_START';
export const SUBSCRIBE_APP_SUCCESS = 'SUBSCRIBE_APP_SUCCESS';

export const UNSUBSCRIBE_APP_ERROR = 'UNSUBSCRIBE_APP_ERROR';
export const UNSUBSCRIBE_APP_START = 'UNSUBSCRIBE_APP_START';
export const UNSUBSCRIBE_APP_SUCCESS = 'UNSUBSCRIBE_APP_SUCCESS';

export const WEBSITES_DATA_START = 'WEBSITES_DATA_START';
export const WEBSITES_DATA_SUCCESS = 'WEBSITES_DATA_SUCCESS';
export const WEBSITES_DATA_ERROR = 'WEBSITES_DATA_ERROR';

export const SUBSCRIBE_WEBSITE_START = 'SUBSCRIBE_WEBSITE_START';
export const SUBSCRIBE_WEBSITE_SUCCESS = 'SUBSCRIBE_WEBSITE_SUCCESS';
export const SUBSCRIBE_WEBSITE_ERROR = 'SUBSCRIBE_WEBSITE_ERROR';

export const SUBSCRIBED_WEBSITES_START = 'SUBSCRIBED_WEBSITES_START';
export const SUBSCRIBED_WEBSITES_SUCCESS = 'SUBSCRIBED_WEBSITES_SUCCESS';
export const SUBSCRIBED_WEBSITES_ERROR = 'SUBSCRIBED_WEBSITED_ERROR';

export const UNSUBSCRIBE_WEBSITE_START = 'UNSUBSCRIBE_WEBSITE_START';
export const UNSUBSCRIBE_WEBSITE_SUCCESS = 'UNSUBSCRIBE_WEBSITE_SUCCESS';
export const UNSUBSCRIBE_WEBSITE_ERROR = 'UNSUBSCRIBE_WEBSITE_ERROR';

export const SAVE_FILTER_START = 'SAVE_FILTER_START';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_ERROR = 'SAVE_FILTER_ERROR';

export const UPDATE_SAVED_FILTER_START = 'UPDATE_SAVED_FILTER_START';
export const UPDATE_SAVED_FILTER_SUCCESS = 'UPDATE_SAVED_FILTER_SUCCESS';
export const UPDATE_SAVED_FILTER_ERROR = 'UPDATE_SAVED_FILTER_ERROR';

export const GET_SAVED_FILTERS_START = 'GET_SAVED_FILTERS_START';
export const GET_SAVED_FILTERS_SUCCESS = 'GET_SAVED_FILTERS_SUCCESS';
export const GET_SAVED_FILTERS_ERROR = 'GET_SAVED_FILTERS_ERROR';

export const DELETE_SAVED_FILTER_START = 'DELETE_SAVED_FILTER_START';
export const DELETE_SAVED_FILTER_SUCCESS = 'DELETE_SAVED_FILTER_SUCCESS';
export const DELETE_SAVED_FILTER_ERROR = 'DELETE_SAVED_FILTER_ERROR';

export const GET_REGIONS_START = 'GET_REGIONS_START';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';

export const SELECT_REGION_START = 'SELECT_REGION_START';
export const SELECT_REGION_SUCCESS = 'SELECT_REGION_SUCCESS';
export const SELECT_REGION_ERROR = 'SELECT_REGION_ERROR';

export const SAVE_EXPORT_START = 'SAVE_EXPORT_START';
export const SAVE_EXPORT_SUCCESS = 'SAVE_EXPORT_SUCCESS';
export const SAVE_EXPORT_ERROR = 'SAVE_EXPORT_ERROR';

export const GET_EXPORT_DATA_START = 'GET_EXPORT_DATA_START';
export const GET_EXPORT_DATA_SUCCESS = 'GET_EXPORT_DATA_SUCCESS';
export const GET_EXPORT_DATA_ERROR = 'GET_EXPORT_DATA_ERROR';

export const DELETE_EXPORT_START = 'DELETE_EXPORT_START';
export const DELETE_EXPORT_SUCCESS = 'DELETE_EXPORT_SUCCESS';
export const DELETE_EXPORT_ERROR = 'DELETE_EXPORT_ERROR';

export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const GET_ALL_ROLE_TASKS_START = 'GET_ALL_ROLE_TASKS_START';
export const GET_ALL_ROLE_TASKS_SUCCESS = 'GET_ALL_ROLE_TASKS_SUCCESS';
export const GET_ALL_ROLE_TASKS_ERROR = 'GET_ALL_ROLE_TASKS_ERROR';

export const CREATE_ROLE_TASK_SUBSCRIPTIONS_START =
  'CREATE_ROLE_TASK_SUBSCRIPTIONS_START';
export const CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS =
  'CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS';
export const CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR =
  'CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR';

export const DELETE_ROLE_TASK_SUBSCRIPTIONS_START =
  'DELETE_ROLE_TASK_SUBSCRIPTIONS_START';
export const DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS =
  'DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS';
export const DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR =
  'DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR';

export const SIGNOUT_ALL_START = 'SIGNOUT_ALL_START';
export const SIGNOUT_ALL_SUCCESS = 'SIGNOUT_ALL_SUCCESS';
export const SIGNOUT_ALL_ERROR = 'SIGNOUT_ALL_ERROR';

export const DISABLE_USER_START = 'DISABLE_USER_START';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_ERROR = 'DISABLE_USER_ERROR';

export const TOGGLE_SELF_STORE_START = 'TOGGLE_SELF_STORE_START';
export const TOGGLE_SELF_STORE_SUCCESS = 'TOGGLE_SELF_STORE_SUCCESS';
export const TOGGLE_SELF_STORE_ERROR = 'TOGGLE_SELF_STORE_ERROR';

export const GET_ALL_SUBSCRIPTIONS_START = 'GET_ALL_SUBSCRIPTIONS_START';
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'GET_ALL_SUBSCRIPTIONS_SUCCESS';
export const GET_ALL_SUBSCRIPTIONS_ERROR = 'GET_ALL_SUBSCRIPTIONS_ERROR';

export const GET_ALL_RRP_TASKS_START = 'GET_ALL_RRP_TASKS_START';
export const GET_ALL_RRP_TASKS_SUCCESS = 'GET_ALL_RRP_TASKS_SUCCESS';
export const GET_ALL_RRP_TASKS_ERROR = 'GET_ALL_RRP_TASKS_ERROR';

export const COMPLETE_RRP_START = 'COMPLETE_RRP_START';
export const COMPLETE_RRP_SUCCESS = 'COMPLETE_RRP_SUCCESS';
export const COMPLETE_RRP_ERROR = 'COMPLETE_RRP_ERROR';

export const UPLOAD_RRP_TASK_START = 'UPLOAD_RRP_TASK_START';
export const UPLOAD_RRP_TASK_SUCCESS = 'UPLOAD_RRP_TASK_SUCCESS';
export const UPLOAD_RRP_TASK_ERROR = 'UPLOAD_RRP_TASK_ERROR';

export const GET_RRP_EXCEL_START = 'GET_RRP_EXCEL_START';
export const GET_RRP_EXCEL_SUCCESS = 'GET_RRP_EXCEL_SUCCESS';
export const GET_RRP_EXCEL_ERROR = 'GET_RRP_EXCEL_ERROR';

export const GET_RRP_DASHBOARD_DATA_START = 'GET_RRP_DASHBOARD_DATA_START';
export const GET_RRP_DASHBOARD_DATA_SUCCESS = 'GET_RRP_DASHBOARD_DATA_SUCCESS';
export const GET_RRP_DASHBOARD_DATA_ERROR = 'GET_RRP_DASHBOARD_DATA_ERROR';

export const GET_RRP_UPLOADERS_START = 'GET_RRP_UPLOADERS_START';
export const GET_RRP_UPLOADERS_SUCCESS = 'GET_RRP_UPLOADERS_SUCCESS';
export const GET_RRP_UPLOADERS_ERROR = 'GET_RRP_UPLOADERS_ERROR';

export const DELETE_OUTPUT_EXCEL_START = 'DELETE_OUTPUT_EXCEL_START';
export const DELETE_OUTPUT_EXCEL_SUCCESS = 'DELETE_OUTPUT_EXCEL_SUCCESS';
export const DELETE_OUTPUT_EXCEL_ERROR = 'DELETE_OUTPUT_EXCEL_ERROR';

export const PING_SRP_JOB_SUCCESS = 'PING_SRP_JOB_SUCCESS';
export const PING_SRP_JOB_ERROR = 'PING_SRP_JOB_ERROR';

export const GET_ALL_JOBS_START = 'GET_ALL_JOBS_START';
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_ERROR = 'GET_ALL_JOBS_ERROR';

//Api key actions
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_START = 'CREATE_API_KEY_START';
export const CREATE_API_KEY_ERROR = 'CREATE_API_KEY_ERROR';
export const UPDATE_API_KEY_START = 'UPDATE_API_KEY_START';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_ERROR = 'UPDATE_API_KEY_ERROR';
export const DELETE_API_KEY_START = 'DELETE_API_KEY_START';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'DELETE_API_KEY_ERROR';
export const GET_ALL_API_KEY_START = 'GET_ALL_API_KEY_START';
export const GET_ALL_API_KEY_SUCCESS = 'GET_ALL_API_KEY_SUCCESS';
export const GET_ALL_API_KEY_ERROR = 'GET_ALL_API_KEY_ERROR';
export const GET_API_KEY_START = 'GET_API_KEY_START';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_ERROR = 'GET_API_KEY_ERROR';

//channels actions
export const GET_CHANELLS_START = 'GET_CHANELLS_START';
export const GET_CHANELLS_SUCCESS = 'GET_CHANELLS_SUCCESS';
export const GET_CHANELLS_ERROR = 'GET_CHANELLS_ERROR';
export const LEAVE_CHANNEL_START = 'LEAVE_CHANNEL_START';
export const LEAVE_CHANNEL_SUCCESS = 'LEAVE_CHANNEL_SUCCESS';
export const LEAVE_CHANNEL_ERROR = 'LEAVE_CHANNEL_ERROR';
export const GET_CHANNEL_INFO_START = 'GET_CHANNEL_INFO_START';
export const GET_CHANNEL_INFO_SUCCESS = 'GET_CHANNEL_INFO_SUCCESS';
export const GET_CHANNEL_INFO_ERROR = 'GET_CHANNEL_INFO_ERROR';
export const GET_CHANNEL_USERS_START = 'GET_CHANNEL_USERS_START';
export const GET_CHANNEL_USERS_SUCCESS = 'GET_CHANNEL_USERS_SUCCESS';
export const GET_CHANNEL_USERS_ERROR = 'GET_CHANNEL_USERS_ERROR';
export const GET_ORGANIZATION_STREAMS_START = 'GET_ORGANIZATION_STREAMS_START';
export const GET_ORGANIZATION_STREAMS_SUCCESS =
  'GET_ORGANIZATION_STREAMS_SUCCESS';
export const GET_ORGANIZATION_STREAMS_ERROR = 'GET_ORGANIZATION_STREAMS_ERROR';
export const JOIN_CHANNEL_START = 'JOIN_CHANNEL_START';
export const JOIN_CHANNEL_SUCCESS = 'JOIN_CHANNEL_SUCCESS';
export const JOIN_CHANNEL_ERROR = 'JOIN_CHANNEL_ERROR';
export const GET_CHANNEL_STREAMS_START = 'GET_CHANNEL_STREAMS_START';
export const GET_CHANNEL_STREAMS_SUCCESS = 'GET_CHANNEL_STREAMS_SUCCESS';
export const GET_CHANNEL_STREAMS_ERROR = 'GET_CHANNEL_STREAMS_ERROR';
export const REMOVE_CHANNEL_START = 'REMOVE_CHANNEL_START';
export const REMOVE_CHANNEL_SUCCESS = 'REMOVE_CHANNEL_SUCCESS';
export const REMOVE_CHANNEL_ERROR = 'REMOVE_CHANNEL_ERROR';
export const ADD_STREAM_START = 'ADD_STREAM_START';
export const ADD_STREAM_SUCCESS = 'ADD_STREAM_SUCCESS';
export const ADD_STREAM_ERROR = 'ADD_STREAM_ERROR';
export const DELETE_STREAM_START = 'DELETE_STREAM_START';
export const DELETE_STREAM_SUCCESS = 'DELETE_STREAM_SUCCESS';
export const DELETE_STREAM_ERROR = 'DELETE_STREAM_ERROR';

export const RRP_DASHBOARD_EXPORT_START = 'RRP_DASHBOARD_EXPORT_START';
export const RRP_DASHBOARD_EXPORT_SUCCESS = 'RRP_DASHBOARD_EXPORT_SUCCESS';
export const RRP_DASHBOARD_EXPORT_ERROR = 'RRP_DASHBOARD_EXPORT_ERROR';

// Currency Selection
export const SELECT_CURRENCY_START = 'SELECT_CURRENCY_START';
export const SELECT_CURRENCY_SUCCESS = 'SELECT_CURRENCY_SUCCESS';
export const SELECT_CURRENCY_ERROR = 'SELECT_CURRENCY_ERROR';
