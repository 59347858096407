import {
  GET_RRP_DASHBOARD_DATA_START,
  GET_RRP_DASHBOARD_DATA_SUCCESS,
  GET_RRP_DASHBOARD_DATA_ERROR,
  GET_RRP_UPLOADERS_START,
  GET_RRP_UPLOADERS_SUCCESS,
  GET_RRP_UPLOADERS_ERROR,
  RRP_DASHBOARD_EXPORT_START,
  RRP_DASHBOARD_EXPORT_SUCCESS,
  RRP_DASHBOARD_EXPORT_ERROR
} from '../../../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  dashboardData: {},
  users: []
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case GET_RRP_DASHBOARD_DATA_START: {
      return { ...state, loader: true, rrp_dashboard_export_success: false };
    }
    case GET_RRP_DASHBOARD_DATA_SUCCESS: {
      let obj = {};
      obj.day = payload.day;
      obj.week = payload.week;
      obj.month = payload.month;
      obj.year = payload.year;
      return { ...state, loader: false, dashboardData: obj };
    }
    case GET_RRP_DASHBOARD_DATA_ERROR: {
      return { ...state, loader: false };
    }

    case GET_RRP_UPLOADERS_START: {
      return { ...state };
    }

    case GET_RRP_UPLOADERS_SUCCESS: {
      return { ...state, users: payload };
    }

    case GET_RRP_UPLOADERS_ERROR: {
      return { ...state };
    }

    case RRP_DASHBOARD_EXPORT_START: {
      return {
        ...state,
        rrp_dashboard_export_start: true,
        rrp_dashboard_export_success: false,
        rrp_dashboard_export_error: false
      };
    }

    case RRP_DASHBOARD_EXPORT_SUCCESS: {
      return {
        ...state,
        rrp_dashboard_export_start: false,
        rrp_dashboard_export_success: true,
        rrp_dashboard_export_error: false,
        download_url: payload
      };
    }

    case RRP_DASHBOARD_EXPORT_START: {
      return {
        ...state,
        rrp_dashboard_export_start: false,
        rrp_dashboard_export_success: false,
        rrp_dashboard_export_error: true
      };
    }

    default:
      return state;
  }
};
