import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Icon,
  Input,
  Button,
  Typography,
  Alert,
  Result
} from '@greendeck/atomic';
import { userResetPassword } from '../appRedux/actions/Auth';
import AuthLayout from '../components/Layouts/AuthLayout';

const { Text, Title, Paragraph } = Typography;
var reset_password_param = '';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ResetPassword extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userResetPassword(reset_password_param, values);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;
    reset_password_param = this.props.location.search;

    return (
      <AuthLayout>
        <div align="center">
          <Title level={2} className="ant-font-heavy">
            Welcome back!
          </Title>
          <Paragraph> Reset password below</Paragraph>
        </div>

        {this.props.reset_password_error ? (
          <Alert
            className="ant-mt-5"
            message="Your password reset token has been expired or is invalid. Please initiate forgot password process again."
            type="error"
            banner
          />
        ) : null}

        {this.props.reset_password_success ? null : (
          <div align="left">
            <Form
              onSubmit={this.handleSubmit}
              layout="vertical"
              className="ant-mt-5"
            >
              <Form.Item
                label={
                  <small className="ant-text-muted">ENTER NEW PASSWORD</small>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                hasFeedback
              >
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please input new Password!' }
                  ]
                })(
                  <Input.Password
                    size="large"
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Enter Password Here"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="ant-w-50"
                  size="large"
                  htmlType="submit"
                  loading={this.props.reset_password_start ? true : false}
                >
                  {' '}
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {this.props.reset_password_success ? (
          <Result
            status="success"
            title="Reset Password Successful!"
            subTitle="Your password has been reset successfully "
            extra={[
              <a href="/">
                <Button type="primary" className="ant-w-50" size="large">
                  Go Back
                </Button>{' '}
              </a>,
              ,
            ]}
          />
        ) : null}
      </AuthLayout>
    );
  }
}

const WrappedHorizontalLoginForm = Form.create()(ResetPassword);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    password_reset_token,
    reset_password_error,
    reset_password_start,
    reset_password_success
  } = auth;
  return {
    token,
    password_reset_token,
    reset_password_error,
    reset_password_start,
    reset_password_success
  };
};

export default connect(mapStateToProps, { userResetPassword })(
  WrappedHorizontalLoginForm
);
