import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Icon,
  Row,
  Col,
  Typography,
  Divider,
  Alert,
  Card,
  Avatar,
  Layout
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
const { Text, Title, Paragraph } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const width = window.screen.width;

const appName = window.env.GET_REACT_APP_NAME;

const SignInLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout style={{ maxHeight: '100vh', overflow: 'scroll' }}>
        <Row className="ant-px-5 ant-py-5">
          <Col
            align="left"
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 18, offset: 3 }}
            xxl={{ span: 12, offset: 6 }}
          >
            {window.env.GET_REACT_APP_NAME === 'cliff' ? (
              <img
                src={require('assets/images/logos/cliff_logo_light.svg')}
                height="30"
              ></img>
            ) : (
              <img
                src="https://www.greendeck.co/images/logo/logo_full.png"
                height="30"
              ></img>
            )}
          </Col>
        </Row>
        <Content className="ant-p-5" align="center">
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 18, offset: 3 }}
              xxl={{ span: 12, offset: 6 }}
            >
              {children}
              {window.env.GET_REACT_APP_ENV == 'production' ? null : (
                <Alert
                  closable
                  className="ant-mt-5"
                  message="System Information"
                  type="warning"
                  description={`${window.env.GET_REACT_APP_NAME} ${window.env.GET_REACT_APP_VERSION} running on ${window.env.GET_REACT_APP_ENV} environment`}
                >
                  {' '}
                </Alert>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
      {width > 450 && (
        <Sider width="60%" className="ant-bg-secondary">
          <Row
            type="flex"
            className="ant-h-100"
            direction="column"
            justify="between"
          >
            {/*
          <Col className="ant-text-white ant-px-5 ant-pt-3">
            {window.env.GET_REACT_APP_NAME === 'cliff' ? (
              <img
                src="https://i.ibb.co/Fxthrhh/Logo-Dark.png"
                height="30"
              ></img>
            ) : (
              <img src={require('assets/images/logo.png')} height="30"></img>
            )}
          </Col>

          <div className="ant-p-5 animated fadeInUp" align="left">
            <Avatar
              size={64}
              className="ant-my-3"
              src="https://logo.clearbit.com/figma.com"
            />
            <Card
              title={
                <div className="">
                  <Text strong>Judith</Text>
                  <br />
                  <Text small>VP Marketing, Casper</Text>
                </div>
              }
              className="ant-w-75"
            >
              <Paragraph className="">
                Greendeck is so amazing that I can't even put it in words. They
                have helped us so much in our day to day work that life feels,
                well, easy!
              </Paragraph>
            </Card>
          </div>
          */}

            <Col className="ant-text-muted ant-p-5 " align="left">
              <Title level={2} className="ant-text-white ant-font-bold">
                {appName === 'cliff'
                  ? 'Control center for modern companies'
                  : 'The ‘next level’ is closer than you think.'}
              </Title>
              <Paragraph className="ant-text-white">
                {appName === 'cliff'
                  ? 'Cliff.ai tracks unexpected changes in your metrics instantly, notifying you when something is not right, allowing you to be in control'
                  : "You're just one step away from the intelligence that will help you make better pricing and promotions decisions"}
              </Paragraph>
            </Col>
            <Col align="center">
              {window.env.GET_REACT_APP_NAME === 'cliff' ? (
                <img
                  src={
                    window.env.PUBLIC_URL +
                    '/product_illustrations/auth/signup.png'
                  }
                  style={{ maxWidth: '90%' }}
                ></img>
              ) : (
                <img
                  src={
                    window.env.PUBLIC_URL +
                    '/product_illustrations/auth/greendeck_signup.png'
                  }
                  style={{ maxWidth: '90%' }}
                ></img>
              )}
            </Col>
          </Row>
        </Sider>
      )}
    </Layout>
  );
};

export default SignInLayout;

SignInLayout.propTypes = {
  children: PropTypes.node.isRequired
};
