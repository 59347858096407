import {
  FETCH_TRAVEL_SALES_START,
  FETCH_TRAVEL_SALES_SUCCESS,
  FETCH_TRAVEL_SALES_ERROR,
  FETCH_PROMOTIONAL_TREND_START,
  FETCH_PROMOTIONAL_TREND_SUCCESS,
  FETCH_PROMOTIONAL_TREND_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRAVEL_SALES_START: {
      return {
        ...state,
        fetch_travel_sales_success: false,
        fetch_travel_sales_error: false,
        fetch_travel_sales_start: true
      };
    }

    case FETCH_TRAVEL_SALES_SUCCESS: {
      return {
        ...state,
        fetch_travel_sales_success: true,
        fetch_travel_sales_error: false,
        fetch_travel_sales_start: false,
        travelSalesData: action.payload
      };
    }

    case FETCH_TRAVEL_SALES_ERROR: {
      return {
        ...state,
        fetch_travel_sales_success: false,
        fetch_travel_sales_error: true,
        fetch_travel_sales_start: false
      };
    }

    case FETCH_PROMOTIONAL_TREND_START: {
      return {
        ...state,
        fetch_promotional_trend_success: false,
        fetch_promotional_trend_error: false,
        fetch_promotional_trend_start: true
      };
    }

    case FETCH_PROMOTIONAL_TREND_SUCCESS: {
      return {
        ...state,
        fetch_promotional_trend_success: true,
        fetch_promotional_trend_error: false,
        fetch_promotional_trend_start: false,
        promotionalTrendData: action.payload
      };
    }

    case FETCH_PROMOTIONAL_TREND_ERROR: {
      return {
        ...state,
        fetch_promotional_trend_success: false,
        fetch_promotional_trend_error: true,
        fetch_promotional_trend_start: false
      };
    }

    default:
      return state;
  }
};
