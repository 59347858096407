import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button,
  Layout,
  Steps
} from '@greendeck/atomic';

import Confetti from 'react-confetti';

import Demo from './Demo';
import Sources from './Sources';
import Streams from './Streams';
import Alerts from './Alerts';
import Metrics from './Metrics';

const { Meta } = Card;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Step } = Steps;

const WIZARD_STEPS = [
  {
    key: 'welcome',
    title: 'Intro',
    subSteps: 0
  },
  {
    key: 'sources',
    title: 'Sources',
    subSteps: 2
  },
  {
    key: 'streams',
    title: 'Streams',
    subSteps: 2
  },
  {
    key: 'metrics',
    title: 'Metrics',
    subSteps: 2
  },
  {
    key: 'alerts',
    title: 'Alerts',
    subSteps: 1
  },
  {
    key: 'demo',
    title: 'Jump right in!',
    subSteps: 1
  }
];

const Wizard = props => {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [signOutLoading, setSignOutLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  const downHandler = ({ key }) => {
    if (key === 'ArrowLeft') {
      incrementSubStepBy(-1);
    } else if (key === 'ArrowRight' && currentStep < WIZARD_STEPS.length - 1) {
      incrementSubStepBy(1);
    } else if (key === 'ArrowRight' && currentStep >= WIZARD_STEPS.length - 1) {
      // if current step goes beyond the number of steps, redirect user to the
      // main app
      props.turnOffOnboarding('/apps/anomaly-detection/getting-started');
    }
  };

  const currentStepKey = WIZARD_STEPS[currentStep].key;
  console.log({ CUR: currentStepKey });
  const handleStepChange = current => {
    // make sure the user can't jump more than 1 step forward
    const newStep = current - currentStep > 1 ? currentStep + 1 : current;
    props.changeMainStage(WIZARD_STEPS[newStep].key);
    setCurrentStep(newStep);
    setCurrentSubStep(0);
  };

  const incrementWizardStepBy = numSteps => {
    setCurrentStep(currentStep + numSteps);
  };

  const incrementSubStepBy = numSteps => {
    const newSubStep = currentSubStep + numSteps;
    const numSubSteps = WIZARD_STEPS[currentStep].subSteps;
    console.log({ substep: newSubStep });
    console.log({ wizardstep: currentStep });

    if (newSubStep >= numSubSteps) {
      setCurrentSubStep(0);
      incrementWizardStepBy(1);
    } else if (newSubStep < 0) {
      if (currentStep === 1) {
        props.changeMainStage('welcome');
      }
      incrementWizardStepBy(-1);
      setCurrentSubStep(0);
    } else {
      setCurrentSubStep(currentSubStep + numSteps);
    }
  };

  const renderView = view => {
    const childProps = {
      turnOffOnboardingLoading: props.turnOffOnboardingLoading,
      incrementWizardStepBy: incrementWizardStepBy,
      incrementSubStepBy: incrementSubStepBy,
      stepChange: handleStepChange,
      currentSubStep: currentSubStep,
      turnOffOnboarding: props.turnOffOnboarding
    };

    switch (view) {
      case 'demo':
        return <Demo {...childProps} />;
      case 'sources':
        return <Sources {...childProps} />;
      case 'streams':
        return <Streams {...childProps} />;
      case 'metrics':
        return <Metrics {...childProps} />;
      case 'alerts':
        return <Alerts {...childProps} />;
      default:
        return <Demo {...childProps} />;
    }
  };

  const buttonText = () => {
    if (currentStep === 1 && currentSubStep === 1) {
      return 'Connect a sample data source';
    }
    if (currentStep === 2 && currentSubStep === 1) {
      return 'Create a sample data stream';
    }
    return 'Next';
  };

  const handleSignOut = () => {
    props.signOut();
    setSignOutLoading(true);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {currentStepKey == 'demo' ? (
        <Confetti
          numberOfPieces={200}
          recycle={false}
          colors={[
            '#ea90b1',
            '#fea58e',
            '#f5c78e',
            '#a2d28f',
            '#51d3d9',
            '#81c1fd',
            '#bda9ea'
          ]}
        />
      ) : (
        <></>
      )}
      <Content className="ant-container ant-pt-5">
        <Row>
          <Col>
            <img
              style={{ width: '7.5rem' }}
              src="https://greendeck-cdn.s3.amazonaws.com/cliff_icons/cliff_light.svg"
            />
          </Col>
          <Col align="right">
            <Button
              loading={signOutLoading}
              disabled={signOutLoading}
              type="link"
              onClick={handleSignOut}
            >
              {signOutLoading ? 'Signing out...' : 'Sign out'}
            </Button>
          </Col>
        </Row>
        <Row className="ant-mt-5 ant-pt-5">
          <Col span={24}>{renderView(currentStepKey)}</Col>
        </Row>
      </Content>

      <Footer
        className="ant-bg-white"
        style={{ position: 'fixed', width: '100%', zIndex: 1, bottom: 0 }}
      >
        <Row style={{ width: '100%' }}>
          <Col>
            <Steps
              direction="horizontal"
              current={currentStep}
              onChange={handleStepChange}
              status="finish"
              size="small"
            >
              {WIZARD_STEPS.map(step => (
                <Step title={step.title} key={step.key}>
                  {step.title}
                </Step>
              ))}
            </Steps>
          </Col>
          <Col align="right">
            {currentStep === WIZARD_STEPS.length - 1 ? (
              <Button
                loading={props.turnOffOnboardingLoading}
                type="primary"
                onClick={() =>
                  props.turnOffOnboarding(
                    '/apps/anomaly-detection/getting-started'
                  )
                }
              >
                See Cliff in action
              </Button>
            ) : (
              <>
                <Button
                  className="ant-mr-5"
                  onClick={() => incrementSubStepBy(-1)}
                  type="link"
                >
                  Previous
                </Button>
                <Button
                  onClick={() => incrementSubStepBy(1)}
                  type="primary"
                  style={{ minWidth: '120px' }}
                >
                  <Row type="flex" justify="center" align="center">
                    {buttonText()}
                    {buttonText() === 'Next' && (
                      <Icon className="ant-ml-2" type="right" />
                    )}
                  </Row>
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default Wizard;
