import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Dropdown,
  Row,
  Col,
  Menu,
  Button,
  Icon,
  List,
  Divider,
  Typography,
  Popover
} from '@greendeck/atomic';
import {
  userSignOut,
  updateDefaultOrganization,
  initUser
} from 'appRedux/actions/Auth';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import './custom.css';

import languageData from '../LanguageModule/LanguageData';
import { switchLanguage } from '../../appRedux/actions/Setting';

const { Text } = Typography;

function handleButtonClick(e) {
  console.log('click left button', e);
}

function handleMenuClick(e) {
  console.log('click', e);
}

class Settings extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.switchOrganizationSuccess !==
        this.props.switchOrganizationSuccess &&
      this.props.switchOrganizationSuccess
    ) {
      this.props.initUser();
    }
  }

  render() {
    const { authUser } = this.props;
    const { collapse } = this.props;
    const { locale } = this.props;
    var organizationsList = [];

    if (this.props.initUserData) {
      var organizationsList = this.props.initUserData.organizations;
    }

    const userMenuOptions = (
      <Menu>
        <Menu.Item>
          <Row type="flex" justify="start" align="center">
            <div>
              {!this.props.initUserData ? null : this.props.initUserData
                  .default_organization.avatar === 'initials' ? (
                <Avatar shape="square" size={40} className="ant-bg-primary">
                  {this.props.initUserData.default_organization.organization_name
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
              ) : (
                <Avatar
                  shape="square"
                  size={40}
                  src={this.props.initUserData.default_organization.avatar}
                />
              )}
            </div>
            <div className="ant-px-3">
              <div>
                <Text strong>
                  {this.props.initUserData
                    ? this.props.initUserData.default_organization
                        .organization_name
                    : 'Loading'}
                </Text>{' '}
                <br />
                <small>
                  {this.props.initUserData
                    ? this.props.initUserData.user.first_name
                    : 'Loading'}
                </small>
              </div>
            </div>
          </Row>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/settings/organization'}>
            <IntlMessages id="sidebar.popup.organizationSettings" />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/settings/organization/members'}>
            <IntlMessages id="settings.organization.members" />
          </Link>
        </Menu.Item>
        {this.props.initUserData &&
        this.props.initUserData.user.email &&
        this.props.initUserData.user.email.includes('greendeck.co') ? (
          <Menu.Item>
            <Link to={'/settings/organization/apps'}>
              <IntlMessages id="settings.organization.appStore" />
            </Link>
          </Menu.Item>
        ) : null}
        <Menu.Item>
          <Link to={'/settings/organization/usage-and-payments'}>
            <IntlMessages id="settings.popup.usageAndBilling" />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/settings/organization/support'}>
            <IntlMessages id="settings.organization.support" />
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          key="sub3"
          title={<IntlMessages id="sidebar.popup.switchOrganization" />}
        >
          <div style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            {this.props.initUserData
              ? organizationsList.map(organization => (
                  <Row
                    key={organization.id}
                    className="scroll-hover-color ant-p-2"
                    type="flex"
                    onClick={() =>
                      this.props.updateDefaultOrganization(organization.id)
                    }
                  >
                    <Col span={24}>
                      <Link to="/">
                        <Row type="flex" justify="start" align="center">
                          <div>
                            {!this.props
                              .initUserData ? null : organization.avatar ===
                              'initials' ? (
                              <Avatar
                                shape="square"
                                size={40}
                                className="ant-bg-primary"
                              >
                                {organization.organization_name
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            ) : (
                              <Avatar
                                shape="square"
                                size={40}
                                src={organization.avatar}
                              />
                            )}
                          </div>
                          <div className="ant-px-3">
                            <div>
                              <Text strong>
                                {this.props.initUserData
                                  ? organization.organization_name
                                  : 'Loading'}
                              </Text>{' '}
                              <br />
                              <small>
                                {this.props.initUserData
                                  ? this.props.initUserData.user.first_name
                                  : 'Loading'}
                              </small>
                            </div>
                          </div>
                        </Row>
                      </Link>
                    </Col>
                  </Row>
                ))
              : null}
          </div>
          <Menu.Item key="8">
            <Link to={'/settings/organization/new'}>
              <Icon type="plus-circle" />{' '}
              <IntlMessages id="sidebar.popup.createOrganization" />
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item>
          <Divider className="ant-my-1"></Divider>
        </Menu.Item>
        <Menu.Item>
          <Row type="flex" justify="start" align="center">
            <div>
              {!this.props.initUserData ? null : this.props.initUserData.user
                  .avatar === 'initials' ? (
                <Avatar size={40} className="ant-bg-primary">
                  {this.props.initUserData.user.first_name
                    .charAt(0)
                    .toUpperCase()}
                  {this.props.initUserData
                    ? this.props.initUserData.user.last_name
                        .charAt(0)
                        .toUpperCase()
                    : null}
                </Avatar>
              ) : (
                <Avatar size={40} src={this.props.initUserData.user.avatar} />
              )}
            </div>
            <div className="ant-px-3">
              <div>
                <Text strong>
                  {this.props.initUserData
                    ? this.props.initUserData.user.first_name
                    : '...'}{' '}
                  {this.props.initUserData
                    ? this.props.initUserData.user.last_name
                    : '...'}
                </Text>{' '}
                <br />
                <Text small className="ant-text-muted ant-mt-0">
                  <small>
                    {this.props.initUserData
                      ? this.props.initUserData.user.email
                      : '...'}
                  </small>
                </Text>
              </div>
            </div>
          </Row>
        </Menu.Item>
        <Menu.Item>
          <Link to={'/settings/account'}>
            <IntlMessages id="sidebar.popup.userSettings" />
          </Link>
        </Menu.Item>
        {this.props.isEnabled && (
          <Menu.Item>
            <Link to={'/settings/account/api'}>
              <IntlMessages id="sidebar.popup.api" />
            </Link>
          </Menu.Item>
        )}
        {window.env.GET_REACT_APP_NAME === 'cliff' &&
        window.env.GET_NODE_ENV === 'production' ? null : (
          <Menu.SubMenu
            key="sub4"
            title={<IntlMessages id="sidebar.popup.switchLanguage" />}
          >
            {languageData.map(language => (
              <Menu.Item
                key={JSON.stringify(language)}
                onClick={e => this.props.switchLanguage(language)}
              >
                <Row type="flex">
                  <div>
                    <i className={`flag flag-24 flag-${language.icon}`} />
                  </div>
                  <div>
                    <span className="ant-ml-3">{language.name}</span>
                  </div>
                </Row>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        )}
        <Menu.Item>
          <a onClick={() => this.props.userSignOut()}>
            <IntlMessages id="sidebar.popup.signOut" />
          </a>
        </Menu.Item>
      </Menu>
    );

    console.info({
      P: this.props
    });

    return (
      <Dropdown
        overlay={userMenuOptions}
        placement="bottomCenter"
        trigger={['click']}
        style={{ wiidth: '100px' }}
      >
        {collapse ? (
          <a>
            <Row
              type="flex"
              justify="start"
              align="stretch"
              className="ant-px-3 ant-pt-3"
            >
              <Col>
                {!this.props.initUserData ? null : this.props.initUserData
                    .default_organization.avatar === 'initials' ? (
                  <Avatar shape="square" size={40} className="ant-bg-primary">
                    {this.props.initUserData.default_organization.organization_name
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                ) : (
                  <Avatar
                    shape="square"
                    size={40}
                    className="ant-bg-primary"
                    src={this.props.initUserData.default_organization.avatar}
                  />
                )}
              </Col>
            </Row>
          </a>
        ) : (
          <a>
            <Row
              type="flex"
              justify="start"
              align="stretch"
              className="ant-px-3 ant-pt-3"
            >
              <Col span={6}>
                {!this.props.initUserData ? null : this.props.initUserData
                    .default_organization.avatar === 'initials' ? (
                  <Avatar shape="square" size={40} className="ant-bg-primary">
                    {this.props.initUserData.default_organization.organization_name
                      .charAt(0)
                      .toUpperCase()}
                  </Avatar>
                ) : (
                  <Avatar
                    shape="square"
                    size={40}
                    src={this.props.initUserData.default_organization.avatar}
                  />
                )}
              </Col>
              <Col span={18} className="ant-px-3">
                <Row type="flex">
                  <Col span={22}>
                    <Text
                      className="ant-text-white"
                      style={{ width: '100%' }}
                      ellipsis
                      strong
                    >
                      {this.props.initUserData
                        ? this.props.initUserData.default_organization
                            .organization_name
                        : 'Loading'}
                    </Text>
                  </Col>
                  <Col span={2}>
                    <Text className="ant-font-bold ant-text-white ant-ml-1">
                      <Icon type="caret-down" />
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className="ant-text-white" small>
                      {this.props.initUserData
                        ? this.props.initUserData.user.first_name
                        : 'Loading'}{' '}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </a>
        )}
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const {
    authUser,
    initUserData,
    organizationData,
    switchOrganizationStart,
    switchOrganizationSuccess,
    switchOrganizationError
  } = auth;
  const { locale } = settings;
  return {
    authUser,
    locale,
    initUserData,
    organizationData,
    switchOrganizationSuccess,
    switchOrganizationError
  };
};

export default connect(mapStateToProps, {
  userSignOut,
  switchLanguage,
  updateDefaultOrganization,
  initUser
})(Settings);
