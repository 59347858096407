import {
  FETCH_INVENTORY_ITEMS_START,
  FETCH_INVENTORY_ITEMS_SUCCESS,
  FETCH_INVENTORY_ITEMS_ERROR,
  FETCH_INVENTORY_BRAND_SUMMARY_START,
  FETCH_INVENTORY_BRAND_SUMMARY_SUCCESS,
  FETCH_INVENTORY_BRAND_SUMMARY_ERROR,
  FETCH_INVENTORY_CLASSIFICATION_SUMMARY_START,
  FETCH_INVENTORY_CLASSIFICATION_SUMMARY_SUCCESS,
  FETCH_INVENTORY_CLASSIFICATION_SUMMARY_ERROR
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_ITEMS_START: {
      return {
        ...state,
        fetch_inventory_items_success: false,
        fetch_inventory_items_error: false,
        fetch_inventory_items_start: true
      };
    }

    case FETCH_INVENTORY_ITEMS_SUCCESS: {
      return {
        ...state,
        fetch_inventory_items_success: true,
        fetch_inventory_items_error: false,
        fetch_inventory_items_start: false,
        inventoryItemsData: action.payload
      };
    }

    case FETCH_INVENTORY_ITEMS_ERROR: {
      return {
        ...state,
        fetch_inventory_items_success: false,
        fetch_inventory_items_error: true,
        fetch_inventory_items_start: false
      };
    }

    case FETCH_INVENTORY_BRAND_SUMMARY_START: {
      return {
        ...state,
        fetch_inventory_brand_summary_success: false,
        fetch_inventory_brand_summary_error: false,
        fetch_inventory_brand_summary_start: true
      };
    }

    case FETCH_INVENTORY_BRAND_SUMMARY_SUCCESS: {
      return {
        ...state,
        fetch_inventory_brand_summary_success: true,
        fetch_inventory_brand_summary_error: false,
        fetch_inventory_brand_summary_start: false,
        inventoryBrandSummaryData: action.payload
      };
    }

    case FETCH_INVENTORY_BRAND_SUMMARY_ERROR: {
      return {
        ...state,
        fetch_inventory_brand_summary_success: false,
        fetch_inventory_brand_summary_error: true,
        fetch_inventory_brand_summary_start: false
      };
    }

    //================

    case FETCH_INVENTORY_CLASSIFICATION_SUMMARY_START: {
      return {
        ...state,
        fetch_inventory_classification_summary_success: false,
        fetch_inventory_classification_summary_error: false,
        fetch_inventory_classification_summary_start: true
      };
    }

    case FETCH_INVENTORY_CLASSIFICATION_SUMMARY_SUCCESS: {
      return {
        ...state,
        fetch_inventory_classification_summary_success: true,
        fetch_inventory_classification_summary_error: false,
        fetch_inventory_classification_summary_start: false,
        inventoryClassificationSummaryData: action.payload
      };
    }

    case FETCH_INVENTORY_CLASSIFICATION_SUMMARY_ERROR: {
      return {
        ...state,
        fetch_inventory_classification_summary_success: false,
        fetch_inventory_classification_summary_error: true,
        fetch_inventory_classification_summary_start: false
      };
    }

    default:
      return state;
  }
};
