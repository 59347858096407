import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button,
  Badge
} from '@greendeck/atomic';

const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;

const steps = [
  {
    title: 'Data Streams',
    description:
      'Streams are the data pipelines where you can define the metrics that you want to monitor using Cliff.ai',
    stepText: 'Step 3 of 6',
    renderBody: () => (
      <>
        <div align="center">
          <img
            height="600"
            src={
              window.env.PUBLIC_URL +
              '/product_illustrations/steps/streams_illustration.svg'
            }
          ></img>
        </div>
      </>
    )
  },
  {
    title: 'Creating a sample data stream',
    description:
      'In our sample data source, we have 4 KPIs - Revenue, Number of Purchases, Payment API Failures and Checkout API Failures. These KPIs can be drilled down further on 4 dimensions - Product Family, Country, Device Type and OS',
    stepText: 'Step 3 of 6',
    renderBody: () => (
      <>
        <div align="center">
          <img
            style={{ maxWidth: '100%' }}
            src={
              window.env.PUBLIC_URL +
              '/product_illustrations/steps/streams_sample.png'
            }
          ></img>
        </div>
      </>
    )
  }
];

const NUM_STEPS = steps.length;

const Streams = props => {
  const currentStep = props.currentSubStep;

  return (
    <div className="">
      <small className="ant-text-muted">{steps[currentStep].stepText}</small>
      <Title level={2} strong>
        {steps[currentStep].title}
      </Title>
      <Paragraph className="ant-mb-3">
        {steps[currentStep].description}
      </Paragraph>
      {steps[currentStep].renderBody()}
    </div>
  );
};

export default Streams;
