import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';

import ProductsReducer from '../../routes/apps/retail-intelligence/products/ProductsReducer';
import SalesReducer from '../../routes/apps/retail-intelligence/sales/SalesReducer';
import AssortmentReducer from '../../routes/apps/retail-intelligence/assortment/AssortmentReducer';
import ArchitectureReducer from '../../routes/apps/retail-intelligence/architecture/ArchitectureReducer';
import PriceDistributionReducer from '../../routes/apps/retail-intelligence/pricedistribution/PriceDistributionReducer';
import NewInsReducer from '../../routes/apps/retail-intelligence/newins/NewInsReducer';
import BrandComparisonReducer from '../../routes/apps/retail-intelligence/brandcomparison/BrandComparisonReducer';
import ExportExcelReducer from '../../routes/apps/product-matching/exportexcel/ExportExcelReducer';
import NewslettersReducer from '../../routes/apps/marketing/newsletters/NewslettersReducer';
import SnapshotsReducer from '../../routes/apps/marketing/snapshots/SnapshotsReducer';
import PromotionsReducer from '../../routes/apps/marketing/promotions/PromotionsReducer';
import BenchmarkingReducer from '../../routes/apps/retail-intelligence/benchmarking/BenchmarkingReducer';
import AppReducer from '../../routes/apps/AppReducer';
import ExportDataReducer from '../../components/Export/ExportDataReducer';
import TravelSalesReducer from '../../routes/apps/travel-intelligence/travelsales/TravelSalesReducer';
import TravelProductsReducer from '../../routes/apps/travel-intelligence/travelproducts/TravelProductsReducer';
import TaxonomyReducer from '../../routes/apps/product-validation/taxonomy/TaxonomyReducer';
import MatchingReducer from '../../routes/apps/product-validation/matching/MatchingReducer';
import ClassificationReducer from '../../routes/apps/product-validation/classification/ClassificationReducer';
import StoresReducer from '../../routes/apps/product-validation/stores/StoresReducer';
import FilterReducer from '../../components/FilterNew/FilterReducer';
import PopoverReducer from '../../components/FilterNew/PopoverReducer';
import ProductSimilarityReducer from '../../components/Drawer/ProductSimilarityReducer';
import InventoryItemsReducer from '../../routes/apps/inventory-intelligence/inventory/InventoryItemsReducer';
import SavedProductsReducer from '../../routes/apps/retail-intelligence/products/views/Saved/SavedProductsReducer'; //////////////////
import MetricsReducer from '../../routes/apps/anomaly-detection/metrics/MetricsReducer';
import SubscriptionReducer from './routes/settings/organization/SubscriptionReducer';
import ChannelsReducer from './routes/settings/organization/ChannelsReducer';
import IntegrationsReducer from '../../routes/apps/anomaly-detection/integrations/IntegrationsReducer';
import DataStreamReducer from '../../routes/apps/anomaly-detection/data-streams/DataStreamsReducer';
import WorkflowReducer from '../../routes/apps/rrp-validation/workflow/WorkflowReducer';
import RrpDashboardReducer from '../../routes/apps/rrp-validation/dashboard/DashboardReducer';
import TemplatesReducer from '../../routes/apps/anomaly-detection/templates/TemplatesReducer';
import MetricHeaderReducer from '../../components/Header/MetricHeaderReducer';
import AlertsReducer from '../../routes/apps/anomaly-detection/alerts/AlertsReducer';
import CliffDashboardReducer from '../../routes/apps/anomaly-detection/dashboard/dashboardReducer';
import LeafletsReducer from '../../routes/apps/marketing/leaflets/LeafletsReducer';
import TrendDashboardReducer from '../../routes/apps/trends/dashboard/DashboardReducer';
import TimeSeriesUploadReducer from '../../routes/apps/upload-timeseries/uploads/UploadsReducer';
import DailyRunsReducer from '../../routes/apps/internal-monitoring/daily-runs/DailyRunsReducer';
import OutlierAnalysisReducer from '../../routes/apps/anomaly-detection/outlier/OutlierReducer';
import OmnisearchReducer from '../../routes/apps/anomaly-detection/components/Omnisearch/OmniSearchReducer';
import FooterReducer from '../../containers/AppFooter/AppFooterReducer';
import IncidentsReducer from '../../routes/apps/anomaly-detection/incidents/IncidentsReducer';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  products: ProductsReducer,
  savedProducts: SavedProductsReducer,
  subscriptions: SubscriptionReducer,
  channels: ChannelsReducer,
  sales: SalesReducer,
  assortment: AssortmentReducer,
  architecture: ArchitectureReducer,
  pricedistribution: PriceDistributionReducer,
  newsletters: NewslettersReducer,
  snapshots: SnapshotsReducer,
  promotions: PromotionsReducer,
  benchmarking: BenchmarkingReducer,
  appUtils: AppReducer,
  exportData: ExportDataReducer,
  exportExcel: ExportExcelReducer,
  newIns: NewInsReducer,
  brandcomparison: BrandComparisonReducer,
  travelsales: TravelSalesReducer,
  travelproducts: TravelProductsReducer,
  taxonomy: TaxonomyReducer,
  matching: MatchingReducer,
  classification: ClassificationReducer,
  stores: StoresReducer,
  filter: FilterReducer,
  productSimilarity: ProductSimilarityReducer,
  inventoryitems: InventoryItemsReducer,
  metrics: MetricsReducer,
  integrations: IntegrationsReducer,
  dataStreams: DataStreamReducer,
  workflow: WorkflowReducer,
  rrpDashboard: RrpDashboardReducer,
  templates: TemplatesReducer,
  metricsHeader: MetricHeaderReducer,
  alerts: AlertsReducer,
  cliffDashboard: CliffDashboardReducer,
  leaflets: LeafletsReducer,
  timeSeriesUploads: TimeSeriesUploadReducer,
  trendsDashboard: TrendDashboardReducer,
  dailyRuns: DailyRunsReducer,
  outlier: OutlierAnalysisReducer,
  omniSearch: OmnisearchReducer,
  appFooter: FooterReducer,
  incidents: IncidentsReducer
});

export default reducers;
