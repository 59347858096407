import axios from 'axios';
import axiosApi from '../config/api';

let call = {};
export const makeRequest = (config = {}, requestType = 'undefined') => {
  if (call[requestType]) {
    call[requestType].cancel('cancel');
  }
  call[requestType] = axios.CancelToken.source();
  config.cancelToken = call[requestType].token;
  return axiosApi(config);
};
