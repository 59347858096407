import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import CodeSplitter from '../components/CodeSplitter/CodeSplitter';
import codeSplitterLoadFallBack from '../components/CodeSplitter/codeSplitterLoadFallBack';
const Discounts = lazy(() =>
  codeSplitterLoadFallBack(() => import('./discounts/Discounts'))
);
const PremiumBrands = lazy(() =>
  codeSplitterLoadFallBack(() => import('./premiumbrands/PremiumBrands'))
);

const UserSettings = lazy(() =>
  codeSplitterLoadFallBack(() => import('./settings/account/UserSettings'))
);
const OrganizationSettings = lazy(() =>
  codeSplitterLoadFallBack(() =>
    import('./settings/organization/OrganizationSettings')
  )
);

const AppHome = lazy(() =>
  codeSplitterLoadFallBack(() => import('./apps/AppHome'))
);

const Dashboard = lazy(() =>
  codeSplitterLoadFallBack(() => import('./dashboard/Dashboard'))
);
const OnBoarding = lazy(() =>
  codeSplitterLoadFallBack(() => import('./onboarding/OnBoarding'))
);

const Error404 = lazy(() =>
  codeSplitterLoadFallBack(() => import('./error404/Error404'))
);
const Error500 = lazy(() =>
  codeSplitterLoadFallBack(() => import('./error500/Error500'))
);

const App = ({ match }) => (
  <CodeSplitter
    codeSplittingComponent={
      <Switch>
        {/*============= APPLICATION ROUTES ============= */}
        <Route path={`${match.url}dashboard`} component={Dashboard} />

        {/*<Route path={`${match.url}products`} component={Products}/>*/}

        <Route path={`${match.url}discounts`} component={Discounts} />

        <Route path={`${match.url}premiumbrands`} component={PremiumBrands} />

        {/*============= PRODUCTS ROUTES ============= */}

        <Route path={`${match.url}apps`} component={AppHome} />

        {/*============= AUTHENTICATION ROUTES ============= */}

        <Route path={`${match.url}settings/account`} component={UserSettings} />
        <Route
          path={`${match.url}settings/organization`}
          component={OrganizationSettings}
        />
        <Route path={`${match.url}onboarding`} component={OnBoarding} />

        {/*============= ERROR ROUTES ============= */}
        <Route path={`${match.url}error500`} component={Error500} />

        <Route component={Error404} />
      </Switch>
    }
    fallBackComponent={null}
  />
);

export default App;
