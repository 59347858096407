import {
  GET_TEMPLATES_START,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_ERROR,
  GET_TEMPLATE_CATEGORIES_START,
  GET_TEMPLATE_CATEGORIES_SUCCESS,
  GET_TEMPLATE_CATEGORIES_ERROR,
  GET_TEMPLATE_SOURCES_START,
  GET_TEMPLATE_SOURCES_SUCCESS,
  GET_TEMPLATE_SOURCES_ERROR
} from '../../../../constants/CliffApiConstants';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATES_START: {
      return {
        ...state,
        get_templates_start: true,
        get_templates_success: false,
        get_templates_error: false
      };
    }

    case GET_TEMPLATES_ERROR: {
      return {
        ...state,
        get_templates_start: false,
        get_templates_success: false,
        get_templates_error: true
      };
    }

    case GET_TEMPLATES_SUCCESS: {
      const data = [
        {
          type: 'Recommended by us',
          data: action.payload.templates.filter(temp => temp.is_internal)
        },
        {
          type: 'Others',
          data: action.payload.templates.filter(temp => !temp.is_internal)
        }
      ];
      const navs = action.payload.templates
        .reduce((acc, temp) => {
          temp.category.forEach(category => {
            if (!acc.includes(category)) acc.push(category);
          });
          return acc;
        }, [])
        .sort();
      return {
        ...state,
        get_templates_start: false,
        get_templates_success: true,
        get_templates_error: false,
        templates: data,
        navs: navs
      };
    }

    case GET_TEMPLATE_CATEGORIES_START: {
      return {
        ...state,
        get_template_categories_start: true,
        get_template_categories_success: false,
        get_template_categories_error: false
      };
    }

    case GET_TEMPLATE_CATEGORIES_ERROR: {
      return {
        ...state,
        get_template_categories_start: false,
        get_template_categories_success: false,
        get_template_categories_error: true
      };
    }

    case GET_TEMPLATE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        get_template_categories_start: false,
        get_template_categories_success: true,
        get_template_categories_error: false,
        categories: action.payload.categories
      };
    }

    case GET_TEMPLATE_SOURCES_START: {
      return {
        ...state,
        get_template_sources_start: true,
        get_template_sources_success: false,
        get_template_sources_error: false
      };
    }

    case GET_TEMPLATE_SOURCES_ERROR: {
      return {
        ...state,
        get_template_sources_start: false,
        get_template_sources_success: false,
        get_template_sources_error: true
      };
    }

    case GET_TEMPLATE_SOURCES_SUCCESS: {
      return {
        ...state,
        get_template_sources_start: false,
        get_template_sources_success: true,
        get_template_sources_error: false,
        sources: action.payload.sources
      };
    }

    default:
      return state;
  }
};
