import React, { Component } from 'react';
import {
  Layout,
  List,
  Avatar,
  Icon,
  Row,
  Col,
  AutoComplete,
  Input,
  Popover,
  Button,
  Menu,
  Affix,
  Badge,
  Tabs,
  Typography,
  Tooltip
} from '@greendeck/atomic';
import RegionSwitcher from './RegionSwitcher';
import CurrencySwitcher from './CurrencySwitcher';
const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Text, Title } = Typography;

function onSelect(value) {
  console.log('onSelect', value);
}

class AppHeader extends Component {
  state = {
    value: '',
    dataSource: []
  };

  onSearch = searchText => {
    this.setState({
      dataSource: !searchText
        ? []
        : [searchText, searchText.repeat(2), searchText.repeat(3)]
    });
  };

  onChange = value => {
    this.setState({ value });
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({
      current: e.key
    });
  };

  render() {
    let config = {
      selector: '.changelogfy-widget',
      account: 'JnXo6x'
    };
    window.Headway && window.Headway.init(config);
    const { dataSource, value } = this.state;
    const notifications_data = [
      {
        title: 'Aayush Jain has joined',
        subtitle: 'A new team member has joined ',
        initials: 'AJ'
      },
      {
        title: 'Market data report is ready',
        subtitle: 'a new port',
        initials: 'MD'
      }
    ];
    const menu = (
      <Tabs defaultActiveKey="1" className="ant-p-0">
        <TabPane tab="Unread" key="1" className="ant-p-3">
          <Text strong level={6}>
            No notifications
          </Text>
        </TabPane>
        <TabPane tab="All" key="2" className="ant-p-3">
          <Text strong level={6}>
            No notifications
          </Text>
        </TabPane>
      </Tabs>
    );
    if (
      window.env.GET_REACT_APP_NAME === 'cliff' ||
      window.location.href.includes('anomaly-detection')
    ) {
      return <div></div>;
    }
    return (
      <Header className="ant-bg-white ant-px-3 ant-m-0 ant-border-bottom">
        <Row type="flex" justify="end" align="center">
          {/*
            <div>
              <AutoComplete
                dataSource={dataSource}
                style={{ width: 200 }}
                onSelect={onSelect}
                onSearch={this.onSearch}
                placeholder="Search"
              >
                <Input
                  suffix={<Icon className="ant-text-primary" type="search" />}
                />
              </AutoComplete>
            </div>
          */}
          {/*
          <div>
            <Popover
              placement="leftBottom"
              content={
                <Affix>
                  <Menu>{menu}</Menu>{' '}
                </Affix>
              }
              trigger="click"
              className="ant-ml-3 animated fadeIn"
            >
              <Badge count={0}>
                <Button shape="circle" icon="bell"></Button>
              </Badge>
            </Popover>
          </div>
          */}
          <div>
            <Button
              shape="circle"
              icon="bell"
              className="ant-ml-3 animated fadeIn"
            >
              <div className="changelogfy-widget"></div>
            </Button>
          </div>
          {(JSON.parse(localStorage.getItem('organization_id')) ===
            'ckz8bu7ha39842437thx6053rgx' ||
            JSON.parse(localStorage.getItem('organization_id')) ===
              '5dbfddaa9e08a50023c9fea3' ||
            JSON.parse(localStorage.getItem('organization_id')) ===
              '5dcd4b4462ebd40019074e4d') && (
            <div>
              <CurrencySwitcher />
            </div>
          )}
          <div>
            <RegionSwitcher />
          </div>
        </Row>
      </Header>
    );
  }
}

export default AppHeader;
