import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Typography,
  Icon,
  Button
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';

const { Meta } = Card;
const { Title, Text } = Typography;

const CONFETTI_CONFIG = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const Demo = props => {
  const [confetti, setConfetti] = useState(false);
  // trigger confetti on first load
  useEffect(() => {
    setConfetti(!confetti);
  }, []);

  return (
    <>
      <div className="">
        <Row type="flex">
          <Col span={24} align="center" className="ant-py-5">
            <img
              height="300"
              src={
                window.env.PUBLIC_URL +
                '/product_illustrations/steps/success.svg'
              }
              className="animated fadeIn"
            ></img>
          </Col>
          <Col span={24} align="center">
            <Title className="ant-mb-3" level={3} strong>
              Congrats, you're ready!
            </Title>
            <Text type="secondary">
              Access a pre-populated dashboard with demo data
            </Text>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Demo;
