import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Menu,
  Icon,
  Switch,
  Row,
  Col,
  Tooltip,
  Skeleton,
  Typography,
  Collapse
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
import Auxiliary from 'util/Auxiliary';
import IntlMessages from '../../util/IntlMessages';
import Settings from './Settings';
import AppSwitcher from './AppSwitcher';
import { removeAllFilters } from '../../components/FilterNew/FilterController';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { omniSearchStateChange } from '../../routes/apps/anomaly-detection/components/Omnisearch/OmniSearchController';
import OnboardingProgress from './OnboardingProgress';

import styled, { css } from 'styled-components';
const height = window.screen.height;
const StyledInputDiv = styled.div`
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  margin: 20px 16px 0px 16px;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #341673;
  color: #b2abc0;
  &:hover {
    color: #f3f5f8;
    box-shadow: none;
  }
`;

const CollapsedInput = styled.div`
  height: 30px;
  width: 30px;
  margin: 20px 16px 0px 25px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #b2abc0;
`;

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;
const { Title, Text } = Typography;

const initialState = {
  collapsed: false,
  isEnabled: false
};
const SidebarContent = props => {
  const [state, setState] = useState(initialState);

  const onCollapse = collapsed => {
    setState({ ...state, collapsed });
    props.changeSideWidth(collapsed);
  };

  const isAnomalDetection = window.env.GET_REACT_APP_NAME === 'cliff';

  const bottomNavs = props.initUserData
    ? props.initUserData.selected_application.bottomNavsSubscribed
    : [];
  if (props.initUserData) {
    var appsNavigation = props.initUserData.selected_application.navsSubscribed;
    var selected_application_name =
      props.initUserData.selected_application.name;
  }

  const { themeType, navStyle, pathname } = props;
  const pathnameSplit = pathname.split('/');
  const selectedKeys = (pathnameSplit.length > 0 && pathnameSplit[3]) || '';
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        breakpoint="lg"
        collapsedWidth={window.screen.width > 850 ? 80 : 0}
        collapsed={state.collapsed}
        // onCollapse={onCollapse}
        className={!state.collapsed && 'ant-w-100'}
        style={{
          height: '100vh',
          left: 0,
          position: 'fixed',
          overflowY: 'auto',
          overflowX: 'hidden'
          // zIndex: 4,
        }}
      >
        <Row>
          <OptimizelyFeature autoUpdate={true} feature="api_integration">
            {isEnabled => (
              <Col>
                {state.collapsed ? (
                  <Settings isEnabled={isEnabled} collapse={true} />
                ) : (
                  <Settings isEnabled={isEnabled} collapse={false} />
                )}
              </Col>
            )}
          </OptimizelyFeature>
        </Row>
        {isAnomalDetection && (
          <Row className="ant-mt-3">
            {!state.collapsed ? (
              <StyledInputDiv onClick={() => props.omniSearchStateChange(true)}>
                <Icon
                  style={{ fontSize: '17px' }}
                  type="search"
                  className="ant-mr-2"
                />
                Find Anything
              </StyledInputDiv>
            ) : (
              <Tooltip placement="rightTop" title="Search Anything">
                <CollapsedInput
                  onClick={() => props.omniSearchStateChange(true)}
                >
                  <Icon style={{ fontSize: '20px' }} type="search" />
                </CollapsedInput>
              </Tooltip>
            )}
          </Row>
        )}
        <div
          className={`ant-w-100 ${isAnomalDetection ? 'ant-pt-3' : 'ant-pt-5'}`}
          type="flex"
          style={{
            height: isAnomalDetection
              ? height < 750
                ? '81%'
                : height > 750 && height < 850
                ? '81%'
                : '85%'
              : '95%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-between'
          }}
          direction="column"
          justify="between"
        >
          <div>
            {/*
                <div className="ant-px-3 ant-mb-3">
                  <Text className="ant-text-white">
                    {selected_application_name}
                  </Text>
                </div>
                */}

            <Menu
              theme="dark"
              mode="vertical"
              defaultOpenKeys={[defaultOpenKeys]}
              defaultSelectedKeys={['dashboard']}
              selectedKeys={[selectedKeys]}
              className="ant-w-100"
            >
              {props.initUserData ? (
                appsNavigation.map(nav =>
                  nav.children ? (
                    <SubMenu
                      key={nav.name}
                      title={
                        <span>
                          <Icon type={nav.icon} />
                          <IntlMessages id={nav.name} />
                        </span>
                      }
                    >
                      {nav.children.map(child => (
                        <Menu.Item key={child.href.slice(1)}>
                          <Link to={child.href}>
                            <Icon type={child.icon} />
                            <IntlMessages id={child.name} />
                          </Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={nav.href.split('/')[3]}>
                      <Link
                        to={nav.href}
                        onClick={() => {
                          props.removeAllFilters();
                        }}
                      >
                        <Icon type={nav.icon} />
                        <IntlMessages id={nav.name} />
                      </Link>
                    </Menu.Item>
                  )
                )
              ) : (
                <>
                  <Menu.Item key="12" className="ant-w-100 ant-px-4">
                    <Skeleton
                      active
                      paragraph={{ rows: 0 }}
                      title={{ width: '100' }}
                    ></Skeleton>
                  </Menu.Item>
                  <Menu.Item key="13" className="ant-w-100 ant-px-4">
                    <Skeleton
                      active
                      paragraph={{ rows: 0 }}
                      title={{ width: '100' }}
                    ></Skeleton>
                  </Menu.Item>
                  <Menu.Item key="14" className="ant-w-100 ant-px-4">
                    <Skeleton
                      active
                      paragraph={{ rows: 0 }}
                      title={{ width: '100' }}
                    ></Skeleton>
                  </Menu.Item>
                </>
              )}
            </Menu>
          </div>
          <div className="ant-text-white">
            <Menu
              theme="dark"
              mode="vertical"
              defaultOpenKeys={[defaultOpenKeys]}
              defaultSelectedKeys={['dashboard']}
              selectedKeys={[selectedKeys]}
              className="ant-w-100"
            >
              {props.initUserData &&
                bottomNavs &&
                bottomNavs.map(nav => (
                  <Menu.Item
                    key={nav.href.split('/')[3]}
                    className="ant-w-100 ant-px-4"
                  >
                    <Link
                      to={nav.href}
                      onClick={() => {
                        props.removeAllFilters();
                      }}
                    >
                      <Icon type={nav.icon} />
                      <IntlMessages id={nav.name} />
                    </Link>
                  </Menu.Item>
                ))}

              {!isAnomalDetection && (
                <Menu.Item style={{ marginLeft: '-10px' }} key="help">
                  <Link to="/settings/organization/support">
                    <Icon type="question-circle" />
                    <IntlMessages id="sidebar.help" />
                  </Link>
                </Menu.Item>
              )}
            </Menu>

            {isAnomalDetection && (
              <Link
                to={{
                  pathname: '/apps/anomaly-detection/getting-started',
                  state: { from: 'sidebar' }
                }}
              >
                <OnboardingProgress isCollapsed={state.collapsed} />
              </Link>
            )}

            <Row>
              <Col>
                {state.collapsed ? (
                  <AppSwitcher collapse={true} />
                ) : (
                  <AppSwitcher collapse={false} />
                )}
              </Col>
            </Row>
            <Row className=" ant-pt-2">
              <Col align="center">
                {state.collapsed ? (
                  <Icon
                    // style = {{backgroundColor:"#210E4A", position:'static'}}
                    onClick={() => {
                      onCollapse(!state.collapsed);
                    }}
                    type="right"
                  />
                ) : (
                  <Icon
                    // style = {{backgroundColor:"#210E4A",  position:'static'}}
                    onClick={() => {
                      onCollapse(!state.collapsed);
                    }}
                    type="left"
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Sider>
    </Layout>
  );
};

const mapStateToProps = ({ settings, auth, filter }) => {
  const { locale, pathname } = settings;
  const { initUserData } = auth;

  return { locale, initUserData, filter };
};

export default React.memo(
  connect(mapStateToProps, {
    omniSearchStateChange,
    removeAllFilters
  })(SidebarContent),
  (prevState, nextState) => prevState.pathname === nextState.pathname
);
