import {
  FETCH_EXPORTEXCEL_START,
  FETCH_EXPORTEXCEL_SUCCESS,
  FETCH_EXPORTEXCEL_ERROR
} from '../../../../constants/ApiActionTypes';

import {
  GET_ALL_JOBS_START,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_ERROR,
  PING_SRP_JOB_SUCCESS,
  PING_SRP_JOB_ERROR
} from '../../../../constants/ActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_EXPORTEXCEL_START: {
      return {
        ...state,
        fetch_exportexcel_success: false,
        fetch_exportexcel_error: false,
        fetch_exportexcel_start: true
      };
    }

    case FETCH_EXPORTEXCEL_SUCCESS: {
      return {
        ...state,
        fetch_exportexcel_success: true,
        fetch_exportexcel_error: false,
        fetch_exportexcel_start: false,
        exportexcelData: action.payload
      };
    }

    case FETCH_EXPORTEXCEL_ERROR: {
      return {
        ...state,
        fetch_exportexcel_success: false,
        fetch_exportexcel_error: true,
        fetch_exportexcel_start: false
      };
    }

    case PING_SRP_JOB_SUCCESS: {
      return {
        ...state,
        ping_srp_job_start: false,
        ping_srp_job_success: true,
        ping_srp_job_error: false,
        pingStatus: action.payload
      };
    }

    case 'PING_SRP_JOB_START': {
      return {
        ...state,
        ping_srp_job_start: true,
        ping_srp_job_success: false,
        ping_srp_job_error: false
      };
    }

    case GET_ALL_JOBS_START: {
      return {
        ...state,
        get_all_jobs_start: true,
        get_all_jobs_success: false,
        get_all_jobs_error: false
      };
    }

    case GET_ALL_JOBS_SUCCESS: {
      return {
        ...state,
        get_all_jobs_start: false,
        get_all_jobs_success: true,
        get_all_jobs_error: false,
        jobsList: action.payload
      };
    }

    case GET_ALL_JOBS_ERROR: {
      return {
        ...state,
        get_all_jobs_start: false,
        get_all_jobs_success: false,
        get_all_jobs_error: true
      };
    }

    case 'GET_EXPORT_JOB_START': {
      return {
        ...state,
        get_export_job_start: true,
        get_export_job_success: false,
        get_export_job_error: false
      };
    }

    case 'GET_EXPORT_JOB_SUCCESS': {
      return {
        ...state,
        get_export_job_start: false,
        get_export_job_success: true,
        get_export_job_error: false,
        jobResults: action.payload.result,
        file_link: action.payload.file_link
      };
    }

    case 'GET_EXPORT_JOB_ERROR': {
      return {
        ...state,
        get_export_job_start: false,
        get_export_job_success: false,
        get_export_job_error: true
      };
    }

    default:
      return state;
  }
};
