import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  USER_INFO_START,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_PASSWORD_RESET_TOKEN,
  FETCH_MEMBERS,
  MEMBERS_DATA,
  MODULES_DATA_START,
  MODULES_DATA_SUCCESS,
  MODULES_DATA_ERROR,
  SUBSCRIBED_MODULES_DATA_START,
  SUBSCRIBED_MODULES_DATA_SUCCESS,
  SUBSCRIBED_MODULES_DATA_ERROR,
  SUBSCRIBE_MODULE_START,
  SUBSCRIBE_MODULE_SUCCESS,
  SUBSCRIBE_MODULE_ERROR,
  UNSUBSCRIBE_MODULE_START,
  UNSUBSCRIBE_MODULE_SUCCESS,
  UNSUBSCRIBE_MODULE_ERROR,
  APPS_DATA_START,
  APPS_DATA_SUCCESS,
  APPS_DATA_ERROR,
  SUBSCRIBE_APP_SUCCESS,
  SUBSCRIBE_APP_ERROR,
  SUBSCRIBE_APP_START,
  UNSUBSCRIBE_APP_SUCCESS,
  UNSUBSCRIBE_APP_ERROR,
  UNSUBSCRIBE_APP_START,
  PROJECTS_DATA,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE,
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_PROFILE_DETAILS_START,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_ERROR,
  DELETE_ORGANIZATION_SUCCES,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
  RESEND_INVITATION_START,
  DELETE_INVITATION_SUCCESS,
  DELETE_INVITATION_ERROR,
  DELETE_INVITATION_START,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_ERROR,
  DELETE_MEMBERSHIP_START,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_ERROR,
  UPDATE_MEMBERSHIP_START,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VALIDATE_PASSWORD_SUCCESS,
  VALIDATE_PASSWORD_ERROR,
  VALIDATE_PASSWORD_START,
  RESET_PASSWORD,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_ERROR,
  ADD_MEMBER,
  INVITATIONS_DATA,
  INIT_USER_DATA,
  FETCH_INIT_START,
  FETCH_INIT_SUCCESS,
  FETCH_INIT_ERROR,
  SESSIONS_DATA,
  LOGS_DATA,
  LOGS_FILTERS_DATA,
  USAGE_AND_PAYMENT_DATA,
  ORGANIZATION_DATA,
  DEFAULT_ORGANIZATION_SUCCESS,
  DEFAULT_ORGANIZATION_ERROR,
  DEFAULT_ORGANIZATION_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_START,
  USER_PREFERENCES_SUCCESS,
  USER_PREFERENCES_ERROR,
  USER_PREFERENCES_START,
  UPDATE_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES_START,
  USER_PREFERENCES_DATA,
  ORGANIZATION_PREFERENCES_SUCCESS,
  ORGANIZATION_PREFERENCES_ERROR,
  ORGANIZATION_PREFERENCES_START,
  ORGANIZATION_PREFERENCES_DATA,
  JOIN_USER_SUCCESS,
  JOIN_USER_ERROR,
  JOIN_USER,
  WEBSITES_DATA_START,
  WEBSITES_DATA_SUCCESS,
  WEBSITES_DATA_ERROR,
  SUBSCRIBE_WEBSITE_START,
  SUBSCRIBE_WEBSITE_SUCCESS,
  SUBSCRIBE_WEBSITE_ERROR,
  SUBSCRIBED_WEBSITES_START,
  SUBSCRIBED_WEBSITES_SUCCESS,
  SUBSCRIBED_WEBSITES_ERROR,
  UNSUBSCRIBE_WEBSITE_START,
  UNSUBSCRIBE_WEBSITE_SUCCESS,
  UNSUBSCRIBE_WEBSITE_ERROR,
  SAVE_FILTER_START,
  SAVE_FILTER_SUCCESS,
  SAVE_FILTER_ERROR,
  UPDATE_SAVED_FILTER_START,
  UPDATE_SAVED_FILTER_SUCCESS,
  UPDATE_SAVED_FILTER_ERROR,
  GET_SAVED_FILTERS_START,
  GET_SAVED_FILTERS_SUCCESS,
  GET_SAVED_FILTERS_ERROR,
  DELETE_SAVED_FILTER_START,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_ERROR,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_START,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_ERROR,
  SIGNUP_USER,
  SIGNIN_USER_FORBIDDEN,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
  SIGNIN_USER,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
  GET_REGIONS_START,
  SELECT_REGION_START,
  SELECT_REGION_SUCCESS,
  SELECT_REGION_ERROR,
  CREATE_ROLE_START,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  UPDATE_ROLE_START,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  DELETE_ROLE_START,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  GET_ALL_ROLE_TASKS_START,
  GET_ALL_ROLE_TASKS_SUCCESS,
  GET_ALL_ROLE_TASKS_ERROR,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_START,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
  CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_START,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
  DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
  SIGNOUT_ALL_START,
  SIGNOUT_ALL_SUCCESS,
  SIGNOUT_ALL_ERROR,
  DISABLE_USER_START,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_ERROR,
  CREATE_API_KEY_START,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_ERROR,
  UPDATE_API_KEY_START,
  UPDATE_API_KEY_SUCCESS,
  UPDATE_API_KEY_ERROR,
  DELETE_API_KEY_START,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_ERROR,
  GET_ALL_API_KEY_START,
  GET_ALL_API_KEY_SUCCESS,
  GET_ALL_API_KEY_ERROR,
  GET_API_KEY_START,
  GET_API_KEY_SUCCESS,
  GET_API_KEY_ERROR,
  EMAIL_VERIFICATON_START,
  EMAIL_VERIFICATON_SUCCESS,
  EMAIL_VERIFICATON_ERROR,
  RESEND_EMAIL_VERIFICATON_START,
  RESEND_EMAIL_VERIFICATON_SUCCESS,
  RESEND_EMAIL_VERIFICATON_ERROR,
  SELECT_CURRENCY_START,
  SELECT_CURRENCY_ERROR,
  SELECT_CURRENCY_SUCCESS,
  SWITCH_ORGANIZATION_ERROR,
  SWITCH_ORGANIZATION_START,
  SWITCH_ORGANIZATION_SUCCESS
} from '../../constants/ActionTypes';
import axios from 'util/config/accounts';
import moment from 'moment-timezone';
import Dexie from 'dexie';

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({
  first_name,
  last_name,
  email,
  password,
  organization_name,
  organization_short_name,
  invitation_code
}) => {
  return dispatch => {
    const timezone = moment.tz.guess(true);
    dispatch({ type: SIGNUP_USER });
    axios
      .post('v2/users/signup', {
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        organization_name: organization_name,
        organization_short_name: organization_short_name,
        invitation_code: invitation_code,
        platform: window.env.GET_REACT_APP_NAME.toLowerCase(),
        timezone: timezone
      })
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('user_id', JSON.stringify(data.user.id));
          // localStorage.setItem(
          //   'organization_id',
          //   JSON.stringify(data.default_organization.id)
          // );
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNUP_USER_SUCCESS, payload: data.user });
          dispatch({
            type: USER_TOKEN_SET,
            payload: JSON.stringify(data.token)
          });
          dispatch({ type: USER_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });

        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch({
            type: SIGNUP_USER_ERROR,
            payload: error.response.data.error
          });
        } else {
          dispatch({
            type: SIGNUP_USER_ERROR,
            payload: 'Sorry something went wrong.'
          });
        }
      });
  };
};

export const userSignIn = ({ email, password, props }) => {
  return dispatch => {
    dispatch({ type: SIGNIN_USER });

    axios
      .post('v2/users/signin', {
        email: email,
        password: password
      })
      .then(({ data, status }) => {
        if (data) {
          localStorage.setItem('user_id', JSON.stringify(data.user.id));
          localStorage.setItem(
            'organization_id',
            JSON.stringify(data.default_organization.id)
          );
          if (data.user.email_verified) {
            axios.defaults.headers.common['access-token'] = data.token;
            localStorage.setItem('token', JSON.stringify(data.token));
          }
          //====================

          let saved_accounts = [];

          saved_accounts = JSON.parse(localStorage.getItem('saved_accounts'));
          if (saved_accounts == null) {
            saved_accounts = [];
            let savedUserItem = {};
            savedUserItem.first_name = data.user.first_name;
            savedUserItem.last_name = data.user.last_name;
            savedUserItem.email = data.user.email;
            saved_accounts.push(savedUserItem);
            localStorage.setItem(
              'saved_accounts',
              JSON.stringify(saved_accounts)
            );
          } else {
            saved_accounts.forEach(function(item) {
              if (item.email == data.user.email) {
                let index = saved_accounts.indexOf(item);
                saved_accounts.splice(index, 1);
              }
            });

            let savedUserItem = {};
            savedUserItem.first_name = data.user.first_name;
            savedUserItem.last_name = data.user.last_name;
            savedUserItem.email = data.user.email;
            saved_accounts.push(savedUserItem);
            localStorage.setItem(
              'saved_accounts',
              JSON.stringify(saved_accounts)
            );
          }

          //==================

          axios.defaults.headers.common['access-token'] = data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNIN_USER_SUCCESS, payload: data });
          getUser();
          dispatch({
            type: USER_TOKEN_SET,
            payload: JSON.stringify(data.token)
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        const response = error && error.response;
        if (response && response.status === 403) {
          dispatch({ type: SIGNIN_USER_FORBIDDEN });
        } else if (response && response.data && response.data.error) {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: error.response.data.error
          });
        } else {
          dispatch({
            type: SIGNIN_USER_ERROR,
            payload: 'Sorry something went wrong.'
          });
        }
      });
  };
};

export const userSignInSSO = ({
  code,
  organization_id,
  invitation_id
}) => dispatch => {
  dispatch({ type: SIGNIN_USER });
  console.log({
    axios,
    code,
    organization_id,
    invitation_id
  });
  axios
    .post('v2/users/authorize/sso', { code, organization_id, invitation_id })
    .then(res => {
      const { data, status } = res;

      // if response was for login
      if (status === 200) {
        localStorage.setItem('user_id', JSON.stringify(data.user.id));
        localStorage.setItem(
          'organization_id',
          JSON.stringify(data.default_organization.id)
        );
        localStorage.setItem('token', JSON.stringify(data.token));

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNIN_USER_SUCCESS, payload: data });

        getUser();

        dispatch({
          type: USER_TOKEN_SET,
          payload: JSON.stringify(data.token)
        });
      } else if (status === 201) {
        // this runs when a signup response is returned
        localStorage.setItem('user_id', JSON.stringify(data.user.id));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNIN_USER_SUCCESS, payload: data });
        dispatch({
          type: USER_TOKEN_SET,
          payload: JSON.stringify(data.token)
        });
        dispatch({ type: USER_DATA, payload: data });
      }
    })
    .catch(err => {
      dispatch({ type: FETCH_ERROR, payload: err.message });
      const response = err && err.response;
      if (response && response.status === 403) {
        dispatch({ type: SIGNIN_USER_FORBIDDEN });
      } else if (response && response.data && response.data.error) {
        dispatch({
          type: SIGNIN_USER_ERROR,
          payload: err.response.data.error
        });
      } else {
        dispatch({
          type: SIGNIN_USER_ERROR,
          payload: 'Sorry something went wrong.'
        });
      }
    });
};

export const userEmailVerification = ({ userId, otp }) => {
  return dispatch => {
    dispatch({ type: EMAIL_VERIFICATON_START });
    axios
      .post(`/v2/users/${userId}/verify-key`, {
        verification_key: otp,
        env: window.env.GET_REACT_APP_NAME === 'cliff' ? 'cliff' : 'greendeck'
      })
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user_id', JSON.stringify(data.user.id));
          localStorage.setItem(
            'organization_id',
            JSON.stringify(data.organization.organization_id)
          );
          dispatch({ type: EMAIL_VERIFICATON_SUCCESS, payload: data });
        } else {
          // console.log("data error", data);
          dispatch({ type: EMAIL_VERIFICATON_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        console.log('error', error.response);
        dispatch({
          type: EMAIL_VERIFICATON_ERROR,
          payload: error.response.data.error
        });
      });
  };
};

export const userResendEmailVerificationCode = userId => {
  return axios.post(`/v2/users/${userId}/resend-verification-key`, {
    env: window.env.GET_REACT_APP_NAME === 'cliff' ? 'cliff' : 'greendeck'
  });
};

export const userForgotPassword = ({ email, password }) => {
  return dispatch => {
    dispatch({ type: FORGOT_PASSWORD });
    axios
      .post('v2/users/forgot_password', {
        email: email,
        env: window.env.GET_REACT_APP_NAME === 'cliff' ? 'cliff' : 'greendeck'
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FORGOT_PASSWORD_SUCCESS });
          dispatch({
            type: USER_PASSWORD_RESET_TOKEN,
            payload: data.reset_password_token
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: FORGOT_PASSWORD_ERROR });
      });
  };
};

export const userResetPassword = (reset_password_param, value) => {
  var qs = require('qs');
  let param = qs.parse(reset_password_param.slice(1));

  return dispatch => {
    dispatch({ type: RESET_PASSWORD });
    axios
      .put('v2/users/reset_password', {
        reset_password_token: param.reset_password_token,
        new_password: value.password
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: RESET_PASSWORD_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: RESET_PASSWORD_ERROR });
      });
  };
};

export const userUpdateProfile = ({
  first_name,
  last_name,
  email,
  secondary_emails,
  default_language,
  avatar
}) => {
  return dispatch => {
    dispatch({ type: UPDATE_PROFILE_DETAILS_START });
    dispatch({ type: UPDATE_PROFILE });
    axios
      .put('v2/users', {
        first_name: first_name,
        last_name: last_name,
        email: email,
        secondary_emails: secondary_emails,
        default_language: default_language,
        avatar: avatar
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: UPDATE_PROFILE_DETAILS_SUCCESS });
          dispatch({ type: UPDATE_PROFILE_SUCCESS });
        } else {
          dispatch({
            type: UPDATE_PROFILE_DETAILS_ERROR,
            payload: 'Network Error'
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: UPDATE_PROFILE_DETAILS_ERROR,
          payload: error.message
        });
        dispatch({ type: UPDATE_PROFILE_ERROR });
      });
  };
};

export const userUpdateOrganization = ({
  organization_name,
  organization_short_name,
  default_role,
  default_language,
  early_adopter,
  open_membership,
  avatar
}) => {
  return dispatch => {
    dispatch({ type: UPDATE_ORGANIZATION_START });
    dispatch({ type: UPDATE_PROFILE });
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')),
        {
          organization_name: organization_name,
          organization_short_name: organization_short_name,
          default_role: default_role,
          default_language: default_language,
          early_adopter: early_adopter,
          open_membership: open_membership,
          avatar: avatar
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: UPDATE_ORGANIZATION_SUCCESS });
          dispatch({ type: UPDATE_PROFILE_SUCCESS });
        } else {
          dispatch({
            type: UPDATE_ORGANIZATION_ERROR,
            payload: 'Network Error'
          });
        }
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_ORGANIZATION_ERROR, payload: error.message });
        dispatch({ type: UPDATE_PROFILE_ERROR });
      });
  };
};

export const createOrganization = ({
  organization_name,
  organization_short_name
}) => {
  return dispatch => {
    const timezone = moment.tz.guess(true);
    dispatch({ type: CREATE_ORGANIZATION_START });
    axios
      .post('v2/organizations/', {
        organization_name: organization_name,
        organization_short_name: organization_short_name,
        platform: window.env.GET_REACT_APP_NAME.toLowerCase(),
        timezone: timezone
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: CREATE_ORGANIZATION_SUCCESS });
        } else {
          dispatch({
            type: CREATE_ORGANIZATION_ERROR,
            payload: 'Network Error'
          });
        }
      })
      .catch(function(error) {
        dispatch({ type: CREATE_ORGANIZATION_ERROR, payload: error.message });
      });
  };
};

export const changePassword = ({ password, new_password }) => {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_START });
    // axios.defaults.headers.common['Authorization'] = JSON.parse(
    //   localStorage.getItem('token')
    // );
    // axios.defaults.headers.common['access-token'] = JSON.parse(
    //   localStorage.getItem('token')
    // );
    axios
      .put('v2/users/change_password', {
        current_password: password,
        new_password: new_password
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: CHANGE_PASSWORD_SUCCESS });
          localStorage.setItem('token', JSON.stringify(data.jwt));
          console.log({
            token: localStorage.getItem('token')
          });
          axios.defaults.headers.common['Authorization'] = JSON.parse(
            localStorage.getItem('token')
          );
          axios.defaults.headers.common['access-token'] = JSON.parse(
            localStorage.getItem('token')
          );
        } else {
          dispatch({ type: CHANGE_PASSWORD_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: error.message });
      });
  };
};

export const validatePassword = (values, organization_ids) => {
  return dispatch => {
    dispatch({ type: VALIDATE_PASSWORD_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post('v2/users/validate_password', {
        password: values.password
      })
      .then(({ data }) => {
        if (data.valid === true) {
          dispatch({ type: VALIDATE_PASSWORD_SUCCESS });
        } else {
          dispatch({ type: VALIDATE_PASSWORD_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: VALIDATE_PASSWORD_ERROR, payload: error.message });
      });
  };
};

export const deleteUser = organizations => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post('v2/users', {
        organizations
      })
      .then(({ status }) => {
        if (status === 200) {
          setTimeout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('organization_id');
            dispatch({ type: SIGNOUT_USER_SUCCESS });
          }, 1000);
        } else {
          dispatch({ type: FETCH_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const disableUser = user_id => dispatch => {
  dispatch({ type: DISABLE_USER_START });
  axios.defaults.headers.common['Authorization'] = JSON.parse(
    localStorage.getItem('token')
  );
  axios
    .post('v2/users/disable_user/' + user_id, {})
    .then(({ status }) => {
      if (status === 200) {
        setTimeout(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('organization_id');
          dispatch({ type: SIGNOUT_USER_SUCCESS });
          dispatch({ type: DISABLE_USER_SUCCESS });
        }, 1000);
      } else {
        dispatch({ type: DISABLE_USER_ERROR, payload: status.error });
      }
    })
    .catch(function(error) {
      dispatch({ type: DISABLE_USER_ERROR, payload: error.message });
    });
};

export const membershipCreateInvitation = ({ env, emails, role, role_id }) => {
  return dispatch => {
    dispatch({ type: ADD_MEMBER });
    axios
      .post(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/invitations',
        {
          emails: emails,
          role: role,
          role_id: role_id,
          env: env
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: ADD_MEMBER_SUCCESS });
        } else {
          dispatch({ type: ADD_MEMBER_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          dispatch({
            type: ADD_MEMBER_ERROR,
            payload: error.response.data.error
          });
        } else {
          dispatch({
            type: ADD_MEMBER_ERROR,
            payload: 'Sorry something went wrong.'
          });
        }
      });
  };
};

export const getProjects = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/projects',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: PROJECTS_DATA, payload: data.projects });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const deleteProject = project_id => {
  return dispatch => {
    dispatch({ type: DELETE_PROJECT_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/projects/' +
          project_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: DELETE_PROJECT_SUCCESS });
        } else {
          dispatch({ type: DELETE_PROJECT_ERROR, payload: status.error });
        }
      })
      .catch(function(status) {
        dispatch({ type: DELETE_PROJECT_ERROR, payload: status.message });
      });
  };
};

export const getSessions = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/users/sessions?limit=20&skip=0', {})
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SESSIONS_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getLogs = (
  filter,
  logType,
  limit = 20,
  skip = 0,
  group_by = false
) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        `v2/organizations/${JSON.parse(
          localStorage.getItem('organization_id')
        )}/audit_logs?limit=${limit}&skip=${skip}&filter=${filter}&log_type=${logType}&group_by=${group_by}`,
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: LOGS_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getLogsFilters = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/audit_logs_filters?' +
          'limit=20&skip=0',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: LOGS_FILTERS_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getUsageAndPayments = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/billing/invoices',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USAGE_AND_PAYMENT_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getMembers = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/members',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: FETCH_MEMBERS });
          dispatch({ type: MEMBERS_DATA, payload: data.users });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getInvitations = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/invitations',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: INVITATIONS_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getAllWebsites = () => {
  return dispatch => {
    dispatch({ type: WEBSITES_DATA_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v1/websites', {})
      .then(({ data }) => {
        if (data) {
          dispatch({ type: WEBSITES_DATA_SUCCESS, payload: data.websites });
        } else {
          dispatch({ type: WEBSITES_DATA_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: WEBSITES_DATA_ERROR, payload: error.message });
      });
  };
};

export const subscribeWebsite = website_id => {
  return dispatch => {
    dispatch({ type: SUBSCRIBE_WEBSITE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/websites/' +
          website_id,
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SUBSCRIBE_WEBSITE_SUCCESS, payload: data });
        } else {
          dispatch({ type: SUBSCRIBE_WEBSITE_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SUBSCRIBE_WEBSITE_ERROR, payload: error.message });
      });
  };
};

export const getSubscribedWebsites = () => {
  return dispatch => {
    dispatch({ type: SUBSCRIBED_WEBSITES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/websites',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: SUBSCRIBED_WEBSITES_SUCCESS,
            payload: { websites: data.websites, competitors: data.competitors }
          });
        } else {
          dispatch({ type: SUBSCRIBED_WEBSITES_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SUBSCRIBE_WEBSITE_ERROR, payload: error.message });
      });
  };
};

export const unSubscribeWebsite = website_id => {
  return dispatch => {
    dispatch({ type: UNSUBSCRIBE_WEBSITE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/websites/' +
          website_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: UNSUBSCRIBE_WEBSITE_SUCCESS });
        } else {
          dispatch({ type: UNSUBSCRIBE_WEBSITE_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UNSUBSCRIBE_WEBSITE_ERROR, payload: error.message });
      });
  };
};

export const getApps = () => {
  return dispatch => {
    dispatch({ type: APPS_DATA_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/apps',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: APPS_DATA_SUCCESS, payload: data.apps });
        } else {
          dispatch({ type: APPS_DATA_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: APPS_DATA_ERROR, payload: error.message });
      });
  };
};

export const subscribeApp = app_id => {
  return dispatch => {
    dispatch({ type: SUBSCRIBE_APP_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/apps/' +
          app_id,
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SUBSCRIBE_APP_SUCCESS });
        } else {
          dispatch({ type: SUBSCRIBE_APP_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SUBSCRIBE_APP_ERROR, payload: error.message });
      });
  };
};

export const unSubscribeApp = app_id => {
  return dispatch => {
    dispatch({ type: UNSUBSCRIBE_APP_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/apps/' +
          app_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: UNSUBSCRIBE_APP_SUCCESS });
        } else {
          dispatch({ type: UNSUBSCRIBE_APP_ERROR, payload: status });
        }
      })
      .catch(function(status) {
        dispatch({ type: UNSUBSCRIBE_APP_ERROR, payload: status });
      });
  };
};

export const updateSelectedApp = (app_id, href) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    dispatch({ type: 'UPDATE_SELECTED_APP_START' });

    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/apps/' +
          app_id +
          '/select'
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: 'UPDATE_SELECTED_APP_SUCCESS' });
          // window.location = href;
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getModules = app_id => {
  return dispatch => {
    dispatch({ type: MODULES_DATA_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/apps/' + app_id + '/app_modules', {})
      .then(({ data }) => {
        if (data) {
          dispatch({ type: MODULES_DATA_SUCCESS, payload: data });
        } else {
          dispatch({ type: MODULES_DATA_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: MODULES_DATA_ERROR, payload: error.message });
      });
  };
};

export const getSubscribedModules = app_subscription_id => {
  return dispatch => {
    dispatch({ type: SUBSCRIBED_MODULES_DATA_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/app_subscriptions/' + app_subscription_id + '/app_modules', {})
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SUBSCRIBED_MODULES_DATA_SUCCESS, payload: data });
        } else {
          dispatch({
            type: SUBSCRIBED_MODULES_DATA_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: SUBSCRIBED_MODULES_DATA_ERROR,
          payload: error.message
        });
      });
  };
};

export const subscribeModule = (app_module_id, app_subscription_id) => {
  return dispatch => {
    dispatch({ type: SUBSCRIBE_MODULE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v2/app_subscriptions/' +
          app_subscription_id +
          '/app_modules/' +
          app_module_id,
        {}
      )
      .then(({ status }) => {
        if (status == 201) {
          dispatch({ type: SUBSCRIBE_MODULE_SUCCESS, payload: status });
        } else {
          dispatch({ type: SUBSCRIBE_MODULE_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SUBSCRIBE_MODULE_ERROR, payload: error.message });
      });
  };
};

export const unSubscribeModule = (app_module_id, app_subscription_id) => {
  return dispatch => {
    dispatch({ type: UNSUBSCRIBE_MODULE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v2/app_subscriptions/' +
          app_subscription_id +
          '/app_modules/' +
          app_module_id,
        {}
      )
      .then(({ status }) => {
        if (status == 204) {
          dispatch({ type: UNSUBSCRIBE_MODULE_SUCCESS, payload: status });
        } else {
          dispatch({ type: UNSUBSCRIBE_MODULE_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UNSUBSCRIBE_MODULE_ERROR, payload: error.message });
      });
  };
};

export const deleteInvitation = invitation_id => {
  return dispatch => {
    dispatch({ type: DELETE_INVITATION_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/invitations/' +
          invitation_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: DELETE_INVITATION_SUCCESS });
        } else {
          dispatch({ type: DELETE_INVITATION_ERROR, payload: status });
        }
      })
      .catch(function(status) {
        dispatch({ type: DELETE_INVITATION_ERROR, payload: status });
      });
  };
};

export const resendInvitation = invitation_id => {
  return dispatch => {
    dispatch({ type: RESEND_INVITATION_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/invitations/' +
          invitation_id,
        {
          env: window.env.GET_REACT_APP_NAME === 'cliff' ? 'cliff' : 'greendeck'
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: RESEND_INVITATION_SUCCESS });
        } else {
          dispatch({ type: RESEND_INVITATION_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: RESEND_INVITATION_ERROR, payload: error.message });
      });
  };
};

export const updateMembership = (role_name, role_id, membership) => {
  return dispatch => {
    dispatch({ type: UPDATE_MEMBERSHIP_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put('v2/memberships', {
        role: role_name,
        role_id: role_id,
        membership: membership
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: UPDATE_MEMBERSHIP_SUCCESS });
        } else {
          dispatch({ type: UPDATE_MEMBERSHIP_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_MEMBERSHIP_ERROR, payload: error.message });
      });
  };
};

export const deleteMembership = data => {
  return dispatch => {
    dispatch({ type: DELETE_MEMBERSHIP_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete('v2/memberships/' + data.organization_id + '/' + data.user_id, {})
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: DELETE_MEMBERSHIP_SUCCESS });
        } else {
          dispatch({ type: DELETE_MEMBERSHIP_ERROR, payload: status.error });
        }
      })
      .catch(function(status) {
        dispatch({ type: DELETE_MEMBERSHIP_ERROR, payload: status.message });
      });
  };
};

export const getOrganization = () => {
  return dispatch => {
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/organizations', {})
      .then(({ data }) => {
        dispatch({
          type: ORGANIZATION_DATA,
          payload: data
        });
      })
      .catch(function(error) {});
  };
};

export const getOneOrganization = () => {
  return dispatch => {
    dispatch({ type: DEFAULT_ORGANIZATION_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')),
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: DEFAULT_ORGANIZATION_SUCCESS,
            payload: data.organization
          });
        } else {
          dispatch({ type: DEFAULT_ORGANIZATION_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: DEFAULT_ORGANIZATION_ERROR, payload: error.message });
      });
  };
};

export const deleteOrganization = values => {
  return dispatch => {
    dispatch({ type: VALIDATE_PASSWORD_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post('v2/users/validate_password', {
        password: values.password
      })
      .then(({ data }) => {
        if (data.valid === true) {
          dispatch({ type: VALIDATE_PASSWORD_SUCCESS });
          axios
            .delete(
              'v2/organizations/' +
                JSON.parse(localStorage.getItem('organization_id')),
              {}
            )
            .then(({ status }) => {
              if (status === 204) {
                dispatch({ type: DELETE_ORGANIZATION_START });
                window.location.reload();

                dispatch({ type: DELETE_ORGANIZATION_SUCCES });
              } else {
                dispatch({
                  type: DELETE_ORGANIZATION_ERROR,
                  payload: status.error
                });
              }
            });
        } else {
          dispatch({ type: VALIDATE_PASSWORD_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: VALIDATE_PASSWORD_ERROR, payload: error.message });
      });
  };
};

export const userJoin = (user_params, values) => {
  var qs = require('qs');
  let param = qs.parse(user_params.slice(1));
  return dispatch => {
    dispatch({ type: JOIN_USER });
    axios
      .post('v2/users/join', {
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        invitation_id: param.invitation_id
      })
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user_id', JSON.stringify(data.user.id));
          localStorage.setItem(
            'organization_id',
            JSON.stringify(data.organization.id)
          );

          axios.defaults.headers.common['access-token'] = data.token;
          dispatch({
            type: JOIN_USER_SUCCESS,
            payload: data.organization_preferences
          });
          dispatch({
            type: USER_TOKEN_SET,
            payload: JSON.stringify(data.token)
          });
          dispatch({ type: USER_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: JOIN_USER_ERROR });
      });
  };
};

export const userAcceptInvitation = (user_params, values) => {
  var qs = require('qs');
  let param = qs.parse(user_params.slice(1));
  return dispatch => {
    dispatch({ type: ACCEPT_INVITATION_START });
    axios
      .post(
        'v2/organizations/' +
          param.organization_id +
          '/invitations/' +
          param.invitation_id +
          '/accept',
        {}
      )
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('token', JSON.stringify(data.token));

          axios.defaults.headers.common['access-token'] = data.token;
          dispatch({
            type: ACCEPT_INVITATION_SUCCESS,
            payload: data.organization_preferences
          });
          dispatch({
            type: USER_TOKEN_SET,
            payload: JSON.stringify(data.token)
          });
        } else {
          dispatch({ type: ACCEPT_INVITATION_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: ACCEPT_INVITATION_ERROR });
      });
  };
};

export const getUserInfo = () => {
  return dispatch => {
    dispatch({ type: USER_INFO_START });
    axios
      .get('v1/utils/myinfo', {})
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: USER_INFO_SUCCESS,
            payload: data
          });
        } else {
          dispatch({ type: USER_INFO_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: USER_INFO_ERROR, payload: error.message });
      });
  };
};

export const initUser = () => {
  return dispatch => {
    dispatch({ type: FETCH_INIT_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/users/init')
      .then(({ data, status }) => {
        if (data) {
          dispatch({ type: FETCH_INIT_SUCCESS, payload: data });
          // dispatch({ type: INIT_USER_DATA, payload: data });
          localStorage.setItem('init_data', JSON.stringify(data));
          localStorage.setItem(
            'organization_id',
            JSON.stringify(data.default_organization.id)
          );

          {
            /*================Apps===========*/
          }
          axios
            .get(
              'v2/organizations/' + data.default_organization.id + '/apps',
              {}
            )
            .then(resp => {
              if (resp) {
                dispatch({ type: APPS_DATA_SUCCESS, payload: resp.data.apps });
              } else {
                dispatch({ type: APPS_DATA_ERROR, payload: resp.data.error });
              }
            })
            .catch(function(error) {
              dispatch({ type: APPS_DATA_ERROR, payload: error.message });
            });

          {
            /*================Apps=========== */
          }
        } else {
          dispatch({ type: FETCH_INIT_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_INIT_ERROR, payload: error.message });
        console.log(error);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('organization_id');
        window.location.reload();
      });
  };
};

export const getOrganizationPreferences = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/preferences',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: ORGANIZATION_PREFERENCES_DATA, payload: data });
          dispatch({ type: ORGANIZATION_PREFERENCES_SUCCESS });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
      });
  };
};

export const updateOrganizationExportPreferences = data_export_preferences => {
  return dispatch => {
    dispatch({ type: ORGANIZATION_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/preferences',
        { data_export_preferences }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: ORGANIZATION_PREFERENCES_SUCCESS });
          dispatch({ type: ORGANIZATION_PREFERENCES_DATA, payload: data });
        } else {
          dispatch({
            type: ORGANIZATION_PREFERENCES_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ORGANIZATION_PREFERENCES_ERROR,
          payload: error.message
        });
      });
  };
};

export const updateOrganizationDataPreferences = data_preferences => {
  return dispatch => {
    dispatch({ type: ORGANIZATION_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/preferences',
        { data_preferences }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: ORGANIZATION_PREFERENCES_SUCCESS });
          dispatch({ type: ORGANIZATION_PREFERENCES_DATA, payload: data });
        } else {
          dispatch({
            type: ORGANIZATION_PREFERENCES_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ORGANIZATION_PREFERENCES_ERROR,
          payload: error.message
        });
      });
  };
};

export const updateOrganizationPreferences = legal_preferences => {
  return dispatch => {
    dispatch({ type: ORGANIZATION_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/preferences',
        { legal_preferences }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: ORGANIZATION_PREFERENCES_SUCCESS });
          dispatch({ type: ORGANIZATION_PREFERENCES_DATA, payload: data });
        } else {
          dispatch({
            type: ORGANIZATION_PREFERENCES_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ORGANIZATION_PREFERENCES_ERROR,
          payload: error.message
        });
      });
  };
};

export const updateOrganizationPreferencesGeneric = preferences => {
  return dispatch => {
    dispatch({ type: ORGANIZATION_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/preferences',
        { ...preferences }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: ORGANIZATION_PREFERENCES_SUCCESS });
          dispatch({ type: ORGANIZATION_PREFERENCES_DATA, payload: data });
        } else {
          dispatch({
            type: ORGANIZATION_PREFERENCES_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ORGANIZATION_PREFERENCES_ERROR,
          payload: error.message
        });
      });
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPreferences = () => {
  return dispatch => {
    dispatch({ type: USER_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/users/preferences')
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: USER_PREFERENCES_SUCCESS,
            payload: data.user_preference
          });
        } else {
          dispatch({ type: USER_PREFERENCES_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: USER_PREFERENCES_ERROR, payload: error.message });
      });
  };
};

export const updatePreferences = preferences => {
  return dispatch => {
    dispatch({ type: UPDATE_USER_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );

    axios
      .put('v2/users/preferences', { ...preferences })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: UPDATE_USER_PREFERENCES_SUCCESS });
        } else {
          dispatch({
            type: UPDATE_USER_PREFERENCES_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: UPDATE_USER_PREFERENCES_ERROR,
          payload: error.message
        });
      });
  };
};

export const updateOnboardingPreferences = (object, callback) => {
  return dispatch => {
    dispatch({ type: USER_PREFERENCES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );

    axios
      .put('v2/users/preferences', object)
      .then(({ data }) => {
        if (data) {
          // dispatch({ type: USER_PREFERENCES_SUCCESS });
          dispatch({
            type: USER_PREFERENCES_SUCCESS,
            payload: data.user_preference
          });
          callback();
        } else {
          dispatch({ type: USER_PREFERENCES_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: USER_PREFERENCES_ERROR, payload: error.message });
      });
  };
};

export const updateNotificationPreferences = notification_preferences => {
  return dispatch => {
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );

    axios
      .put('v2/users/preferences', {
        notification_preferences
      })
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: USER_PREFERENCES_DATA,
            payload: data.user_preference
          });
        } else {
          dispatch({ type: USER_PREFERENCES_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: USER_PREFERENCES_ERROR, payload: error.message });
      });
  };
};

export const getUser = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get('v2/users')
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const updateDefaultOrganization = organization_id => {
  return dispatch => {
    dispatch({ type: SWITCH_ORGANIZATION_START });

    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put('v2/organizations/' + organization_id + '/make_default')
      .then(({ data }) => {
        if (data) {
          localStorage.setItem(
            'organization_id',
            JSON.stringify(organization_id)
          );
          dispatch({ type: SWITCH_ORGANIZATION_SUCCESS });
          // window.location.href = process.env.REACT_APP_BASENAME;
        } else {
          dispatch({ type: SWITCH_ORGANIZATION_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SWITCH_ORGANIZATION_ERROR, payload: error.message });
      });
  };
};

export const saveFilter = filterObject => {
  return dispatch => {
    dispatch({ type: SAVE_FILTER_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/saved/filters',
        filterObject
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SAVE_FILTER_SUCCESS, payload: data });
        } else {
          dispatch({ type: SAVE_FILTER_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SAVE_FILTER_ERROR, payload: error.message });
      });
  };
};

export const getSavedFilters = () => {
  return dispatch => {
    dispatch({ type: GET_SAVED_FILTERS_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/saved/filters',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_SAVED_FILTERS_SUCCESS,
            payload: data.saved_filters
          });
        } else {
          dispatch({ type: GET_SAVED_FILTERS_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('organization_id');
          dispatch({ type: SIGNOUT_USER_SUCCESS });
        }
        dispatch({ type: GET_SAVED_FILTERS_ERROR, payload: error.message });
      });
  };
};

export const updateSavedFilter = (filter_id, filterObj, name) => {
  return dispatch => {
    dispatch({ type: UPDATE_SAVED_FILTER_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/saved/filters/' +
          filter_id,
        {
          filter: filterObj,
          name
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: UPDATE_SAVED_FILTER_SUCCESS,
            payload: data.saved_filters
          });
        } else {
          dispatch({ type: UPDATE_SAVED_FILTER_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_SAVED_FILTER_ERROR, payload: error.message });
      });
  };
};

export const deleteSavedFilter = filter_id => {
  return dispatch => {
    dispatch({ type: DELETE_SAVED_FILTER_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/saved/filters/' +
          filter_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: DELETE_SAVED_FILTER_SUCCESS, payload: status });
        } else {
          dispatch({ type: DELETE_SAVED_FILTER_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: DELETE_SAVED_FILTER_ERROR, payload: error.message });
      });
  };
};

//======================= ROLE SSTART========

export const createRole = roleObject => {
  return dispatch => {
    dispatch({ type: CREATE_ROLE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/roles',
        roleObject
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: CREATE_ROLE_SUCCESS, payload: data });
        } else {
          dispatch({ type: CREATE_ROLE_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: CREATE_ROLE_ERROR, payload: error.message });
      });
  };
};

export const getRoles = () => {
  return dispatch => {
    dispatch({ type: GET_ROLES_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/roles',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_ROLES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({ type: GET_ROLES_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_ROLES_ERROR, payload: error.message });
      });
  };
};

export const updateRole = (role_id, filterObj, name) => {
  return dispatch => {
    dispatch({ type: UPDATE_ROLE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(
        'v1/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/roles/' +
          role_id,
        {
          filter: filterObj,
          name
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: UPDATE_ROLE_SUCCESS,
            payload: data.saved_filters
          });
        } else {
          dispatch({ type: UPDATE_ROLE_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_ROLE_ERROR, payload: error.message });
      });
  };
};

export const deleteRole = (replacement_role, selected_role) => {
  return dispatch => {
    dispatch({ type: DELETE_ROLE_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/roles/' +
          selected_role +
          '/' +
          replacement_role,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: DELETE_ROLE_SUCCESS, payload: status });
        } else {
          dispatch({ type: DELETE_ROLE_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: DELETE_ROLE_ERROR, payload: error.message });
      });
  };
};

//=======ROLES TASK SUBSCRIPTION STARTS HERE =======

export const createRoleTakeSubscription = roleTaskSubsciptionObject => {
  return dispatch => {
    dispatch({ type: CREATE_ROLE_TASK_SUBSCRIPTIONS_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/role_task_subscriptions/create',
        roleTaskSubsciptionObject
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: CREATE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: CREATE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
          payload: error.message
        });
      });
  };
};

export const getAllRoleTasks = () => {
  return dispatch => {
    dispatch({ type: GET_ALL_ROLE_TASKS_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/role_tasks',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({
            type: GET_ALL_ROLE_TASKS_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: GET_ALL_ROLE_TASKS_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: GET_ALL_ROLE_TASKS_ERROR,
          payload: error.message
        });
      });
  };
};

export const deleteRoleTaskSubscription = role_task_subscription_object => {
  return dispatch => {
    dispatch({ type: DELETE_ROLE_TASK_SUBSCRIPTIONS_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        'v2/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/role_task_subscriptions/delete/' +
          role_task_subscription_object.role_id +
          '/' +
          role_task_subscription_object.role_task_id,
        {}
      )
      .then(({ status }) => {
        if (status === 204) {
          dispatch({
            type: DELETE_ROLE_TASK_SUBSCRIPTIONS_SUCCESS,
            payload: status
          });
        } else {
          dispatch({
            type: DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
            payload: status.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: DELETE_ROLE_TASK_SUBSCRIPTIONS_ERROR,
          payload: error.message
        });
      });
  };
};

//=======ROLE TASK SUBSCRIPTION ENDS HERE ==========

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete('v2/users/signout')
      .then(({ status }) => {
        if (status === 204) {
          dispatch({ type: FETCH_SUCCESS });

          setTimeout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('recentSelectedStreams');
            localStorage.removeItem('user_id');
            localStorage.removeItem('organization_id');
            localStorage.removeItem('init_data');
            Dexie.delete('FiltersIndexDB');

            dispatch({ type: SIGNOUT_USER_SUCCESS });
          }, 1000);
        } else {
          dispatch({ type: FETCH_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const signOutAllDevices = () => {
  // sign out of all devices except the one from which this request was made
  return dispatch => {
    dispatch({ type: SIGNOUT_ALL_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post('v2/users/signout_all_devices')
      .then(({ data, status }) => {
        if (status === 201) {
          dispatch({ type: SIGNOUT_ALL_SUCCESS });

          setTimeout(() => {
            localStorage.setItem('token', JSON.stringify(data.jwt));
            Dexie.delete('FiltersIndexDB');

            dispatch({ type: SIGNOUT_ALL_SUCCESS });
          }, 1000);
        } else {
          dispatch({ type: SIGNOUT_ALL_ERROR, payload: status.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SIGNOUT_ALL_ERROR, payload: error.message });
      });
  };
};

export const getRegionsList = () => {
  return dispatch => {
    dispatch({ type: GET_REGIONS_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/regions',
        {}
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: GET_REGIONS_SUCCESS, payload: data });
        } else {
          dispatch({ type: GET_REGIONS_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_REGIONS_ERROR, payload: error.message });
      });
  };
};

export const selectRegion = regionObject => {
  return dispatch => {
    dispatch({ type: SELECT_REGION_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        'v1/users/organizations/' +
          JSON.parse(localStorage.getItem('organization_id')) +
          '/regions',
        { region: regionObject.code }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SELECT_REGION_SUCCESS, payload: data });
          window.location.reload();
        } else {
          dispatch({ type: SELECT_REGION_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: SELECT_REGION_ERROR, payload: error.message });
      });
  };
};

export const createApiKey = data => {
  return dispatch => {
    dispatch({ type: CREATE_API_KEY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        `v1/api_key/organization/${JSON.parse(
          localStorage.getItem('organization_id')
        )}/user/${JSON.parse(localStorage.getItem('user_id'))}`,
        {
          ...data
        }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: CREATE_API_KEY_SUCCESS, payload: data.api_key });
        } else {
          dispatch({ type: CREATE_API_KEY_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: CREATE_API_KEY_ERROR, payload: error.message });
      });
  };
};

export const updateApiKey = data => {
  return dispatch => {
    dispatch({ type: UPDATE_API_KEY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .put(`v1/api_key/user/${JSON.parse(localStorage.getItem('user_id'))}`, {
        ...data
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: UPDATE_API_KEY_SUCCESS, payload: data });
        } else {
          dispatch({ type: UPDATE_API_KEY_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: UPDATE_API_KEY_ERROR, payload: error.message });
      });
  };
};

export const deleteApiKey = key => {
  return dispatch => {
    dispatch({ type: DELETE_API_KEY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .delete(
        `v1/api_key/user/${JSON.parse(
          localStorage.getItem('user_id')
        )}/apiKey/${key}`
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: DELETE_API_KEY_SUCCESS, payload: data });
        } else {
          dispatch({ type: DELETE_API_KEY_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: DELETE_API_KEY_ERROR, payload: error.message });
      });
  };
};

export const getAllApiKeys = () => {
  return dispatch => {
    dispatch({ type: GET_ALL_API_KEY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .get(
        `v1/api_key/organization/${JSON.parse(
          localStorage.getItem('organization_id')
        )}/user/${JSON.parse(localStorage.getItem('user_id'))}`
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: GET_ALL_API_KEY_SUCCESS, payload: data.api_keys });
        } else {
          dispatch({ type: GET_ALL_API_KEY_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_ALL_API_KEY_ERROR, payload: error.message });
      });
  };
};

export const getApiKey = key => {
  return dispatch => {
    dispatch({ type: GET_API_KEY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        `v1/api_key/user/${JSON.parse(
          localStorage.getItem('user_id')
        )}/apiKey/${key}`
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: GET_API_KEY_SUCCESS, payload: data });
        } else {
          dispatch({ type: GET_API_KEY_SUCCESS, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: GET_API_KEY_SUCCESS, payload: error.message });
      });
  };
};

export const clearApiSuccess = () => dispatch => {
  dispatch({ type: 'CLEAR_API_SUCCESS' });
};

export const createRestApiKey = data => {
  axios.defaults.headers.common['Authorization'] = JSON.parse(
    localStorage.getItem('token')
  );
  return axios.post(
    `v1/api_key/organization/${JSON.parse(
      localStorage.getItem('organization_id')
    )}/user/${JSON.parse(localStorage.getItem('user_id'))}`,
    {
      ...data
    }
  );
};

export const selectCurrency = currency => {
  return dispatch => {
    dispatch({ type: SELECT_CURRENCY_START });
    axios.defaults.headers.common['Authorization'] = JSON.parse(
      localStorage.getItem('token')
    );
    axios
      .post(
        `v2//users/organizations/${JSON.parse(
          localStorage.getItem('organization_id')
        )}/currencies`,
        { currency: currency.code }
      )
      .then(({ data }) => {
        if (data) {
          dispatch({ type: SELECT_CURRENCY_SUCCESS, payload: data });
          window.location.reload();
        } else {
          dispatch({ type: SELECT_CURRENCY_ERROR, payload: data.error });
        }
      })
      .catch(error => {
        dispatch({ type: SELECT_CURRENCY_ERROR, payload: error.message });
      });
  };
};
