import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { defineWindowSetterGetter } from '../secureEnv';

import { userSignInSSO } from '../appRedux/actions/Auth';
import { loadURLFromLocal } from 'util/indexDB/urlCRUD';

const KeycloakRedirect = props => {
  const [state, setState] = useState({
    generateEnv: false
  });

  const environmentVariables = window.env;

  console.info({
    environmentVariables,
    state
  });

  useLayoutEffect(() => {
    if (environmentVariables) {
      defineWindowSetterGetter();

      setState({
        ...state,
        generateEnv: true
      });
    }
  }, [environmentVariables]);

  useEffect(() => {
    if (state.generateEnv) {
      const urlParams = new URLSearchParams(props.location.search);
      const code = urlParams.get('code');
      let ssoParams = {
        code
      };

      const state = JSON.parse(urlParams.get('state'));

      console.info({
        state,
        message: 'state from sso'
      });

      if (state) {
        const { organization_id, invitation_id } = state;
        ssoParams = {
          ...ssoParams,
          organization_id,
          invitation_id
        };
      }
      props.userSignInSSO(ssoParams);
    }
  }, [state.generateEnv]);

  useEffect(() => {
    if (state.generateEnv && props.signin_success === true) {
      const user_id = JSON.parse(localStorage.getItem('user_id'));
      const organization_id = JSON.parse(
        localStorage.getItem('organization_id')
      );

      props.history.push('/dashboard');
    }
  }, [props.signin_success, state.generateEnv]);

  useEffect(() => {
    if (state.generateEnv && props.signin_error) {
      props.history.push('/signin');
    }
  }, [props.signin_error, state.generateEnv]);

  // console.info({
  //   env: window.env,

  // });

  return <p>Loading...</p>;
};

const mapStateToProps = ({ auth }) => {
  return {
    signin_error: auth.signin_error,
    signin_start: auth.signin_start,
    signin_success: auth.signin_success
  };
};

export default connect(mapStateToProps, { userSignInSSO })(KeycloakRedirect);
