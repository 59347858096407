import antdSA from '@greendeck/atomic/lib/locale-provider/de_DE';
import appLocaleData from 'react-intl/locale-data/de';
import saMessages from '../locales/de_DE.json';

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdSA,
  locale: 'de-DE',
  data: appLocaleData
};
export default saLang;
