const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'UPLOAD_TEXT_START': {
      return {
        ...state,
        upload_text_start: true,
        upload_text_success: false,
        upload_text_error: false
      };
    }

    case 'UPLOAD_TEXT_SUCCESS': {
      return {
        ...state,
        upload_text_start: false,
        upload_text_success: true,
        upload_text_error: false,
        jobId: action.jobId
      };
    }

    case 'UPLOAD_TEXT_ERROR': {
      return {
        ...state,
        upload_text_start: false,
        upload_text_success: false,
        upload_text_error: true
      };
    }

    default:
      return state;
  }
};
