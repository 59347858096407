import {
  GET_CLASSIFICATION_TREE_START,
  GET_CLASSIFICATION_TREE_SUCCESS,
  GET_CLASSIFICATION_TREE_ERROR,
  GET_CLASSIFICATION_TREE_START2,
  GET_CLASSIFICATION_TREE_SUCCESS2,
  GET_CLASSIFICATION_TREE_ERROR2
} from '../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSIFICATION_TREE_START: {
      return {
        ...state,
        classification_tree_success: false,
        classification_tree_error: false,
        classification_tree_start: true
      };
    }

    case GET_CLASSIFICATION_TREE_SUCCESS: {
      return {
        ...state,
        classification_tree_success: true,
        classification_tree_error: false,
        classification_tree_start: false,
        classificationTreeData: action.payload
      };
    }

    case GET_CLASSIFICATION_TREE_ERROR: {
      return {
        ...state,
        classification_tree_success: false,
        classification_tree_error: true,
        classification_tree_start: false
      };
    }

    case GET_CLASSIFICATION_TREE_START2: {
      return {
        ...state,
        classification_tree_success: false,
        classification_tree_error: false,
        classification_tree_start: true
      };
    }

    case GET_CLASSIFICATION_TREE_SUCCESS2: {
      const tree = action.payload;
      return {
        ...state,
        classification_tree_success: true,
        classification_tree_error: false,
        classification_tree_start: false,
        classificationTreeData2: action.payload
      };
    }

    case GET_CLASSIFICATION_TREE_ERROR2: {
      return {
        ...state,
        classification_tree_success: false,
        classification_tree_error: true,
        classification_tree_start: false
      };
    }

    default:
      return state;
  }
};
