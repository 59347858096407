const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'FETCH_LEAFLETS_START': {
      return {
        ...state,
        fetch_leaflets_success: false,
        fetch_leaflets_error: false,
        fetch_leaflets_start: true
      };
    }

    case 'FETCH_LEAFLETS_SUCCESS': {
      return {
        ...state,
        fetch_leaflets_success: true,
        fetch_leaflets_error: false,
        fetch_leaflets_start: false,
        leafletsData: action.payload
      };
    }

    case 'FETCH_LEAFLETS_ERROR': {
      return {
        ...state,
        fetch_leaflets_success: false,
        fetch_leaflets_error: true,
        fetch_leaflets_start: false
      };
    }

    default:
      return state;
  }
};
