import {
  FETCH_NEWINS_START,
  FETCH_NEWINS_SUCCESS,
  FETCH_NEWINS_ERROR,
  FETCH_NEWINS_HISTORY_START,
  FETCH_NEWINS_HISTORY_SUCCESS,
  FETCH_NEWINS_HISTORY_ERROR,
  EXPORT_NEWINS_DATA_ERROR,
  EXPORT_NEWINS_DATA_SUCCESS,
  EXPORT_NEWINS_DATA_START
} from '../../../../constants/ApiActionTypes';

const INIT_STATE = {
  initURL: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_NEWINS_START: {
      return {
        ...state,
        fetch_newins_success: false,
        fetch_newins_error: false,
        fetch_newins_start: true
      };
    }

    case FETCH_NEWINS_SUCCESS: {
      return {
        ...state,
        fetch_newins_success: true,
        fetch_newins_error: false,
        fetch_newins_start: false,
        newinsData: action.payload
      };
    }

    case FETCH_NEWINS_ERROR: {
      return {
        ...state,
        fetch_newins_success: false,
        fetch_newins_error: true,
        fetch_newins_start: false
      };
    }

    // newins history
    case FETCH_NEWINS_HISTORY_START: {
      return {
        ...state,
        fetch_newins_history_success: false,
        fetch_newins_history_error: false,
        fetch_newins_history_start: true
      };
    }

    case FETCH_NEWINS_HISTORY_SUCCESS: {
      // remove the first element from the historical data
      action.payload.hits.forEach((hit, i) => {
        hit.newin_history.shift();
      });

      // cast the date from sting to Date
      action.payload.hits = action.payload.hits.map(hit => ({
        ...hit,
        newin_history: hit.newin_history.map(history => ({
          ...history,
          timestamp: new Date(history.timestamp)
        }))
      }));

      return {
        ...state,
        fetch_newins_history_success: true,
        fetch_newins_history_error: false,
        fetch_newins_history_start: false,
        newinsHistoryData: action.payload
      };
    }

    case FETCH_NEWINS_HISTORY_ERROR: {
      return {
        ...state,
        fetch_newins_history_success: false,
        fetch_newins_history_error: true,
        fetch_newins_history_start: false
      };
    }

    case EXPORT_NEWINS_DATA_START: {
      return {
        ...state,
        export_newins_data_error: false,
        export_newins_data_start: true,
        export_newins_data_success: false
      };
    }
    case EXPORT_NEWINS_DATA_SUCCESS: {
      return {
        ...state,
        export_newins_data_error: false,
        export_newins_data_start: false,
        export_newins_data_success: true,
        exportData: action.payload
      };
    }
    case EXPORT_NEWINS_DATA_ERROR: {
      return {
        ...state,
        export_newins_data_error: true,
        export_newins_data_start: false,
        export_newins_data_success: false
      };
    }

    default:
      return state;
  }
};
