import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Alert
} from '@greendeck/atomic';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userJoin } from '../appRedux/actions/Auth';
import { Redirect } from 'react-router-dom';
import AuthLayout from '../components/Layouts/AuthLayout';
var url_params = '';

const { Title, Paragraph } = Typography;
class Join extends Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      console.log('values', values);
      if (!err) {
        this.props.userJoin(url_params, values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      console.log('JOIN CHECKK');
      console.log(this.props);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    if (!this.props.join_success) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('organization_id');
      localStorage.removeItem('init_data');
    }

    url_params = this.props.location.search;

    if (
      window.env.GET_REACT_APP_NAME === 'cliff' &&
      this.props.join_success &&
      this.props.token
    ) {
      if (this.props.organization_preferences.onboarded) {
        return <Redirect to="/apps/anomaly-detection/getting-started" />;
      } else {
        return <Redirect to="/onboarding" />;
      }
    }
    if (
      window.env.GET_REACT_APP_NAME !== 'cliff' &&
      this.props.join_success &&
      this.props.token
    ) {
      return <Redirect to="/dashboard" />;
    }

    // if (this.props.token) return <Redirect to="/dashboard" />;
    return (
      <AuthLayout>
        <div align="center">
          <Title level={2} className="ant-font-heavy">
            Welcome !
          </Title>
          <Paragraph>Join Greendeck below</Paragraph>
        </div>

        {this.props.join_error ? (
          <Alert
            className="ant-mt-5"
            message="Sorry your joining token has been expired. Please try again."
            type="error"
            banner
          />
        ) : null}

        <div align="left">
          <Form
            onSubmit={this.handleSubmit}
            layout="vertical"
            className="ant-mt-5"
          >
            <Form.Item
              label={<small className="ant-text-muted">YOUR FIRST NAME</small>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'Enter your First Name' }]
              })(<Input size="large" placeholder="First Name" />)}
            </Form.Item>
            <Form.Item
              label={<small className="ant-text-muted">YOUR LAST NAME</small>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Enter your Last Name' }]
              })(<Input size="large" placeholder=" Last Name" />)}
            </Form.Item>
            <Form.Item
              label={<small className="ant-text-muted">YOUR PASSWORD</small>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
            >
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your Password!' }
                ]
              })(<Input size="large" type="password" placeholder="Password" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true
              })(<Checkbox>I Accept Terms & Conditions</Checkbox>)}
            </Form.Item>
            <Form.Item>
              <Row type="flex">
                <Col span={12} align="left" className="ant-px-3">
                  <Button size="large" className="ant-w-100" type="link">
                    <Link to="/signin">Already a Member ?</Link>
                  </Button>
                </Col>
                <Col span={12} align="right">
                  <Button
                    type="primary"
                    className="ant-w-100"
                    size="large"
                    htmlType="submit"
                    loading={this.props.join_start ? true : false}
                  >
                    Join Now
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </AuthLayout>
    );
  }
}

const WrappedSignUpForm = Form.create()(Join);

const mapStateToProps = ({ auth }) => {
  const {
    token,
    join_error,
    join_start,
    join_success,
    organization_preferences
  } = auth;
  return {
    token,
    join_error,
    join_start,
    join_success,
    organization_preferences
  };
};

export default connect(mapStateToProps, { userJoin })(WrappedSignUpForm);
