import React from 'react';
import { Spin, Icon, Typography } from '@greendeck/atomic';

const { Text } = Typography;

class Spinner extends React.Component {
  render() {
    const spinnerIcon = (
      <Icon
        type="loading"
        style={{ fontSize: this.props.spinnerSize || 40 }}
        spin
      />
    );

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: this.props.marginTop || '15rem',
          marginBottom: this.props.marginBottom || '15rem'
        }}
      >
        <Spin indicator={spinnerIcon} />
        <br />
        {this.props.loadingText && (
          <Text style={{ marginTop: '5rem', marginLeft: '-9rem' }}>
            Loading... this might take a few seconds
          </Text>
        )}
      </div>
    );
  }
}

export default Spinner;
